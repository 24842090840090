import { callExternalApi } from "../../services/external-api.service";

class AdvisorHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = `${apiServerUrl}/v2`;
    }

    async getSelf(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data.data || null,
            error,
        };
    }

    async getViewpointsForSelf(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/viewpoints`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data.data || null,
            error,
        };
    }
    
    async getScheduler(projectTag: string, type: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/scheduler/${type}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const response = await callExternalApi({ config });

        return {
            data: response.data?.data || null,
            error: response.error,
        };
    }

    async getCalendlyMeetingDetails(projectTag: string, inviteeId: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/calendlyMeetingDetails/${inviteeId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getAdvisorMeetings(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/meetings`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getAdvisorMeeting(projectTag: string, meetingId: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/meeting/${meetingId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async advisorCreateMeeting(projectTag: string, meetingData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/meeting`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                ...meetingData
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async advisorRateMeeting(projectTag: string, meetingUuid: string, rating: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/meeting/${meetingUuid}/rate`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                rating: rating
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getAdvisorSuggestions(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/suggestions`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }   
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async addAdvisorSuggestion(projectTag: string, suggestionData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisor/suggestions`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                ...suggestionData
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getActiveAdvisors(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisors/active`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getRecentAdvisors(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisors/recent`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }

    async getInvestorAdvisors(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}/advisors/investor`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }   
}

export default AdvisorHttpClient;