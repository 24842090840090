import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAdvisor } from "./core/services/gutcheck-api.service";
import Loading from "./components/Loading";

type TAuth = {
    children: any
}

export const Auth0ProviderWithNavigate = ({ children }: TAuth) => {
    const navigate = useNavigate();
    const [advisorEmail, setAdvisorEmail] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = document.location.origin;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const cookieDomain = process.env.REACT_APP_AUTH0_COOKIE_DOMAIN;

    const onRedirectCallback = (appState: any) => {
        return navigate(appState?.returnTo || window.location.pathname);
    };

    // Extract URL parameters for Auth0 configuration
    const getUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const theme = urlParams.get('theme');
        const loginHint = urlParams.get('emh');
        const advisorPath = window.location.pathname.includes("/advisor");

        return {
            theme: (theme === 'advisor' || advisorPath || loginHint) ? 'advisor' : 'default',
            loginHint: loginHint || undefined
        };
    };

    const { theme, loginHint } = getUrlParams();

    // Check if we have a login hint (emh) and try to get advisor information
    useEffect(() => {
        const fetchAdvisorInfo = async () => {
            if (loginHint) {
                try {
                    const { data, error } = await getAdvisor(loginHint);
                    if (data && data.advisor) {
                        const email = data.advisor.user?.email_address || data.advisor.email_address;
                        if (email) {
                            setAdvisorEmail(email);
                            localStorage.setItem("advisorEmail", email);
                        }
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.error("Error fetching advisor:", error);
                    setIsLoading(false);
                }
            }
        };
        
        fetchAdvisorInfo();
    }, [loginHint]);

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    if (loginHint && isLoading) {
        return <Loading />;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            cookieDomain={cookieDomain}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
                "ext-theme": theme,
                ...(advisorEmail ? { login_hint: advisorEmail } : {})
            }}
            onRedirectCallback={onRedirectCallback}
            //useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};
