import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { ITouchpoint } from "../../../../types";
import dayjs from "dayjs";

const SectionHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    "& span": {
        fontSize: "1rem",
        marginLeft: "1rem",
        color: "#000000",
        backgroundColor: "#c9c9c9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        width: "25px",
        height: "25px",
        borderRadius: "50%"
    }
}));

const typeLabel: { [key: string]: string } = {
    default: "Default",
    auto: "Auto",
    manual: "Manual"
}

const statusLabel: { [key: string]: string } = {
    scheduled: "Scheduled",
    active: "Running",
    completed: "Completed",
    draft: "Draft"
}

export const NurtureList = ({ section, touchpoints }: { section: string, touchpoints: ITouchpoint[] }) => {

    const formatDate = (date: string) => {
        return dayjs(date).utc().format("MMM DD, YYYY");
    }

    return (
        <Box mb={10}>
            {touchpoints && 
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Feedback Loop</TableCell>
                        <TableCell className="center">Type</TableCell>
                        <TableCell className="center">Status</TableCell>
                        <TableCell className="center">Start Date</TableCell>
                        <TableCell className="center">End Date</TableCell>
                        <TableCell className="center"># Participants</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {touchpoints.map((touchpoint: any) => (
                        <TableRow key={touchpoint.uuid}>
                            <TableCell>
                                { touchpoint.status === "active" || touchpoint.status === "completed" ?
                                    <Link to={`${touchpoint.uuid}`} style={{ fontSize: "1rem"}}>{touchpoint.name}</Link>
                                :
                                    <span>{touchpoint.name}</span>
                                }
                            </TableCell>
                            <TableCell className="center">{typeLabel[touchpoint.type]}</TableCell>
                            <TableCell className="center">{statusLabel[touchpoint.status]}</TableCell>
                            <TableCell className="center">{formatDate(touchpoint.started_at)}</TableCell>
                            <TableCell className="center">{touchpoint.status === "completed" ? formatDate(touchpoint.completed_at) : "---"}</TableCell>
                            <TableCell className="center">{touchpoint.viewpoints.length || "---"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            }
        </Box>
    )
}