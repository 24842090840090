import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, TextField, Button, Dialog, Typography } from "@mui/material";
import { IProfile, ICompany } from "../../../../types";
import ClientProvider from "../../../../modules/ClientProvider";
import StyledDropzone from "../../../../components/AssetUpload";
import { getCompanyByDomain, updateCompany, createCompany, updateProfile, refreshCompany } from "../../services/gutcheck-admin-api.service";
import { uploadLogo } from "../../../../core/services/gutcheck-api.service";
import { CompanyLogo } from "../../../../components/CompanyLogo";



export const CompanyForm = ({ company, setCompany, open, onClose }: { company: ICompany, setCompany: (company: ICompany) => void, open: boolean, onClose: () => void }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [queuedFiles, setQueuedFiles] = useState<any[]>([]);
    const [companyForm, setCompanyForm] = useState<Partial<ICompany> | null>({
        id: company?.id,
        name: company?.name,
        domain: company?.domain,
        logo_url: company?.logo_url
    });
    const [editMode, setEditMode] = useState<'view' | 'edit' | 'lookup' | 'create'>('view');
    const [lookupResult, setLookupResult] = useState<ICompany | null>(null);
    const [refreshStatus, setRefreshStatus] = useState<string | null>(null);

    const handleCompanyLookup = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        if (!companyForm?.domain) return;
        
        const { data, error } = await getCompanyByDomain(companyForm.domain, accessToken);
        if (data && data.company) {
            setLookupResult(data.company);
        } else {
            // If no company found, switch to create mode with the domain pre-filled
            setEditMode('create');
        }
    }

    const handleCompanySave = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        if (!companyForm?.domain) return;

        let companyLogoUrl = companyForm.logo_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], companyForm.domain ?? companyForm.id, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            companyLogoUrl = data.response.url;
        }

        companyForm.logo_url = companyLogoUrl;
        
        try {
            if (editMode === 'edit' && companyForm.id) {
                const { data } = await updateCompany(companyForm.id, companyForm, accessToken);
                setCompanyForm(data.company);
                setCompany(data.company);
            } else if (editMode === 'create') {
                const { data } = await createCompany(companyForm, accessToken);
                setCompanyForm(data.company);
                setCompany(data.company);
            }
            setEditMode('view');
            onClose();
        } catch (error) {
            console.error('Error saving company:', error);
        }
    }

    const handleRefreshCompany = async () => {
        const accessToken = await getAccessTokenSilently();
        if (!companyForm?.id) return;
        setRefreshStatus('Refreshing...');
        const { data } = await refreshCompany(companyForm.id, accessToken);
        if (data && data.company) {
            setCompanyForm(data.company);
            setCompany(data.company);
        }
        setRefreshStatus(null);
    }

    return (
        <Dialog open={open} onClose={onClose}>
        <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
            <Box sx={{ border: "1px solid #c9c9c9", backgroundColor: "#ffffff", padding: "1rem", flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2 }}>
                {editMode === 'lookup' ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField 
                            size="small" 
                            name="domain" 
                            label="Company Domain" 
                            onChange={(e) => setCompanyForm({ ...companyForm, domain: e.target.value })} 
                            value={companyForm?.domain ?? ""} 
                            helperText="Enter the company's domain (e.g., company.com)"
                        />

                        {lookupResult ? (
                            <>
                                <Box sx={{ my: 2 }}>
                                    <CompanyLogo company={lookupResult} />
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button 
                                        variant="contained" 
                                        onClick={() => {
                                            setCompanyForm(lookupResult);
                                            setLookupResult(null);
                                            setCompany(lookupResult);
                                            setEditMode('view');
                                            onClose();
                                        }}
                                    >
                                        Use This Company
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        onClick={() => {
                                            setLookupResult(null);
                                            setCompanyForm({ domain: '', name: '', logo_url: '' });
                                        }}
                                    >
                                        Try Again
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button variant="contained" onClick={handleCompanyLookup}>
                                    Lookup Company
                                </Button>
                                <Button variant="outlined" onClick={() => setEditMode('view')}>
                                    Cancel
                                </Button>
                            </Box>
                        )}
                    </Box>
                ) : editMode === 'edit' || editMode === 'create' ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField 
                            size="small" 
                            name="domain" 
                            label="Domain" 
                            onChange={(e) => setCompanyForm({ ...companyForm, domain: e.target.value })} 
                            value={companyForm?.domain ?? ""} 
                        />
                        <TextField 
                            size="small" 
                            name="company_name" 
                            label="Name" 
                            onChange={(e) => setCompanyForm({ ...companyForm, name: e.target.value })} 
                            value={companyForm?.name ?? ""} 
                        />
                        <div className="logo-uploader">
                            <StyledDropzone 
                                fileTypes={{ 'image/*': [] }} 
                                queuedFiles={queuedFiles} 
                                setQueuedFiles={setQueuedFiles} 
                                initialFile={companyForm?.logo_url ?? ""} 
                            />
                        </div>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button variant="contained" onClick={handleCompanySave}>
                                {editMode === 'create' ? 'Create Company' : 'Save Changes'}
                            </Button>
                            <Button variant="outlined" onClick={() => setEditMode('view')}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    companyForm ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                            <CompanyLogo company={companyForm as ICompany} />
                            <Typography variant="body1" sx={{ textAlign: 'center' }}><strong>{companyForm.name}</strong><br/>
                            {companyForm.domain}</Typography>
                        </Box>
                    ) : (
                        <TextField name="company_name" label="Company" value={company?.name ?? ''} disabled />
                    )
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: 1 }}>
                <Button variant="text" onClick={handleRefreshCompany} disabled={refreshStatus === 'Refreshing...'}>
                    {refreshStatus === 'Refreshing...' ? 'Refreshing...' : 'Refresh Brand'}
                </Button>
                <Button variant="text" onClick={() => setEditMode('edit')}>
                    Edit Company
                </Button>
                <Button variant="text" onClick={() => {
                    setCompanyForm({ domain: '', name: '', logo_url: '' });
                    setEditMode('lookup');
                }}>
                    Lookup Different Company
                </Button>
                <Button variant="text" onClick={() => {
                    setCompanyForm({ name: '', domain: '', logo_url: '' });
                    setEditMode('create');
                }}>
                    Create New Company
                </Button>
            </Box>
        </Box>
        </Dialog>
    );
}