import { callExternalApi } from "../../services/external-api.service";

class OrganizationHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = apiServerUrl;
    }

    async getOrganization(organizationTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationTag}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async getOrganizationDomains(organizationTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationTag}/domains`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async addOrganizationDomain(organizationTag: string, domain: { domain_name: string, email_domain_name: string }, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationTag}/domain`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: domain
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async verifyOrganizationDomain(organizationTag: string, domainUuid: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationTag}/domain/${domainUuid}/verify`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async updateMemberRole(organizationTag: string, memberId: string, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationTag}/member/${memberId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                role: role
            }
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async getOrganizationMembers(organizationId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/members`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        }
      
        const { data, error } = await callExternalApi({ config });
      
        return {
          data: data || null,
          error
        }
    }

    async getOrganizationInvitations(organizationId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/invitations`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async resendInvitation(organizationId: number, invitationId: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/invitation/${invitationId}/resend`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async removeMemberFromOrganization(organizationId: number, memberId: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/member/${memberId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async updateInvitationRole(organizationId: number, invitationId: string, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/invitation/${invitationId}/role`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                invitee_role: role
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async getOrganizationAdvisorActivity(organizationId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/organization/${organizationId}/advisorActivity`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data, error } = await callExternalApi({ config });

        return {
            data: data || null,
            error,
        };
    }

    async createInvitations(organizationId: number, invitees: any[], note: string, role: string, type: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/invitations`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                organizationId,
                invitees,
                note,
                role,
                type
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
}

export default OrganizationHttpClient;