import { Cog, Globe, House, Mail, Users, File } from "lucide-react";
import { Sidebar, SidebarSection } from "../../../components/Sidebar";
import { useFirmContext } from "../context/FirmContext";


export const FirmSidebar = () => {

    const { firmContext } = useFirmContext();
    const { role } = firmContext;

    const sections: SidebarSection[] = [
        {
            visible: true,
            links: [
                {
                    to: "/portfolio",
                    iconComponent: <House size={16}/>,
                    text: "Portfolio",
                    enabled: true,
                    end: true,
                    visible: true
                },
                {
                    to: "/portfolio/team",
                    iconComponent: <Users size={16}/>,
                    text: "Team",
                    enabled: true,
                    visible: true
                }
            ]
        },
        
        {
            header: "Configuration",
            visible: role === "admin",
            links: [
                {
                    to: "/portfolio/branding",
                    iconComponent: <Cog size={16}/>,
                    text: "Branding",
                    enabled: true,
                    visible: role === "admin"
                },
                {
                    to: "/portfolio/domains",
                    iconComponent: <Globe size={16}/>,
                    text: "Domains",
                    enabled: true,
                    visible: role === "admin"
                },
                {
                    to: "/portfolio/websites",
                    iconComponent: <File size={16}/>,
                    text: "Landing Pages",
                    enabled: false,
                    visible: role === "admin"
                },
                {
                    to: "/portfolio/emails",
                    iconComponent: <Mail size={16}/>,
                    text: "Emails",
                    enabled: false,
                    visible: role === "admin"
                }
            ]
        }
    ];

    return (
        <Sidebar sections={sections} />
    );
}