import { Route, Routes, useNavigate } from "react-router-dom";
import { Advisor } from "./Advisor";
import { useSubdomain } from "../../../hooks/useSubdomain";
import { useDealContext } from "../../deal/context";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../modules/ClientProvider";
import Loading from "../../../components/Loading";
import { AdvisorFeedback } from "./AdvisorFeedback";
import { AdvisorMeet } from "./AdvisorMeet";
import { AdvisorRefer } from "./AdvisorRefer";
import { AdvisorInvest } from "./AdvisorInvest";
import { AdvisorHome } from "./AdvisorHome";
import { useAdvisor } from "../context/AdvisorContext";
import { AdvisorTouchpoint } from "./AdvisorTouchpoint";

export const AdvisorRoutes = () => {
    const { subdomain, isCustomDomain, subdomainFetched } = useSubdomain();
    const { dealContext, setDealContext } = useDealContext();
    const { fetchAdvisor } = useAdvisor();

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        
        const fetchDealData = async () => {
            if (subdomain && isCustomDomain) {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await ClientProvider.provideProjectClient().getProjectData(subdomain, accessToken);
                if (data) {
                    data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : {};
                    data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : {};
                    
                    setDealContext({
                        project: data.project,
                        organization: data.project.organization,
                        role: data.role,
                        fetched: true
                    });

                    fetchAdvisor(data.project.tag);
                } else {
                    navigate('/');
                }
            }
        };

        if (subdomainFetched && !dealContext.fetched) {
            fetchDealData();
        }

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        };
    }, [subdomainFetched, subdomain]);

    if (!subdomainFetched || !dealContext.fetched) {
        return <Loading />;
    }
    return (
        <Routes>
            <Route path="/" element={<Advisor />}>
                <Route index element={<AdvisorHome />} />
                <Route path="feedback">
                    <Route index element={<AdvisorFeedback />} />
                    <Route path=":touchpointUuid" element={<AdvisorTouchpoint />} />
                </Route>
                <Route path="meet" element={<AdvisorMeet />} />
                <Route path="meet/:meetingUuid" element={<AdvisorMeet />} />
                <Route path="refer" element={<AdvisorRefer />} />
                <Route path="invest" element={<AdvisorInvest />} />
            </Route>
        </Routes>
    );
};