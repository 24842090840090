import { Button, TextField, TableBody, Dialog, Box, Stack, TableRow, TableCell, TableHead, styled, BoxProps } from "@mui/material";
import { useState, useEffect } from "react";
import { useDealContext } from "../../../deal/context";
import { AdvisorTable } from "../../styles/AdvisorStyles";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LinkedinSearch {
    id: number;
    uuid: string;
    title: string;
    description: string;
    linkedin_url: string;
    is_active: boolean;
}

interface SearchFormProps {
    showForm: boolean;
    setShowForm: (show: boolean) => void;
    fetchSearches: () => void;
    editSearch?: LinkedinSearch;
}

const LinkedinSearchBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    paddingBottom: '40px',
    borderBottom: '1px solid #c9c9c9',
    "&:first-of-type": {
        borderTop: '1px solid #c9c9c9',
    },
    "& h5": {
        marginBottom: '10px',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '10px',
    },
    "& p": {
        marginBottom: '10px',
    }
}));

const SearchForm = ({ showForm, setShowForm, fetchSearches, editSearch }: SearchFormProps) => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [title, setTitle] = useState(editSearch?.title || '');
    const [description, setDescription] = useState(editSearch?.description || '');
    const [linkedinUrl, setLinkedinUrl] = useState(editSearch?.linkedin_url || '');

    const handleSave = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();
        
        const searchData = {
            title,
            description,
            linkedin_url: linkedinUrl,
            is_active: true
        };

        if (editSearch) {
            await projectClient.updateLinkedinSearch(
                project.id,
                editSearch.uuid,
                searchData,
                accessToken
            );
        } else {
            await projectClient.addLinkedinSearch(
                project.id,
                searchData,
                accessToken
            );
        }
        
        setShowForm(false);
        fetchSearches();
    };

    const handleCancel = () => {
        setTitle('');
        setDescription('');
        setLinkedinUrl('');
        setShowForm(false);
    }

    return (
        <Dialog open={showForm} onClose={handleCancel}>
            <h5>{editSearch ? 'Edit LinkedIn Search' : 'Add LinkedIn Search'}</h5>
            <p>{editSearch ? 'Edit your LinkedIn search.' : 'Add a new LinkedIn search to track potential advisors.'}</p>
            <Stack direction="column" spacing={2}>
                <TextField 
                    label="Title" 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField 
                    multiline 
                    rows={2} 
                    label="Description" 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField 
                    label="LinkedIn URL" 
                    value={linkedinUrl}
                    onChange={(e) => setLinkedinUrl(e.target.value)}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                </Box>
            </Stack>
        </Dialog>
    );
}

export const LinkedinSearches = () => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [showForm, setShowForm] = useState(false);
    const [searches, setSearches] = useState<LinkedinSearch[]>([]);
    const [editingSearch, setEditingSearch] = useState<LinkedinSearch | undefined>();
    const [deleteSearch, setDeleteSearch] = useState<LinkedinSearch | null>(null);

    const fetchSearches = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();
        const { data } = await projectClient.getLinkedinSearches(project.id, accessToken);
        if (data) {
            setSearches(Array.isArray(data.searches) ? data.searches : []);
        }
    };

    useEffect(() => {
        fetchSearches();
    }, [project.id, getAccessTokenSilently]);

    const handleAddSearch = () => {
        setEditingSearch(undefined);
        setShowForm(true);
    };

    const handleEdit = (search: LinkedinSearch) => {
        setEditingSearch(search);
        setShowForm(true);
    };

    const handleDeleteClick = (search: LinkedinSearch) => {
        setDeleteSearch(search);
    };

    const handleDeleteConfirm = async () => {
        if (deleteSearch) {
            const projectClient = new ProjectHttpClient();
            const accessToken = await getAccessTokenSilently();
            await projectClient.deleteLinkedinSearch(project.id, deleteSearch.uuid, accessToken);
            setDeleteSearch(null);
            fetchSearches();
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {searches?.map((search) => (
                <LinkedinSearchBox key={search.uuid}>
                                        <Box sx={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        alignItems: "center",
                        gap: "30px", 
                        justifyContent: "space-between", 
                        flexGrow: 1, 
                        width: "100%",
                        minHeight: "40px"
                    }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <h6>{search.title}</h6>
                            <p style={{ margin: 0 }}>{search.description}</p>
                        </Box>
                        <a className="btn btn-primary no-wrap" href={search.linkedin_url} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin}/> Search
                        </a>
                    </Box>
                </LinkedinSearchBox>
            ))}

            <Button sx={{ marginTop: '20px' }} variant="text" onClick={handleAddSearch}>+ Add LinkedIn Search</Button>
            
            {showForm && (
                <SearchForm 
                    showForm={showForm}
                    setShowForm={setShowForm}
                    fetchSearches={fetchSearches}
                    editSearch={editingSearch}
                />
            )}
            
            <ConfirmationModal
                open={!!deleteSearch}
                onClose={() => setDeleteSearch(null)}
                onConfirm={handleDeleteConfirm}
                success={false}
                title="Delete LinkedIn Search"
                message="Are you sure you want to delete this LinkedIn search?"
            />
        </Box>
    );
};