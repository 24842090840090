import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Modal, TextField, Button, Tabs, Tab } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getPendingMessages, updateMessage, sendMessage, getSentMessages } from "../services/gutcheck-admin-api.service";
import Quill from 'quill';
import dayjs from "dayjs";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { IMessage } from "../../../types";
// Configure Quill to use div instead of p tags
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);


export const AdminMessages = () => {
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [activeTab, setActiveTab] = useState('pending');
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        fetchMessages();
    }, [activeTab]);

    const fetchMessages = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = activeTab === 'pending' 
            ? await getPendingMessages(accessToken)
            : await getSentMessages(accessToken);
        if (data) {
            setMessages(data.messages);
        }
    };

    const handleEdit = (message: IMessage) => {
        setSelectedMessage(message);
        setEditMode(true);
        setHasChanges(false);
    };

    const handleSave = async () => {
        if (!selectedMessage || !hasChanges) return;

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateMessage(selectedMessage.uuid, selectedMessage, accessToken);
        if (!error) {
            setHasChanges(false);
            fetchMessages();
        }
    };

    const handleSend = async () => {
        if (!selectedMessage) return;

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateMessage(selectedMessage.uuid, selectedMessage, accessToken);
        if (data) {
            const { error } = await sendMessage(selectedMessage.uuid, accessToken);
        }

        if (!error) {
            setEditMode(false);
            setSelectedMessage(null);
            fetchMessages();
        }
    };

    const handleDelete = () => {
        setDeleteConfirmation(true);
    };

    const handleDeleteConfirm = async () => {
        if (!selectedMessage) return;

        const accessToken = await getAccessTokenSilently();
        const { error } = await updateMessage(selectedMessage.uuid, { status: 'deleted' }, accessToken);

        if (!error) {
            setEditMode(false);
            setSelectedMessage(null);
            fetchMessages();
        }
        setDeleteConfirmation(false);
    };

    const handleClose = () => {
        setEditMode(false);
        setSelectedMessage(null);
        setHasChanges(false);
    };

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                    <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                        <Tab label="Pending Messages" value="pending" />
                        <Tab label="Sent Messages" value="sent" />
                    </Tabs>
                </Box>
            </div>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Recipient</TableCell>
                        <TableCell>Subject</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell className="admin-action">
                            {activeTab === 'pending' ? 'Action' : 'View'}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages.map((message) => (
                        <TableRow key={message.uuid}>
                            <TableCell>{dayjs(message.created_at).format('MMM DD, YYYY HH:mm A')}</TableCell>
                            <TableCell>{message.project.name}</TableCell>
                            <TableCell>{message.advisor.user?.profile?.name}</TableCell>
                            <TableCell>{message.subject}</TableCell>
                            <TableCell>{message.status.toUpperCase()}</TableCell>
                            <TableCell onClick={() => handleEdit(message)}>
                                <span className="link">{activeTab === 'pending' ? 'Edit' : 'View'}</span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal
                open={editMode}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }}>
                    <h5>{activeTab === 'pending' ? 'Edit Message' : 'View Message'}</h5>
                    {selectedMessage && (
                        <Box sx={{ mt: 2 }}>
                            <Box sx={{ mb: 2 }}>
                                <strong>From:</strong> {selectedMessage.sender_name} &lt;{selectedMessage.sender_email}&gt;
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <strong>Reply To:</strong> {selectedMessage.reply_to_email}
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <strong>To:</strong> {selectedMessage.advisor.user?.profile?.name || selectedMessage.advisor.name} &lt;{selectedMessage.advisor.user?.email_address}&gt;
                            </Box>
                            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                <strong>Subject:</strong>
                                {activeTab === 'pending' ? (
                                    <TextField
                                        value={selectedMessage.subject}
                                        onChange={(e: any) => {
                                            setSelectedMessage({ ...selectedMessage, subject: e.target.value });
                                            setHasChanges(true);
                                        }}
                                        size="small"
                                    />
                                ) : (
                                    <span>{selectedMessage.subject}</span>
                                )}
                            </Box>
                            {activeTab === 'pending' ? (
                                <ReactQuill
                                    value={selectedMessage.message ?? ""}
                                    onChange={(content) => {
                                        setSelectedMessage({
                                            ...selectedMessage,
                                            message: content
                                        });
                                        setHasChanges(true);
                                    }}
                                />
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: selectedMessage.message ?? "" }} />
                            )}
                            <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>

                                {activeTab === 'pending' && (
                                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="text"
                                            color="error"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            onClick={handleSave}
                                            disabled={!hasChanges}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSend}
                                        >
                                            Send Message
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal>

            <ConfirmationModal
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                onConfirm={handleDeleteConfirm}
                success={false}
                title="Delete Message"
                message="Are you sure you want to delete this message? This action cannot be undone."
            />
        </div>
    );
};