import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { EvaluationSummary } from "../../evaluations/components/EvaluationSummary"
import { Button } from "@mui/material";
import { updateAdvisor } from "../../../core/services/gutcheck-api.service";
import { InvestmentActivity } from "../../evaluations/components/InvestmentActivity";
import { useEffect, useState } from "react";
import { Switch, FormLabel, Typography, Box } from "@mui/material";
import { DealUpdates } from "../../evaluations/components/DealUpdates";
import { InvestmentMaterials } from "../../evaluations/components/InvestmentMaterials";
import { SpvFaq } from "../../evaluations/components/SpvFaq";
import useAnalytics from "../../../hooks/useAnalytics";
import { useDealContext } from "../../deal/context";
import { useAdvisor } from "../context/AdvisorContext";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { InvestorAdvisors } from "../components/InvestorAdvisors";

type SpvStatus = {
    pending: string;
    revealed: string;
    approved: string;
    open: string;
    closed: string;
};

const spvStatus: SpvStatus = {
    pending: "Awaiting Greenlight",
    revealed: "Revealed to the Company",
    approved: "In Process",
    open: "Open for Co-Investment",
    closed: "Closed"
};

const ContextMessage = ({ advisor, handleLearnMore }: any) => {

    const project = advisor.survey_link.project;

    if (advisor.survey_link?.project?.spv_stage === "approved") {
        return (
            <></>
        )
    } else if (advisor.survey_link?.project?.spv_stage === "open") {
        return (
            <div className="deal-page-intro">
                <h5>The investment round is now open.</h5>
                <p>Click below to view {project.name}'s investor deck and a summary of terms for the round.<br /><Link className="bold" to="#" onClick={handleLearnMore}>Learn more &raquo;</Link></p>
            </div>
        )
    } else if (advisor.survey_link?.project?.spv_stage === "closed_funded") {
        return (
            <div className="deal-page-intro">
                <h5>The investment round is now closed.</h5>
                <p>BrightLoop and {project.organization.name} completed their investment in {project.name}.</p>
            </div>
        )
    }
}

const ReservationDetails = ({ advisor, displaySpvDetails, setDisplaySpvDetails }: any) => {
    const project = advisor.survey_link.project;
    const { trackAdvisorEvent } = useAnalytics();

    const [reservedSpot, setReservedSpot] = useState(advisor.reserved_spot ?? false);
    const [originalReservedAmount, setOriginalInvestmentAmount] = useState(advisor.reserved_amount ?? Math.ceil(advisor.investment_amount_high / 1000) * 1000);
    const [reservedAmount, setReservedAmount] = useState(advisor.reserved_amount ?? Math.ceil(advisor.investment_amount_high / 1000) * 1000);
    const [formOpen, setFormOpen] = useState(false);
    const [reservationError, setReservationError] = useState("");

    const navigate = useNavigate();

    const handleInterestChange = async () => {
        setReservedSpot(!reservedSpot);
    }

    const handleAmountChange = async (event: any) => {
        setReservedAmount(event.target.value.replace(/[^0-9,]/g, ''));
    }

    const handleAmountSubmit = async () => {
        if (reservedSpot && (reservedAmount === "" || reservedAmount === "0" || reservedAmount === 0)) {
            setReservationError("Please enter a valid amount.");
            return;
        }

        const updatedAmount = typeof reservedAmount === "string" ? reservedAmount.replace(/[^0-9]/g, '') : reservedAmount;
        const { data, error } = await updateAdvisor(advisor.public_id, { reserved_amount: reservedSpot ? parseInt(updatedAmount) : 0, reserved_spot: reservedSpot });

        if (error) {
            console.error(error);
            setReservationError("Please enter a valid amount.");
        } else {
            setOriginalInvestmentAmount(data.advisor?.reserved_amount);
            setReservedAmount(data.advisor?.reserved_amount);
            setReservationError("");
            setReservedSpot(data.advisor?.reserved_spot);
            //setDisplaySpvDetails(reservedSpot);
            setFormOpen(false);

            trackAdvisorEvent(
                advisor,
                "Lifecycle - Revenue - Reserved",
                {
                    amount: reservedAmount,
                    survey_link_partner: advisor.survey_link.partner_name,
                    project: advisor.survey_link.project.public_title,
                    organization: advisor.survey_link.project.organization.name,
                    survey_link_name: advisor.survey_link.name,
                    survey_link_id: advisor.survey_link.public_id,
                    survey_link: window.location.href
                }
            );
        }
    }

    const handleReserveClick = async () => {
        setFormOpen(true);
    }

    const handleSpvUrlClick = () => {

        trackAdvisorEvent(advisor, 'Clicked SPV link');
        if (project.spv_platform_name === "Sydecar") {
            trackAdvisorEvent(
                advisor,
                "Lifecycle - Revenue - Clicked SPV Instructions",
                {
                    survey_link_partner: advisor.survey_link.partner_name,
                    project: advisor.survey_link.project.public_title,
                    organization: advisor.survey_link.project.organization.name,
                    survey_link_name: advisor.survey_link.name,
                    survey_link_id: advisor.survey_link.public_id,
                    survey_link: window.location.href
                }
            );
            navigate(`instructions`);
        } else {
            trackAdvisorEvent(
                advisor,
                "Lifecycle - Revenue - Clicked SPV",
                {
                    survey_link_partner: advisor.survey_link.partner_name,
                    project: advisor.survey_link.project.public_title,
                    organization: advisor.survey_link.project.organization.name,
                    survey_link_name: advisor.survey_link.name,
                    survey_link_id: advisor.survey_link.public_id,
                    survey_link: window.location.href
                }
            );
            window.open(project.spv_platform_url, '_blank');
        }

    }

    return (
        <>
            <div className="reservation-form">
                <h5>Your Reservation</h5>
                {!formOpen && !reservedSpot &&
                    <div className="display-box  flex-row">
                        <div>
                            <h5>Interested in participating?</h5>
                            <div>Reserve a spot to be notified when it's time to co-invest.</div>
                        </div>
                        <div className="launch-evaluation">
                            <Button variant="contained" color="primary" className="btn" onClick={handleReserveClick}>Reserve your spot</Button>
                        </div>
                    </div>
                }
                {!formOpen && reservedSpot && (project.spv_stage === "approved" || project.spv_stage === "closed_canceled" || project.spv_platform_url === "") &&
                    <div className="display-box  flex-column dual-layer">
                        <div className="reservation-summary top-layer">
                            <div>
                                <h5>You reserved a spot to co-invest for ${reservedAmount.toLocaleString("en-US")}. (<span onClick={handleReserveClick} className="link bold">change</span>)</h5>
                                <div>We'll notify you when it's time to invest.</div>
                            </div>
                        </div>
                    </div>
                }
                {!formOpen && reservedSpot && project.spv_stage === "open" && project.spv_platform_url !== "" &&
                    <div className="display-box  flex-column">
                        {displaySpvDetails && advisor.survey_link.project.spv_stage === "open" && project.spv_platform_url !== "" &&
                            <div className="flex-row bottom-layer">
                                <div>
                                    <h5>Complete your investment on {project.spv_platform_name}.</h5>
                                    <div>You reserved a spot to co-invest for ${reservedAmount.toLocaleString("en-US")}. (<span onClick={handleReserveClick} className="link bold">change</span>)</div>
                                </div>
                                <Button variant="contained" color="primary" className="btn btn-primary" rel="noreferrer" onClick={handleSpvUrlClick}>Next &raquo;</Button>
                            </div>
                        }
                    </div>
                }
                {formOpen &&
                    <div className="display-box  reservation-details">
                        <h5>Manage your reservation</h5>
                        <p>Let us know if you're interested and for how much. There's no commitment at this time.</p>
                        <div className="flex-row reservation-form">
                            <div className="reservation-control">
                                <FormLabel>Reserve a Spot</FormLabel>
                                <Switch checked={reservedSpot} onChange={handleInterestChange} />

                            </div>
                            <div className="reservation-control">
                                <FormLabel>Ideal Amount $ </FormLabel>
                                <input
                                    disabled={!reservedSpot}

                                    type="text"
                                    value={reservedAmount}
                                    onChange={handleAmountChange} />
                            </div>
                        </div>
                        <Box className="reservation-form-submit" sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "20px", alignItems: "center" }}>
                            <Typography color={"error"}>{reservationError}</Typography>
                            <Button variant="contained" color="primary" className="btn btn-primary" onClick={handleAmountSubmit}>Confirm Reservation Details</Button>
                        </Box>
                    </div>
                }
            </div>

        </>
    )
}

const ReservationProgress = ({ advisor }: any) => {
    const project = advisor.survey_link.project;

    const currentTime = new Date();
    const currentDay = currentTime.setHours(0, 0, 0, 0);
    let daysToClose = project.funding_at ? Math.ceil(((new Date(project.funding_at)).getTime() - (new Date(currentDay)).getTime()) / (1000 * 60 * 60 * 24)) : -1;

    if (daysToClose < 0 && (project.spv_stage === "approved" || project.spv_stage === "open")) {
        daysToClose = 3;
    } else if (daysToClose < 0) {
        daysToClose = 0;
    }

    return (
        <>
            <h5>Investment Progress</h5>
            <div className="flex-row results-boxes reservation-stats">
                <div className="display-box white">
                    <p className="overview-results-value">{project.stats_angels} experts</p>
                    <p className="overview-results-title">Reserved Spots</p>
                </div>
                <div className="display-box white">
                    <p className="overview-results-value">${(Math.round(((project.stats_investment_amount_low + project.stats_investment_amount_high) / 2) / 1000) * 1000).toLocaleString("en-US")}</p>
                    <p className="overview-results-title">Soft Circled So Far</p>
                </div>
                <div className="display-box white">
                    <p className="overview-results-value">~{daysToClose} days</p>
                    <p className="overview-results-title">Until Funding Closes</p>
                </div>
            </div>
        </>
    )
}

export const AdvisorInvest = () => {
    const { dealContext } = useDealContext();
    const { advisor, loading } = useAdvisor();
    const project = dealContext.project;
    const { trackAdvisorPage } = useAnalytics();

    const [assetPagination, setAssetPagination] = useState({
        numPages: 1,
        currentPage: 1
    });

    const [displaySpvDetails, setDisplaySpvDetails] = useState(project.spv_stage === "open" || project.spv_stage === "approved");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (advisor) {
            trackAdvisorPage(advisor, 'expert flow - invest');
        }
    }, [advisor]);

    const handleNda = async () => {
        if (!advisor) return;
        const { data, error } = await updateAdvisor(advisor.public_id, { accepted_nda: true });

        if (error) {
            console.error(error);
        } else {
            window.location.reload();
        }
    }

    const handleAssetLoad = ({ numPages }: any) => {
        setAssetPagination({
            ...assetPagination,
            numPages: numPages,
            currentPage: 1
        });
    };

    const handleAssetChange = (event: any, offset: any) => {
        setAssetPagination({
            ...assetPagination,
            currentPage: assetPagination.currentPage + offset
        });
    };


    const handleLearnMore = () => {
        setIsOpen(true);
    }

    if (loading || !advisor) return (<></>);



    return (
        <>
            <AdvisorTitle project={project} section="Invest" subheading={`It's time to reserve your spot. ${project.organization.name} is working on closing their next round and have invited you to participate alongside ${project.organization.name}.`} />
            <Box sx={{ display: "flex", flexDirection: "row", gap: "40px" }}>
                <Box className="flex-column left-column" sx={{ flex: "1 1 500px" }}>
                    <ContextMessage advisor={advisor} handleLearnMore={handleLearnMore} />
                    {(project.spv_stage === "open" || project.spv_stage === "approved") && <ReservationDetails advisor={advisor} displaySpvDetails={displaySpvDetails} setDisplaySpvDetails={setDisplaySpvDetails} />}
                    {(project.spv_stage === "open" || project.spv_stage === "approved") && <ReservationProgress advisor={advisor} />}
                    {(project.spv_stage === "open" || project.spv_stage === "approved") && <InvestmentMaterials advisor={advisor} displaySpvDetails={displaySpvDetails} setDisplaySpvDetails={setDisplaySpvDetails} />}
                    <DealUpdates advisor={advisor} />
                </Box>
                <Box className="flex-column right-column" sx={{ flex: "0 1 250px", width: "250px" }}>
                    {(project.spv_stage === "open" || project.spv_stage === "approved") && <InvestorAdvisors advisor={advisor} project={project} />}
                </Box>
                <SpvFaq advisor={advisor} isOpen={isOpen} setIsOpen={setIsOpen} />
            </Box>
        </>
    )
}