import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";
import { useDealContext } from "../../deal";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box, Typography } from "@mui/material";


export const AdminProject = () => {
    const { projectId } = useParams();
    const { fetched, organization, project, error, reloadProjectData } = useAdminGetProjectData(parseInt(projectId ?? ""));

    const { dealContext, setDealContext } = useDealContext();

    useEffect(() => {

        if (project) {
            setDealContext({
                project: project,
                organization: project.organization,
                role: "admin",
                fetched: true
            });

        }

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        }
    }, [fetched]);

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (!project) return;

    return (
        <>
        <DocumentTitle pageTitle={`Admin - ${project?.name} - ${project.organization.name} `} />
        <div>
            <div className="admin-project-header">
                <h4>{project.organization.name} &raquo; {project?.name}</h4>
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <Box className="admin-subnav-bar" sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 2, alignItems: "flex-start", borderRight: "1px solid #e9e9e9", padding: "10px 20px 10px 0", marginRight: 2 }}>
                    <NavLink to={`/admin/project/${project.id}`} end>Home</NavLink>
                    <NavLink to={`/admin/project/${project.id}/advisors`}>Advisors</NavLink>
                    <NavLink to={`/admin/project/${project.id}/viewpoints`}>Viewpoints</NavLink>
                    <NavLink to={`/admin/project/${project.id}/suggestions`}>Suggestions</NavLink>
                    <Typography sx={{ color: "gray", paddingBottom: "5px", marginBottom: "5px", borderBottom: "1px solid #c9c9c9", width: "100%", fontSize: "0.875rem"}}>Setup</Typography>
                    <NavLink to={`/admin/project/${project.id}/deal`}>Config</NavLink>
                    <NavLink to={`/admin/project/${project.id}/surveyLinks`}>Survey Links</NavLink>
                    <NavLink to={`/admin/project/${project.id}/touchpoints`}>Feedback Loops</NavLink>
                    <Typography sx={{ color: "gray", paddingBottom: "5px", marginBottom: "5px", borderBottom: "1px solid #c9c9c9", width: "100%", fontSize: "0.875rem" }}>Outreach</Typography>
                    <NavLink to={`/admin/project/${project.id}/linkedin`}>LinkedIn Searches</NavLink>
                    <NavLink to={`/admin/project/${project.id}/paidCampaigns`}>Paid Campaigns</NavLink>
                    <Typography sx={{ color: "gray", paddingBottom: "5px", marginBottom: "5px", borderBottom: "1px solid #c9c9c9", width: "100%", fontSize: "0.875rem" }}>Investment</Typography>
                    <NavLink to={`/admin/project/${project.id}/investmentMaterials`}>Investment Materials</NavLink>
                    <NavLink to={`/admin/project/${project.id}/dealUpdates`}>Deal Updates</NavLink>
                    <NavLink to={`/admin/project/${project.id}/notifications`}>Notifications</NavLink>
                    <Typography sx={{ color: "gray", paddingBottom: "5px", marginBottom: "5px", borderBottom: "1px solid #c9c9c9", width: "100%", fontSize: "0.875rem" }}>Misc</Typography>
                    <NavLink to={`/admin/project/${project.id}/ai`}>AI</NavLink>
                    <NavLink to={`/admin/project/${project.id}/edit`}>Eval Config</NavLink>

                </Box>
                <Box sx={{ flex: 1, padding: 2 }}>
                    <Outlet context={{ project: project, organization: organization, reloadProjectData: reloadProjectData }} />
                </Box>
            </Box>
        </div>
        </>
    );
}