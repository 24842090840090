import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetter, GridRenderEditCellParams } from '@mui/x-data-grid';
import { DateTimePicker } from "@mui/x-date-pickers";
import { updatePaidCampaign } from "../../services/gutcheck-admin-api.service";
import dayjs from 'dayjs';

export const PaidCampaignTable = ({ project, paidCampaigns, reloadPaidCampaigns }: any) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleCellEdit = async (params: any) => {
        const accessToken = await getAccessTokenSilently();
        const { id, field, value } = params;
        const campaign = paidCampaigns.find((c: any) => c.uuid === id);
        
        if (!campaign) return;

        const updateData = {
            ...campaign,
            [field]: value,
            // Ensure dates are properly formatted when sending to API
            start_at: dayjs(campaign.start_at).toISOString(),
            end_at: dayjs(campaign.end_at).toISOString()
        };

        await updatePaidCampaign(project.id, campaign.uuid, updateData, accessToken);
        reloadPaidCampaigns();
    };

    const columns: GridColDef[] = [
        {
            field: 'start_at',
            headerName: 'Start Date',
            editable: true,
            width: 100,
            type: 'dateTime',
            valueGetter: (params: { value: any }) => dayjs(params.value).toDate(),
            valueFormatter: (params: { value: any }) => dayjs(params.value).format('MMM D, YYYY'),
        },
        {
            field: 'end_at',
            headerName: 'End Date',
            editable: true,
            width: 100,
            type: 'dateTime',
            valueGetter: (params: { value: any }) => dayjs(params.value).toDate(),
            valueFormatter: (params: { value: any }) => dayjs(params.value).format('MMM D, YYYY'),
        },
        { field: 'amount_ordered', headerName: 'Amount Ordered', type: 'number', width: 130, editable: true },
        { field: 'amount_delivered', headerName: 'Amount Delivered', type: 'number', width: 130, editable: true },
        { 
            field: 'cpa_target', 
            headerName: 'Target CPA', 
            type: 'number', 
            width: 110, 
            editable: true
        },
        { 
            field: 'cost', 
            headerName: 'Cost', 
            type: 'number', 
            width: 100, 
            editable: true,
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 100, 
            editable: true,
            type: 'singleSelect',
            valueOptions: ['draft', 'pending',  'active', 'paused', 'completed', 'cancelled'],
        },
        { field: 'num_meetings', headerName: 'Meetings', type: 'number', width: 100, editable: true },
        { field: 'num_opps', headerName: 'Opportunities', type: 'number', width: 110, editable: true },
        { field: 'num_customers', headerName: 'Customers', type: 'number', width: 100, editable: true },
    ];

    const rows = paidCampaigns.map((campaign: any) => ({
        ...campaign,
        id: campaign.uuid, // DataGrid requires a unique id field
    }));

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                processRowUpdate={async (newRow, oldRow) => {
                    await handleCellEdit({
                        id: newRow.id,
                        field: Object.keys(newRow).find(key => newRow[key] !== oldRow[key]),
                        value: newRow[Object.keys(newRow).find(key => newRow[key] !== oldRow[key]) || '']
                    });
                    return newRow;
                }}
                onProcessRowUpdateError={(error) => {
                    console.error('Error updating row:', error);
                }}
            />
        </Box>
    );
}; 