import { useEffect, useState } from "react";
import { Box, MenuItem, Modal, Stack, TextField, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { getProjects,getSurveyLinks, moveAdvisor, getTouchpoints } from "../../services/gutcheck-admin-api.service";
import { AdvisorProfileImage } from "../../../advisor/components/AdvisorProfileImage";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

export const MoveAdvisor = ({ project, advisor, reloadAdvisors, open, setOpen }: any) => {
    const [projects, setProjects] = useState<any>([]);
    const [selectedProject, setSelectedProject] = useState<string>("");
    const [surveyLinks, setSurveyLinks] = useState<any>([]);
    const [selectedSurveyLink, setSelectedSurveyLink] = useState<string>("");
    const [touchpoints, setTouchpoints] = useState<any>([]);
    const [selectedTouchpoint, setSelectedTouchpoint] = useState<string>("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        // Fetch projects - you'll need to create this API endpoint
        const fetchProjects = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getProjects(accessToken);
            if (data) {
                const sortedProjects = data.projects.sort((a: any, b: any) => a.name.localeCompare(b.name));
                setProjects(data.projects);
            } else {
                console.error(error);
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        const fetchSurveyLinks = async () => {
            if (!selectedProject) return;
            
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getSurveyLinks(Number(selectedProject), accessToken);
            if (data) {
                setSurveyLinks(data.surveyLinks);
            } else {
                console.error(error);
            }
        };

        fetchSurveyLinks();
    }, [selectedProject]);

    useEffect(() => {
        const fetchTouchpoints = async () => {
            if (!selectedProject) return;
            
            const accessToken = await getAccessTokenSilently();
            const { data } = await getTouchpoints(Number(selectedProject), accessToken);
            setTouchpoints(data.touchpoints);
        };

        fetchTouchpoints();
    }, [selectedProject]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await moveAdvisor(project.id, advisor.uuid, Number(selectedProject), Number(selectedSurveyLink), Number(selectedTouchpoint), accessToken);
        if (data) {
            await reloadAdvisors();
            setOpen(false);
        } else {
            console.error(error);
        }

        await reloadAdvisors();
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={modalStyle}>
                <h2>Move Advisor</h2>
                
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{marginTop: '20px'}}>
                        {advisor.viewpoints?.[0] && <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: "20px",
                            padding: "20px",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "10px"
                        }}>
                            <AdvisorProfileImage advisor={advisor} size="medium" />
                            
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "left"
                            }}>
                                <Typography sx={{ fontSize: "1.1rem", textAlign: "left", fontWeight: "700" }}>
                                    {advisor.viewpoints[0].name}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "1.1rem",
                                    textAlign: "left",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    marginBottom: "0.5rem"
                                }}>
                                    {advisor.viewpoints[0].title}
                                </Typography>
                                <Typography sx={{ fontSize: "1.1rem", textAlign: "left", fontWeight: "700" }}>
                                    {advisor.viewpoints[0].company_name}
                                </Typography>
                            </Box>
                        </Box>}

                        <Typography sx={{ fontSize: "1.1rem", textAlign: "left" }}><strong>Current Project: </strong>{project.name}</Typography>
                        
                        <TextField
                            label="Select New Project"
                            value={selectedProject}
                            onChange={(e) => {
                                setSelectedProject(e.target.value);
                                setSelectedSurveyLink("");
                                setSelectedTouchpoint("");
                            }}
                            select
                            required
                        >
                            {projects.map((proj: any) => (
                                <MenuItem key={proj.id} value={proj.id}>{proj.name} - {proj.organization.name} ({project.id})</MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Select Survey Link"
                            value={selectedSurveyLink}
                            onChange={(e) => setSelectedSurveyLink(e.target.value)}
                            select
                            required
                            disabled={!selectedProject}
                        >
                            {surveyLinks.map((link: any) => (
                                <MenuItem key={link.id} value={link.id}>{link.name}</MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Select Touchpoint"
                            value={selectedTouchpoint}
                            onChange={(e) => setSelectedTouchpoint(e.target.value)}
                            select
                            required
                            disabled={!selectedProject}
                        >
                            {touchpoints.map((touchpoint: any) => (
                                <MenuItem key={touchpoint.id} value={touchpoint.id}>{touchpoint.name}</MenuItem>
                            ))}
                        </TextField>

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>Cancel</button>
                            <button type="submit">Move Advisor</button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
};