import { CompanyTitle } from "../../components/CompanyTitle";
import { useDealContext } from "../../../deal/context";
import { Link } from "react-router-dom";
import { LinkedinSearches } from "../../components/outreach/LinkedinSearches";
import { Box, Stack } from "@mui/material";

export const OutreachLinkedIn = () => {
    const { dealContext } = useDealContext();
    const { project, role } = dealContext;

    return (
        <>
            <CompanyTitle 
                viewerRole={role} 
                project={project} 
                section="Outreach > LinkedIn Connections" 
                subheading={`Search for connections that could be good ${project.name} advisors.`} 
            />


            <Box sx={{ display: "flex", flexDirection: "row", gap: "40px", justifyContent: "space-between" }}>
                <LinkedinSearches />
                <Box className="display-box" sx={{ maxWidth: "250px" }}>
                    <h5>Tips</h5>
                    <p>
                        Click the button to launch a LinkedIn search of your network in a new window.
                    </p>
                    <p>
                        Go through the results, find your connections you think would make good advisors, and send them a messasge with a link to the app.
                    </p>
                    <p>
                        Feel free to use the pre-written messages in the <Link to="/c/links">Invitation Links</Link> section or write your own.
                    </p>
                </Box>
            </Box>
        </>
    )
}