import { Outlet } from "react-router-dom";

export const AdvisorsFirm = () => {
    return (
        <>
            <aside className="page-sidebar">
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet />
            </main>
        </>
    );
}