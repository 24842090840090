import React, { createContext, useContext, ReactNode, useState } from 'react';
import { IAdvisor } from '../../../types';
import ClientProvider from '../../../modules/ClientProvider';
import { useAuth0 } from '@auth0/auth0-react';
// Define the context value type
interface AdvisorsContextType {
    advisors: IAdvisor[];
    loading: boolean;
    error: string | null;
    fetchAdvisors: (projectId: number) => Promise<void>;
    addAdvisor: (advisor: Omit<IAdvisor, 'id'>) => Promise<void>;
    updateAdvisor: (advisor: IAdvisor) => Promise<void>;
    deleteAdvisor: (id: string) => Promise<void>;
}

// Create the context
const AdvisorsContext = createContext<AdvisorsContextType | undefined>(undefined);

// Create a provider component
export function AdvisorsProvider({ children }: { children: ReactNode }) {
    const [advisors, setAdvisors] = useState<IAdvisor[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();

    const fetchAdvisors = async (projectId: number) => {
        setLoading(true);
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await ClientProvider.provideProjectClient().getAdvisors(projectId, accessToken);
            if (data) {
                setAdvisors(data.advisors);
            } else {
                setError(error?.message || 'An error occurred');
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const addAdvisor = async (advisorData: Omit<IAdvisor, 'id'>) => {
        setLoading(true);
        try {
            // Implement your API call here
            // const response = await api.createAdvisor(advisorData);
            // setAdvisors(prev => [...prev, response.data]);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const updateAdvisor = async (advisor: IAdvisor) => {
        setLoading(true);
        try {
            // Implement your API call here
            // await api.updateAdvisor(advisor);
            // setAdvisors(prev => prev.map(a => a.id === advisor.id ? advisor : a));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const deleteAdvisor = async (id: string) => {
        setLoading(true);
        try {
            // Implement your API call here
            // await api.deleteAdvisor(id);
            // setAdvisors(prev => prev.filter(a => a.id !== id));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const value = {
        advisors,
        loading,
        error,
        fetchAdvisors,
        addAdvisor,
        updateAdvisor,
        deleteAdvisor,
    };

    return (
        <AdvisorsContext.Provider value={value}>
            {children}
        </AdvisorsContext.Provider>
    );
}

// Create a custom hook to use the context
export function useAdvisors() {
    const context = useContext(AdvisorsContext);
    if (context === undefined) {
        throw new Error('useAdvisors must be used within an AdvisorsProvider');
    }
    return context;
}
