import { updateAdvisor } from "../../../core/services/gutcheck-api.service";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAnalytics from "../../../hooks/useAnalytics";

export const EvaluationExit = () => {

    const [advisor, setAdvisor] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const advisorId = searchParams.get('blid');
    const { trackAdvisorEvent } = useAnalytics();

    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        let hasRun = false;

        const exitSurvey = async () => {
            if (!isMounted || hasRun) return;
            hasRun = true;

            if (advisorId) {
                const advisorPartial = {
                    status: searchParams.get('status') ?? null,
                    name: searchParams.get('contact[0]') ?? searchParams.get('contact') ?? searchParams.get('name') ?? null,
                    email_address: searchParams.get('contact[1]') ?? searchParams.get('email') ?? null,
                    linkedin_url: searchParams.get('linkedin') ?? null
                }
                const { data, error } = await updateAdvisor(advisorId, advisorPartial);

                if (data) {
                    setAdvisor(data.advisor);
                    setLoading(false);
                    if (data.advisor.status === 'complete') {
                        const trackingData = {
                            survey_link_partner: data.advisor.survey_link.partner_name ?? '',
                            project: data.advisor.survey_link.project.public_title ?? '',
                            organization: data.advisor.survey_link.project.organization.name ?? '',
                            survey_link_name: data.advisor.survey_link.name ?? '',
                            survey_link_id: data.advisor.survey_link.public_id ?? '',
                            survey_link: window.location.href ?? '',
                            email: data.advisor.email_address ?? ''
                        }
                        trackAdvisorEvent(
                            data.advisor,
                            'Lifecycle - Activated - Complete',
                            trackingData
                        );
                        trackAdvisorEvent(data.advisor, `Completed expert evaluation`);
                    } else if (data.advisor.status === 'screened') {
                        trackAdvisorEvent(data.advisor, `Screened out of expert evaluation`);
                    } else if (data.advisor.status === 'over_quota') {
                        trackAdvisorEvent(data.advisor, `Over quota for expert evaluation`);
                    }
                }
            }
        };

        if (advisorId) {
            exitSurvey();
        }

        return () => { isMounted = false };
    }, []);

    if (loading || !advisor) return (<></>);

    if (advisor?.survey_link?.passthrough) {

        switch (advisor.status) {
            case 'complete': {
                const paramsPrefix = /\?/.test(advisor.survey_link.complete_redirect_url) ? '&' : '?';
                const params = new URLSearchParams(advisor.params);
                const redirectUrl = params.get('from') === 'landing' ?
                    advisor.survey_link.project.organization.custom_domain_enabled ?
                        `//${advisor.survey_link.project.organization.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ''}/${advisor.survey_link.project.tag}/${advisorId}`
                        :
                        `//${advisor.survey_link.project.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/advise/${advisor.survey_link.project.tag}/${advisorId}`
                    :
                    `${advisor.survey_link.complete_redirect_url}${paramsPrefix}${advisor.params}`;
                trackAdvisorEvent(
                    advisor,
                    'Redirected to complete',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            case 'screened': {
                const paramsPrefix = /\?/.test(advisor.survey_link.screen_redirect_url) ? '&' : '?';
                const redirectUrl = `${advisor.survey_link.screen_redirect_url}${paramsPrefix}${advisor.params}`;
                trackAdvisorEvent(
                    advisor,
                    'Redirected to screened',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            case 'over_quota': {
                const paramsPrefix = /\?/.test(advisor.survey_link.quota_redirect_url) ? '&' : '?';
                const redirectUrl = `${advisor.survey_link.quota_redirect_url}${paramsPrefix}${advisor.params}`;
                trackAdvisorEvent(
                    advisor,
                    'Redirected to over_quota',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            default:
                return;
        }
    } else {
        navigate(`/e/${advisor?.survey_link?.public_id}/expert/${advisorId}`);
        return
    }
}