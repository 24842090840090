import { Table, TableBody, TableCell, TableHead, TableProps, TableRow, styled, TableSortLabel, Box, Checkbox, FormLabel, Input, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState, useReducer, useEffect, Reducer } from "react";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { AdvisorTable, DemandOptions, ScoreCircle } from "../../styles/AdvisorStyles";
import { AdvisorCompany } from "../../../advisor";
import { AdvisorProfileSummary } from "../../../advisor";
import { IViewpoint, IAdvisor } from "../../../../types";

const ScoringTable = styled(Table)<TableProps>(({ theme }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 0.5rem",
    marginBottom: "60px",
    "& th": {
        fontWeight: 700,
        fontSize: "1rem",
        borderBottom: "1px solid #000000",
        "&.td-sm": {
            width: "10%"
        },
        "&.td-md": {
            width: "20%"
        },
        "&.td-lg": {
            width: "30%"
        }
    },
    "& td": {
        fontSize: "1rem",
        "&.action": {
            fontSize: "1.5rem",
            cursor: "pointer",
        }
    },
    "& .td-center": {
        textAlign: "center",
    }
}));

type SortColumn = 'company' | 'advisor' | 'score' | 'lastActive' | 'role' | 'demand' | 'maxSpend';
type SortDirection = 'asc' | 'desc';

type TAdvisorsState = {
    advisors: IAdvisor[];
    filteredAdvisors: IAdvisor[];
};

const filterLabelStyle = {
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    "&:hover": {
        cursor: "pointer"
    }
};

const filterLabelCheckedStyle = {
    border: "1px solid #1A5DBD",
    backgroundColor: "#F0F6FF",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    color: "#000000",
    "&:hover": {
        cursor: "pointer"
    }
};

interface FilterCriteria {
    roles: string[];
    demand: string[];
}

const advisorsReducer = (state: TAdvisorsState, action: any) => {
    switch (action.type) {
        case 'FILTER':
            const { searchText, filterCriteria, sortDirection, sortColumn } = action.payload;
            let filteredAdvisors = state.advisors.filter((advisor: IAdvisor) => {
                const matchesSearchText = advisor.user?.profile?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                                       advisor.user?.profile?.title?.toLowerCase().includes(searchText.toLowerCase());
                const matchesRole = filterCriteria.roles.length === 0 || 
                                  filterCriteria.roles.includes(advisor.role);
                const matchesDemand = filterCriteria.demand.length === 0 || 
                                    filterCriteria.demand.includes(advisor.demand);
                
                return matchesSearchText && matchesRole && matchesDemand;
            });

            // Add sorting logic here
            filteredAdvisors.sort((a: IAdvisor, b: IAdvisor) => {
                const direction = sortDirection === 'asc' ? 1 : -1;
                
                switch (sortColumn) {
                    case 'company':
                        return (a.user?.profile?.company?.name || '').localeCompare(b.user?.profile?.company?.name || '') * direction;
                    case 'advisor':
                        return (a.user?.profile?.name || '').localeCompare(b.user?.profile?.name || '') * direction;
                    case 'score':
                        return ((a.score || 0) - (b.score || 0)) * direction;
                    case 'lastActive':
                        return ((a.created_at || '') > (b.created_at || '') ? 1 : -1) * direction;
                    case 'role':
                        return (a.role || '').localeCompare(b.role || '') * direction;
                    case 'demand':
                        return (a.demand || '').localeCompare(b.demand || '') * direction;
                    case 'maxSpend':
                        return ((a.max_spend || 0) - (b.max_spend || 0)) * direction;
                    default:
                        return 0;
                }
            });

            return {
                ...state,
                filteredAdvisors
            };
        case 'UPDATE':
            return {
                ...state,
                advisors: action.payload.advisors,
                filteredAdvisors: action.payload.advisors
            };
        default:
            return state;
    }
};

export const ScoringList = ({advisors}: {advisors: IAdvisor[]}) => {
    const [state, dispatch] = useReducer<Reducer<TAdvisorsState, any>>(advisorsReducer, { 
        advisors: advisors, 
        filteredAdvisors: advisors 
    });
    const [sortColumn, setSortColumn] = useState<SortColumn>('score');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [searchText, setSearchText] = useState('');
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
        roles: [],
        demand: []
    });

    // Get unique roles and demand values for filters
    const uniqueRoles = [...new Set(advisors.map((a: IAdvisor) => a.role))]
        .filter((role): role is string => Boolean(role));

    const uniqueDemand = [...new Set(advisors.map((a: IAdvisor) => a.demand))]
        .filter((demand): demand is string => Boolean(demand));

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        setFilterCriteria(prev => ({
            ...prev,
            [name as keyof FilterCriteria]: checked 
                ? [...prev[name as keyof FilterCriteria], value]
                : prev[name as keyof FilterCriteria].filter((item: string) => item !== value)
        }));
    };

    useEffect(() => {
        dispatch({
            type: 'FILTER',
            payload: {
                searchText,
                filterCriteria,
                sortDirection,
                sortColumn
            }
        });
    }, [filterCriteria, sortDirection, sortColumn, searchText]);

    const handleSort = (column: SortColumn) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    return (
        <>
            <Box sx={{
                //display: "flex",
                display: "none",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "end",
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: "10px",
            }}>
                <Box sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>SEARCH</Typography>
                        <Input placeholder="Search company or advisor" onChange={handleFilter} />
                    </fieldset>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>ROLE</Typography>
                        {uniqueRoles.map((role: string) => (
                            <FormLabel key={role} sx={filterCriteria.roles.includes(role) ? filterLabelCheckedStyle : filterLabelStyle}>
                                <Checkbox
                                    name="roles"
                                    value={role}
                                    onChange={handleCriteriaChange}
                                    checked={filterCriteria.roles.includes(role)}
                                    sx={{ display: "none" }}
                                /> {role}
                            </FormLabel>
                        ))}
                    </fieldset>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>DEMAND</Typography>
                        {uniqueDemand.map((demand: string) => (
                            <FormLabel key={demand} sx={filterCriteria.demand.includes(demand) ? filterLabelCheckedStyle : filterLabelStyle}>
                                <Checkbox
                                    name="demand"
                                    value={demand}
                                    onChange={handleCriteriaChange}
                                    checked={filterCriteria.demand.includes(demand)}
                                    sx={{ display: "none" }}
                                /> {DemandOptions.find((option) => option.value === demand)?.label || demand}
                            </FormLabel>
                        ))}
                    </fieldset>
                </Box>
            </Box>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="company">
                            <TableSortLabel
                                active={sortColumn === 'company'}
                                direction={sortColumn === 'company' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="profile">
                            <TableSortLabel
                                active={sortColumn === 'advisor'}
                                direction={sortColumn === 'advisor' ? sortDirection : 'asc'}
                                onClick={() => handleSort('advisor')}
                            >
                                Advisor
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortColumn === 'score'}
                                direction={sortColumn === 'score' ? sortDirection : 'asc'}
                                onClick={() => handleSort('score')}
                            >
                                Score
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortColumn === 'lastActive'}
                                direction={sortColumn === 'lastActive' ? sortDirection : 'asc'}
                                onClick={() => handleSort('lastActive')}
                            >
                                Last Active
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortColumn === 'role'}
                                direction={sortColumn === 'role' ? sortDirection : 'asc'}
                                onClick={() => handleSort('role')}
                            >
                                Role
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortColumn === 'demand'}
                                direction={sortColumn === 'demand' ? sortDirection : 'asc'}
                                onClick={() => handleSort('demand')}
                            >
                                Purchasing Interest
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortColumn === 'maxSpend'}
                                direction={sortColumn === 'maxSpend' ? sortDirection : 'asc'}
                                onClick={() => handleSort('maxSpend')}
                            >
                                Max Spend
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {state.filteredAdvisors.map((advisor: IAdvisor) => {
                        const latestViewpoint = advisor.viewpoints?.sort((a: IViewpoint, b: IViewpoint) => new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime())[0];
                        return <CompanyAdvisorLink uuid={advisor.uuid} key={`link-${advisor.uuid}`}>
                            <TableRow key={advisor.uuid}>
                                <TableCell className="company"><AdvisorCompany advisor={advisor} /></TableCell>
                                <TableCell className="profile"><AdvisorProfileSummary advisor={advisor} /></TableCell>
                                <TableCell className="center">
                                <ScoreCircle score={advisor.score || 0}>
                                    {advisor.score || '-'}
                                </ScoreCircle>
                            </TableCell>
                            <TableCell className="center">{latestViewpoint ? dayjs(latestViewpoint.created_at).format('MMM D, YYYY') : '-'}</TableCell>
                            <TableCell className="center">{advisor.role}</TableCell>
                            <TableCell className="center">{DemandOptions.find((option) => option.value === advisor.demand)?.label}</TableCell>
                            <TableCell className="center">
                                {advisor.max_spend ? 
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    }).format(advisor.max_spend)
                                        : '-'
                                    }
                                </TableCell>
                            </TableRow>
                        </CompanyAdvisorLink>
                    })}

                </TableBody>
            </AdvisorTable>
        </>
    );
}
