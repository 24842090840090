import { Box, Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Button, TextField, Stack, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useBrandContext } from '../../../hooks/useBrandContext';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { getAdvisor, getAdvisorByProjectAndEmail, getCompanyLanding, initEvaluation, updateAdvisor } from '../../../core/services/gutcheck-api.service';
import { useDealContext } from '../../../features/deal/context';
import Loading from '../../../components/Loading';
import { DocumentTitle } from '../../../components/DocumentTitle';
import { PopupModal, InlineWidget, useCalendlyEventListener } from "react-calendly";
import { CalendarPlus, MessageCirclePlus } from 'lucide-react';


export const CompanyStart = () => {

    const { brandContext } = useBrandContext();
    const { dealContext, setDealContext } = useDealContext();
    const { companyTag, advisorId } = useParams();

    const [project, setProject] = useState<any>(null);
    const [organization, setOrganization] = useState<any>(null);
    const [link, setLink] = useState<any>(null);
    const [surveyLink, setSurveyLink] = useState<any>(null);
    const [advisor, setAdvisor] = useState<any>(null);
    const [advisorLink, setAdvisorLink] = useState<any>(null);
    const [email, setEmail] = useState<any>(null);
    const { brand } = brandContext;
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState<boolean>(false);
    const [isFaqOpen, setIsFaqOpen] = useState<boolean>(false);
    const rootRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        rootRef.current = document.getElementById('root');
    }, []);

    useEffect(() => {

        const fetchCompanyLanding = async () => {
            if (companyTag) {
                const { data, error } = await getCompanyLanding(companyTag);
                if (data) {
                    setProject(data.project);
                    setLink(data.surveyLink?.link);
                    setSurveyLink(data.surveyLink);

                    setDealContext({
                        ...dealContext,
                        project: data.project,
                        fetched: true
                    });
                }
            }
        }

        const fetchAdvisor = async () => {
            if (advisorId) {
                const { data, error } = await getAdvisor(advisorId);
                if (data) {
                    setAdvisor(data.advisor);
                    setAdvisorLink(data.advisor.latest_survey_url || data.advisor.link);
                }
            }
        }

        if (companyTag) {
            fetchCompanyLanding();
        }
        if (advisorId) {
            fetchAdvisor();
        }
    }, [companyTag, advisorId]);

    if (!advisor) {
        return <Loading />;
    }

    const launchCalendly = () => {
        const url = process.env.REACT_APP_ADVISOR_CALENDLY_URL;
        if (url) {
            setIsCalendlyOpen(true);
        }
    }

    return (
        <>
            <DocumentTitle pageTitle={`${project?.organization?.name} Advisor Network for ${project?.name}`} showAttribution={false} />
            <Box sx={{
                width: '100%',
                background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh',
                display: 'flex',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: -1
            }}>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: "30px",
                maxWidth: '1200px',
                width: '100%',
                margin: '0 auto',
                height: 'fit-content',
                padding: "80px 80px",
                position: 'relative',
                zIndex: 1
            }}>
                <Box className="display-box white" sx={{ padding: "40px 40px", maxWidth: '700px', margin: '0 auto' }}>
                    <Typography variant="h5" fontSize={"1.7rem"} fontWeight="bold">
                        {(!project?.start_page_headline || project?.start_page_headline === '')
                            ? "20 shares have been allocated for your initial feedback"
                            : project?.start_page_headline}
                    </Typography>
                    <Typography variant="body1" mt={2}>
                        Helpful advisors will have opportunities to earn more equity, invest in the next round, and interact directly with the company and its investors.

                        Initial feedback should take 5-10 minutes and can be completed online.
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" fontSize={"1.2rem"} textAlign="center" mt={4} mb={4}>
                        {advisor?.latest_survey_link ? "How would you like to proceed?" : "How would you like to get started?"}
                    </Typography>

                    <Box display="flex" flexDirection="row" gap="40px" sx={{ justifyContent: 'space-between', padding: '0 40px' }} mt={2} mb={4}>
                        {advisor?.latest_survey_url ? (
                            <Box sx={{ flex: 1 }}>
                                <Button variant="contained" color="primary" href={advisorLink} sx={{ width: '100%' }}><MessageCirclePlus size={14} />&nbsp;&nbsp;Continue Online</Button>
                                <Typography variant="body1" mt={1} sx={{ fontSize: '0.9rem', padding: "10px" }}>Continue providing feedback online now through short video questions. Your progress has been saved.</Typography>
                            </Box>
                        ) : (
                            <Box sx={{ flex: 1 }}>
                                <Button variant="contained" color="primary" href={advisorLink} sx={{ width: '100%' }}><MessageCirclePlus size={14} />&nbsp;&nbsp;Begin Online</Button>
                                <Typography variant="body1" mt={1} sx={{ fontSize: '0.9rem', padding: "10px" }}>Start providing feedback online now through short video questions. You can always pick back up at a later time.</Typography>
                            </Box>
                        )}
                        <Box sx={{ flex: 1 }}>
                            <Button variant="outlined" color="primary" onClick={launchCalendly} sx={{ width: '100%' }}><CalendarPlus size={14} />&nbsp;&nbsp;Schedule a Call</Button>
                            <Typography variant="body1" mt={1} sx={{ fontSize: '0.9rem', padding: "10px" }}>Book time with an advisor specialist who can help determine if you're a good match for {project?.name}.</Typography>
                        </Box>
                    </Box>

                    <Box display={isFaqOpen ? "none" : "flex"} flexDirection="row" gap="20px" mt={2} sx={{ justifyContent: 'space-between' }}>
                        <Button variant="text" color="primary" onClick={() => setIsFaqOpen(true)} sx={{ width: '100%' }}>Need more info?</Button>
                    </Box>




                    <Box display={isFaqOpen ? "flex" : "none"} flexDirection="row" gap="20px" mt={2} mb="20px" sx={{ justifyContent: 'space-between' }}>
                        <Box sx={{ flex: 1, backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", border: "1px solid #c9c9c9", "& p": { fontSize: "0.9rem" } }}  >
                            <Typography variant="body1" fontWeight="bold" sx={{ borderBottom: "1px solid #c9c9c9", paddingBottom: "15px", marginBottom: "15px" }}>As an advisor, you'll...</Typography>
                            <p><strong>Provide monthly feedbck</strong> on new products and initiatives</p>
                            <p><strong>Refer colleagues</strong> who could also be good advisors or customers</p>
                            <p><strong>Schedule a demo</strong> or trial if and when the product fits your needs</p>
                        </Box>
                        <Box sx={{ flex: 1, backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", border: "1px solid #c9c9c9", "& p": { fontSize: "0.9rem" } }}>
                            <Typography variant="body1" fontWeight="bold" sx={{ borderBottom: "1px solid #c9c9c9", paddingBottom: "15px", marginBottom: "15px" }}>Start now, finish later</Typography>
                            <p>Provide feedback in a mix of open-ended and multiple choice questions, many of which can be answered with a video response.</p>
                            <p>You can always pick back up at a later time.</p>
                        </Box>
                        <Box sx={{ flex: 1, backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", border: "1px solid #c9c9c9", "& p": { fontSize: "0.9rem" } }}>
                            <Typography variant="body1" fontWeight="bold" sx={{ borderBottom: "1px solid #c9c9c9", paddingBottom: "15px", marginBottom: "15px" }}>Claim your shares</Typography>
                            <p>We've already allocated shares for your initial feedback with an opportunity to earn more.</p>
                            <p>If you're accepted as an advisor, we'll walk you through the process to claim your shares.</p>
                        </Box>
                    </Box>
                    <Box display={isFaqOpen ? "block" : "none"} sx={{ flex: 1, backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", border: "1px solid #c9c9c9", "& p": { fontSize: "0.9rem" } }}  >
                        <Typography variant="body1" fontWeight="bold" sx={{ borderBottom: "1px solid #c9c9c9", paddingBottom: "15px", marginBottom: "15px" }}>About the Advisory Program</Typography>
                        <p>Advising comes in different levels, with shares or grants earned based on your level of involvement. Advisors start by earning small amounts of equity for individual contributions. Those who provide significant value receive larger grants as key contributors in the network. At the highest level, the most impactful advisors are invited to co-invest alongside top VCs, gaining a larger equity stake. The most successful advisors often engage at all three levels and advise multiple companies.</p>
                        <p>It's important to note that because these are legal vehicles, advisors do need to earn $200K per year (or wait until they do), to claim these shares. You'll also need to be actively advising and have earned a minimal number of shares (i.e. 100 or 500) to participate.</p>
                        <p>There will be some cases where a company can't offer shares, or you have a conflict that prevents you from owning shares (or a part of an SPV that owns a company's shares) in a particular company. In this case, you'll receive the option to acquire an equivalent number of BrightLoop shares, which includes a share of the profits from all investments coordinated across networks that BrightLoop is powering.</p>   
                    </Box>
                    <Box display={isFaqOpen ? "flex" : "none"} mt={2} sx={{ justifyContent: 'center' }}>
                        <Typography fontSize={"0.8rem"}>
                            {(() => {
                                const emailDomain = project?.organization?.custom_domain_enabled ? project?.organization?.custom_email_domain : 'brightloop.com';
                                const emailAddress = `info@${emailDomain}`;
                                return (
                                    <>
                                        Still have questions? <span onClick={launchCalendly} className="link">Schedule a call</span> or email us at <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                                    </>
                                );
                            })()}
                        </Typography>
                    </Box>


                </Box>

            </Box>

            {process.env.REACT_APP_ADVISOR_CALENDLY_URL && rootRef.current && (
                <PopupModal
                    rootElement={rootRef.current}
                    open={isCalendlyOpen}
                    url={process.env.REACT_APP_ADVISOR_CALENDLY_URL + `?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    onModalClose={() => { setIsCalendlyOpen(false); }}
                    prefill={{
                        name: advisor.name,
                        email: advisor?.user?.email_address ?? advisor.email_address,
                    }}
                    pageSettings={{
                        primaryColor: '00a2ff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false
                    }}
                />
            )}
        </>
    );


}