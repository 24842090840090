import { Table, TableBody, TableCell, TableHead, TableRow, TableProps, Typography, TableContainer } from "@mui/material"
import { ViewpointCompany, ViewpointProfileSummary } from "../../../viewpoints";
import { ScoreCircle, DemandOptions, AdvisorTable } from "../../styles/AdvisorStyles";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { DateDisplay } from "../../../meeting/components/DateDisplay";
import { useParams } from "react-router-dom";
import { IMeeting } from "../../../../types";
import { AdvisorCompany, AdvisorProfileSummary } from "../../../advisor";

export const UpcomingList = ({ meetings }: { meetings: IMeeting[] }) => {

    const { meetingUuid } = useParams();
    if (meetingUuid) {
        //set hash to meetingUuid
        window.location.hash = meetingUuid;
    }

    return (
        <TableContainer sx={{ marginBottom: "60px", marginTop: "20px" }}>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="company">Company</TableCell>
                        <TableCell className="profile">Advisor</TableCell>
                        <TableCell className="center">Score</TableCell>
                        <TableCell className="center">Purchasing Interest</TableCell>
                        <TableCell className="center">Max Annual Spend</TableCell>
                        <TableCell className="center">Date</TableCell>
                        <TableCell className="center">Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {meetings
                        .filter(m => m.event_start)
                        .sort((a: IMeeting, b: IMeeting) => 
                            new Date(a.event_start!).getTime() - new Date(b.event_start!).getTime()
                        )
                        .map((meeting: IMeeting) => (
                            <CompanyAdvisorLink uuid={meeting.advisor.uuid} key={`link-${meeting.uuid}`}>
                                <TableRow key={meeting.uuid}>
                                    <TableCell className="company"><AdvisorCompany advisor={meeting.advisor} /></TableCell>
                                    <TableCell className="profile"><AdvisorProfileSummary advisor={meeting.advisor} /></TableCell>
                                    <TableCell className="center">
                                        <ScoreCircle score={meeting.advisor.score || 0}>
                                            {meeting.advisor.score || '-'}
                                        </ScoreCircle>
                                    </TableCell>
                                    <TableCell className="center">{DemandOptions.find((option) => option.value === meeting.advisor.demand)?.label}</TableCell>
                                    <TableCell className="center">
                                    {meeting.advisor.max_spend ?
                                        new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(meeting.advisor.max_spend)
                                        : '-'
                                    }
                                    </TableCell>
                                    <TableCell className="center">
                                        <DateDisplay meeting={meeting} showTime={true} />
                                    </TableCell>
                                    <TableCell className="center">
                                        {new Date(meeting.event_start!).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}
                                    </TableCell>
                                </TableRow>
                            </CompanyAdvisorLink>
                        ))}
                </TableBody>
            </AdvisorTable>
        </TableContainer>
    )
}