import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";
import { IAdvisor } from "../../../types";

const ProfileImageBox = styled("div")(({ theme }) => ({
    width: "80px",
    height: "80px",
    position: "relative",
    overflow: "hidden",
    borderRadius: "50%",
    border: "1px solid #c9c9c9",
    boxShadow: "inset 0 0 5px #c9c9c9",
    textAlign: "center",
    "&.small": {
        width: "50px",
        height: "50px"
    },
    "&.medium": {
        width: "80px",
        height: "80px"
    },
    "&.large": {
        width: "120px",
        height: "120px"
    },
}));

const ProfileImage = styled("img")(({ theme }) => ({
    display: "inline",
    width: "audo",
    height: "100%",
}));

const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';

export const AdvisorProfileImage = ({ advisor, size = "medium" }: { advisor: IAdvisor, size?: string }) => {

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString
    }

    const handleLinkedInClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(advisor.user?.profile?.linkedin_url, '_blank');
    }

    const initials = advisor.user?.profile?.name ? advisor.user?.profile?.name.split(" ").map((n: string) => n[0]).join("") : advisor.user?.email_address ? advisor.user?.email_address[0] + advisor.user?.email_address[1] : "";
    const placeholderImage = `https://cdn.auth0.com/avatars/${initials.toLowerCase()}.png`;

    return (
        <div style={{ position: "relative" }}>
            <ProfileImageBox className={size}>
                <ProfileImage
                    src={advisor.user?.profile?.image_url ?? advisor.user?.image_url ?? placeholderImage}
                    alt={advisor.user?.profile?.name}
                    onError={handleImageError}
                />
            </ProfileImageBox>
            {size === "small" && advisor.user?.profile?.linkedin_url && advisor.user?.profile?.linkedin_url !== "" &&
                <Box onClick={handleLinkedInClick} component="img" className="li-icon" src={'/images/linkedin.png'} sx={{ position: "absolute", bottom: 0, right: 0 }} />
            }
            {size === "medium" && advisor.user?.profile?.linkedin_url && advisor.user?.profile?.linkedin_url !== "" &&
                <Box onClick={handleLinkedInClick} component="img" className="li-icon" src={'/images/linkedin.png'} sx={{ position: "absolute", top: 0, right: 0 }} />
            }
            {size === "large" && advisor.user?.profile?.linkedin_url && advisor.user?.profile?.linkedin_url !== "" &&
                <Box onClick={handleLinkedInClick} sx={{ position: "absolute", top: 0, right: 0, fontSize: "1.6rem", color: "#0e76a8" }} >
                    <FontAwesomeIcon icon={faLinkedin} />
                </Box>
            }
            {(advisor.preview || advisor.is_test) &&
                <Box sx={{
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: advisor.is_test ? "#feb70f" : "#667788",
                    color: advisor.is_test ? "#000000" : "#ffffff",
                    fontSize: "0.8rem",
                    padding: "0 0.5rem",
                    whiteSpace: "nowrap",
                    borderRadius: "0.5rem"

                }}>
                    {advisor.preview ? "In Process" : "Test"}
                </Box>
            }

        </div>
    );
}
