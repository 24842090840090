import { Box, Button, Dialog, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Link, Stack } from "@mui/material"
import { Plus, Trash, FileUp, Download } from "lucide-react"
import { AdvisorTable, EmptyList } from "../../styles/AdvisorStyles"
import { useState } from "react";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import Dropzone from "react-dropzone";
import { useDealContext } from "../../../deal/context";

const AddToBlocklistModal = ({ project, open, onClose }: { project: any, open: boolean, onClose: () => void }) => {

    const [company, setCompany] = useState("");
    const [domain, setDomain] = useState("");
    const [error, setError] = useState("");
    const projectClient = new ProjectHttpClient()
    const { getAccessTokenSilently } = useAuth0();

    const handleAddToBlocklist = async () => {
        if (!company || !domain) {
            setError("Please enter a company and domain");
            return;
        }
        const exclusionData = {
            company_name: company,
            email_domain: domain,
        }
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.addExclusion(project.id, exclusionData, accessToken);
        if (data) {
            onClose();
            setCompany("");
            setDomain("");
            setError("");
        }
    }

    const handleCancel = () => {
        setCompany("");
        setDomain("");
        setError("");
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleCancel} title="Add to Blocklist">
            <Box>
                <h5>Add to Blocklist</h5>
                <p>Add a company or email domain to the blocklist. This will prevent any emails from this company or domain from being sent to your investors.</p>
                <Stack direction="column" spacing={2}>
                    <TextField label="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
                    <TextField label="Domain" value={domain} onChange={(e) => setDomain(e.target.value)} />
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 2 }}>
                    <span style={{ color: "red" }}>{error}</span>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" onClick={handleAddToBlocklist}>Add</Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

const UploadCSVModal = ({ project, open, onClose }: { project: any, open: boolean, onClose: () => void }) => {
    const [files, setFiles] = useState<File[]>([]);
    const projectClient = new ProjectHttpClient();
    const { getAccessTokenSilently } = useAuth0();

    const downloadTemplate = () => {
        const csvContent = "company,domain\n"; // CSV header
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'blocklist_template.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    };

    const handleDrop = (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
    }

    const handleUpload = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.uploadExclusions(project.id, files[0], accessToken);
        if (data) {
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} title="Upload CSV">
            <h5>Upload Blocklist CSV</h5>
            <p>
                Upload a CSV file with company names and email domains to blocklist. Make sure to include a header row indicating "company" and "domain".{' '}
                <Link
                    component="button"
                    variant="body2"
                    onClick={downloadTemplate}
                    sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
                >
                    <Download size={16} style={{ marginRight: '4px' }} /> Download template
                </Link>
            </p>
            <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }: any) => (
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: 2,
                            p: 3,
                            textAlign: 'center',
                            cursor: 'pointer',
                            bgcolor: '#fafafa',
                            '&:hover': {
                                bgcolor: '#f0f0f0',
                                borderColor: '#999'
                            }
                        }}
                    >
                        <input {...getInputProps()} />
                        <FileUp size={32} style={{ marginBottom: 8, color: '#666' }} />
                        <Typography>
                            Drag and drop a CSV file here, or click to select a file
                        </Typography>
                        {files.length > 0 && (
                            <Box sx={{ mt: 2, color: 'success.main' }}>
                                <Typography>Selected: {files[0].name}</Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Dropzone>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button onClick={onClose} variant="outlined">Cancel</Button>
                <Button variant="contained" disabled={files.length === 0} onClick={handleUpload}>
                    Upload
                </Button>
            </Box>
        </Dialog>
    )
}

const RemoveExclusionModal = ({ project, exclusion, open, onClose }: { project: any, exclusion: any, open: boolean, onClose: () => void, fetchExclusions: () => void }) => {
    const projectClient = new ProjectHttpClient();
    const { getAccessTokenSilently } = useAuth0();

    const handleRemove = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.deleteExclusion(project.id, exclusion.id, accessToken);
        if (data) {
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} title="Remove Exclusion">
            <Box>
                <p>Are you sure you want to remove this exclusion?</p>
                <Button variant="contained" onClick={handleRemove}>Remove</Button>
            </Box>
        </Dialog>
    )
}

export const Blocklist = ({ project, exclusions, fetchExclusions, setup = false }: { project: any, exclusions: any[], fetchExclusions: () => void, setup?: boolean }) => {


    const [addToBlocklistModalOpen, setAddToBlocklistModalOpen] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [removeExclusionModalOpen, setRemoveExclusionModalOpen] = useState(false);
    const [exclusion, setExclusion] = useState(null);
    const { dealContext } = useDealContext();

    const openAddToBlocklistModal = () => {

        setAddToBlocklistModalOpen(true);
    }

    const closeAddToBlocklistModal = () => {
        setAddToBlocklistModalOpen(false);
        fetchExclusions();
    }

    const openUploadModal = () => {
        setUploadModalOpen(true);
    }

    const closeUploadModal = () => {
        setUploadModalOpen(false);
        fetchExclusions();
    }

    const openRemoveExclusionModal = (exclusion: any) => {
        setRemoveExclusionModalOpen(true);
        setExclusion(exclusion);
    }

    const closeRemoveExclusionModal = () => {
        setRemoveExclusionModalOpen(false);
        fetchExclusions();
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: "10px", mb: 2 }}>
                <Button variant="outlined" size="small" onClick={openUploadModal}>
                    <FileUp size={16} />&nbsp;&nbsp;Upload CSV
                </Button>
                <Button variant="outlined" size="small" onClick={openAddToBlocklistModal}>
                    <Plus size={16} />&nbsp;&nbsp;Add Company
                </Button>
            </Box>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Domain</TableCell>
                        {!setup && <TableCell>Added By</TableCell>}
                        {!setup && <TableCell>Date Added</TableCell>}
                        <TableCell className="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {exclusions?.map((exclusion) => (
                        <TableRow key={exclusion.id}>
                            <TableCell>{exclusion.company_name}</TableCell>
                            <TableCell>{exclusion.email_domain}</TableCell>
                            {!setup && <TableCell>{exclusion.user?.name || exclusion.user?.email_address}</TableCell>}
                            {!setup && <TableCell>{new Date(exclusion.created_at).toLocaleDateString()}</TableCell>}
                            <TableCell className="right">
                                { dealContext?.role === "admin" &&
                                    <span className="link" onClick={() => openRemoveExclusionModal(exclusion)}>
                                        <Trash size={16} style={{ marginRight: "5px" }} /> Remove
                                    </span>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </AdvisorTable>
            {exclusions.length === 0 && <EmptyList>
                <p>
                    No exclusions added.
                </p>
            </EmptyList>
            }
            <AddToBlocklistModal project={project} open={addToBlocklistModalOpen} onClose={closeAddToBlocklistModal} />
            <UploadCSVModal project={project} open={uploadModalOpen} onClose={closeUploadModal} />
            <RemoveExclusionModal project={project} exclusion={exclusion} open={removeExclusionModalOpen} onClose={closeRemoveExclusionModal} fetchExclusions={fetchExclusions} />
        </Box>
    )
}
