import { Typography } from "@mui/material";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Snackbar } from "@mui/material";
import { OrganizationTitle } from "../components/OrganizationTitle";
import { useFirmContext } from "../context/FirmContext";
import { useEffect, useState } from "react";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircle, Circle, Dot, Timer, Copy, Check } from "lucide-react";
import Loading from "../../../components/Loading";

const getSuggestedDomainNames = (website: string | null): { domainName: string, emailDomainName: string } => {
    if (!website) return { domainName: "", emailDomainName: "" };

    // Remove protocol if present
    let domain = website.replace(/^https?:\/\//, '');

    // Extract the main domain by taking the last two parts (or three for co.uk style domains)
    const parts = domain.split('.');
    if (parts.length >= 2) {
        // Handle special cases like .co.uk, .com.au, etc.
        if (parts.length > 2 && parts[parts.length - 2] === 'co') {
            domain = parts.slice(-3).join('.');
        } else {
            domain = parts.slice(-2).join('.');
        }
    }

    // If domain is empty after replacements, return empty string
    if (!domain) return { domainName: "", emailDomainName: "" };

    // Add advisors subdomain
    return { domainName: `advisors.${domain}`, emailDomainName: `advisor.${domain}` };
};

export const FirmDomains = () => {

    const { firmContext } = useFirmContext();
    const { organization } = firmContext;
    const { getAccessTokenSilently } = useAuth0();
    const [domains, setDomains] = useState<any[]>([]);
    const [domainName, setDomainName] = useState("");
    const [emailDomainName, setEmailDomainName] = useState("");
    const organizationClient = ClientProvider.provideOrganizationClient();
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const [copiedText, setCopiedText] = useState("");
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [ isProvisioning, setIsProvisioning ] = useState(false);

    const fetchDomains = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.getOrganizationDomains(organization.tag, accessToken);
        if (data) {
            setDomains(data.domains);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchDomains();
        // Set initial domain name suggestion if no domains exist
        if (domains.length === 0 && organization?.website) {
            const { domainName, emailDomainName } = getSuggestedDomainNames(organization.website);
            setDomainName(domainName);
            setEmailDomainName(emailDomainName);
        }
    }, [organization]);

    const handleAddDomain = async () => {
        setIsProvisioning(true);
        if (domainName === emailDomainName) {
            setErrorMessage("Landing page domain and email domain must be different");
            setShowError(true);
            setIsProvisioning(false);
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.addOrganizationDomain(organization.tag, { domain_name: domainName, email_domain_name: emailDomainName }, accessToken);
        if (data) {
            setDomains([...domains, data.domain]);
        } else {
            setErrorMessage(error?.message);
            setShowError(true);
        }
        setIsProvisioning(false);
    }

    const handleVerifyDomain = async (domain: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.verifyOrganizationDomain(organization.tag, domain.uuid, accessToken);
        if (data) {
            fetchDomains();
        }
    }

    const handleCopyRecordValue = async (text: string) => {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        setShowCopySuccess(true);
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <OrganizationTitle organization={organization} section="Custom Domains" subheading="Manage your custom domains here." />

            {!domains.length && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
                    <Box className="display-box white" sx={{ width: "75%", maxWidth: "800px", minWidth: "400px", display: "flex", flexDirection: "column", gap: 2 }}>
                        <h5>Add custom domains</h5>
                        <p>Adding custom domains will allow you to host advisor landing pages on your own domain. In addition, all outreach will come from your own subdomain.</p>
                        <Typography sx={{ fontWeight: "bold", marginBottom: "0", marginTop: "10px" }}><strong>Landing Page Domain</strong></Typography>
                        <Typography>This domain is used to host landing pages for recruiting advisors.</Typography>
                        <TextField 
                            label="Landing Page Domain" 
                            value={domainName} 
                            onChange={(e) => setDomainName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box component="span" sx={{ color: '#c9c9c9', whiteSpace: "nowrap" }}>
                                        https://
                                    </Box>
                                ),
                            }}
                        />
                        <Typography sx={{ fontWeight: "bold", marginBottom: "0", marginTop: "10px" }}><strong>Email Domain</strong></Typography>
                        <Typography>This domain is used to send system notifications to advisors. It must be different from the landing page domain.</Typography>
                        <TextField 
                            label="Email Domain" 
                            value={emailDomainName} 
                            onChange={(e) => setEmailDomainName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box component="span" sx={{ color: '#c9c9c9', whiteSpace: "nowrap" }}>
                                        no-reply@
                                    </Box>
                                ),
                            }}
                        />
                        {showError && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
                        <Button variant="contained" color="primary" onClick={handleAddDomain} disabled={isProvisioning}>Add Domain</Button>
                    </Box>

                </Box>
            )}

            {domains.map((domain: any) => {
                const hostname = domain.domain_name.split(".")[0];
                const domainName = domain.domain_name.replace(hostname, "");
                
                // Create a Set to track unique combinations of hostname and proxied_cname
                const seenRecords = new Set();
                // Filter out duplicate records
                const uniqueRecords = domain.records.filter((record: any) => {
                    const key = `${record.hostname}-${record.proxied_cname}`;
                    if (seenRecords.has(key)) {
                        return false;
                    }
                    seenRecords.add(key);
                    return true;
                });

                return (
                    <Box key={domain.id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {domain.is_verified ?
                                <Circle fill="#4CAF50" color="#4CAF50" size={16} /> :
                                <Circle fill="#9e9e9e" color="#9e9e9e" size={16} />
                            }
                            <Typography variant="h5">{domain.domain_name}</Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Hostname</TableCell>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>Verified</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uniqueRecords.map((record: any) => (
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>{record.description}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box sx={{ fontFamily: "monospace", wordBreak: "break-all" }}>
                                                    {record.hostname.replace(domainName, "")}
                                                </Box>
                                                <IconButton onClick={() => handleCopyRecordValue(record.hostname.replace(domainName, ""))}>
                                                    {copiedText === record.hostname.replace(domainName, "") ? <Check size={16} /> : <Copy size={16} />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{domainName}</TableCell>
                                        <TableCell>{record.proxied_cname ? "CNAME" : record.record_type}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box sx={{ fontFamily: "monospace", maxWidth: "300px", wordBreak: "break-all" }}>
                                                    {record.proxied_cname ? record.proxied_cname : record.record_value}
                                                </Box>
                                                <IconButton onClick={() => handleCopyRecordValue(record.proxied_cname ? record.proxied_cname : record.record_value)}>
                                                    {copiedText === (record.proxied_cname ? record.proxied_cname : record.record_value) ? <Check size={16} /> : <Copy size={16} />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>{record.is_verified ? <CheckCircle color="#4CAF50" /> : <Timer />}<br />
                                            {record.is_verified ? <span onClick={() => handleVerifyDomain(domain)}>Verified</span> : <span className="link" onClick={() => handleVerifyDomain(domain)}>Check Now</span>}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )
            })}

            <Snackbar
                open={showCopySuccess}
                autoHideDuration={2000}
                onClose={() => setShowCopySuccess(false)}
                message="Copied to clipboard!"
            />
        </>
    );
}