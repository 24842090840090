import { Outlet } from "react-router-dom";
import { useFirmContext } from "../../../features/firm";

export const Landing = () => {

    const { firmContext, setFirmContext } = useFirmContext();

    if (window.location.href.includes("%E2%80%8B%E2%80%8B")) {
        window.location.href = window.location.href.replace("%E2%80%8B%E2%80%8B", "");
    }

    return (
        <Outlet />
    );
}