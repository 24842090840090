import { Box, Button, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { CompanyTitle } from "../../components/CompanyTitle"
import { Plus } from "lucide-react"
import { useEffect, useState } from "react";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { useDealContext } from "../../../deal/context/DealContext";
import { AdvisorTable } from "../../styles/AdvisorStyles";
import { Blocklist } from "../../components/outreach/Blocklist";

export const OutreachBlocklist = ({ setup = false }: { setup?: boolean }) => {

    const { dealContext } = useDealContext();
    const { project, role } = dealContext;
    const projectClient = new ProjectHttpClient();

    const { getAccessTokenSilently } = useAuth0();
    const [exclusions, setExclusions] = useState<any[]>([]);

    const fetchExclusions = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.getExclusions(project.id, accessToken);
        if (data) {
            setExclusions(data.exclusions);
        }
    }

    useEffect(() => {
        if (project) {
            fetchExclusions();
        }
    }, [project])

    return (
        <Box>
            { setup ?
                <CompanyTitle viewerRole={role} project={project} section="Blocklist" subheading="Consider excluding executives from any companies that you are already engaged with or that you see as competitive." />
            :
                <CompanyTitle viewerRole={role} project={project} section="Outreach > Blocklist" subheading="Exclude current customers and opportunities from being invited to be advisors." />
            }
            <Blocklist project={project} exclusions={exclusions} fetchExclusions={fetchExclusions} setup={setup} />
        </Box>
    )
}