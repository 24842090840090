import { getViewpointInvestmentActivity } from "../../../core/services/gutcheck-api.service";
import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";

export const InvestmentActivity = ( {advisor}: any) => {

    const [ viewpointActivity, setViewpointActivity ] = useState<any>(null);
    const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';

    useEffect(() => {
        const loadViewpointActivity = async () => {
            const { data, error } = await getViewpointInvestmentActivity(advisor.public_id);
            if (data) {
                setViewpointActivity(data.viewpointActivity);
            }
        }

        loadViewpointActivity();

    }, [advisor]);

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString 
    }

    if (!viewpointActivity || viewpointActivity.length === 0) {
        return <></>;
    }

    return (
        <div className="display-box">
            <h5>Soft-Circled Experts</h5>
            <List className="recent-activity-list">
                {viewpointActivity?.map((activity: any, index: number) => {
                    const image = /placeholder\.png/.test(activity.image) ? defaultIconSvg : activity.image;
                    return (
                        <ListItem className="recent-activity-item" id={activity.uuid} key={index} >
                            <div className="activity-photo-wrapper">
                                <img onError={handleImageError} className="activity-photo" src={image} alt="user" height={30} />
                            </div>
                            <p><strong>{activity.name.replace(/\ .*/,"")}</strong><br/>{activity.title}</p>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    );
}