import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useUserContext } from "../../../features/users";
import { useBrandContext } from "../../../hooks/useBrandContext";
import { useSubdomain } from "../../../hooks/useSubdomain";

export const Dashboard = () => {

    const navigate = useNavigate();

    const { userContext, setUserContext } = useUserContext();
    const { brandContext } = useBrandContext();
    const { subdomain, subdomainFetched } = useSubdomain();

    const invitationCode = localStorage.getItem("invcode");

    useEffect(() => {

        if (invitationCode && invitationCode !== "") {
            return navigate(`/invitation/${invitationCode}/accept`);

        } else if (userContext.fetched && userContext.dbUser) {

            // Find the organization that matches the brand tag. If not found, use the first organization if it exists
            const organization = userContext.organizationMemberships.find((membership: any) => membership.organization?.tag === brandContext?.brand?.tag)?.organization || userContext.organizationMemberships?.[0]?.organization || null;
            if (organization) {
                if (organization.experience === "advisor") {
                    window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/portfolio`;
                    return;
                } else {
                    window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://app.${process.env.REACT_APP_APP_ORIGIN}/o/${organization.tag}`;
                    return;
                }

            } else {

                // Find the project that matches the subdomain tag. If not found, use the first project if it exists
                const projectMembership =
                    userContext.projectMemberships.find((membership: any) => membership.project?.organization?.tag === brandContext?.brand?.tag)
                    || userContext.projectMemberships.find((membership: any) => membership.project?.tag === subdomain)
                    || userContext.projectMemberships?.[0];

                const project = projectMembership?.project || null;

                if (project) {
                    if (projectMembership?.role === "advisor") {
                        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/advisor`;
                    } else if (project.experience === "advisor") {
                        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c`;
                    } else {
                        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://app.${process.env.REACT_APP_APP_ORIGIN}/o/${project?.organization?.tag}/d/${project?.tag}`;
                    }

                // If the user is not a member of any organization or project, redirect to the organization creation page
                } else if (userContext.organizationMemberships?.length === 0 && userContext.projectMemberships?.length === 0) {
                    navigate('/not-available', { replace: true });
                    return;
                }
            }

        } else {
            <UserNotFound />
        }

    }, [userContext.fetched, brandContext.fetched, subdomainFetched]);



    if (!userContext.fetched) return <Loading />


    if (userContext.fetched && !userContext.dbUser) return <UserNotFound />

    return <></>;
}