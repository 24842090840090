import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import EmailVerification from "./components/EmailVerification";
import { useAuth0 } from "@auth0/auth0-react";
import { SilentLogin } from "./components/SilentLogin";
import { SignUp } from "./components/SignUp";
import { CallbackPage } from "./views/pages/CallbackPage";
import { PageLayout } from "./components/PageLayout";
import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";
import AppLayoutRoutes from "./routes/AppLayoutRoutes";
import BasicLayoutRoutes from "./routes/BasicLayoutRoutes";
import UnrecognizedUser from "./components/UncrecognizedUser";
import { AppLayout } from "./components/AppLayout";
import { DealProvider } from "./features/deal/context";
import { UserProvider } from "./features/users";
import { GuestRoutes } from "./features/guest/routes";
import { FirmProvider } from "./features/firm";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LandingRoutes } from "./features/landing/routes/index";
import { BasicLayout } from "./components/BasicLayout";
import { BrandProvider } from "./hooks/useBrandContext";
import { Invitation } from "./features/invitations/routes/Invitation";
import { InvitationsRoutes } from "./features/invitations";
import { AdvisorProvider } from "./features/advisor/context/AdvisorContext";
import { ViewportManager } from './components/ViewportManager';


export const Root = () => {
    initFontAwesome();

    const { user, isLoading, error, loginWithRedirect } = useAuth0();

    if (error) {
        if (error.message === 'Login required') {
            loginWithRedirect({
                appState: {
                    returnTo: "/dashboard",
                },
                authorizationParams: {
                }
            });

        } else {
            return <div>Oops... {error.message}</div>;
        }
    };

    if (isLoading) {
        return (
            <Loading />
        );
    };


    if (!window.location.host.includes(process.env.REACT_APP_APP_ORIGIN || "brightloop.com")) {

        const responsiveRoutes = [
            {
                pattern: '/*',
                content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
            },
            {
                pattern: '/company/*',
                content: 'width=device-width, initial-scale=1.0, user-scalable=1'
            }
        ];

        return (
            <BrandProvider>
                <UserProvider>
                    <FirmProvider>
                        <DealProvider>
                            <ViewportManager routes={responsiveRoutes} />
                            <AppLayout>
                                <Routes>
                                    <Route path="/invitation/*" element={<InvitationsRoutes />} />
                                    <Route path="/login/silent" element={<SilentLogin auth0User={user} isLoading={isLoading} />} />
                                    <Route path="*" element={<LandingRoutes />} />
                                </Routes>
                            </AppLayout>
                        </DealProvider>
                    </FirmProvider>
                </UserProvider>
            </BrandProvider>
        )
    }

    if (user && !user.email_verified) {
        return (
            <BrandProvider>
                <UserProvider>
                    <FirmProvider>
                        <DealProvider>
                            <Routes>
                                <Route path="/login/silent" element={<SilentLogin auth0User={user} isLoading={isLoading} />} />
                            </Routes>
                            <AppLayout>
                                <Routes>
                                    <Route path="/unrecognized" element={<UnrecognizedUser />} />
                                    <Route path="*" element={<EmailVerification auth0User={user} isLoading={isLoading} />} />
                                </Routes>
                            </AppLayout>
                        </DealProvider>
                    </FirmProvider>
                </UserProvider>
            </BrandProvider>
        );
    };

    const responsiveRoutes = [
        {
            pattern: '/e/*',
            content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        },
        {
            pattern: '/advise/*',
            content: 'width=device-width, initial-scale=1.0, user-scalable=1'
        }
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrandProvider>
                <UserProvider>
                    <FirmProvider>
                        <DealProvider>
                            <AdvisorProvider>
                                <ViewportManager routes={responsiveRoutes} />
                                <Routes>
                                    {/* Pages WITHOUT layout */}
                                    <Route path="/login/silent" element={<SilentLogin auth0User={user} isLoading={isLoading} />} />
                                    <Route path="/signup" element={<SignUp />} />
                                    <Route path="/callback" element={<CallbackPage />} />
                                    <Route path="/e/*" element={<BasicLayoutRoutes />} />
                                    <Route path="/guest/*" element={<GuestRoutes />} />
                                    {/* Pages WITH layout */}
                                    <Route path="*" element={<AppLayoutRoutes />} />
                                </Routes>
                            </AdvisorProvider>
                        </DealProvider>
                    </FirmProvider>
                </UserProvider>
            </BrandProvider>
        </LocalizationProvider>
    );
};

export default Root;