import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Hero from "../components/Hero";
import { useAuth0 } from "@auth0/auth0-react";
import { useSubdomain } from "../hooks/useSubdomain";
import { useBrandContext } from "../hooks/useBrandContext";
import { FirmLanding } from "../features/landing/routes/FirmLanding";
import { CompanyLanding } from "../features/landing/routes/CompanyLanding";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export const HomeComponent = () => {

    const navigate = useNavigate();
    const { subdomain, subdomainFetched } = useSubdomain();
    const { brandContext } = useBrandContext();

    const {
        isLoading,
        isAuthenticated
    } = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            return navigate("./dashboard");
        }
        if (brandContext?.brand?.custom_domain) {
            if (subdomain !== brandContext?.brand?.tag) {
                window.location.href = `https://${brandContext?.brand?.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ""}/${subdomain}`;
                return;
            } else {
                window.location.href = `https://${brandContext?.brand?.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ""}/`;
                return;
            }
        } else {
            window.location.href = `${process.env.REACT_APP_MARKETING_SITE}`;
            return;
        }
    }, [isLoading, isAuthenticated, subdomainFetched, brandContext?.brand?.custom_domain])


    if (subdomain && subdomain === brandContext?.brand?.tag) {
        return <FirmLanding />
    } else if (subdomain && brandContext?.brand?.tag && subdomain !== brandContext?.brand?.tag) {
        const url = brandContext?.brand?.custom_domain ? `${brandContext?.brand?.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ""}` : `${brandContext?.brand?.tag}.${process.env.REACT_APP_APP_ORIGIN}/advise`;
        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ? process.env.REACT_APP_APP_PROTOCOL : "https"}://${url}/${subdomain}`;
    } 

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Box sx={{
                background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#ffffff',
                minHeight: '20vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: "30px",
                    maxWidth: '1200px',
                    width: '100%',
                    margin: '0 auto',
                    padding: "40px 40px" }}>
                    
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flex: '1 1 500px',
                        minWidth: 0,
                    }}>
                        <Typography variant="h3" fontWeight="bold">More successful startups.</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#e9e9e9", padding: "20px 40px", textAlign: "center" }}>
                <Typography fontSize={"1.5rem"} sx={{ fontStyle: "italic" }}>BrightLoop powers light-touch advisory networks for top venture firms and VC-backed startups.</Typography>
            </Box>
        </Box>
    );



}

export default HomeComponent;
