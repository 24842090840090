import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "../modules/ClientProvider";

type OrganizationMembersData = {
    fetched: boolean;
    members: any;
    error: { message: any } | null;
}

const initState: OrganizationMembersData = {
    fetched: false,
    members: [],
    error: null
}

export const useGetOrganizationMembers = (organizationId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);
    const organizationClient = ClientProvider.provideOrganizationClient();

    const reloadOrganizationMembers = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.getOrganizationMembers(organizationId, accessToken);

        if (data) {
            const fetchedOrganizationMembers: OrganizationMembersData = {
                fetched: true,
                members: data.members ?? [],
                error: error
            };
            setState(fetchedOrganizationMembers);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchOrganizationMembers = async () => {
            if (!isMounted) return;
            reloadOrganizationMembers();
        };

        fetchOrganizationMembers();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadOrganizationMembers };
};
