import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getLinkedinSearches } from "../services/gutcheck-admin-api.service";

export const useLinkedinSearches = (projectId: string) => {
    const [linkedinSearches, setLinkedinSearches] = useState<any[]>([]);
    const [fetched, setFetched] = useState(false);
    const [error, setError] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const loadLinkedinSearches = async () => {
        if (!projectId) return;
        
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getLinkedinSearches(parseInt(projectId), accessToken);

        if (data) {
            setLinkedinSearches(data.linkedinSearches || []);
            setFetched(true);
        } else {
            setError(error?.message);
            setLinkedinSearches([]);
        }
    };

    useEffect(() => {
        loadLinkedinSearches();
    }, [projectId, getAccessTokenSilently]);

    return {
        fetched,
        linkedinSearches,
        error,
        reloadLinkedinSearches: loadLinkedinSearches
    };
}; 