import { MeetingsReport } from "../reports/MeetingsReport";
import { AdvisorReport } from "../reports/AdvisorReport";
import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Grid, TextField } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { getOrganizations, getProjects } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";

type Organization = {
    id: string;
    name: string;
    tag: string;
};

type Project = {
    id: string;
    name: string;
    tag: string;
    organization: Organization;
};

export const AdminReports = () => {

    const [selectedOrg, setSelectedOrg] = useState<string>('');
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [projectOptions, setProjectOptions] = useState<Project[]>([]);
    const [organizationOptions, setOrganizationOptions] = useState<Organization[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date(2025, 0, 26)); // January 26, 2025
    const [endDate, setEndDate] = useState<Date>(new Date());

    const { getAccessTokenSilently } = useAuth0();


    useEffect(() => {

        const fetchProjects = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data } = await getProjects(accessToken, "advisor");
            const sortedProjects = data.projects.sort((a: any, b: any) => a.name.localeCompare(b.name));
            const sortedOrganizations = data.projects.reduce((acc: any, project: any) => {
                if (!acc.some((org: any) => org.id === project.organization.id)) {
                    acc.push(project.organization);
                }
                return acc;
            }, []).sort((a: any, b: any) => a.name.localeCompare(b.name));
            setOrganizations(sortedOrganizations);
            setProjects(sortedProjects);
            setProjectOptions(sortedProjects);
            setOrganizationOptions(sortedOrganizations);
        };

        fetchProjects();
    }, []);

    
    const handleOrgChange = (event: SelectChangeEvent<string>) => {
        setSelectedOrg(event.target.value);
        setProjectOptions(event.target.value ? projectOptions.filter((project: any) => project.organization.id === event.target.value) : projectOptions);
    };

    const handleProjectChange = (event: SelectChangeEvent<string>) => {
        setSelectedProject(event.target.value);
        setOrganizationOptions(event.target.value ? organizationOptions.filter((organization: any) => organization.id === projectOptions.find((project: any) => project.id === event.target.value)?.organization.id) : organizationOptions);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Create date in local timezone to avoid UTC conversion issues
        const dateValue = event.target.value; // Format: YYYY-MM-DD        
        const [year, month, day] = dateValue.split('-').map(Number);
        
        // Create date with year, month, day components to avoid timezone issues
        const newDate = new Date(year, month - 1, day, 0, 0, 0, 0); // Month is 0-indexed in JavaScript        
        setStartDate(newDate);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Create date in local timezone to avoid UTC conversion issues
        const dateValue = event.target.value; // Format: YYYY-MM-DD
        const [year, month, day] = dateValue.split('-').map(Number);
        
        // Create date with year, month, day components to avoid timezone issues
        const newDate = new Date(year, month - 1, day, 23, 59, 59, 999); // Month is 0-indexed in JavaScript        
        setEndDate(newDate);
    };

    return (
        <div>
            <h3>Admin Dashboard</h3>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'space-between', marginTop: "40px"}}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>

                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate.toISOString().split('T')[0]}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ minWidth: 200 }}
                    />
                    
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate.toISOString().split('T')[0]}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ minWidth: 200 }}
                    />

                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Organization</InputLabel>
                        <Select
                            value={selectedOrg}
                            label="Organization"
                            onChange={handleOrgChange}
                        >
                            <MenuItem value="">All Organizations</MenuItem>
                            {organizations.map(org => (
                                <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Project</InputLabel>
                        <Select
                            value={selectedProject}
                            label="Project"
                            onChange={handleProjectChange}
                        >
                            <MenuItem value="">All Projects</MenuItem>
                            {projects.map(project => (
                                <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "20px", marginBottom: "40px"}}>
                <Box sx={{ flex: "1 1 520px"}}>
                    <AdvisorReport startDate={startDate} endDate={endDate} selectedOrg={selectedOrg} selectedProject={selectedProject} />
                </Box>
                <Box sx={{ flex: "1 1 520px"}}>
                    <MeetingsReport startDate={startDate} endDate={endDate} selectedOrg={parseInt(selectedOrg)} selectedProject={parseInt(selectedProject)} />
                </Box>
            </Box>
        </div>
    );
}