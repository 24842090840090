import { useState, useEffect } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { getCompanies, deleteCompany } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Button } from "@mui/material";
import { CompanyLogo } from "../../../components/CompanyLogo";
import dayjs from "dayjs";
import { ICompany } from "../../../types";
import { CompanyForm } from "../projects/components/CompanyUpdate";
import { ConfirmationModal } from "../../../components/ConfirmationModal";

export const AdminBrands = () => {

    const [companies, setCompanies] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const [visibleRows, setVisibleRows] = useState(new Set());
    const [editCompany, setEditCompany] = useState<ICompany | null>(null);
    const [open, setOpen] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState<ICompany | null>(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const fetchCompanies = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getCompanies(accessToken);
        if (data) {
            setCompanies(data.companies);
        }
    }

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry: IntersectionObserverEntry) => {
                    if (entry.isIntersecting) {
                        const target = entry.target as HTMLElement;
                        setVisibleRows((prev) => {
                            const newSet = new Set([...prev, target.dataset.companyId]);
                            return newSet;
                        });
                    }
                });
            },
            { threshold: 0.1 }
        );

        setTimeout(() => {
            const rows = document.querySelectorAll('[data-company-id]');
            rows.forEach((row) => {
                observer.observe(row);
            });
        }, 100);

        return () => observer.disconnect();
    }, [companies]);

    const handleCompanyFormClose = () => {
        fetchCompanies();
        setEditCompany(null);
        setOpen(false);
    }

    const handleDeleteConfirm = async () => {
        if (!companyToDelete) return;
        
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await deleteCompany(companyToDelete.id, accessToken);
        
        if (!error) {
            setDeleteSuccess(true);
            fetchCompanies();
        }
    };

    const handleDeleteClose = () => {
        setCompanyToDelete(null);
        setDeleteOpen(false);
        setDeleteSuccess(false);
    };

    return (
        <>
            <DocumentTitle pageTitle="Admin - Brands" />
            <div>AdminBrands</div>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Domain</TableCell>
                            <TableCell>Logo</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((company: any) => (
                            <TableRow 
                                key={company.id} 
                                data-company-id={company.id}
                            >
                                <TableCell>{company.name}</TableCell>
                                <TableCell>{company.domain}</TableCell>
                                <TableCell>
                                    {visibleRows.has(String(company.id)) && (
                                        <CompanyLogo company={company} />
                                    )}
                                </TableCell>
                                <TableCell>{dayjs(company.created_at).format('MMM D, YYYY')}</TableCell>
                                <TableCell>{dayjs(company.updated_at).format('MMM D, YYYY')}</TableCell>
                                <TableCell>
                                    <Button onClick={() => {
                                        setEditCompany(company);
                                        setOpen(true);
                                    }}>Edit</Button>
                                    <Button onClick={() => {
                                        setCompanyToDelete(company);
                                        setDeleteOpen(true);
                                    }}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editCompany && <CompanyForm company={editCompany} setCompany={setEditCompany} open={open} onClose={handleCompanyFormClose} />}
            {companyToDelete && (
                <ConfirmationModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onConfirm={handleDeleteConfirm}
                    success={deleteSuccess}
                    title={deleteSuccess ? "Company Deleted" : "Delete Company"}
                    message={deleteSuccess 
                        ? `${companyToDelete.name} has been deleted successfully.`
                        : `Are you sure you want to delete ${companyToDelete.name}?`
                    }
                />
            )}
        </>
    );
}