import { Box, Button, FormControlLabel, FormGroup, Stack, Switch, TextField, Autocomplete, Chip } from "@mui/material";
import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import ClientProvider from "../../../../modules/ClientProvider";
import { useDealContext } from "../../../deal/context";
import debounce from "lodash/debounce";

const defaultScheduler = {
    scheduling_url: "",
    is_active: true,
    provider: "calendly",
    type: "prospect",
    attendees: []
};

const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const CalendlySetup = ({ schedulerData, onSubmit, setup = false }: { schedulerData: any, onSubmit: () => void, setup?: boolean }) => {
    const [calendlyUrl, setCalendlyUrl] = useState<string>(schedulerData?.url || "");
    const { getAccessTokenSilently } = useAuth0();
    const { dealContext } = useDealContext();
    const { project } = dealContext;

    const [attendeeEmails, setAttendeeEmails] = useState<string[]>(
        schedulerData?.attendees?.map((attendee: { email: string }) => attendee.email) || []
    );
    const [inputValue, setInputValue] = useState('');
    const processedEmails = useRef<Set<string>>(new Set(attendeeEmails));

    const [scheduler, setScheduler] = useState<any>(schedulerData || defaultScheduler);

    const handleSubmit = async () => {
        const projectClient = ClientProvider.provideProjectClient();
        const accessToken = await getAccessTokenSilently();

        const { data, error } = await projectClient.updateDemoScheduler(project.id, "prospect", scheduler, accessToken);
        if (error) {
            console.error('Error updating demo scheduler:', error);
        } else {
            onSubmit();
        }
    };

    // Create a debounced version of handleSubmit
    const debouncedSubmit = debounce(handleSubmit, 1000);

    // Auto-save when scheduling_url changes
    useEffect(() => {
        if (scheduler?.scheduling_url && scheduler?.scheduling_url !== schedulerData?.scheduling_url) {
            debouncedSubmit();
        }
    }, [scheduler?.scheduling_url]);

    const handleAddEmails = useCallback((emails: string[]) => {
        const newEmails = emails.filter(email => {
            const trimmedEmail = email.trim();
            return trimmedEmail &&
                isValidEmail(trimmedEmail) &&
                !attendeeEmails.includes(trimmedEmail) &&
                !processedEmails.current.has(trimmedEmail);
        });

        if (newEmails.length > 0) {
            setAttendeeEmails(prev => [...prev, ...newEmails]);
            // Update scheduler.attendees with the correct format
            setScheduler((prev: any) => ({
                ...prev,
                attendees: [...prev.attendees, ...newEmails.map(email => ({ email }))]
            }));
            newEmails.forEach(email => processedEmails.current.add(email));
        }
    }, [attendeeEmails]);

    const handleDeleteEmail = (emailToDelete: string) => {
        setAttendeeEmails(prev => prev.filter(email => email !== emailToDelete));
        // Update scheduler.attendees with the correct format
        setScheduler((prev: any) => ({
            ...prev,
            attendees: prev.attendees.filter((attendee: { email: string }) => attendee.email !== emailToDelete)
        }));
    };

    // Add new useEffect to trigger submit when attendees change
    useEffect(() => {
        if (scheduler?.attendees &&
            JSON.stringify(scheduler.attendees) !== JSON.stringify(schedulerData?.attendees)) {
            debouncedSubmit();
        }
    }, [scheduler?.attendees]);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "30px", position: "relative" }}>
            <Box className="display-box" sx={{ display: "flex", flexDirection: "column", gap: "24px", width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0" }}>
                    <h5>Please provide your Calendly Event URL</h5>
                    <p>Be sure to setup a new event type so it's clear these are meetings with advisors.</p>
                    <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        label="Calendly URL"
                        value={scheduler?.scheduling_url}
                        name="scheduling_url"
                        onChange={(e) => setScheduler({ ...scheduler, scheduling_url: e.target.value })}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <h5>Enter the email address of each team member that will attend.</h5>
                    <p>We'll send them prep material so they're ready for each meeting. </p>
                    <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={attendeeEmails}
                        inputValue={inputValue}
                        size="small"
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                            setAttendeeEmails(newValue);
                        }}
                        onKeyDown={(event) => {
                            if (['Enter', ',', ' '].includes(event.key)) {
                                event.preventDefault();
                                if (inputValue.trim() && isValidEmail(inputValue.trim())) {
                                    handleAddEmails([inputValue]);
                                    setInputValue('');
                                }
                            } else if (event.key === 'Tab' && inputValue.trim()) {
                                // Don't prevent default for Tab to allow normal navigation
                                if (isValidEmail(inputValue.trim())) {
                                    handleAddEmails([inputValue]);
                                }
                                // We don't clear the input here to allow the browser to move focus as normal
                            }
                        }}
                        onBlur={() => {
                            if (inputValue.trim() && isValidEmail(inputValue.trim())) {
                                handleAddEmails([inputValue]);
                                setInputValue('');
                            }
                        }}
                        onPaste={(event) => {
                            event.preventDefault();
                            const pastedText = event.clipboardData.getData('text');
                            const emails = pastedText.split(/[\s,]+/).filter(isValidEmail);
                            handleAddEmails(emails);
                            setInputValue('');
                        }}
                        renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip
                                    variant="filled"
                                    label={option}
                                    {...getTagProps({ index })}
                                    onDelete={() => handleDeleteEmail(option)}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Type or paste emails and press Enter, comma, or space"
                                fullWidth
                            />
                        )}
                        clearIcon={null}
                        forcePopupIcon={false}
                    />
                </Box>
            </Box>
            <Box className="display-box white" sx={{ maxWidth: "250px", position: setup ? "absolute" : "relative", right: setup ? "-270px" : "0", top: setup ? "0" : "0" }}>
                <h5>Calendly Tips</h5>
                <p>We recommend setting up a new event type for scheduling meetings with your advisors. Use the following settings:</p>
                <h6>Event Type</h6>
                <p>"{project.name} Advisor Intro"</p>
                <h6>Description</h6>
                <p>"Short demo and feedback with {project.name} advisors."</p>
                <h6>Additional Question 1</h6>
                <p>"Please see the following link for additional details"<br/>(Note: This field will be populated with a link to their advisor profile and must be the first additional question)</p>
                <h6>Calendar Invitation Title</h6>
                <p>"{project.name} Advisor Demo with &lt;Invitee Full Name&gt;"</p>
                <h6>Email Reminders</h6>
                <p>1 day before and 1 hour before</p>
            </Box>
        </Box>
    );
};