import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";

const ProfileImageBox = styled("div")(({ theme }) => ({
    width: "80px",
    height: "80px",
    position: "relative",
    overflow: "hidden",
    borderRadius: "50%",
    border: "1px solid #c9c9c9",
    boxShadow: "inset 0 0 5px #c9c9c9",
    textAlign: "center",
    "&.small": {
        width: "50px",
        height: "50px"
    },
    "&.medium": {
        width: "80px",
        height: "80px"
    },
    "&.large": {
        width: "120px",
        height: "120px"
    },
}));

const ProfileImage = styled("img")(({ theme }) => ({
    display: "inline",
    width: "audo",
    height: "100%",
}));

const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';

export const ViewpointProfileImage = ({ viewpoint, size = "medium" }: any) => {

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString
    }

    const handleLinkedInClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(viewpoint.linkedin, '_blank');
    }

    return (
        <div style={{position: "relative"}}>
            <ProfileImageBox className={size}>
                <ProfileImage
                    src={viewpoint.image}
                    alt={viewpoint.name}
                    onError={handleImageError} />
            </ProfileImageBox>
            {size === "small" && viewpoint.linkedin && viewpoint.linkedin !== "" &&
                <Box onClick={handleLinkedInClick} component="img" className="li-icon" src={'/images/linkedin.png'} sx={{ position: "absolute", bottom: 0, right: 0 }} />
            }
            {size === "medium" && viewpoint.linkedin && viewpoint.linkedin !== "" &&
                <Box onClick={handleLinkedInClick} component="img" className="li-icon" src={'/images/linkedin.png'} sx={{ position: "absolute", top: 0, right: 0 }} />
            }
            {size === "large" && viewpoint.linkedin && viewpoint.linkedin !== "" &&
                <Box onClick={handleLinkedInClick} sx={{ position: "absolute", top: 0, right: 0, fontSize: "1.6rem", color: "#0e76a8" }} >
                    <FontAwesomeIcon icon={faLinkedin} />
                </Box>
            }
        </div>
    );
}
