import { Box } from "@mui/material";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { useDealContext } from "../../deal/context";
import { AdvisorReferals } from "../components/AdvisorReferals";

export const AdvisorRefer = () => {
    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const viewerRole = dealContext.role;

    return (
        <>
            <AdvisorTitle 
                project={project} 
                section="Refer > Suggest an Advisor" 
                subheading={`Suggest your contacts and colleagues to become ${project.name} advisors and they'll receive an invitation.`} 
            />
            <AdvisorReferals />

        </>
    )
}