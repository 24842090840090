import { Box, Button, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { AdvisorCompany, AdvisorProfileSummary } from "../../../advisor";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { AdvisorTable } from "../../styles/AdvisorStyles";
import { IAdvisor } from "../../../../types";

export const CustomersList = ({ advisors }: { advisors: IAdvisor[] }) => {

    return (
        <TableContainer sx={{ marginBottom: "60px", marginTop: "20px" }}>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="company">Company</TableCell>
                        <TableCell className="profile">Advisor</TableCell>
                        <TableCell className="center">Rating</TableCell>
                        <TableCell className="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {advisors.map((advisor: any) => {
                        const lastMeeting = advisor.meetings?.sort((a: any, b: any) => new Date(b.event_start).getTime() - new Date(a.event_start).getTime())[0];
                        return (
                            <CompanyAdvisorLink uuid={advisor.uuid} key={`link-${advisor.uuid}`}>
                                <TableRow key={advisor.uuid}>
                                    <TableCell className="company"><AdvisorCompany advisor={advisor} /></TableCell>
                                    <TableCell className="profile"><AdvisorProfileSummary advisor={advisor} /></TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()}>
                                        <Rating value={lastMeeting.ratings?.length > 0 ? lastMeeting.ratings[0].rating : 0} max={4} readOnly />
                                    </TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()}>
                                        ---
                                    </TableCell>
                                </TableRow>
                            </CompanyAdvisorLink>
                        )
                    })}
                </TableBody>
            </AdvisorTable>
        </TableContainer>


    )
}