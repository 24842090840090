import { callExternalApi } from "../../services/external-api.service";

class ProjectHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = apiServerUrl;
    }

    async start(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/start`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectData(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}`,
            method: "GET",
            headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    };

    async getRespondantsData(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/respondents/${projectId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getLeadsData(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/leads`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getCollaborationToken(accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/user/self/collaboration/token`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createInvitations(organizationId: number, projectId: number, invitees: any[], note: string, role: string, type: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/invitations`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                projectId,
                organizationId,
                invitees,
                note,
                role,
                type
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectAssets(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/assets`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createAsset(projectId: number, assetData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: assetData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateAsset(projectId: number, assetId: number, assetData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset/${assetId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: assetData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteAsset(projectId: number, assetId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset/${assetId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectMembers(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/members`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectInvitations(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitations`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectShareLinks(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLinks`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectShareLink(projectId: number, shareLinkId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLink/${shareLinkId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateProjectShareLink(projectId: number, shareLinkId: number, shareLinkData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLink/${shareLinkId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: shareLinkData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async removeMemberFromProject(projectId: number, memberUserId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/member/${memberUserId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
    
    async updateMemberRole(projectId: number, memberUserId: number, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/member/${memberUserId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                role: role
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateInvitationRole(projectId: number, invitationId: number, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitation/${invitationId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                invitee_role: role
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async resendInvitation(projectId: number, invitationId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitation/${invitationId}/resend`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendAngelMessage(projectId: number, viewpointId: number, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/message/${viewpointId}`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "ANGEL"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendAdvisorAngelMessage(projectId: number, advisorUuid: string, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisor/${advisorUuid}/angel`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "ANGEL"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendProspectMessage(projectId: number, viewpointId: number, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/message/${viewpointId}`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "PROSPECT"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendAdvisorProspectMessage(projectId: number, advisorUuid: string, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisor/${advisorUuid}/prospect`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "PROSPECT"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async generateContributorLink(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/contributorLink`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectDemoSchedulers(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/schedulers`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getDemoSchedulersByType(projectId: number, type: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/schedulers/${type}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createDemoScheduler(projectId: number, demoSchedulerData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: demoSchedulerData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateDemoScheduler(projectId: number, type: string, demoScheduleData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${type}`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: demoScheduleData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteDemoScheduler(projectId: number, demoScheduleId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${demoScheduleId}`,
            method: "DELETE",   
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getDemoScheduler(projectId: number, schedulerType: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${schedulerType}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async hideProspect(projectId: number, viewpointId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/viewpoint/${viewpointId}/hideProspect`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async unhideProspect(projectId: number, viewpointId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/viewpoint/${viewpointId}/unhideProspect`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getViewpoint(uuid: string, projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/viewpoint/${uuid}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectTouchpoints(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/touchpoints`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getTouchpoint(projectId: number, touchpointUuid: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/touchpoint/${touchpointUuid}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getMeetings(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/meetings`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getAdvisors(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisors`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getPreviewAdvisors(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisors/preview`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getAdvisorRatings(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisors/ratings`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getAdvisor(advisorUuid: string, projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisor/${advisorUuid}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async rateMeeting(projectId: number, meetingUuid: string, rating: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/meeting/${meetingUuid}/rate`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: { rating }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateAdvisorStage(projectId: number, advisorUuid: string, stage: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisor/${advisorUuid}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: { stage }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getExclusions(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/exclusions`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
    
    async addExclusion(projectId: number, exclusionData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/exclusion`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: exclusionData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteExclusion(projectId: number, exclusionId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/exclusion/${exclusionId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async uploadExclusions(projectId: number, file: File, accessToken: any) {
        const formData = new FormData();
        formData.append('csv', file);

        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/exclusions/bulk`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                // Don't set content-type - it will be automatically set with the correct boundary
            },
            data: formData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getInvitationTemplates(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitationTemplates`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async addInvitationTemplate(projectId: number, templateData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitationTemplate`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: templateData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateInvitationTemplate(projectId: number, templateUuid: string, templateData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitationTemplate/${templateUuid}`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: templateData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteInvitationTemplate(projectId: number, templateUuid: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitationTemplate/${templateUuid}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getAdvisorsSuggestions(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisorSuggestions`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async addAdvisorSuggestion(projectId: number, suggestionData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisorSuggestion`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: suggestionData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async uploadAdvisorSuggestions(projectId: number, file: File, accessToken: any) {
        const formData = new FormData();
        formData.append('csv', file);

        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisorSuggestions/bulk`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: formData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteAdvisorSuggestion(projectId: number, suggestionId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/advisorSuggestion/${suggestionId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getLinkedinSearches(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/linkedinSearches`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async addLinkedinSearch(projectId: number, linkedinSearchData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/linkedinSearch`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: linkedinSearchData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateLinkedinSearch(projectId: number, linkedinSearchUuid: string, linkedinSearchData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/linkedinSearch/${linkedinSearchUuid}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: linkedinSearchData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteLinkedinSearch(projectId: number, linkedinSearchUuid: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/linkedinSearch/${linkedinSearchUuid}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getPaidCampaigns(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/paidCampaigns`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async addPaidCampaign(projectId: number, campaignData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/paidCampaign`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: campaignData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updatePaidCampaign(projectId: number, campaignUuid: string, campaignData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/paidCampaign/${campaignUuid}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: campaignData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deletePaidCampaign(projectId: number, campaignUuid: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/paidCampaign/${campaignUuid}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createMeetingRequestMessage(projectId: number, advisorUuid: string, prospectForm: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/meetingRequest`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                advisorUuid: advisorUuid,
                prospectForm: prospectForm
            }
        };

            const { data, error } = await callExternalApi({ config });
            
            return {
                data: data || null,
                error,
        };
    }


}

export default ProjectHttpClient;
