import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName} from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";

const sections = {
    evaluate: {
        title: "Feedback",
        lockedDescription: "",
        description: "Add your viewpoint on this company.",
        icon: "magnifying-glass-chart"
    },
    invest: {
        title: "Invest",
        lockedDescription: "Co-invest in this deal alongside the firm",
        description: "Co-invest in this deal alongside the firm",
        icon: "money-bill-trend-up"
    },
    advise: {
        title: "Advise",
        lockedDescription: "Earn more by helping after the deal",
        description: "Earn more equity by helping and advising.",
        icon: "hand-holding-medical"
    },
    engage: {
        title: "Meet",
        lockedDescription: "Get invited to meet the team",
        description: "Meet the founders and team.",
        icon: "calendar"
    },
    refer: {
        title: "Refer",
        lockedDescription: "Connect the team with your network",
        description: "Connect the team with your network",
        icon: "share-nodes"
    }

}

const SectionTab = ({section, title, description, to, locked}: any) => {


    
    if (locked) {
        return (
            <div className={`sidebar-tab locked`}>
                <div className="tab-icon">
                    <FontAwesomeIcon icon={sections[section as keyof typeof sections].icon as IconName} />
                </div>
                <div className="tab-content">
                    <p className="tab-title">{sections[section as keyof typeof sections].title}</p>
                    <p className="tab-description">{sections[section as keyof typeof sections].lockedDescription}</p>
                </div>
            </div>
        )
    } else {
        return (
            <NavLink to={to} className={`sidebar-tab unlocked`} end={section !== "invest"}>
                <div className="tab-icon">
                    <FontAwesomeIcon icon={sections[section as keyof typeof sections].icon as IconName} />
                </div>
                <div className="tab-content">
                    <p className="tab-title">{sections[section as keyof typeof sections].title}</p>
                    <p className="tab-description">{sections[section as keyof typeof sections].description}</p>
                </div>
            </NavLink>
        )
    }

}

export const EvaluationSidebar = ({ advisor, investmentUnlocked, engageUnlocked, section, setSection }: any) => {

    return (
        <div className="sidebar-tab-container evaluation-sidebar">
            <SectionTab section="evaluate" to={`/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}`} locked={false} />
            <SectionTab section="engage" to={`/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}/engage`} locked={!engageUnlocked} />
            <SectionTab section="invest" to={`/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}/invest`} locked={!investmentUnlocked} />
            <SectionTab section="refer" to={`/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}/refer`} locked={true} />
       </div>

    )
}
