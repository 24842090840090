import { Box } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { ExecTabs, ExecTab } from "../../company/styles/AdvisorStyles";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { ITouchpoint, IViewpoint } from "../../../types";
import { TouchpointResponse } from "../components/touchpoint/TouchpointResponse";
import { TouchpointAnalytics } from "../components/touchpoint/TouchpointAnalytics";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { useAdvisor } from "../context/AdvisorContext";

export const AdvisorTouchpoint = () => {

    const { dealContext } = useDealContext();
    const { advisor, loading } = useAdvisor();

    const [ value, setValue ] = useState(0);
    const project = dealContext.project;
    const { touchpointUuid } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    
    const viewpoint = advisor?.viewpoints?.find((viewpoint: IViewpoint) => viewpoint.touchpoint?.uuid === touchpointUuid);

    const handleTabSwitch = (event: any, newValue: any) => {
        setValue(newValue);
    }


    if (!advisor || !viewpoint || !viewpoint.touchpoint) {
        return <Loading />;
    }
    
    return (
        <Box>
            <AdvisorTitle project={project} section={`Feedback > ${viewpoint?.touchpoint?.name}`} subheading="Review your response and what other advisors had to say." />

            <Box>
                <ExecTabs variant="fullWidth" value={value} onChange={handleTabSwitch} sx={{ marginBottom: "0", position: "relative", zIndex: 1000 }}>
                    <ExecTab label={<span>Summary Analytics</span>} disabled={!viewpoint.touchpoint.analysis_url}></ExecTab>
                    <ExecTab label={<span>Your Response</span>}></ExecTab>
                </ExecTabs>
            </Box>
            { value === 0 && <TouchpointAnalytics project={project} touchpoint={viewpoint?.touchpoint} /> }
            { value === 1 && <TouchpointResponse project={project} touchpoint={viewpoint?.touchpoint}/> }
        </Box>
    )
}