import { createSearchParams } from "react-router-dom";
import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_ORIGIN;

export const getProjectData = async (projectId: number, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllDataForSelf = async (accessToken: any, invitationCode?: string|null) => {

  const params = invitationCode ? `?invitationCode=${invitationCode}` : "";
  const config = {
    url: `${apiServerUrl}/user/self${params}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const updateDataForSelf = async (user: any, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/user/self`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: user
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
  
}

export const deleteSelf = async (accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/user/self`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
  
}

export const verifySelf = async (accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/user/self/verify`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
  
}

export const getOrganization = async (organizationTag: string, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/organization/${organizationTag}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getBranding = async (organizationTag: string) => {
  const config = {
    url: `${apiServerUrl}/brand/tag/${organizationTag}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getBrandByDomain = async (domain: string) => {
  const config = {
    url: `${apiServerUrl}/brand/domain/${domain}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

export const getProjectRespondentData = async (projectId: number, respondentId: number, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/respondent/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getRespondentData = async (respondentId: string, project: any, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/respondent/${project?.organization.tag}/${project?.tag}/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllUsers = async ( accessToken: any ) => {

  const config = {
    url: `${apiServerUrl}/admin/user/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};

export const getOrganizations = async ( accessToken: any ) => {

  const config = {
    url: `${apiServerUrl}/admin/organization/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};

export const createOrganizationAndProject = async ( organizationName: string, projectName: string, projectType: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      organizationName: organizationName,
      projectName: projectName,
      projectType: projectType
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const createOrganization = async ( organizationName: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/organization`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      organizationName: organizationName
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const updateOrganization = async ( organizationId: number, organzationForm: any, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/organization/${organizationId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: organzationForm
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
}

export const createProject = async ( organizationID: number, projectForm: any, accessToken: any ) => {
  let goalAttributes = '';
  
  projectForm.goals?.forEach((element: any, idx: number) => {
    if (idx !== 0) goalAttributes += ", ";
    goalAttributes += element.value;
  });

  const config = {
    url: `${apiServerUrl}/project`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ...projectForm,
      type: projectForm.type ?? "investor",
      goals: goalAttributes,
      organizationId: organizationID,
      adminEmail: projectForm.admin_email,
      experience: projectForm.experience
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const uploadAsset = async ( file: any, organizationTag: string, projectTag: string, fileId: string, accessToken: any) => {
  const formData = new FormData();

  formData.append("file", file, fileId);
  formData.append("organization_tag", organizationTag);
  formData.append("project_tag", projectTag);

  const config = {
    url: `${apiServerUrl}/project/upload`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`
    },
    data: formData
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
}

export const uploadLogo = async ( file: any, organizationTag: string, fileId: string, accessToken: any) => {
  const formData = new FormData();

  formData.append("file", file, `${organizationTag}/${fileId}`);
  formData.append("organization_tag", organizationTag);

  const config = {
    url: `${apiServerUrl}/organization/upload`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`
    },
    data: formData
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
}

export const updateProject = async ( projectId: number, projectForm: any, accessToken: any ) => {
  let goalAttributes = '';
  
  projectForm.goals.forEach((element: any, idx: number) => {
    if (idx !== 0) goalAttributes += ", ";
    goalAttributes += element.value;
  });

  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ...projectForm,
      type: projectForm.type,
      goals: goalAttributes
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const deleteProject = async ( projectId: number, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
};

export const archiveProject = async ( projectId: number, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/archive`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
};

export const getInvitation = async ( invitationCode: string | null, accessToken?: any) => {
  const config = {
    url: `${apiServerUrl}/invitation/${invitationCode}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const createInvitations = async ( inviteeEmails: string, organizationId: number, projectId: number | null, role: string, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/organization/${organizationId}/invitation`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      inviteeEmails: inviteeEmails,
      projectId: projectId,
      role: role
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const acceptInvitation = async ( invitationCode: string, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/invitation/${invitationCode}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const approveInvitation = async ( invitationCode: string, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/invitation/${invitationCode}/approve`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const rejectInvitation = async ( invitationCode: string, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/invitation/${invitationCode}/reject`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const removeMemberFromProject = async ( projectId: any, userId: any, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/member/${userId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getOrganizationActivity = async ( organizationId: number, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/organization/${organizationId}/activity`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const logClickstream = async ( location: string, event: string, organizationTag: string, projectTag: string, eventData: any, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/clickstream`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      location: location,
      event: event,
      organizationTag: organizationTag,
      projectTag: projectTag,
      eventData: eventData
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const initEvaluation = async ( evaluationId: any, searchParams: any, email: string | null = null, name: string | null = null) => {
  const paramsString = createSearchParams(searchParams).toString();
  const config = {
    url: `${apiServerUrl}/evaluation/${evaluationId}/init`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: {
      params: searchParams ? createSearchParams(searchParams).toString() : "",
      email: email,
      name: name
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const updateAdvisor = async ( advisorId: string, advisorData: any) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
    },
    data: {
      ...advisorData
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const advisorScheduleEvent = async ( advisorId: string, advisorData: any) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/prospectScheduled`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
    },
    data: {
      ...advisorData
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const advisorCreateMeeting = async ( advisorId: string, meetingData: any) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/meeting`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: {
      ...meetingData
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const advisorRateMeeting = async ( advisorId: string, meetingUuid: string, rating: number) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/meeting/${meetingUuid}/rate`,
    method: "PUT",
    headers: {
      "content-type": "application/json"
    },
    data: {
      rating: rating
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisor = async ( advisorId: string) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getViewpointActivity = async ( advisorId: string) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/viewpointActivity`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getViewpointInvestmentActivity = async ( advisorId: string) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/viewpointInvestmentActivity`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getReservationActivity = async ( advisorId: string) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/reservationActivity`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisorViewpointHtml = async ( advisorId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/viewpointHtml`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getDealUpdates = async ( advisorId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/dealUpdates`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getInvestmentMaterials = async ( advisorId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/investmentMaterials`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const postAdvisorEvent = async ( advisorId: string, eventName: string, eventProperties: any ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/event`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: {
      eventName: eventName,
      eventProperties: eventProperties
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const postAdvisorPage = async ( advisorId: string, pageName: string, pageProperties: any ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/page`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: {
      pageName: pageName,
      pageProperties: pageProperties
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const postEvent = async ( eventName: string, eventProperties: any, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/analytics/event`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      eventName: eventName,
      eventProperties: eventProperties
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const postPage = async ( pageName: string, pageProperties: any, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/analytics/page`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      pageName: pageName,
      pageProperties: pageProperties
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const sendFeatureRequest = async ( projectTag: string, featureName: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project/${projectTag}/featureRequest`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      featureName: featureName
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getScheduler = async ( advisorId: string, type: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/scheduler/${type}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getCalendlyMeetingDetails = async ( advisorId: string, inviteeId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/calendlyMeetingDetails/${inviteeId}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisorMeetings = async ( advisorId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/meetings`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisorMeeting = async ( advisorId: string, meetingId: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/${advisorId}/meeting/${meetingId}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getOrganizationStats = async ( organizationTag: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/organization/${organizationTag}/stats`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getCompanyLanding = async ( companyTag: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/landing/${companyTag}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getFirmLanding = async ( organizationTag: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/firmLanding/${organizationTag}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });  

  return {
    data: data || null,
    error
  }
}

export const getAdvisorByProjectAndEmail = async ( projectTag: string, email: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/project/${projectTag}/email/${email}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisorByProjectAndUuid = async ( projectTag: string, advisorUuid: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/project/${projectTag}/uuid/${advisorUuid}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

export const getAdvisorByUuid = async ( advisorUuid: string ) => {
  const config = {
    url: `${apiServerUrl}/advisor/uuid/${advisorUuid}`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
}

