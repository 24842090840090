import { CompanyTitle } from "../../components/CompanyTitle";
import { useDealContext } from "../../../deal/context";
import { AdvisorSuggestions } from "../../components/outreach/AdvisorSuggestions";

export const OutreachSuggest = () => {
    const { dealContext } = useDealContext();
    const { project, role } = dealContext;

    return (
        <>
            <CompanyTitle 
                viewerRole={role} 
                project={project} 
                section="Outreach > Suggest an Advisor" 
                subheading={`Suggest your contacts and your company's contacts to become ${project.name} advisors and they'll receive an invitation.`} 
            />
            <AdvisorSuggestions />

        </>
    )
}