import { DealFilter, DealList } from "../../projects";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { v4 } from "uuid";
import { OrganizationInviteControl } from "../components/organization-invite";
import { useEffect, useMemo, useState } from "react";
import { WorkspaceSummary } from "../components/WorkspaceSummary";
import { WorkspaceActivity } from "../components/WorkspaceActivity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAnalytics from "../../../hooks/useAnalytics";
import { AddDeal } from "../components/AddDeal";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllDataForSelf } from "../../../core/services/gutcheck-api.service";
import { useUserContext } from "../../users";
import Membership from "../../users/types/Membership";

export const WorkspaceDeals = () => {
    const navigate = useNavigate();
    const { organization } = useOutletContext<any>();
    const { trackPage, trackUser } = useAnalytics();
    const { userContext, setUserContext } = useUserContext();
    const { getAccessTokenSilently } = useAuth0();

    const [modalOpen, setModalOpen] = useState(false);

    const [filteredProjects, setFilteredProjects] = useState(organization?.projects ?? []);

    const handleNewProjectClick = () => { navigate(`/o/${organization.tag}/project/new`, { state: { organization: organization } }); };

    useEffect(() => {

        trackPage(`dealspace`, { organization: organization.tag });
        //reloadUserdata();
        //(window as any).analytics.page(`${organization.tag} dealspace`);
    }, []);

    const handleAddDeal = () => {
        setModalOpen(true);
    }

    return organization ? (

        <div className="dashboard">
            <div className="organization-header">
                <h2 className="dashboard-title">{organization.name} Dealspace</h2>
                <OrganizationInviteControl organization={organization} />
            </div>

            <div className="flex-row workspace-dashboard">
                <div className="flex-column left-column">

                    <WorkspaceSummary projects={organization.projects} />
                    <div className="deal-control flex-row">
                        <DealFilter
                            allProjects={organization && organization?.projects?.length ? organization.projects : []}
                            setFilteredProjects={setFilteredProjects}
                        />
                        <button className="btn btn-primary no-wrap" onClick={handleAddDeal}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Deal</button>
                    </div>

                    <DealList
                        organization={organization}
                        filteredProjects={filteredProjects}
                        allProjects={organization.projects}
                        key={v4()}
                    />
                </div>
                <div className="flex-column right-column">
                    <WorkspaceActivity organization={organization} projects={organization.projects} />
                </div>
            </div>
            <AddDeal modalOpen={modalOpen} setModalOpen={setModalOpen} organization={organization} />

        </div>

    ) : <></>;
};

