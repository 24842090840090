import { useState, useEffect } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { useAuth0 } from "@auth0/auth0-react";
import { 
    TableContainer, Table, TableHead, TableRow, TableCell, 
    Paper, TableBody, Button, TextField, Box, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import dayjs from "dayjs";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { getDomains, updateDomain } from "../services/gutcheck-admin-api.service";
import { IDomain } from "../../../types";


const fetchDomains = async (accessToken: string) => {
    const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
    const config = {
        url: `${apiServerUrl}/admin/domains`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    };

    // Assuming you have a callExternalApi function similar to other services
    const { data, error } = await getDomains(accessToken);

    return {
        data: data || null,
        error
    };
};

// You'll need to create this API service function
const updateDomainAcmeChallenge = async (domainId: string, acmeChallenge: string, acmeResponse: string, accessToken: string) => {
    const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
    const config = {
        url: `${apiServerUrl}/admin/domain/${domainId}/acme`,
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            acme_challenge: acmeChallenge,
            acme_response: acmeResponse
        }
    };

    const { data, error } = await updateDomain(domainId, {acme_challenge: acmeChallenge, acme_response: acmeResponse}, accessToken);

    return {
        data: data || null,
        error
    };
};

export const AdminDomains = () => {
    const [domains, setDomains] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [editDomain, setEditDomain] = useState<IDomain | null>(null);
    const [open, setOpen] = useState(false);
    const [acmeChallenge, setAcmeChallenge] = useState("");
    const [acmeResponse, setAcmeResponse] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const fetchDomains = async () => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getDomains(accessToken);
        if (data) {
            setDomains(data.domains);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDomains();
    }, []);

    const handleEditOpen = (domain: IDomain) => {
        setEditDomain(domain);
        setAcmeChallenge(domain.acme_challenge || "");
        setAcmeResponse(domain.acme_response || "");
        setOpen(true);
    };

    const handleClose = () => {
        setEditDomain(null);
        setOpen(false);
        setUpdateSuccess(false);
    };

    const handleUpdate = async () => {
        if (!editDomain) return;
        
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateDomainAcmeChallenge(
            editDomain.uuid, 
            acmeChallenge, 
            acmeResponse, 
            accessToken
        );
        
        if (!error) {
            setUpdateSuccess(true);
            fetchDomains();
        }
    };

    return (
        <>
            <DocumentTitle pageTitle="Admin - Domains" />
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Domain Management
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Manage domains and ACME challenge settings
                </Typography>
            </Box>
            
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Domain Name</TableCell>
                            <TableCell>Organization</TableCell>
                            <TableCell>Verified</TableCell>
                            <TableCell>ACME Challenge</TableCell>
                            <TableCell>ACME Response</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {domains.map((domain: IDomain) => (
                            <TableRow key={domain.id}>
                                <TableCell>{domain.domain_name}</TableCell>
                                <TableCell>{domain.organization?.name || "N/A"}</TableCell>
                                <TableCell>{domain.is_verified ? "Yes" : "No"}</TableCell>
                                <TableCell>
                                    {domain.acme_challenge ? 
                                        domain.acme_challenge.substring(0, 15) + "..." : 
                                        "Not set"}
                                </TableCell>
                                <TableCell>
                                    {domain.acme_response ? 
                                        domain.acme_response.substring(0, 15) + "..." : 
                                        "Not set"}
                                </TableCell>
                                <TableCell>{dayjs(domain.created_at).format('MMM D, YYYY')}</TableCell>
                                <TableCell>{dayjs(domain.updated_at).format('MMM D, YYYY')}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditOpen(domain)}>
                                        Edit ACME
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {editDomain && (
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle>
                        {updateSuccess ? "ACME Challenge Updated" : "Edit ACME Challenge"}
                    </DialogTitle>
                    <DialogContent>
                        {updateSuccess ? (
                            <Typography>
                                ACME challenge for {editDomain.domain_name} has been updated successfully.
                            </Typography>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                                <Typography variant="h6">Domain: {editDomain.domain_name}</Typography>
                                
                                <TextField
                                    label="ACME Challenge Token"
                                    value={acmeChallenge}
                                    onChange={(e) => setAcmeChallenge(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                
                                <TextField
                                    label="ACME Response"
                                    value={acmeResponse}
                                    onChange={(e) => setAcmeResponse(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            {updateSuccess ? "Close" : "Cancel"}
                        </Button>
                        {!updateSuccess && (
                            <Button onClick={handleUpdate} variant="contained" color="primary">
                                Update
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};