import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="bg-light text-center">
    <p>
      Copyright &copy; 2025 BrightLoop. All rights reserved. - <Link to={"https://www.brightloop.ai/legal"} target="_blank">Terms of Use</Link> - <Link to={"https://www.brightloop.ai/legal/privacy"} target="_blank">Privacy Policy</Link>
    </p>
  </footer>
);

export default Footer;
