import { IAdvisor } from "../../../types";
import { AdvisorFeatureListBase } from "../../../components/shared/StyledComponents";
import { AdvisorProfileImage } from "./AdvisorProfileImage";
import { Box } from "@mui/material";
import { ListItem } from "@mui/material";
import { Link } from "react-router-dom";

export const FeaturedList = ({ title, advisors }: { title: string, advisors: IAdvisor[] }) => {

    return (
        <div className="display-box">
            <h5>{title}</h5>
            <AdvisorFeatureListBase>
                {advisors.length > 0 ? advisors.map((advisor) => (
                    <ListItem id={advisor.uuid} key={advisor.uuid}>
                        <AdvisorProfileImage advisor={advisor} size="small" />
                        <Box>
                            <strong>{advisor.user?.profile?.name}</strong>
                            {advisor.user?.profile?.title && <span className="text-muted"><br/>{advisor.user?.profile?.title}</span>}
                            {advisor.user?.profile?.company?.name && <span className="text-muted"><br/><strong>{advisor.user?.profile?.company?.name}</strong></span>}
                        </Box>
                    </ListItem>
                )) : (
                    <ListItem>
                        <Box>
                            <p>You are part of the inaugural group of advisors! Your peers will be displayed here as the group gains critical mass.</p>
                            <p>If you know of any colleagues you think would be a good fit, feel free to <Link to="/advisor/refer">suggest them</Link> for consideration.</p>
                        </Box>
                    </ListItem>
                )}
            </AdvisorFeatureListBase>
        </div>
    );
}