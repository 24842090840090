import { styled } from "@mui/material/styles";
import { IMeeting } from "../../../types";
import { Box, BoxProps, Tooltip } from "@mui/material";

export const DateBox = styled(Box)<BoxProps>(({ theme }) => ({
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    width: '53px',
    "& .datebox-month": {
        backgroundColor: '#00637C',
        color: '#ffffff',
        fontSize: '11px',
        fontWeight: '900',
        padding: '2px',
        textAlign: 'center',
    },
    "& .datebox-day": {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '3px',
        textAlign: 'center',
        "& span": {
            fontSize: '16px',
            fontWeight: 'bold',
        }
    },
}));

export const DateDisplay = ({ meeting, showTime = true }: { meeting: IMeeting, showTime?: boolean }) => {
    const month = new Date(meeting.event_start!).toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const day = new Date(meeting.event_start!).getDate();
    const timeZone = new Date(meeting.event_start!).toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();

    return (
        <>
            <Tooltip placement="top" arrow title={`${new Date(meeting.event_start!).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })} ${timeZone}`}>
                <DateBox>
                    <div className="datebox-month">
                        {month}
                    </div>
                    <div className="datebox-day">
                        <span>{day}</span>
                    </div>
                </DateBox>
            </Tooltip>
        </>
    );
};
