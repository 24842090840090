import { Route, Routes } from "react-router-dom";
import Home from "../views/Home";
import { NotFoundPage } from "../views/pages/NotFoundPage";
import { AuthenticationGuard } from "../components/AuthenticationGuard";
import { AppLayout } from "../components/AppLayout";
import { WorkspacesRoutes } from "../features/workspaces";
import { ProjectsRoutes } from "../features/projects";
import { InvitationsRoutes } from "../features/invitations";
import { LegalRoutes } from "../features/legal";
import { AdminRoutes } from "../features/admin";
import { UsersRoutes } from "../features/users";
import "../App.css";
import { Dashboard } from "../features/misc";
import UnrecognizedUser from "../components/UncrecognizedUser";
import { DealRoutes } from "../features/deal";
import { CompanyRoutes } from "../features/company";
import { FirmRoutes } from "../features/firm";
import { Landing } from "../features/landing/routes/Landing";
import { Meeting } from "../features/meeting/routes/Meeting";
import { LandingRoutes } from "../features/landing/routes";
import { AdvisorRoutes } from "../features/advisor/routes";
import { Limbo } from "../features/misc/routes/Limbo";
import { Schedule } from "../features/meeting/routes/Schedule";

export const AppLayoutRoutes = () => {
    return (
        <AppLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/advise/*" element={<LandingRoutes />} />
            <Route path="/unrecognized" element={<UnrecognizedUser />} />
            <Route path="/c/*" element={<AuthenticationGuard component={CompanyRoutes} />} />
            <Route path="/advisor/*" element={<AuthenticationGuard component={AdvisorRoutes} />} />
            <Route path="/portfolio/*" element={<AuthenticationGuard component={FirmRoutes} />} />
            <Route path="/dashboard" element={<AuthenticationGuard component={ Dashboard } />}/>
            <Route path="/not-available" element={<Limbo />} />
            <Route path="/profile/*" element={<AuthenticationGuard component={UsersRoutes} />} />
            <Route path="/o/:organizationTag/d/*" element={<AuthenticationGuard component={DealRoutes} />} />
            <Route path="/o/:organizationTag/project/*" element={<AuthenticationGuard component={ProjectsRoutes} />} />
            <Route path="/o/*" element={<AuthenticationGuard component={WorkspacesRoutes} />} />
            <Route path="/organization/*" element={<AuthenticationGuard component={WorkspacesRoutes} />} />
            <Route path="/invitation/*" element={<InvitationsRoutes/>} />
            <Route path="/admin/*" element={<AuthenticationGuard component={AdminRoutes} role="admin" />} />
            <Route path="/legal/*" element={<LegalRoutes/>} />
            <Route path="/join/*" element={<AuthenticationGuard component={Landing} />} />
            <Route path="/meeting/:advisorUuid" element={<Meeting />} />
            <Route path="/meet/:advisorPublicId" element={<Schedule />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AppLayout>
      );
}

export default AppLayoutRoutes;