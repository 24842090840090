import { IProject, ITouchpoint } from "../../../../types";
import { Box } from "@mui/material";

export const TouchpointAnalytics = ({project, touchpoint}: {project: IProject, touchpoint: ITouchpoint}) => {

    if (!touchpoint.analysis_url) {
        return null;
    }

    return (
        <Box sx={{
            display: "flex", 
            flexDirection: "column", 
            height: "1000vh",
            marginTop: "-100px",
            paddingTop: "70px",
            boxSizing: "border-box"
        }}>
            <Box
                component={"iframe"}
                src={touchpoint?.analysis_url}
                sx={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100%",
                    border: "none",
                    height: "100%",
                }}
            />
        </Box>
    );
};