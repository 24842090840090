import { useState } from "react";
import { Box, Button, TextareaAutosize, TextField, Table, TableHead, TableRow, TableCell, TableBody, styled, Autocomplete, Menu, MenuItem, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAnalytics from "../../../../hooks/useAnalytics";
import ClientProvider from "../../../../modules/ClientProvider";
import { useUserContext } from "../../../users";
import { useDealContext } from "../../../deal/context";

type TProject = {
    project: any
}

type TProjectShareComponent = TProject & {
    role: any,
    setActive: any,
    reloadInvitations: any
}

type TProjectShareSuccess = TProject & {
    role: any,
    invitationForm: any,
    user: any
}

type TProjectShare = TProject & {
    role: any,
    handleInvitationChange: any,
    handleInvitationSubmit: any,
    handleSwitchTab: any,
    invitationForm: any
}

type TInviteeError = {
    index: number,
    field: string,
    message: string
}
type InviteeProps = {
    name: string,
    email: string,
    organization: string,
    role: string
}

const roles: { [key: string]: string } = {
    admin: "Admin",
    contributor: "Team Member",
    guest: "Guest"
}

const InviteeTable = styled(Table)(({ theme }) => ({
    width: "100%",
    "& th": {
        fontWeight: 700,
        padding: "0.5rem",
        fontSize: "1rem",
        "&.med": {
            width: "30%"
        },
        "&.small": {
            width: "10%"
        },
        "&.center": {
            textAlign: "center"
        }
    },
    "& td": {
        borderBottom: "0",
        padding: "0.5em",
        fontSize: "1rem",
        verticalAlign: "top",
        "&.center": {
            textAlign: "center"
        }
    },
    "& .add-invitee": {
        paddingLeft: "7px",
    }
}));

const PreviewBox = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "1rem 0",
    backgroundColor: "#ffffff",
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    overflowY: "auto",
    maxHeight: "300px",
    fontFamily: "Arial, sans-serif",
    "& .logo-bar": {
        backgroundColor: "#f8f9fa",
        borderTop: "1px solid #c9c9c9",
        borderBottom: "1px solid #c9c9c9",
        padding: "15px 0",
        marginBottom: "30px",
        "& .logo-wrapper": {
            maxWidth: "600px",
            margin: "0 auto",
        },
        "& img": {
            maxHeight: "40px",
        }
    },
    "& .invitation-message": {
        maxWidth: "600px",
        margin: "0 auto",
        "& h2": {
            fontSize: "24px",
            lineHeight: "32px",
            marginBottom: "1rem"
        },
        "& blockquote": {
            fontStyle: "normal",
            borderLeft: "4px solid #c9c9c9",
            fontFamily: "Arial, sans-serif",
            fontWeight: 500,
        },
        "& .mock-buttom": {
            backgroundColor: "#1a5dbd",
            color: "#ffffff",
            padding: "10px 40px",
            borderRadius: "5px",
            fontWeight: "bold",
            marginTop: "20px",
            display: "inline-block"
        }
    }
}));

const ActionIcon = styled(Box)(({ theme }) => ({
    fontSize: "1.2rem",
    cursor: "pointer",
    paddingTop: "7px",
    "& .red": {
        color: "red"
    },
    "& .green": {
        color: "green"
    }

}))

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


const PreviewForm = ({ user, project, role, invitationForm }: TProjectShareSuccess) => {

    if (project.status === "draft") {
        return (
            <Box>
                <p><strong>Subject:</strong> {user.name ?? user.email_address} invited you to start generating advisor insights and intros</p>
                <PreviewBox>
                    <Box className="logo-bar">
                        <Box className="logo-wrapper" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "20px",
                            flexDirection: "row"
                        }}>
                            <img src={project.organization.logo_url} alt={project.organization.name} />
                            <Typography variant="body1" fontSize={"1rem"}>for</Typography>
                            <img src={project.logo_url} alt={project.name} />
                        </Box>
                    </Box>
                    <Box className="invitation-message">
                        <h2>You've been invited.</h2>

                        <p>{project.organization.name} is helping {project.name} engage buyers as light touch advisors, nurture them, and set up meetings. To get started, just spend a few minutes to define the buyer, the product, and any
                            key questions you want answered.</p>

                        <p>Here's a personal note from {user.name ?? user.email_address}:</p>

                        <blockquote>{invitationForm.note}</blockquote>

                        <p>The app (powered by BrightLoop) makes it easy to engage high level executives as light-touch advisors.</p>

                        <Box className="mock-buttom">Accept Invitation</Box>

                    </Box>

                </PreviewBox>
            </Box>
        )
    } else {
        // Main Copy
        return (
            <Box>
                <p><strong>Subject:</strong> {user.name ?? user.email_address} invited you to check out advisor insights and intros</p>
                <PreviewBox>
                    <Box className="logo-bar">
                        <Box className="logo-wrapper" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "20px",
                            flexDirection: "row"
                        }}>
                            {!project.suppress_organization && (
                                <>
                                    <img src={project.organization.logo_url} alt={project.organization.name} />
                                    <Typography variant="body1" fontSize={"1rem"}>for</Typography>
                                </>
                            )}
                            <img src={project.logo_url} alt={project.name} />
                        </Box>
                    </Box>
                    <Box className="invitation-message">
                        <h2>You've been invited.</h2>

                        {project.suppress_organization ? (
                            <p>{project.name} is engaging buyers as light touch advisors, nurturing them, and setting up meetings. Here's a personal note from {user.name ?? user.email_address}:</p>
                        ) : (
                            <p>{project.organization.name} is helping {project.name} engage buyers as light touch advisors, nurture them, and set up meetings. Here's a personal note from {user.name ?? user.email_address}:</p>
                        )}

                        <blockquote>{invitationForm.note}</blockquote>

                        <p>The app (powered by BrightLoop) makes it easy to engage high level executives as light-touch advisors.</p>

                        <Box className="mock-buttom">Accept Invitation</Box>

                    </Box>

                </PreviewBox>
            </Box>
        )
    }
}

const initialInvitee = {
    name: "",
    email: "",
    organization: "",
    role: "contributor"
};


export const InvitationForm = ({ project, role, setActive, reloadInvitations }: TProjectShareComponent) => {

    const { state } = useLocation();
    const { trackEvent } = useAnalytics();
    const { userContext } = useUserContext();
    const { dealContext } = useDealContext();

    const [invitationForm, setInvitationForm] = useState({
        invitees: [initialInvitee],
        note: project.status === "draft" ? `I'm excited to start generating insights and intros for ${project.name}. Please accept the invite and help me complete the setup.` : `I'm excited for you to check out the insights and intros generated for ${project.name}.`,
        submitted: false,
        errorMessage: "",
        inviteeErrors: [] as TInviteeError[],
        stage: "EDIT"
    });



    const [open, setOpen] = useState(state?.invitationAccepted ?? false);

    const { getAccessTokenSilently } = useAuth0();

    const handleInvitationSubmit = async () => {

        if (invitationForm.invitees.length === 0) {
            setInvitationForm({
                ...invitationForm,
                errorMessage: "Please add at least one invitee"
            });
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await ClientProvider.provideProjectClient().createInvitations(project.organization.id, project.id, invitationForm.invitees, invitationForm.note, "contributor", "collaboration", accessToken);
        setInvitationForm({
            ...invitationForm,
            submitted: true,
            errorMessage: "",
            inviteeErrors: [],
            stage: "COMPLETE"

        });
        reloadInvitations();
    }
    const handleInviteeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newInvitees: { name: string; email: string; organization: string; role: string }[] = [...invitationForm.invitees];
        newInvitees[index] = {
            ...newInvitees[index],
            [event.target.name]: event.target.value
        };

        const newErrorMessages = invitationForm.inviteeErrors.filter((error: TInviteeError) => error.index !== index && error.field !== event.target.name);
        setInvitationForm({
            ...invitationForm,
            invitees: newInvitees,
            inviteeErrors: newErrorMessages
        });
    };

    const handleInvitationPreview = () => {
        let newErrorMessages: TInviteeError[] = [];
        invitationForm.invitees.forEach((invitee: InviteeProps, index: number) => {
            if (invitee.email === "") {
                newErrorMessages.push({
                    index: index,
                    field: "email",
                    message: `Please provde an email address.`,
                });
            }
            if (invitee.name === "") {
                newErrorMessages.push({
                    index: index,
                    field: "name",
                    message: `Please provide a name.`,
                });
            }
            if (!emailRegex.test(invitee.email)) {
                newErrorMessages.push({
                    index: index,
                    field: "email",
                    message: `Please provide a valid email address`,
                });
            }
        });


        if (newErrorMessages.length > 0) {
            setInvitationForm({
                ...invitationForm,
                inviteeErrors: newErrorMessages,
                errorMessage: "Please fix any issues before proceeding."
            });
            return;
        } else {
            setInvitationForm({
                ...invitationForm,
                inviteeErrors: [],
                errorMessage: "",
                stage: "PREVIEW"
            });
        }
    }

    const handleAddInvitee = () => {
        setInvitationForm({
            ...invitationForm,
            invitees: [...invitationForm.invitees, initialInvitee]
        });
    };

    const handleRemoveInvitee = (index: number) => {
        setInvitationForm({
            ...invitationForm,
            invitees: invitationForm.invitees.filter((invitee: InviteeProps, i: number) => i !== index)
        });
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvitationForm({
            ...invitationForm,
            note: event.target.value
        });
    };

    const handleCancel = () => {
        // TODO: Include logic to check for unsubmitted invitations
        setInvitationForm({
            invitees: [initialInvitee],
            note: project.status === "draft" ? `I'm excited to start generating opportunities for ${project.name}. Please accept the invite and help me complete the setup.` : `I'm excited for you to check out the insights and opportunities generated for ${project.name}.`,
            submitted: false,
            errorMessage: "",
            inviteeErrors: [],
            stage: "EDIT"
        });
        reloadInvitations();
        setActive(false);
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        }}>
            <InviteeTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="med">
                            Name
                        </TableCell>
                        <TableCell className="med">
                            Email
                        </TableCell>
                        <TableCell className="med">
                            Role
                        </TableCell>
                        <TableCell className="small center">
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invitationForm.invitees.map((invitee: InviteeProps, index: number) => (

                        <TableRow key={index}>
                            <TableCell>
                                {invitationForm.stage === "EDIT" ?
                                    <TextField
                                        type="text"
                                        id={`name-${index}`}
                                        name="name"
                                        label="Name"
                                        size="small"
                                        inputProps={{ "data-1p-ignore": "true" }}
                                        value={invitee.name}
                                        error={invitationForm.inviteeErrors.find((error: TInviteeError) => error.index === index && error.field === "name") ? true : false}
                                        helperText={invitationForm.inviteeErrors.find((error: TInviteeError) => error.index === index && error.field === "name")?.message || ""}
                                        onChange={(e: any) => { handleInviteeChange(e, index) }}
                                    />
                                    :
                                    <Typography>{invitee.name}</Typography>
                                }
                            </TableCell>
                            <TableCell>
                                {invitationForm.stage === "EDIT" ?
                                    <TextField
                                        type="email"
                                        id={`email-${index}`}
                                        name="email"
                                        label="Email"
                                        size="small"
                                        inputProps={{ "data-1p-ignore": "true" }}
                                        value={invitee.email}
                                        error={invitationForm.inviteeErrors.find((error: TInviteeError) => error.index === index && error.field === "email") ? true : false}
                                        helperText={invitationForm.inviteeErrors.find((error: TInviteeError) => error.index === index && error.field === "email")?.message || ""}
                                        onChange={(e: any) => { handleInviteeChange(e, index) }}
                                    />
                                    :
                                    <Typography>{invitee.email}</Typography>
                                }
                            </TableCell>
                            <TableCell>
                                {invitationForm.stage === "EDIT" ?
                                    <TextField
                                        select
                                        id={`role-${index}`}
                                        name="role"
                                        label="Role"
                                        size="small"
                                        value={invitee.role}
                                        onChange={(e: any) => { handleInviteeChange(e, index) }}
                                    >
                                        {dealContext.role === "admin" && <MenuItem key="admin" value="admin">Admin</MenuItem>}
                                        <MenuItem key="contributor" value="contributor">Team Member</MenuItem>
                                    </TextField>
                                    :
                                    <Typography>{roles[invitee.role]}</Typography>
                                }
                            </TableCell>
                            <TableCell className="center">
                                {!invitationForm.submitted ? invitationForm.invitees.length > 1 && invitationForm.stage === "EDIT" &&
                                    <ActionIcon sx={{ color: "red" }} onClick={() => handleRemoveInvitee(index)} color="danger">
                                        <FontAwesomeIcon icon="times" />
                                    </ActionIcon>
                                    :
                                    <ActionIcon sx={{ color: "green" }}>
                                        <FontAwesomeIcon icon="check" />
                                    </ActionIcon>
                                }
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
                {invitationForm.stage === "EDIT" && <span className="link add-invitee" onClick={handleAddInvitee}>+ Add Invitee</span>}
            </InviteeTable>

            {invitationForm.stage === "EDIT" &&
                <Box>
                    <h6>Add a Personal Note</h6>
                    <TextField
                        multiline
                        rows={4}
                        disabled={invitationForm.submitted}
                        id="note"
                        placeholder="Add a personal note"
                        value={invitationForm.note}
                        onChange={handleNoteChange}
                    />
                </Box>
            }
            {invitationForm.stage === "PREVIEW" && <PreviewForm user={userContext?.dbUser} project={project} role={role} invitationForm={invitationForm} />}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "20px",
                "& button": {
                    width: "200px"
                }
            }}>
                {invitationForm.errorMessage !== "" && <Box sx={{ color: "red" }}>{invitationForm.errorMessage}</Box>}

                {invitationForm.stage === "COMPLETE" &&
                    <>
                        <Typography sx={{ color: "green" }} className="success">Success! You've sent {invitationForm.invitees.length} invitation{invitationForm.invitees.length !== 1 ? "s" : ""}</Typography>
                        <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Close</Button>
                    </>
                }

                {invitationForm.stage === "EDIT" && <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Cancel</Button>}
                {invitationForm.stage === "EDIT" && <Button variant="contained" size="large" color="primary" className="btn active" onClick={handleInvitationPreview}>Preview</Button>}

                {invitationForm.stage === "PREVIEW" && <Button variant="outlined" size="large" color="primary" onClick={() => setInvitationForm({ ...invitationForm, stage: "EDIT" })}>Back</Button>}
                {invitationForm.stage === "PREVIEW" && <Button variant="contained" size="large" color="primary" onClick={handleInvitationSubmit}>Send</Button>}
            </Box>
        </Box>
    )
}