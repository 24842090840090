import { Box, Typography, Button, TextField, styled } from '@mui/material';
import { useBrandContext } from '../../../hooks/useBrandContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { getAdvisorByProjectAndEmail, getCompanyLanding, initEvaluation } from '../../../core/services/gutcheck-api.service';
import { useDealContext } from '../../../features/deal/context';
import Loading from '../../../components/Loading';
import { DocumentTitle } from '../../../components/DocumentTitle';
import useAnalytics from '../../../hooks/useAnalytics';
import { getAdvisor } from '../../../core/services/gutcheck-api.service';
import logo from "../../../assets/images/brightloop-logo-web.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { DoorOpen, TrendingUp, PieChart } from 'lucide-react';
import { CompanyLogo } from '../../company/components/CompanyLogo';
import { FirmLogo } from '../../firm/components/FirmLogo';
import Footer from '../../../components/Footer';

const HighlightBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 2,
    color: theme.palette.primary.contrastText,
    flex: '1 0 225px',
    backgroundColor: '#002b49',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '0px',
    "& svg": {
        margin: "0 auto"
    },
    "& h6": {
        margin: "0 auto"
    }
}));


  

  

const decodeUserData = (encoded: string): { name: string; email: string } | null => {
    try {
      const decoded = atob(encoded.replace(/-/g, '+').replace(/_/g, '/'));
      const [name, email] = decoded.split('|').map(s => decodeURIComponent(s.split(':')[1]));
      return { name, email };
    } catch {
      return null;
    }
  }

export const CompanyLanding = () => {

    const { brandContext } = useBrandContext();
    const { companyTag } = useParams();
    const { dealContext, setDealContext, loadDealContext } = useDealContext();
    const { trackAdvisorPage, trackAdvisorEvent, trackAdvisorUser } = useAnalytics();

    const [project, setProject] = useState<any>(null);
    const [organization, setOrganization] = useState<any>(null);
    const [link, setLink] = useState<any>(null);
    const [surveyLink, setSurveyLink] = useState<any>(null);
    const [email, setEmail] = useState<any>("");
    const [name, setName] = useState<any>("");
    const [message, setMessage] = useState<any>(null);
    const { brand } = brandContext;
    const [advisorLink, setAdvisorLink] = useState<any>(null);
    const [advisor, setAdvisor] = useState<any>(null);
    const { loginWithRedirect } = useAuth0();

    const navigate = useNavigate();
    
    const inviteProcessedRef = useRef(false);
    const [inviteName, setInviteName] = useState<string | undefined>(undefined);
    const [inviteEmail, setInviteEmail] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        const initialize = async () => {
            // Step 1: Process invite parameter if it exists
            if (!inviteProcessedRef.current) {
                const searchParams = new URLSearchParams(window.location.search);
                const invite = searchParams.get('invite') ?? localStorage.getItem('invite');
                
                if (invite) {
                    localStorage.setItem('invite', invite);
                    const decodedInvite = decodeUserData(invite);
                    if (decodedInvite) {
                        setInviteName(decodedInvite.name);
                        setInviteEmail(decodedInvite.email);
                        setEmail(decodedInvite.email || "");
                        setName(decodedInvite.name || "");
                    }
                    
                    searchParams.delete('invite');
                    window.history.replaceState(
                        {}, 
                        '', 
                        window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '')
                    );
                }
                
                inviteProcessedRef.current = true;
            }
            
            // Step 2: Fetch company landing data
            if (companyTag) {
                const { data, error } = await getCompanyLanding(companyTag);
                if (data) {
                    setProject(data.project);
                    setLink(data.surveyLink?.link);
                    setSurveyLink(data.surveyLink);

                    setDealContext({
                        ...dealContext,
                        project: data.project,
                        fetched: true
                    });

                    const advisorId = localStorage.getItem("eval" + data.surveyLink?.public_id);
                    if (advisorId) {
                        const { data: advisorData, error } = await getAdvisor(advisorId);
                        if (advisorData?.advisor) {
                            setAdvisor(advisorData.advisor);
                            // Only set email/name if not already set from invite
                            if (!email) {
                                setEmail(advisorData.advisor.user?.email_address ?? advisorData.advisor.email_address);
                            }
                            if (!name) {
                                setName(advisorData.advisor.user?.profile?.name?.split(" ")[0] ?? advisorData.advisor.name);
                            }
                            setAdvisorLink(
                                advisorData.advisor.survey_link.project.experience === "advisor" ?
                                    `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${advisorData.advisor.survey_link.project.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/advisor?emh=${advisorData.advisor.public_id}` :
                                    advisorData.advisor.link
                            );
                        }
                    }
                }
            }
        };
        
        initialize();
    }, [companyTag]);

    const promoteShares = true;

    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

    useEffect(() => {
        if (project?.organization) {
            const searchParams = new URLSearchParams(window.location.search);
            const campaign = searchParams.get('invite') ?? localStorage.getItem('utm_campaign');
            trackAdvisorPage(null, "Advisor - Company Landing", {
                projectTag: companyTag,
                project: project?.name,
                organizationTag: project?.organization?.tag,
                organization: project?.organization.name,
                link: link,
                inviteName: inviteName,
                inviteEmail: inviteEmail,
                campaign: campaign
            });
        }
    }, [project]);


    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setHasAttemptedSubmit(true);

        if (!isValidEmail(email)) {
            return;
        }
        if (project && email) {
            const { data, error } = await getAdvisorByProjectAndEmail(project.tag, email);
            if (data) {
                if (data.advisor?.status === "approved") {
                    await loginWithRedirect({
                        appState: {
                            returnTo: "/login",
                        },
                        authorizationParams: {
                            prompt: "login",
                            login_hint: email,
                            "ext-theme": "advisor",
                        },
                    });
                } else if (data.advisor?.status === "complete") {
                    navigate(location.pathname + `/${data.advisor.public_id}`);
                } else if (data.advisor?.status === "init") {
                    setMessage("Thanks! We sent you an email with a link to continue where you left off.");
                } else {
                    setMessage("Check your email for a link");
                }
            } else {
                const searchParams = new URLSearchParams(window.location.search);
                // Add the "from" parameter
                searchParams.set('from', 'landing');
                if (project.enable_bifurcated_landing_page) {
                    searchParams.set('intro', 'skip');
                }
                const { data, error } = await initEvaluation(surveyLink.public_id, searchParams, email, name);
                if (data) {
                    localStorage.setItem("eval" + surveyLink.public_id, data.advisor.public_id);
                    trackAdvisorUser(data.advisor, {
                        email: email
                    });
                    trackAdvisorEvent(data.advisor, "Advisior - Initiated Survey", {
                        projectTag: project.tag,
                        project: project.name,
                        organizationTag: project.organization.tag,
                        organization: project.organization.name,
                        email: email
                    }, () => {
                        if (project.enable_bifurcated_landing_page) {
                            navigate(`${data.advisor.public_id}/start`);
                            return;
                        } else {
                            window.location.href = data.advisor.link;
                            return;
                        }
                    });
                }
            }
        }
    }

    if (!companyTag) {
        navigate("/404");
    }

    if (!project) {
        return <Loading />;
    }

    if (advisor && advisor?.status === "complete" && advisor.survey_link.project.experience === "advisor") {
        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${project.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}`;
    }

    // Hero text
    //{project?.name} recently closed a funding round
    //<strong>{project?.organization?.name}</strong> participated in the round and is actively helping {project?.name} recruit advisors.

    return (
        <>
            <DocumentTitle pageTitle={`${project?.organization?.name} Advisor Network for ${project?.name}`} showAttribution={false} />
            <Box sx={{
                width: '100%'
            }}>
                <Box sx={{
                    background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#ffffff',
                    minHeight: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px 0 60px 0'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: "30px",
                        maxWidth: '1200px',
                        width: '100%',
                        margin: '0 auto',
                        padding: "40px 60px"
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flex: '1 1 300px',
                            minWidth: 0,
                            marginTop: "20px"
                        }}>
                            <Typography variant="h3" fontSize={"2.3rem"} sx={{ marginBottom: "30px" }} fontWeight="bold">
                                { (!project?.landing_page_hero_text || project?.landing_page_hero_text === '')
                                        ? <>Would you like to be an advisor for {project?.name}?</>
                                        : project?.landing_page_hero_text}
                            </Typography>
                            <Typography variant="body1" fontSize={"1.5rem"} fontWeight="normal">
                                {(!project?.landing_page_hero_subtitle || project?.landing_page_hero_subtitle === '')
                                    ? <>The <strong>{project?.organization?.name} Advisor Network</strong> builds more successful startups by pairing senior executives with top startups in their wheelhouse.</>
                                    : project?.landing_page_hero_subtitle}
                            </Typography>
                        </Box>
                        <Box className="display-box white"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                gap: 2,
                                color: '#000000',
                                flex: '1 0 300px',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '8px',
                                marginBottom: '0px',
                                maxWidth: { xs: '100%', md: '300px' },
                                "& button": {
                                    width: '100%'
                                }
                            }}
                        >
                            {advisorLink && advisor?.status === "init" ? (
                                <>
                                    <h5>Welcome Back!</h5>
                                    {message ? <p className="success">{message}</p> : <p>It looks like you already started providing feedback. Click below to pick up where you left off.</p>}
                                    <Button sx={{ width: "100%" }} onClick={handleSubmit} variant="contained" className="btn btn-primary" color="primary" disabled={message !== null}>Continue</Button>
                                    <Box className="bl-attribution" sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant="body2" fontSize={"0.75rem"}>
                                            Powered by <img
                                                src={logo.toString()}
                                                alt="Brightloop Logo"
                                                style={{
                                                    height: '12px',
                                                    width: 'auto',
                                                    verticalAlign: 'middle',
                                                    marginLeft: '4px'
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                </>
                            ) : advisorLink && advisor?.status === "complete" ? (
                                <>
                                    <h5>Welcome Back!</h5>
                                    {message ? <p className="success">{message}</p> : <p>Click below to visit your advisor dashboard.</p>}
                                    {advisor.survey_link?.project?.experience === "advisor" ?
                                        <Button sx={{ width: "100%" }} onClick={handleSubmit} variant="contained" className="btn btn-primary" color="primary">Continue</Button>
                                        :
                                        <Button sx={{ width: "100%" }} onClick={handleSubmit} variant="contained" className="btn btn-primary" color="primary">Continue</Button>
                                    }
                                </>
                            ) : advisorLink && advisor?.status === "approved" ? (
                                <>
                                    <h5>Welcome Back, {advisor?.user?.profile?.name?.split(" ")[0]}!</h5>
                                    {message ? <p className="success">{message}</p> : <p>You're already approved! Click below to log in to your advisor dashboard.</p>}
                                    <Button sx={{ width: "100%" }} href={advisorLink} variant="contained" className="btn btn-primary" color="primary">Continue</Button>
                                    <Box className="bl-attribution" sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant="body2" fontSize={"0.75rem"}>
                                            Powered by <img
                                                src={logo.toString()}
                                                alt="Brightloop Logo"
                                                style={{
                                                    height: '12px',
                                                    width: 'auto',
                                                    verticalAlign: 'middle',
                                                    marginLeft: '4px'
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                </>
                            ) : surveyLink ? (
                                <>
                                <Box>
                                    <h5>{inviteName ? <>Welcome, {inviteName.split(" ")[0]}!</> : "Enter your email to learn more."}</h5>
                                    {inviteName ? <Typography variant="body1" fontSize={"1rem"}><strong>Confirm your email to learn more.</strong></Typography> : null}
                                    </Box>
                                    <Typography variant="body1" fontSize={"1rem"} >{project.enable_bifurcated_landing_page ? "Earn advisor shares for providing initial feedback with an opportunity to earn more the more you engage." : `Spend 5-10 minutes sharing your perspective on ${project.name}, then let us know if you'd like to advise.`}</Typography>
                                    {message ? (
                                        <p className="success">{message}</p>
                                    ) : (
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <TextField
                                                value={email}
                                                label="Email"
                                                onChange={handleEmailChange}
                                                fullWidth
                                                type="email"
                                                required
                                                error={hasAttemptedSubmit && !isValidEmail(email)}
                                                helperText={hasAttemptedSubmit && !isValidEmail(email) ? "Please enter a valid email address" : ""}
                                            />
                                            <Button
                                                sx={{ width: "100%", mt: 2 }}
                                                disabled={message !== null}
                                                type="submit"
                                                variant="contained"
                                                className="btn btn-primary"
                                                color="primary"
                                            >
                                                Get Started
                                            </Button>
                                        </form>
                                    )}
                                    <Box className="bl-attribution" sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant="body2" fontSize={"0.75rem"}>
                                            Powered by <img
                                                src={logo.toString()}
                                                alt="Brightloop Logo"
                                                style={{
                                                    height: '12px',
                                                    width: 'auto',
                                                    verticalAlign: 'middle',
                                                    marginLeft: '4px'
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <h5>Coming Soon</h5>
                                    <p>We're working hard to get this up and running. Please check back soon.</p>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    padding: '0 60px', position: 'relative',
                    transform: 'translateY(-50%)',
                    margin: { xs: '0 auto -40px', sm: '0 auto -20px', md: '0 auto -10px' },
                    zIndex: 1
                }}>
                    <Box sx={{
                        backgroundColor: '#f8f9fa',
                        maxWidth: '1200px',
                        width: '100%',
                        padding: '20px 30px',
                        borderRadius: '8px',
                        border: "1px solid #002b49",
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: { xs: "10px", md: "15px" }
                    }}>
                        <Typography variant="body1" fontWeight="bold" fontSize={{ xs: '0.75rem', sm: '0.85rem', md: "1rem" }} sx={{
                            maxWidth: { xs: "100%", sm: "145px", md: "175px" },
                            flex: "1"
                        }}>Join other advisors already on the platform...</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            flex: "1",
                            flexWrap: "wrap",
                            justifyContent: { xs: "center", sm: "space-between" },
                            alignItems: "center",
                            gap: { xs: "10px", md: "15px" }
                        }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px" }}>
                                <Box component="img"
                                    src="https://cdn.brandfetch.io/idkuvXnjOH/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B"
                                    sx={{
                                        height: { xs: '18px', md: '24px' },  // 18px on mobile, 24px on medium and up
                                        width: 'auto',
                                        verticalAlign: 'middle',
                                        marginLeft: '4px',
                                        marginBottom: '4px',
                                        filter: 'grayscale(100%) brightness(0)',
                                        opacity: 0.6,
                                    }}
                                />
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={{ xs: "0.5rem", md: "0.8rem" }} color="#a9a9a9">Global Director</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px" }}>
                                <Box component="img"
                                    src="https://cdn.brandfetch.io/idgsqETs2x/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B"
                                    sx={{
                                        height: { xs: '18px', md: '24px' },  // 18px on mobile, 24px on medium and up
                                        width: 'auto',
                                        verticalAlign: 'middle',
                                        marginLeft: '4px',
                                        marginBottom: '4px',
                                        filter: 'grayscale(100%) brightness(0)',
                                        opacity: 0.6
                                    }}
                                />
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={{ xs: "0.5rem", md: "0.8rem" }} color="#a9a9a9">Vice President</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px" }}>
                                <Box component="img"
                                    src="https://cdn.brandfetch.io/id7L59h6d0/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B"
                                    sx={{
                                        height: { xs: '18px', md: '24px' },  // 18px on mobile, 24px on medium and up
                                        width: 'auto',
                                        verticalAlign: 'middle',
                                        marginLeft: '4px',
                                        marginBottom: '4px',
                                        filter: 'grayscale(100%) brightness(0)',
                                        opacity: 0.6
                                    }}
                                />
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={{ xs: "0.5rem", md: "0.8rem" }} color="#a9a9a9">Senior VP, CX</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px" }}>
                                <Box component="img"
                                    src="https://cdn.brandfetch.io/idhem73aId/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B"
                                    sx={{
                                        height: { xs: '18px', md: '24px' },  // 18px on mobile, 24px on medium and up
                                        width: 'auto',
                                        verticalAlign: 'middle',
                                        marginLeft: '4px',
                                        marginBottom: '4px',
                                        filter: 'grayscale(100%) brightness(0)',
                                        opacity: 0.6
                                    }}
                                />
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={{ xs: "0.5rem", md: "0.8rem" }} color="#a9a9a9">Head of AI</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px" }}>
                                <Box component="img"
                                    src="https://cdn.brandfetch.io/id_KsyK7J9/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B"
                                    sx={{
                                        height: { xs: '18px', md: '24px' },  // 18px on mobile, 24px on medium and up
                                        width: 'auto',
                                        verticalAlign: 'middle',
                                        marginLeft: '4px',
                                        marginBottom: '4px',
                                        filter: 'grayscale(100%) brightness(0)',
                                        opacity: 0.6
                                    }}
                                />
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={{ xs: "0.5rem", md: "0.8rem" }} color="#a9a9a9">Senior Director, CX</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ padding: '0 60px' }}>
                    <Typography variant="h6" sx={{ margin: "0 auto 40px", textAlign: "center" }} fontWeight="bold">Get more ways to contribute and get more ways to win</Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    width: '100%',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: '0 60px 40px',
                    gap: "30px",
                    "& .MuiTypography-root": {
                        marginBottom: "1rem",
                        "& strong": {
                            fontWeight: "900"
                        },
                        "&:last-child": {
                            marginBottom: "0"
                        }
                    }
                }}>
                    <HighlightBox>
                        <TrendingUp size={38} style={{ margin: "0 auto" }} />
                        <Typography variant="h6" fontSize={"1.75rem"} fontWeight="900" mb={2}>Impact</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Shape products</strong><br />by helping teams design what you need.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Shape startups</strong><br />by advising founders on product & strategy.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Shape markets</strong><br />by influencing which companies VCs invest in.</Typography>
                    </HighlightBox>
                    <HighlightBox>
                        <DoorOpen size={38} />
                        <Typography variant="h6" fontSize={"1.75rem"} fontWeight="900" mb={2}>Access</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>To info</strong><br />Learn about new tech years before it hits the mainstream.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>To people</strong><br />Meet founders and investors at top startups & VC firms.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>To peers</strong><br />Meet and collaborate with senior executives in your industry.</Typography>
                    </HighlightBox>
                    <HighlightBox>
                        <PieChart size={38} />
                        <Typography variant="h6" fontSize={"1.75rem"} fontWeight="900" mb={2}>Equity</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Advisor shares</strong><br />Earn shares in VC-backed startups.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Investment</strong><br />Get invited to invest in & with the best founders and VCs.</Typography>
                        <Typography variant="body1" fontSize={"1rem"} ><strong>Portfolio</strong><br />Build a portfolio of startups you've advised and invested in.</Typography>
                    </HighlightBox>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    width: '100%',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: "40px 60px",
                }}>
                    {project.organization && !project.suppress_organization && (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1 1 300px',
                                minWidth: 0,
                                borderRight: { xs: 'none', md: '1px solid #e0e0e0' },
                                padding: { xs: '0 20px 30px 20px', md: '0 30px 0 20px' },
                                alignSelf: 'stretch'
                            }}>
                                <Typography variant="h6"><strong>About</strong></Typography>
                                <Box sx={{
                                    filter: 'grayscale(100%) brightness(0)',
                                    opacity: 0.8,
                                    margin: "10px 0 20px"
                                }}>
                                    <FirmLogo organization={project.organization} size="medium" style="left" />
                                </Box>
                                <Typography variant="body1" sx={{ flex: 1 }}>{project.organization?.description}</Typography>
                            </Box>
                        </>
                    )}

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1 1 300px',
                                minWidth: 0,
                                padding: { xs: '30px 20px 0 20px', md: '0 20px 0 30px' },
                                alignSelf: 'stretch'
                            }}>
                                <Typography variant="h6"><strong>About</strong></Typography>
                                <Box sx={{
                                    filter: 'grayscale(100%) brightness(0)',
                                    opacity: 0.8,
                                    margin: "10px 0 20px"
                                }}>
                                    <CompanyLogo project={project} size="medium" style="left" />
                                </Box>
                                <Typography variant="body1" sx={{ flex: 1 }}>{project.public_description}</Typography>
                            </Box>
                </Box>
                <Footer />
            </Box>
        </>
    );
};