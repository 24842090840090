import { IAdvisor } from "../../../types";
import { CompanyLogoBase, CompanyNameBase } from "../../../components/shared/StyledComponents";

export const AdvisorCompany = ({ advisor, size="medium", style="centered" }: {advisor: IAdvisor, size?: string, style?: string}) => {

    if (advisor?.user?.profile?.company?.logo_url) {
        return (
            <CompanyLogoBase className={`${size} ${style}`}>
                <img src={advisor.user.profile.company.logo_url} alt={advisor.user.profile.company.name} />
            </CompanyLogoBase>
        );
    }
    return (
        <CompanyNameBase className={`${size} ${style}`}>
            {advisor?.user?.profile?.company?.name}
        </CompanyNameBase>
    );
}