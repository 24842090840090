import { Box, Modal, TableCell, TableRow } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { TouchpointForm } from "./TouchpointForm";

const typeLabel = {
    "default": "Default",
    "manual": "Manual",
    "auto": "Automated"
};

const statusLabel = {
    "draft": "Draft",
    "scheduled": "Scheduled",
    "active": "Active",
    "completed": "Completed"
};

export const AdminTouchpoint = ({ project, touchpoint, reloadProjectData }: any) => {
    const [editMode, setEditMode] = useState(false);

    const handleEdit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setEditMode(true);
    };

    return (
        <>
            <TableRow>
                <TableCell>{touchpoint.name}</TableCell>
                <TableCell>{touchpoint.uuid}</TableCell>
                <TableCell className="center">{typeLabel[touchpoint.type as keyof typeof typeLabel]}</TableCell>
                <TableCell className="center">{statusLabel[touchpoint.status as keyof typeof statusLabel]}</TableCell>
                <TableCell className="center">
                    {touchpoint.scheduled_at ? new Date(touchpoint.scheduled_at).toLocaleDateString() : "---"}
                </TableCell>
                <TableCell className="center">
                    {touchpoint.started_at ? new Date(touchpoint.started_at).toLocaleDateString() : "---"}
                </TableCell>
                <TableCell className="center">
                    {touchpoint.completed_at ? new Date(touchpoint.completed_at).toLocaleDateString() : "---"}
                </TableCell>
                <TableCell className="center">{touchpoint.viewpoints?.length || 0}</TableCell>
                <TableCell className="center">
                    <span className="link" onClick={handleEdit}>
                        <FontAwesomeIcon icon="edit" />
                    </span>
                </TableCell>
            </TableRow>

            <Modal 
                open={editMode} 
                onClose={() => setEditMode(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    <TouchpointForm
                        project={project}
                        touchpoint={touchpoint}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        reloadProjectData={reloadProjectData}
                    />
                </Box>
            </Modal>
        </>
    );
}; 