import { useEffect, useState } from 'react';
import { Box, Paper, Typography, Table, TableHead, TableBody, TableRow, TableCell, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { getAllAdvisors } from '../services/gutcheck-admin-api.service';
import {
    ChartContainer,
    BarPlot,
    LinePlot,
    ChartsXAxis,
    ChartsYAxis,
    ChartsReferenceLine,
    ChartsTooltip,
    MarkPlot,
    ResponsiveChartContainer
} from '@mui/x-charts';

type AdvisorStats = {
    weekStart: string;
    total: number;
    initiated: number;
    completed: number;
    approved: number;
    percentageActivated: number;
    percentageApproved: number;
};

type Project = {
    id: number;
    name: string;
    organization: Organization;
};

type Organization = {
    id: number;
    name: string;
};

const colors = {
    acquired: '#e9e9e9',
    activated: '#869ec1',
    approved: '#1a5dbd',
    percentageActivated: '#ffd700', // yellow
    percentageApproved: '#4caf50'
};

export const AdvisorReport = ({ startDate, endDate, selectedOrg, selectedProject, showTable = true }: { startDate: Date, endDate: Date, selectedOrg: string, selectedProject: string, showTable?: boolean }) => {
    const [weeklyStats, setWeeklyStats] = useState<AdvisorStats[]>([]);
    const [totalAdvisors, setTotalAdvisors] = useState(0);
    const { getAccessTokenSilently } = useAuth0();
    // Add new state for project statistics
    const [projectStats, setProjectStats] = useState<{
        [key: string]: {
            total: number,
            initiated: number,
            completed: number,
            approved: number
        }
    }>({});
    const [tableView, setTableView] = useState<'weekly' | 'project'>('weekly');

    useEffect(() => {
        fetchAdvisorStats();
    }, [startDate, endDate, selectedOrg, selectedProject]);

    const fetchAdvisorStats = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data } = await getAllAdvisors(accessToken, "advisor");

            if (!data?.advisors) {
                console.error('No advisor data available');
                return;
            }

            // Filter advisors based on selected criteria
            const filteredAdvisors = filterAdvisors(data.advisors);

            // Calculate total advisors from filtered data
            setTotalAdvisors(filteredAdvisors.length);

            // Process weekly stats with filtered data
            const weeklyData = processAdvisorData(filteredAdvisors);
            setWeeklyStats(weeklyData);

            // Calculate project-based statistics
            const projectData = processProjectData(filteredAdvisors);
            setProjectStats(projectData);

        } catch (error) {
            console.error('Error fetching advisor stats:', error);
        }
    };

    // Add a new function to filter advisors based on criteria
    const filterAdvisors = (advisors: any[]): any[] => {
        return advisors.filter((advisor: any) => {
            // Filter by date range if both dates are provided
            const advisorDate = new Date(advisor.created_at);
            const isInDateRange = !startDate || !endDate ||
                (advisorDate >= startDate && advisorDate <= endDate);

            // Filter by organization if selected
            const isInSelectedOrg = !selectedOrg ||
                advisor.survey_link?.project?.organization?.id === selectedOrg;

            // Filter by project if selected
            const isInSelectedProject = !selectedProject ||
                advisor.survey_link?.project?.id === selectedProject;

            return isInDateRange && isInSelectedOrg && isInSelectedProject;
        });
    };

    const processAdvisorData = (advisors: any[]): AdvisorStats[] => {
        // Use the selected date range instead of min/max from advisors
        let chartStartDate = new Date(startDate);
        let chartEndDate = new Date(endDate);

        // If no dates are selected, fall back to the min/max from advisors
        if (!startDate || !endDate) {
            if (advisors.length === 0) {
                return [];
            }

            const dates = advisors.map(a => new Date(a.created_at));
            const minDate = new Date(Math.min(...dates.map(d => d.getTime())));
            const maxDate = new Date(Math.max(...dates.map(d => d.getTime())));

            chartStartDate = minDate;
            chartEndDate = maxDate;
        }

        // Get the Monday of the week for the start date
        chartStartDate.setDate(chartStartDate.getDate() - chartStartDate.getDay() + 1); // Move to Monday
        chartStartDate.setHours(0, 0, 0, 0);

        // Get the Sunday of the week for the end date
        chartEndDate.setDate(chartEndDate.getDate() + (7 - chartEndDate.getDay())); // Move to Sunday

        // Create an object with all weeks initialized
        const weeklyData: { [key: string]: AdvisorStats } = {};
        const currentDate = new Date(chartStartDate);

        while (currentDate <= chartEndDate) {
            const weekStart = currentDate.toISOString().split('T')[0];
            weeklyData[weekStart] = {
                weekStart,
                total: 0,
                initiated: 0,
                approved: 0,
                completed: 0,
                percentageActivated: 0,
                percentageApproved: 0
            };
            currentDate.setDate(currentDate.getDate() + 7);
        }

        // Fill in the actual advisor data
        advisors.forEach((advisor: any) => {
            const advisorDate = new Date(advisor.created_at);
            advisorDate.setDate(advisorDate.getDate() - advisorDate.getDay() + 1);
            advisorDate.setHours(0, 0, 0, 0);
            const weekStart = advisorDate.toISOString().split('T')[0];

            if (weeklyData[weekStart]) {
                weeklyData[weekStart].total++;
                if (advisor.status === 'approved') {
                    weeklyData[weekStart].approved++;
                } else if (advisor.status === 'complete') {
                    weeklyData[weekStart].completed++;
                } else {
                    weeklyData[weekStart].initiated++;
                }
            }
        });

        // Calculate percentages for each week
        Object.values(weeklyData).forEach(week => {
            if (week.total > 0) {
                // Activated = completed + approved
                week.percentageActivated = ((week.completed + week.approved) / week.total) * 100;
                // Approved = approved only
                week.percentageApproved = (week.approved / week.total) * 100;
            }
        });

        return Object.values(weeklyData).sort((a, b) =>
            new Date(a.weekStart).getTime() - new Date(b.weekStart).getTime()
        );
    };

    // Add new function to process advisors by project
    const processProjectData = (advisors: any[]) => {
        const projectData: {
            [key: string]: {
                total: number,
                initiated: number,
                completed: number,
                approved: number
            }
        } = {};

        advisors.forEach((advisor: any) => {
            const projectName = advisor.survey_link?.project?.name || 'Unassigned';

            // Initialize project data if it doesn't exist
            if (!projectData[projectName]) {
                projectData[projectName] = {
                    total: 0,
                    initiated: 0,
                    completed: 0,
                    approved: 0
                };
            }

            // Increment total count
            projectData[projectName].total++;

            // Categorize the advisor
            if (advisor.status === 'approved') {
                projectData[projectName].approved++;
            } else if (advisor.status === 'complete') {
                projectData[projectName].completed++;
            } else {
                projectData[projectName].initiated++;
            }
        });

        return projectData;
    };

    const handleTableViewChange = (
        event: React.MouseEvent<HTMLElement>,
        newView: 'weekly' | 'project' | null,
    ) => {
        if (newView !== null) {
            setTableView(newView);
        }
    };

    return (
        <Box width={'100%'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'space-between' }}>
                {/* New MUI X Charts version */}
                <Box className="display-box white" sx={{ flex: 1 }}>
                    <h5>
                        Advisor Acquisition
                    </h5>
                    <ResponsiveChartContainer
                        height={300}
                        series={[
                            {
                                data: weeklyStats.map(w => w.approved),
                                label: 'Approved',
                                stack: 'a',
                                color: colors.approved,
                                type: 'bar',
                                valueFormatter: (value, context) => `${value}`
                            },
                            {
                                data: weeklyStats.map(w => w.completed),
                                label: 'Activated',
                                stack: 'a',
                                color: colors.activated,
                                type: 'bar',
                                valueFormatter: (value, context) => `${value}`
                            },
                            {
                                data: weeklyStats.map(w => w.initiated),
                                label: 'Acquired',
                                stack: 'a',
                                color: colors.acquired,
                                type: 'bar',
                                valueFormatter: (value, context) => `${value}`
                            },
                            {
                                data: weeklyStats.map(w => w.total > 0 ? w.percentageActivated : null),
                                label: '% Activated',
                                type: 'line',
                                color: colors.percentageActivated,
                                yAxisKey: 'percentage',
                                valueFormatter: (value: number | null) => value ? `${value.toFixed(1)}%` : '0.0%',
                                showMark: true,
                            },
                            {
                                data: weeklyStats.map(w => w.total > 0 ? w.percentageApproved : null),
                                label: '% Approved',
                                type: 'line',
                                color: colors.percentageApproved,
                                yAxisKey: 'percentage',
                                valueFormatter: (value: number | null) => value ? `${value.toFixed(1)}%` : '0.0%',
                                showMark: true,
                            },
                        ]}
                        xAxis={[{
                            data: weeklyStats.map(w => {
                                const d = new Date(w.weekStart);
                                return `${d.getMonth() + 1}/${d.getDate()}`;
                            }),
                            scaleType: 'band',
                            valueFormatter: (value, context) => {
                                // Get the index from the label which matches the date format
                                const index = weeklyStats.findIndex(w => {
                                    const d = new Date(w.weekStart);
                                    return `${d.getMonth() + 1}/${d.getDate()}` === value;
                                });
                                if (index === -1) return value;

                                const week = weeklyStats[index];
                                const date = new Date(week.weekStart);
                                return `${date.getMonth() + 1}/${date.getDate()}`;
                            }
                        }]}
                        yAxis={[
                            {
                                id: 'count',
                                scaleType: 'linear',
                            },
                            {
                                id: 'percentage',
                                scaleType: 'linear',
                                min: 0,
                                max: 100,
                                position: 'right',
                                valueFormatter: (value: number) => `${value}%`,
                            }
                        ]}
                        sx={{
                            '.MuiMarkElement-root': {
                                r: 5, // Controls the radius of the mark circles
                            }
                        }}
                    >
                        <BarPlot />
                        <LinePlot />
                        <MarkPlot />
                        <ChartsXAxis />
                        <ChartsYAxis axisId="count" />
                        <ChartsYAxis axisId="percentage" position="right" />

                        {/* Custom tooltip component */}
                        <ChartsTooltip
                            trigger="axis"
                            slotProps={{
                                popper: {

                                }
                            }}
                            itemContent={(props) => {
                                // Get the current item index
                                if (!props.itemData || props.itemData.length === 0) return null;

                                // Find the week data based on the x-axis index
                                const dataIndex = props.itemData[0]?.dataIndex;
                                if (dataIndex === undefined || dataIndex < 0 || dataIndex >= weeklyStats.length) return null;

                                const week = weeklyStats[dataIndex];
                                const date = new Date(week.weekStart);
                                const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

                                // Calculate totals
                                const acquired = week.initiated + week.completed + week.approved;
                                const activated = week.completed + week.approved;

                                return (
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            Week of {formattedDate}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Total:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{week.total}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Acquired:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{acquired} ({week.total > 0 ? ((acquired / week.total) * 100).toFixed(1) : '0.0'}%)</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Activated:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{activated} ({week.total > 0 ? ((activated / week.total) * 100).toFixed(1) : '0.0'}%)</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Approved:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{week.approved} ({week.total > 0 ? ((week.approved / week.total) * 100).toFixed(1) : '0.0'}%)</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            }}
                        />
                    </ResponsiveChartContainer>

                    {/* Legend - moved outside of ChartContainer */}
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', gap: 2, border: '1px solid #eaeaea', borderRadius: 1, p: 1, backgroundColor: '#f9f9f9' }}>
                        {/* Bar series */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.acquired, mr: 1 }} />
                                <Typography variant="caption">Acquired</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.activated, mr: 1 }} />
                                <Typography variant="caption">Activated</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.approved, mr: 1 }} />
                                <Typography variant="caption">Approved</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, justifyContent: 'center' }}>
                            {/* Line series */}
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 16, height: 2, backgroundColor: colors.percentageActivated, mr: 1 }} />
                                <Typography variant="caption">% Activated</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: 16, height: 2, backgroundColor: colors.percentageApproved, mr: 1 }} />
                                <Typography variant="caption">% Approved</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Table Toggle and Title */}
            <Box sx={{ mt: 4, display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                    Advisor Detail
                </Typography>
                <ToggleButtonGroup
                    value={tableView}
                    exclusive
                    onChange={handleTableViewChange}
                    aria-label="table view"
                    size="small"
                    sx={{ width: '300px' }}
                >
                    <ToggleButton value="weekly" aria-label="weekly view">
                        By Week
                    </ToggleButton>
                    <ToggleButton value="project" aria-label="project view">
                        By Project
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {/* Conditional Table Rendering */}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {tableView === 'weekly' ? (
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Week</TableCell>
                                <TableCell align="right">Acquired</TableCell>
                                <TableCell align="right">Activated</TableCell>
                                <TableCell align="right">Approved</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...weeklyStats].sort((a, b) => 
                                new Date(b.weekStart).getTime() - new Date(a.weekStart).getTime()
                            ).map((week) => {
                                // Calculate acquired and activated counts
                                const acquiredCount = week.initiated + week.completed + week.approved;
                                const acquiredPercent = week.total > 0
                                    ? ((acquiredCount / week.total) * 100).toFixed(1)
                                    : '0.0';
                                const activatedCount = week.completed + week.approved;
                                const activatedPercent = week.total > 0
                                    ? ((activatedCount / week.total) * 100).toFixed(1)
                                    : '0.0';
                                const approvedCount = week.approved;
                                const approvedPercent = week.total > 0
                                    ? ((approvedCount / week.total) * 100).toFixed(1)
                                    : '0.0';

                                return (
                                    <TableRow key={week.weekStart}>
                                        <TableCell component="th" scope="row">
                                            {new Date(week.weekStart).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                                        </TableCell>
                                        <TableCell align="right">{acquiredCount}</TableCell>
                                        <TableCell align="right">
                                            {activatedPercent}% ({activatedCount})
                                        </TableCell>
                                        <TableCell align="right">
                                            {approvedPercent}% ({approvedCount})
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {/* Total row */}
                            {weeklyStats.length > 0 && (
                                <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {weeklyStats.reduce((sum, week) => sum + week.total, 0)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {(() => {
                                            const totalAdvisors = weeklyStats.reduce((sum, week) => sum + week.total, 0);
                                            const totalAcquired = weeklyStats.reduce((sum, week) => sum + week.completed + week.approved, 0);
                                            const acquiredPercent = totalAdvisors > 0
                                                ? ((totalAcquired / totalAdvisors) * 100).toFixed(1)
                                                : '0.0';
                                            return `${acquiredPercent}% (${totalAcquired})`;
                                        })()}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {(() => {
                                            const totalAdvisors = weeklyStats.reduce((sum, week) => sum + week.total, 0);
                                            const totalApproved = weeklyStats.reduce((sum, week) => sum + week.approved, 0);
                                            const approvedPercent = totalAdvisors > 0
                                                ? ((totalApproved / totalAdvisors) * 100).toFixed(1)
                                                : '0.0';
                                            return `${approvedPercent}% (${totalApproved})`;
                                        })()}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                ) : (
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project</TableCell>
                                <TableCell align="right">Acquired</TableCell>
                                <TableCell align="right">Activated</TableCell>
                                <TableCell align="right">Approved</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(projectStats).sort((a, b) => a[0].localeCompare(b[0])).map(([project, stats]) => {
                                // Calculate percentages
                                const acquiredPercent = stats.total > 0
                                    ? (((stats.completed + stats.approved) / stats.total) * 100).toFixed(1)
                                    : '0.0';
                                const activatedPercent = stats.total > 0
                                    ? ((stats.approved / stats.total) * 100).toFixed(1)
                                    : '0.0';

                                return (
                                    <TableRow key={project}>
                                        <TableCell component="th" scope="row">
                                            {project}
                                        </TableCell>
                                        <TableCell align="right">{stats.total}</TableCell>
                                        <TableCell align="right">
                                            {acquiredPercent}% ({stats.completed + stats.approved})
                                        </TableCell>
                                        <TableCell align="right">
                                            {activatedPercent}% ({stats.approved})
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {/* Total row */}
                            {Object.keys(projectStats).length > 0 && (
                                <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {Object.values(projectStats).reduce((sum, stats) => sum + stats.total, 0)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {(() => {
                                            const totalAdvisors = Object.values(projectStats).reduce((sum, stats) => sum + stats.total, 0);
                                            const totalAcquired = Object.values(projectStats).reduce((sum, stats) => sum + stats.completed + stats.approved, 0);
                                            const acquiredPercent = totalAdvisors > 0
                                                ? ((totalAcquired / totalAdvisors) * 100).toFixed(1)
                                                : '0.0';
                                            return `${acquiredPercent}% (${totalAcquired})`;
                                        })()}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {(() => {
                                            const totalAdvisors = Object.values(projectStats).reduce((sum, stats) => sum + stats.total, 0);
                                            const totalActivated = Object.values(projectStats).reduce((sum, stats) => sum + stats.approved, 0);
                                            const activatedPercent = totalAdvisors > 0
                                                ? ((totalActivated / totalAdvisors) * 100).toFixed(1)
                                                : '0.0';
                                            return `${activatedPercent}% (${totalActivated})`;
                                        })()}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </Paper>
        </Box>
    );
};
