import { Box, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const boxStyle = {
    flex: 1
}

const OverviewBox = styled(Box)({
    flex: 1,
    " a": {
        fontWeight: 700,
    }

});

export const DealOverviewStats = ({project, viewerRole}: any) => {

    const numOffersToAdvise = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_advisor)?.length ?? 0;
    const numDemoOrTrial = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.length ?? 0;
    const numInvestors = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_investor)?.length ?? 0;
    const totalProspectiveSales = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.reduce((acc: number, viewpoint: any) => acc + viewpoint?.max_spend, 0) ?? 0;

    const totalPotentialInvestment = Math.round(project?.viewpoints?.filter((viewpoint: any) => (viewpoint?.interest_investor || viewpoint.advisor?.funded || viewpoint.advisor?.reserved_spot))?.reduce((acc: number, viewpoint: any) => {
        if (viewpoint?.advisor?.funded) return acc + viewpoint?.advisor?.funded_amount;
        if (viewpoint?.advisor?.reserved_spot) return acc + viewpoint?.advisor?.reserved_amount;  
        return acc + (viewpoint?.investment_amount_high + viewpoint?.investment_amount_low)/2;
    }, 0) ?? 0);

    const shareLink = project.project_share_links[0];

    const viewpointsEnabled = viewerRole === "guest" && !shareLink.display_viewpoints ? false : true;
    const prospectsEnabled = viewerRole === "guest" && !shareLink.display_prospects ? false : true;
    const investorsEnabled = viewerRole === "guest" && !shareLink.display_investors ? false : true;

    return (
        <Stack spacing={2} direction="row" sx={{marginBottom: "40px"}}>
            <OverviewBox className="display-box white">
                <Typography variant="h5">
                    { viewpointsEnabled ? <Link to="viewpoints">{project.viewpoints.length}</Link> : <span>{project.viewpoints.length}</span> }
                </Typography>
                <Typography>Viewpoints</Typography>
            </OverviewBox>
            <OverviewBox className="display-box white">
                <Typography variant="h5">
                    { prospectsEnabled ? <Link to="prospects">${totalProspectiveSales.toLocaleString("en-US")}</Link> : <span>${totalProspectiveSales.toLocaleString("en-US")}</span> }
                </Typography>
                <Typography>Prospective Sales</Typography>
            </OverviewBox>
            <OverviewBox className="display-box white">
                <Typography variant="h5">
                    { investorsEnabled ? <Link to="industry-angels">${totalPotentialInvestment.toLocaleString("en-US")}</Link>: <span>${totalPotentialInvestment.toLocaleString("en-US")}</span> }
                </Typography>
                <Typography>Potential Angel Investment</Typography>
            </OverviewBox>
            <OverviewBox className="display-box white">
                <Typography variant="h5">{numOffersToAdvise}</Typography>
                <Typography>Offers to Advise</Typography>
            </OverviewBox>
        </Stack>
    );
}