import { IProfile } from "../../../types";
import { callExternalApi } from "../../services/external-api.service"; 

class UserHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = `${apiServerUrl}/v2`;
    }

    async updateProfile(profile: Partial<IProfile>, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/user/me/profile`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(profile),
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error,
        };
    }

    async uploadProfileImage(file: File, fileId: string, accessToken: any) {

        const formData = new FormData();
        formData.append("file", file, fileId);

        const config = {
            url: `${this._apiServerUrl}/user/me/profile/image`,
            method: "POST",
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${accessToken}`,
            },
            data: formData,
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error,
        };
    }
}

export default UserHttpClient;
    