import { CompanyTitle } from "../../components/CompanyTitle";
import { useDealContext } from "../../../deal/context";
import { useState, useEffect } from "react";
import ClientProvider from "../../../../modules/ClientProvider";
import { Button, Dialog, TableBody, TableCell, TableHead, TableRow, TextField, Stack, TableSortLabel } from "@mui/material";
import { Box } from "@mui/material";
import { AdvisorTable, ExecTabs, ExecTab, EmptyList } from "../../styles/AdvisorStyles";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../../../../features/users/context";
import { FileUp, Download } from "lucide-react";
import Dropzone from "react-dropzone";
import { Typography, Link } from "@mui/material";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AdvisorSuggestion {
    id: number;
    uuid: string;
    name: string;
    email: string;
    linkedin_url: string;
    company_name: string;
    job_title: string;
    company_website: string;
    company_linkedin_url: string;
    status: string;
    created_at: string;
    created_by: {
        uuid: string;
        name: string;
    };
}

const initialFormData = {
    name: '',
    email: '',
    linkedin_url: '',
    company_name: '',
    job_title: '',
    company_website: '',
    company_linkedin_url: ''
};

const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Invited', value: 'invited' },
    { label: 'Active', value: 'active' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deleted', value: 'deleted' }
];

const AdvisorSuggestionForm = ({ open, onClose, fetchSuggestions }: { 
    open: boolean, 
    onClose: () => void,
    fetchSuggestions: () => void 
}) => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        try {
            const projectClient = ClientProvider.provideProjectClient();
            const accessToken = await getAccessTokenSilently();
            
            const { error } = await projectClient.addAdvisorSuggestion(
                project.id,
                formData,
                accessToken
            );

            if (error) {
                setError(error.message);
                return;
            }
            
            setFormData(initialFormData);
            onClose();
            fetchSuggestions();
        } catch (err) {
            setError('Failed to submit suggestion. Please try again.');
        }
    };

    const handleClose = () => {
        setFormData(initialFormData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box>
                <h5>Suggest an Advisor</h5>
                <p>Suggest an advisor to your network.</p>
                {error && (
                    <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>
                )}
                <form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={2}>
                        <TextField 
                            label="Name" 
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <TextField 
                            label="Email" 
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <TextField 
                            label="LinkedIn URL" 
                            name="linkedin_url"
                            value={formData.linkedin_url}
                            onChange={handleChange}
                        />
                        <TextField 
                            label="Company Name" 
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                        />
                        <TextField 
                            label="Job Title" 
                            name="job_title"
                            value={formData.job_title}
                            onChange={handleChange}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type="submit">Submit</Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Dialog>
    );
};

const UploadCSVModal = ({ project, open, onClose, fetchSuggestions }: { 
    project: any, 
    open: boolean, 
    onClose: () => void,
    fetchSuggestions: () => void 
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const projectClient = ClientProvider.provideProjectClient();
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | null>(null);

    const downloadTemplate = () => {
        const csvContent = "name,email,linkedin_url,company_name,job_title\n"; // CSV header
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'advisor_suggestions_template.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    };

    const handleDrop = (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
    };

    const handleUpload = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await projectClient.uploadAdvisorSuggestions(project.id, files[0], accessToken);
            
            if (error) {
                setError(error.message);
                return;
            }
            
            onClose();
            fetchSuggestions();
        } catch (err) {
            setError('Failed to upload suggestions. Please try again.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Box>
                <h5>Upload Advisor Suggestions</h5>
                <p>
                    Upload a CSV file with advisor information. Include a header row with: name, email, linkedin_url, company_name, and job_title.{' '}
                    <Link 
                        component="button"
                        variant="body2"
                        onClick={downloadTemplate}
                        sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <Download size={16} style={{ marginRight: '4px' }} /> Download template
                    </Link>
                </p>
                {error && (
                    <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>
                )}
                <Dropzone onDrop={handleDrop} accept={{ 'text/csv': ['.csv'] }}>
                    {({ getRootProps, getInputProps }: any) => (
                        <Box
                            {...getRootProps()}
                            sx={{
                                border: '2px dashed #ccc',
                                borderRadius: 2,
                                p: 3,
                                textAlign: 'center',
                                cursor: 'pointer',
                                bgcolor: '#fafafa',
                                '&:hover': {
                                    bgcolor: '#f0f0f0',
                                    borderColor: '#999'
                                }
                            }}
                        >
                            <input {...getInputProps()} />
                            <FileUp size={32} style={{ marginBottom: 8, color: '#666' }} />
                            <Typography>
                                Drag and drop a CSV file here, or click to select a file
                            </Typography>
                            {files.length > 0 && (
                                <Box sx={{ mt: 2, color: 'success.main' }}>
                                    <Typography>Selected: {files[0].name}</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Dropzone>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button onClick={onClose} variant="outlined">Cancel</Button>
                    <Button variant="contained" disabled={files.length === 0} onClick={handleUpload}>
                        Upload
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

// Add these type definitions
type SortColumn = 'name' | 'email' | 'linkedin' | 'company' | 'title' | 'suggestedBy' | 'dateSuggested' | 'status';
type SortDirection = 'asc' | 'desc';

export const AdvisorSuggestions = () => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [suggestions, setSuggestions] = useState<AdvisorSuggestion[]>([]);
    const [openAdvisorSuggestionForm, setOpenAdvisorSuggestionForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);    
    const [selectedTab, setSelectedTab] = useState<string>("all");
    const [ filteredSuggestions, setFilteredSuggestions ] = useState<AdvisorSuggestion[]>([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>('name');
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

    const projectClient = ClientProvider.provideProjectClient();
    const { userContext } = useUserContext();

    const user = userContext?.dbUser;

    const fetchSuggestions = async () => {
        setLoading(true);
        setError(null);
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await projectClient.getAdvisorsSuggestions(project.id, accessToken);
            if (error) {
                setError(error.message);
                return;
            }
            if (data) {
                setSuggestions(data.suggestions);
                setFilteredSuggestions( selectedTab === "your" ? data.suggestions.filter((suggestion: AdvisorSuggestion) => suggestion.created_by.uuid === user.uuid) : data.suggestions);
            }
        } catch (err) {
            setError('Failed to fetch suggestions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSuggestions();
    }, [project.id]);

    const handleTabChange = (tab: string) => {
        setSelectedTab(tab);
        setFilteredSuggestions(tab === "your" ? suggestions.filter((suggestion: AdvisorSuggestion) => suggestion.created_by.uuid === user.uuid) : suggestions);
    };

    // Add sort handler
    const handleSort = (column: SortColumn) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    // Update useEffect to include sorting
    useEffect(() => {
        const sortedSuggestions = [...filteredSuggestions].sort((a, b) => {
            const direction = sortDirection === 'asc' ? 1 : -1;
            
            switch (sortColumn) {
                case 'name':
                    return (a.name || '').localeCompare(b.name || '') * direction;
                case 'email':
                    return (a.email || '').localeCompare(b.email || '') * direction;
                case 'linkedin':
                    return (a.linkedin_url || '').localeCompare(b.linkedin_url || '') * direction;
                case 'company':
                    return (a.company_name || '').localeCompare(b.company_name || '') * direction;
                case 'title':
                    return (a.job_title || '').localeCompare(b.job_title || '') * direction;
                case 'suggestedBy':
                    return (a.created_by.name || '').localeCompare(b.created_by.name || '') * direction;
                case 'dateSuggested':
                    return ((new Date(a.created_at).getTime() - new Date(b.created_at).getTime())) * direction;
                case 'status':
                    return (a.status || '').localeCompare(b.status || '') * direction;
                default:
                    return 0;
            }
        });
        setFilteredSuggestions(sortedSuggestions);
    }, [suggestions, selectedTab, sortColumn, sortDirection]);

    return (
        <Box>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button 
                        variant="outlined" 
                        onClick={() => setUploadModalOpen(true)}
                    >
                        <FileUp size={16} style={{ marginRight: '4px' }} />
                        Upload CSV
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={() => setOpenAdvisorSuggestionForm(true)}
                    >
                        Suggest an Advisor
                    </Button>
                </Box>
            </Box>
            
            {error && (
                <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>
            )}

            <ExecTabs value={selectedTab} onChange={(_, value) => handleTabChange(value)}>
                <ExecTab label="All Suggestions" value="all" />
                <ExecTab label="Your Suggestions" value="your" />
            </ExecTabs>
            
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'name'}
                                direction={sortColumn === 'name' ? sortDirection : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'company'}
                                direction={sortColumn === 'company' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'email'}
                                direction={sortColumn === 'email' ? sortDirection : 'asc'}
                                onClick={() => handleSort('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'linkedin'}
                                direction={sortColumn === 'linkedin' ? sortDirection : 'asc'}
                                onClick={() => handleSort('linkedin')}
                            >
                                <FontAwesomeIcon icon={faLinkedin}/>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'suggestedBy'}
                                direction={sortColumn === 'suggestedBy' ? sortDirection : 'asc'}
                                onClick={() => handleSort('suggestedBy')}
                            >
                                Invited By
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'status'}
                                direction={sortColumn === 'status' ? sortDirection : 'asc'}
                                onClick={() => handleSort('status')}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredSuggestions.map((suggestion) => (
                        <TableRow key={suggestion.id}>
                            <TableCell>{suggestion.name}</TableCell>
                            <TableCell>{suggestion.company_name}</TableCell>
                            <TableCell>{suggestion.email}</TableCell>
                            <TableCell>{suggestion.linkedin_url && <Link href={suggestion.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></Link>}</TableCell>
                            <TableCell>{suggestion.created_by.name}</TableCell>
                            <TableCell>{statusOptions.find(option => option.value === suggestion.status)?.label}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </AdvisorTable>
            { loading && (
                <EmptyList>
                    <p>Loading...</p>
                </EmptyList>
            )}
            { !loading && filteredSuggestions.length === 0 && (
                <EmptyList>
                    <p>No suggestions yet</p>
                </EmptyList>
            )}
            
            <AdvisorSuggestionForm 
                open={openAdvisorSuggestionForm} 
                onClose={() => setOpenAdvisorSuggestionForm(false)}
                fetchSuggestions={fetchSuggestions}
            />

            <UploadCSVModal 
                project={project}
                open={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                fetchSuggestions={fetchSuggestions}
            />
        </Box>
    );
};