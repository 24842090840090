import React, { BaseSyntheticEvent, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from 'react-router-dom';
import { createOrganization, getAllDataForSelf } from "../../../core/services/gutcheck-api.service";
import { OrganizationHero, CreateOrganizationForm } from "../components/organization_widgets";
import { Dialog, Box, Typography, FormControl, InputLabel, TextareaAutosize, Input, TextField, LinearProgress } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import Membership from "../../users/types/Membership";

export type TOrganization = {
  name: string
};

const initState: TOrganization = {
  name: ''
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 900,
  bgcolor: 'background.paper',
  border: '1px solid #c9c9c9',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

export const CreateWorkspace = () => {
  const [form, setForm] = useState(initState);
  const [open, setOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { userContext, loadUserContext } = useUserContext();

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const handleChange = (event: BaseSyntheticEvent) => {
    setForm({
      name: event.target.value,
    });
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      event.preventDefault();
      setOpen(true);
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await createOrganization(form.name, accessToken);

      if (error) throw error;

      const organization = data.organization;

      if (organization) {
        trackEvent("Created organization", { organization: organization.tag, organizationName: organization.name });
        loadUserContext();
      }
      if (organization.projects?.length > 0) {
        return navigate(`/o/${organization.tag}`);
      } else {
        return navigate(`/o/${organization.tag}/project/new`, { state: { organization: data.organization } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (userContext.dbUser.role !== "admin") {
    return (
      <main className="page-main">
        <div className="gc_view">
          <div className="splash-page">
            <h2>This feature is not available.</h2>
            <button className="btn btn-primary" onClick={() => navigate("/")}>Click here to continue</button>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="page-main">
      <div className="gc_view">
        <div className="splash-page">
          <OrganizationHero />
          <h2 className="dashboard-title">What is the name of your firm?</h2>
          <CreateOrganizationForm form={form} handleSubmit={handleSubmit} handleChange={handleChange} />
        </div>
      </div>
      <Dialog open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">


        <Typography id="modal-modal-title" variant="h5" component="h2">
          Please wait while we setup <b>{form.name}</b>
        </Typography>
        <LinearProgress />

      </Dialog>
    </main>
  );
};
