import { CompanyLogoBase, CompanyNameBase } from "../../../components/shared/StyledComponents";

export const ViewpointCompany = ({ viewpoint, size="medium", style="centered" }: any) => {

    if (viewpoint?.company?.logo_url) {
        return (
            <CompanyLogoBase className={`${size} ${style}`}>
                <img src={viewpoint.company.logo_url} alt={viewpoint.company.name} />
            </CompanyLogoBase>
        );
    }
    return (
        <CompanyNameBase className={`${size} ${style}`}>
            {viewpoint?.company?.name ?? viewpoint.company_name}
        </CompanyNameBase>
    );
}