import { Box, Button, Dialog, Typography } from "@mui/material";
import { useFirmContext } from "../../context";
import { OrganizationTitle } from "../../components/OrganizationTitle";
import { useUserContext } from "../../../users";
import Loading from "../../../../components/Loading";
import React from "react";
import { UserProfileUpdate } from "../../../users/components/UserProfileUpdate";

const DisplayCompanyLogo = React.memo(({ project }: any) => {

    if (!project) {
        return <></>;
    }

    const logoUrl = project.logo_url || project.company?.logo_url;

    const CompanyLogoWrapper = ({ children }: { children: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", height: "100%", alignItems: "center" }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );

    if (logoUrl && logoUrl !== "") {
        return (
            <CompanyLogoWrapper>
                <Box sx={{ height: "100%" }}><img className="nav-logo-img" src={logoUrl} /></Box>
            </CompanyLogoWrapper>
        );
    } else if (project?.name && project?.name !== "") {
        return (
            <CompanyLogoWrapper>
                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>{project?.name}</Typography>
            </CompanyLogoWrapper>
        );
    }

    return <></>;
});

export const AdvisorsFirmPortfolio = () => {
    const { firmContext } = useFirmContext();
    const { userContext, loadUserContext } = useUserContext();
    const { organization } = firmContext;
    const user = userContext.dbUser;

    if (!organization || !user || !userContext.fetched || !firmContext.fetched) {
        return <Loading />;
    }

    const portfolioProjects = user.memberships.filter((membership: any) => membership.project?.organization?.id === organization.id && membership.role === "advisor").map((membership: any) => membership.project);

    return (
        <Box>
            <OrganizationTitle organization={organization} section="Portfolio" subheading="Advise companies in your portfolio" />
            {portfolioProjects.map((project: any) => (
                <a href={`${process.env.REACT_APP_APP_PROTOCOL}://${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/advisor`}>
                    <Box className="display-box white link">
                        <DisplayCompanyLogo key={project.id} project={project} />
                    </Box>
                </a>
            ))}
            { !user.profile.user_reviewed &&
            <Dialog open={true} onClose={() => {}}>
                <h5>Welcome to the {organization.name} Advisor Network</h5>
                <p>Please take a moment to review and updateyour profile.</p>
                <UserProfileUpdate user={user} onUpdate={() => {
                    loadUserContext();
                }} />
            </Dialog>
            }
        </Box>
    );
}