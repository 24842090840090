import { CompanyLogoBase, CompanyNameBase } from "../../../components/shared/StyledComponents";

export const FirmLogo = ({ organization, size="medium", style="centered" }: any) => {

    const logoUrl = organization?.logoUrl || organization?.logo_url || organization.company?.logo_url || organization.company?.logoUrl;

    if (logoUrl) {
        return (
            <CompanyLogoBase className={`${size} ${style}`}>
                <img src={logoUrl} alt={organization?.name} />
            </CompanyLogoBase>
        );
    }
    return (
        <CompanyNameBase className={`${size} ${style}`}>
            {organization?.name ?? organization.name}
        </CompanyNameBase>
    );
}