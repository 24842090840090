import { Button, TextField, TableBody, Box, Stack, TableRow, TableCell, TableHead, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { useDealContext } from "../../../deal/context";
import { AdvisorTable, EmptyList } from "../../styles/AdvisorStyles";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Equal, X } from "lucide-react";

interface PaidCampaign {
    id: number;
    uuid: string;
    name: string;
    description: string;
    amount_ordered: number;
    amount_delivered: number;
    cpa_target: number;
    cpa_actual: number;
    cost: number;
    status: string;
    num_meetings: number;
    num_opps: number;
    num_customers: number;
    start_at: Date;
    end_at: Date;
    is_active: boolean;
    ordered_by: {
        name: string;
    };
}

const initialFormData = {
    name: '',
    description: '',
    amount_ordered: 0,
    cpa_target: 200,
    start_at: new Date(),
    end_at: dayjs().add(30, 'days').toDate(),
    is_active: true,
    cost: 0
};

const SectionControl = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1rem",
    "& .formfields": {
        backgroundColor: "#f8f9fa",
        boxShadow: "0 0 5px #c9c9c9",
        borderRadius: "10px",
        width: "100%",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "1rem",
        "& h5": {
            fontSize: "1.2rem",
            fontWeight: 600,
            margin: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "1rem",
            cursor: "pointer",
            "& span": {
                fontSize: "1rem",
                color: "#ffffff",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem",
                width: "35px",
                height: "35px",
                borderRadius: "50%"
            }
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
            backgroundColor: "#f8f9fa",
            color: "rgba(0, 0, 0, 0.87)",
            "& input": {
                color: "rgba(0, 0, 0, 0.87)",
                "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87)"
            }
        }
    },
    "& .submit": {
        backgroundColor: "transparent",
        padding: "0",
        boxShadow: "none",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center",
        "& button": {
            width: "200px",
            marginLeft: "20px"
        }
    }
}));

export const PaidCampaigns = () => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [campaigns, setCampaigns] = useState<PaidCampaign[]>([]);
    const [formData, setFormData] = useState({
        ...initialFormData,
        cpa_target: project.cpa_default ? project.cpa_default : 200
    });
    const [editingCampaign, setEditingCampaign] = useState<PaidCampaign | null>(null);
    const [deleteCampaign, setDeleteCampaign] = useState<PaidCampaign | null>(null);

    const fetchCampaigns = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();
        const { data } = await projectClient.getPaidCampaigns(project.id, accessToken);
        if (data) {
            setCampaigns(Array.isArray(data.campaigns) ? data.campaigns : []);
        }
    };

    useEffect(() => {
        fetchCampaigns();

    }, [project.id, getAccessTokenSilently]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
        
        // Calculate cost using the current values
        const amount = name === 'amount_ordered' ? Number(value) : Number(updatedFormData.amount_ordered);
        const cpa = name === 'cpa_target' ? Number(value) : Number(updatedFormData.cpa_target);
        setFormData(prev => ({
            ...prev,
            cost: amount * cpa
        }));
    };

    const handleDateChange = (field: 'start_at' | 'end_at', date: Date | null) => {
        if (date) {
            if (field === 'start_at') {
                // When start date changes, ensure end date is at least 1 month later
                const newEndDate = dayjs(formData.end_at);
                const minimumEndDate = dayjs(date).add(1, 'month');
                
                setFormData(prev => ({
                    ...prev,
                    start_at: date,
                    end_at: newEndDate.isBefore(minimumEndDate) ? minimumEndDate.toDate() : prev.end_at
                }));
            } else {
                setFormData(prev => ({
                    ...prev,
                    [field]: date
                }));
            }
        }
    };

    const handleSave = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();

        // Add date validation
        const daysBetween = dayjs(formData.end_at).diff(dayjs(formData.start_at), 'days');
        if (daysBetween < 30) {
            alert('Campaign duration must be at least 30 days');
            return;
        }

        if (editingCampaign) {
            await projectClient.updatePaidCampaign(
                project.id,
                editingCampaign.uuid,
                formData,
                accessToken
            );
        } else {
            await projectClient.addPaidCampaign(
                project.id,
                formData,
                accessToken
            );
        }

        setFormData(initialFormData);
        setEditingCampaign(null);
        fetchCampaigns();
    };

    const handleEdit = (campaign: PaidCampaign) => {
        setEditingCampaign(campaign);
        setFormData({
            name: campaign.name,
            description: campaign.description,
            amount_ordered: campaign.amount_ordered,
            cpa_target: campaign.cpa_target,
            start_at: new Date(campaign.start_at),
            end_at: new Date(campaign.end_at),
            is_active: campaign.is_active,
            cost: campaign.cost
        });
    };

    const handleDeleteClick = (campaign: PaidCampaign) => {
        setDeleteCampaign(campaign);
    };

    const handlePause = async (campaign: PaidCampaign) => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();
        await projectClient.updatePaidCampaign(
            project.id,
            campaign.uuid,
            { ...campaign, status: campaign.status === "paused" ? "pending" : "paused" },
            accessToken
        );
        fetchCampaigns();
    };

    const handleDeleteConfirm = async () => {
        if (deleteCampaign) {
            const projectClient = new ProjectHttpClient();
            const accessToken = await getAccessTokenSilently();
            await projectClient.updatePaidCampaign(
                project.id,
                deleteCampaign.uuid,
                { ...deleteCampaign, status: "cancelled" },
                accessToken
            );
            setDeleteCampaign(null);
            fetchCampaigns();
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "30px" }}>

            <Stack gap={2}>
                <SectionControl>
                    <Box className="formfields">
                        <h5><span>1</span> Set the target number</h5>
                        <Box>
                            <p>How many new advisors do you want per month?</p>
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                <TextField
                                    label="Number of Advisors per month"
                                    name="amount_ordered"
                                    type="number"
                                    value={formData.amount_ordered}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                                <Box><X /></Box>
                                <TextField
                                    disabled={true}
                                    label="Cost per advisor"
                                    name="cpa_target"
                                    type="number"
                                    value={formData.cpa_target}
                                    onChange={handleInputChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: '$',
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 0
                                    }}
                                />
                                <Box><Equal /></Box>
                                <TextField
                                    disabled={true}
                                    label="Monthly Cost"
                                    name="cost"
                                    type="number"
                                    value={formData.cost}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: '$',
                                        readOnly: true
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 0
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Box>

                </SectionControl>

                <SectionControl>
                    <Box className="formfields">
                        <h5><span>2</span> Specify the dates this should run</h5>
                        <Box>
                            <p>We'll plan to run this campaign from the start date to the end date. If you want to run it indefinitely, set the end date to a far future date.</p>
                            <Stack direction="row" spacing={2}>
                                <DatePicker
                                    label="Start Date"
                                    value={dayjs(formData.start_at)}
                                    onChange={(date: any) => handleDateChange('start_at', date?.toDate() || null)}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={dayjs(formData.end_at)}
                                    onChange={(date: any) => handleDateChange('end_at', date?.toDate() || null)}
                                    minDate={dayjs(formData.start_at).add(30, 'days')}
                                    slotProps={{
                                        textField: {
                                            helperText: 'Must be at least 30 days after start date'
                                        }
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Box>

                </SectionControl>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                    >
                        {editingCampaign ? 'Update Campaign' : 'Add Campaign'}
                    </Button>
                </Box>


                <AdvisorTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell align="right">Ordered</TableCell>
                            <TableCell align="right">Delivered</TableCell>
                            <TableCell align="right">CPA Target</TableCell>
                            <TableCell align="right">Monthly Cost</TableCell>
                            <TableCell align="right">Ordered By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaigns.map((campaign) => {
                            const months = dayjs(campaign.end_at).diff(dayjs(campaign.start_at), 'month', true);
                            const monthlyCost = campaign.amount_ordered * campaign.cpa_target;
                            const totalCost = monthlyCost * Math.max(months, 1);
                            
                            return (
                                <TableRow key={campaign.uuid}>
                                    <TableCell>{dayjs(campaign.start_at).format('MMM D, YYYY')}</TableCell>
                                    <TableCell>{dayjs(campaign.end_at).format('MMM D, YYYY')}</TableCell>
                                    <TableCell align="right">{campaign.amount_ordered}</TableCell>
                                    <TableCell align="right">{campaign.amount_delivered}</TableCell>
                                    <TableCell align="right">${campaign.cpa_target}</TableCell>
                                    <TableCell align="right">${monthlyCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell align="right">{campaign.ordered_by?.name}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </AdvisorTable>

                {campaigns?.length === 0 && (
                    <EmptyList>
                        <p>No paid campaigns yet</p>
                    </EmptyList>
                )}
                <ConfirmationModal
                    open={!!deleteCampaign}
                    onClose={() => setDeleteCampaign(null)}
                    onConfirm={handleDeleteConfirm}
                    success={false}
                    title="Cancel Paid Campaign"
                    message="Are you sure you want to cancel this paid campaign?"
                />
            </Stack>
            <Box className="display-box white" sx={{ maxWidth: "250px", flexShrink: 1 }}>
                <h5>FAQ</h5>
                <h6>How is Cost per Advisor calculated?</h6>
                <p>Cost per Advisor is based on the seniority and experience of the advisor you're targeting.</p>
            </Box>
        </Box>
    );
};
