import { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { getAllMeetings } from '../services/gutcheck-admin-api.service';
import {
    ChartContainer,
    BarPlot,
    LinePlot,
    ChartsXAxis,
    ChartsYAxis,
    ChartsReferenceLine,
    ChartsTooltip,
    MarkPlot,
    ResponsiveChartContainer
} from '@mui/x-charts';

type MeetingStats = {
    weekStart: string;
    total: number;
    scheduled: number;
    completedNoRating: number;
    completedLowRating: number;
    completedHighRating: number;
    percentageHighRating: number;
};

const ratingLabels: { [index: number]: string } = {
    0: "NA - Did not attend",
    1: 'No-show or complete miss',
    2: 'Not the best use of time',
    3: 'Worthwhile, would do more',
    4: 'Great call, with next steps',
};

// Add interface
interface RatingDistribution {
    rating: string;
    count: number;
}

const colors = {
    valuable: '#1a5dbd',
    notValuable: '#F44336',
    notRated: '#D3E2F3',
    scheduled: '#e9e9e9',
    percentageValuable: '#4caf50'
};

export const MeetingsReport = ({ startDate, endDate, selectedOrg, selectedProject, showTable = true, fullWidth = false }: { startDate: Date, endDate: Date, selectedOrg: number, selectedProject: number, showTable?: boolean, fullWidth?: boolean }) => {
    const [weeklyStats, setWeeklyStats] = useState<MeetingStats[]>([]);
    const [totalMeetings, setTotalMeetings] = useState(0);
    const [averageRating, setAverageRating] = useState(0);
    const [ratingDistribution, setRatingDistribution] = useState<RatingDistribution[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const [meetings, setMeetings] = useState<any[]>([]);
    const [projectStats, setProjectStats] = useState<{
        [key: string]: {
            total: number,
            scheduled: number,
            completedNoRating: number,
            completedLowRating: number,
            completedHighRating: number,
            percentageHighRating: number
        }
    }>({});
    const [tableView, setTableView] = useState<'weekly' | 'project'>('weekly');

    useEffect(() => {
        fetchMeetingStats();
    }, [startDate, endDate, selectedOrg, selectedProject]);

    const fetchMeetingStats = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data } = await getAllMeetings(accessToken, "advisor");

            if (!data?.meetings) {
                console.error('No meetings data available');
                return;
            }

            // Filter meetings based on selected criteria
            const filteredMeetings = filterMeetings(data.meetings);

            // Calculate total meetings
            setTotalMeetings(filteredMeetings.length);
            setMeetings(filteredMeetings);

            // Calculate average rating
            const allRatings = filteredMeetings
                .filter((meeting: any) => meeting.ratings && meeting.ratings.length > 0)
                .flatMap((meeting: any) => meeting.ratings.map((r: any) => r.rating));

            setAverageRating(allRatings.length > 0 ?
                allRatings.reduce((sum: number, rating: number) => sum + rating, 0) / allRatings.length :
                0
            );

            // Process weekly stats
            const weeklyData = processMeetingData(filteredMeetings);
            setWeeklyStats(weeklyData);

            // Calculate project-based statistics
            const projectData = processProjectData(filteredMeetings);
            setProjectStats(projectData);

            // Calculate rating distribution directly from meetings data
            const ratingCounts: { [key: number]: number } = {};
            filteredMeetings.forEach((meeting: any) => {
                const rating = meeting.ratings?.[0]?.rating;
                if (rating !== undefined) {
                    ratingCounts[rating] = (ratingCounts[rating] || 0) + 1;
                }
            });

            setRatingDistribution(Object.entries(ratingLabels).map(([rating, label]) => ({
                rating: label,
                count: ratingCounts[parseInt(rating)] || 0
            })));

        } catch (error) {
            console.error('Error fetching meeting stats:', error);
        }
    };

    // Add a new function to filter meetings based on criteria
    const filterMeetings = (meetings: any[]): any[] => {
        return meetings.filter((meeting: any) => {
            // Filter by date range if both dates are provided
            const meetingDate = new Date(meeting.event_start);
            const isInDateRange = !startDate || !endDate ||
                (meetingDate >= startDate && meetingDate <= endDate);

            // Filter by organization if selected
            const isInSelectedOrg = !selectedOrg ||
                meeting.project?.organization?.id === selectedOrg;

            // Filter by project if selected
            const isInSelectedProject = !selectedProject ||
                meeting.project?.id === selectedProject;

            return isInDateRange && isInSelectedOrg && isInSelectedProject;
        });
    };

    const processMeetingData = (meetings: any[]): MeetingStats[] => {
        // Use the selected date range instead of min/max from meetings
        let chartStartDate = new Date(startDate);
        let chartEndDate = new Date(endDate);

        // If no dates are selected, fall back to the min/max from meetings
        if (!startDate || !endDate) {
            if (meetings.length === 0) {
                return [];
            }

            const dates = meetings.map(m => new Date(m.event_start));
            const minDate = new Date(Math.min(...dates.map(d => d.getTime())));
            const maxDate = new Date(Math.max(...dates.map(d => d.getTime())));

            chartStartDate = minDate;
            chartEndDate = maxDate;
        }

        // Get the Monday of the week for the start date
        chartStartDate.setDate(chartStartDate.getDate() - chartStartDate.getDay() + 1); // Move to Monday
        chartStartDate.setHours(0, 0, 0, 0);

        // Get the Sunday of the week for the end date
        chartEndDate.setDate(chartEndDate.getDate() + (7 - chartEndDate.getDay())); // Move to Sunday

        // Create an object with all weeks initialized
        const weeklyData: { [key: string]: MeetingStats } = {};
        const currentDate = new Date(chartStartDate);

        while (currentDate <= chartEndDate) {
            const weekStart = currentDate.toISOString().split('T')[0];
            weeklyData[weekStart] = {
                weekStart,
                total: 0,
                scheduled: 0,
                completedNoRating: 0,
                completedLowRating: 0,
                completedHighRating: 0,
                percentageHighRating: 0
            };
            currentDate.setDate(currentDate.getDate() + 7);
        }

        // Fill in the actual meeting data
        meetings.forEach((meeting: any) => {
            const meetingDate = new Date(meeting.event_start);
            meetingDate.setDate(meetingDate.getDate() - meetingDate.getDay() + 1);
            meetingDate.setHours(0, 0, 0, 0);
            const weekStart = meetingDate.toISOString().split('T')[0];

            if (weeklyData[weekStart]) {
                weeklyData[weekStart].total++;
                if (new Date(meeting.event_start) > new Date()) {
                    weeklyData[weekStart].scheduled++;
                } else {
                    const advisorRating = meeting.ratings?.find((r: any) => r.advisor)?.rating;
                    const companyRating = meeting.ratings?.sort((a: any, b: any) => a.rating - b.rating).find((r: any) => r.user)?.rating;
                    if (!advisorRating || !companyRating) weeklyData[weekStart].completedNoRating++;
                    else if (advisorRating < 3 || companyRating < 3) weeklyData[weekStart].completedLowRating++;
                    else weeklyData[weekStart].completedHighRating++;
                }
            }
        });

        // Calculate percentageHighRating for each week
        Object.values(weeklyData).forEach(week => {
            const completedMeetings = week.completedHighRating + week.completedLowRating + week.completedNoRating;
            week.percentageHighRating = completedMeetings > 0
                ? (week.completedHighRating / completedMeetings) * 100
                : 0;
        });

        return Object.values(weeklyData).sort((a, b) =>
            new Date(a.weekStart).getTime() - new Date(b.weekStart).getTime()
        );
    };

    const processProjectData = (meetings: any[]) => {
        const projectData: {
            [key: string]: {
                total: number,
                scheduled: number,
                completedNoRating: number,
                completedLowRating: number,
                completedHighRating: number,
                percentageHighRating: number
            }
        } = {};

        meetings.forEach((meeting: any) => {
            const projectName = meeting.project?.name || 'Unassigned';

            // Initialize project data if it doesn't exist
            if (!projectData[projectName]) {
                projectData[projectName] = {
                    total: 0,
                    scheduled: 0,
                    completedNoRating: 0,
                    completedLowRating: 0,
                    completedHighRating: 0,
                    percentageHighRating: 0
                };
            }

            // Increment total count
            projectData[projectName].total++;

            // Categorize the meeting
            if (new Date(meeting.event_start) > new Date()) {
                projectData[projectName].scheduled++;
            } else {
                const rating = meeting.ratings?.[0]?.rating;
                if (!rating) {
                    projectData[projectName].completedNoRating++;
                } else if (rating < 3) {
                    projectData[projectName].completedLowRating++;
                } else {
                    projectData[projectName].completedHighRating++;
                }
            }
        });

        // Calculate percentageHighRating for each project
        Object.values(projectData).forEach(project => {
            const completedMeetings = project.completedHighRating + project.completedLowRating + project.completedNoRating;
            project.percentageHighRating = completedMeetings > 0
                ? (project.completedHighRating / completedMeetings) * 100
                : 0;
        });

        return projectData;
    };

    const getYPosition = (rating: number | null): number => {
        // Unrated meetings (null) will be shown above
        if (rating === null) return Math.random() * 30 + 10;

        // Meetings with rating < 3 will be shown below
        if (rating < 3) return -(Math.random() * 30 + 10);

        // Meetings with rating >= 3 will be shown above
        return Math.random() * 30 + 10;
    };

    const getXPosition = (date: string): number => {
        // Convert date string to Date object
        const meetingDate = new Date(date);

        // Get the day of the week (0-6, where 0 is Sunday)
        const dayOfWeek = meetingDate.getDay();

        // Calculate the x position based on the day of the week
        const x = (dayOfWeek * 100) + (Math.random() * 20);

        return x;
    };

    const getCircleClass = (rating: number | null): string => {
        if (rating === null) return 'circle-unrated';
        if (rating < 3) return 'circle-low';
        return 'circle-high';
    };

    const renderMeetings = () => {
        return meetings.map((meeting: any, index: number) => {
            const x = getXPosition(meeting.date);
            const y = getYPosition(meeting.averageRating);

            return (
                <g key={meeting.id}>
                    <circle
                        cx={x}
                        cy={y}
                        r={5}
                        className={getCircleClass(meeting.averageRating)}
                    />
                    // ... rest of the render code ...
                </g>
            );
        });
    };

    const handleTableViewChange = (
        event: React.MouseEvent<HTMLElement>,
        newView: 'weekly' | 'project' | null,
    ) => {
        if (newView !== null) {
            setTableView(newView);
        }
    };

    // Calculate the appropriate min/max values to align zero points
    const calculateAlignedAxes = () => {
        // Define the range for the percentage axis (right)
        const percentageMin = 0;
        const percentageMax = 100;

        // Define the range for the count axis (left)
        // We know we want the min to be -10 and the max to be 30
        const countMin = -10;
        const countMax = 30;

        // Calculate the position of zero in the percentage scale (as a fraction)
        const percentageZeroPosition = Math.abs(percentageMin) / (percentageMax - percentageMin);

        // Calculate the position of zero in the count scale (as a fraction)
        const countZeroPosition = Math.abs(countMin) / (countMax - countMin);

        // If the zero positions don't match, we need to adjust one of the scales
        if (percentageZeroPosition !== countZeroPosition) {
            // We'll keep the percentage scale as is (0 to 100)
            // and adjust the count scale to match the zero position

            // Calculate what the count min should be to align zeros
            // If percentageZeroPosition is 0, then countMin should be 0
            // Otherwise, countMin = -countMax * percentageZeroPosition / (1 - percentageZeroPosition)

            // Since percentageMin is 0, percentageZeroPosition is 0
            // So we don't need to adjust countMin/countMax in this case

            // But for demonstration, here's how you would calculate it:
            // const adjustedCountMin = -countMax * percentageZeroPosition / (1 - percentageZeroPosition);

            // Return the calculated values
            return {
                percentageMin,
                percentageMax,
                countMin,
                countMax
            };
        }

        // If they already match, return the original values
        return {
            percentageMin,
            percentageMax,
            countMin,
            countMax
        };
    };

    const { percentageMin, percentageMax, countMin, countMax } = calculateAlignedAxes();

    return (
        <Box width={'100%'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'space-between' }}>
                {/* New MUI X Charts version */}
                <Box className="display-box white" sx={{ flex: 1 }}>
                    <h5>
                        Valuable Intros
                    </h5>
                    <ResponsiveChartContainer
                        height={300}
                        series={[
                            {
                                data: weeklyStats.map(w => w.completedHighRating),
                                label: 'Valuable',
                                stack: 'a',
                                color: colors.valuable,
                                type: 'bar',
                                valueFormatter: (value, context) => {
                                    if (context.dataIndex === undefined || !weeklyStats[context.dataIndex]) {
                                        return `${value}`;
                                    }
                                    const week = weeklyStats[context.dataIndex];
                                    return `${value}`;
                                }
                            },
                            {
                                data: weeklyStats.map(w => w.completedNoRating),
                                label: 'Not Rated',
                                stack: 'a',
                                color: colors.notRated,
                                type: 'bar',
                                valueFormatter: (value) => `${value}`
                            },
                            {
                                data: weeklyStats.map(w => w.completedLowRating * -1),
                                label: 'Not Valuable',
                                stack: 'a',
                                color: colors.notValuable,
                                type: 'bar',
                                valueFormatter: (value: number | null) => value ? `${Math.abs(value)}` : '0'
                            },
                            {
                                data: weeklyStats.map(w => w.scheduled),
                                label: 'Scheduled',
                                stack: 'a',
                                color: colors.scheduled,
                                type: 'bar',
                                valueFormatter: (value) => `${value}`
                            },
                            {
                                data: weeklyStats.map(w => w.total > 0 ? w.percentageHighRating : null),
                                label: '% Valuable',
                                type: 'line',
                                color: colors.percentageValuable,
                                yAxisKey: 'percentage',
                                valueFormatter: (value: number | null) => value ? `${value.toFixed(1)}%` : '0.0%',
                            },
                        ]}
                        xAxis={[{
                            data: weeklyStats.map(w => {
                                const d = new Date(w.weekStart);
                                return `${d.getMonth() + 1}/${d.getDate()}`;
                            }),
                            scaleType: 'band',
                            valueFormatter: (value, context) => {
                                // Get the index from the label which matches the date format
                                const index = weeklyStats.findIndex(w => {
                                    const d = new Date(w.weekStart);
                                    return `${d.getMonth() + 1}/${d.getDate()}` === value;
                                });
                                if (index === -1) return value;

                                const week = weeklyStats[index];
                                const date = new Date(week.weekStart);
                                return `${date.getMonth() + 1}/${date.getDate()}`;
                            }
                        }]}
                        // TODO: Make the y-axis tick interval dynamic based on the data.
                        // We always want the 0's to line up
                        yAxis={[
                            {
                                id: 'count',
                                scaleType: 'linear',
                                min: -5,
                                max: 25,
                                tickInterval: [ -5, 0, 5, 10, 15, 20, 25 ],
                            },
                            {
                                id: 'percentage',
                                scaleType: 'linear',
                                min: -20,
                                max: 100,
                                position: 'right',
                                valueFormatter: (value: number) => `${value}%`,

                            }
                        ]}
                    >
                        <BarPlot />
                        <LinePlot />
                        <MarkPlot />
                        <ChartsXAxis />
                        <ChartsYAxis axisId="count" />
                        <ChartsYAxis axisId="percentage" position="right" />
                        <ChartsReferenceLine y={0} axisId="count" stroke="#000" />
                        <ChartsReferenceLine y={80} axisId="percentage" label="Target" lineStyle={{ stroke: "#4CAF50", strokeWidth: 1, strokeDasharray: "5 5" }} />

                        <ChartsTooltip
                            trigger="axis"
                            slotProps={{
                                popper: {

                                }
                            }}
                            itemContent={(props) => {
                                // Get the current item index
                                if (!props.itemData || props.itemData.length === 0) return null;

                                // Find the week data based on the x-axis index
                                const dataIndex = props.itemData[0]?.dataIndex;
                                if (dataIndex === undefined || dataIndex < 0 || dataIndex >= weeklyStats.length) return null;

                                const week = weeklyStats[dataIndex];
                                const date = new Date(week.weekStart);
                                const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

                                // Calculate completed count
                                const completedCount = week.completedHighRating + week.completedLowRating + week.completedNoRating;

                                return (
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            Week of {formattedDate}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Total:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{week.total}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Completed:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{completedCount}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Valuable:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {week.completedHighRating} ({completedCount > 0 ? ((week.completedHighRating / completedCount) * 100).toFixed(1) : '0.0'}%)
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Not Valuable:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {week.completedLowRating} ({completedCount > 0 ? ((week.completedLowRating / completedCount) * 100).toFixed(1) : '0.0'}%)
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Not Rated:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {week.completedNoRating} ({completedCount > 0 ? ((week.completedNoRating / completedCount) * 100).toFixed(1) : '0.0'}%)
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                                <Typography variant="body2">Scheduled:</Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{week.scheduled}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            }}
                        />
                    </ResponsiveChartContainer>

                    {/* Legend - moved outside of ChartContainer */}
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', gap: 2, border: '1px solid #eaeaea', borderRadius: 1, p: 1, backgroundColor: '#f9f9f9' }}>
                        {/* Bar series */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.valuable, mr: 1 }} />
                                <Typography variant="caption">Valuable</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.notValuable, mr: 1 }} />
                                <Typography variant="caption">Not Valuable</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.notRated, mr: 1 }} />
                                <Typography variant="caption">Not Rated</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box sx={{ width: 12, height: 12, backgroundColor: colors.scheduled, mr: 1 }} />
                                <Typography variant="caption">Scheduled</Typography>
                            </Box>
                        </Box>
                        {/* Line series */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: 16, height: 2, backgroundColor: colors.percentageValuable, mr: 1 }} />
                                <Typography variant="caption">% Valuable</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {showTable && (
                <>
                    {/* Table Toggle and Title */}
                    <Box sx={{ mt: 4, display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                            Intros Detail
                        </Typography>
                        <ToggleButtonGroup
                            value={tableView}
                            exclusive
                            onChange={handleTableViewChange}
                            aria-label="table view"
                            size="small"
                            sx={{ width: '300px' }}
                        >
                            <ToggleButton value="weekly" aria-label="weekly view">
                                By Week
                            </ToggleButton>
                            <ToggleButton value="project" aria-label="project view">
                                By Project
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    {/* Conditional Table Rendering */}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        {tableView === 'weekly' ? (
                            <Table sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Week</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right">Completed</TableCell>
                                        <TableCell align="right">Valuable</TableCell>
                                        <TableCell align="right">Not Valuable</TableCell>
                                        <TableCell align="right">Not Rated</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...weeklyStats].sort((a, b) =>
                                        new Date(b.weekStart).getTime() - new Date(a.weekStart).getTime()
                                    ).map((week) => {
                                        // Calculate completed count and percentages
                                        const completedCount = week.completedHighRating + week.completedLowRating + week.completedNoRating;
                                        const valuablePercent = completedCount > 0
                                            ? ((week.completedHighRating / completedCount) * 100).toFixed(1)
                                            : '0.0';
                                        const notValuablePercent = completedCount > 0
                                            ? ((week.completedLowRating / completedCount) * 100).toFixed(1)
                                            : '0.0';
                                        const notRatedPercent = completedCount > 0
                                            ? ((week.completedNoRating / completedCount) * 100).toFixed(1)
                                            : '0.0';

                                        return (
                                            <TableRow key={week.weekStart}>
                                                <TableCell component="th" scope="row">
                                                    {new Date(week.weekStart).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                                                </TableCell>
                                                <TableCell align="right">{week.total}</TableCell>
                                                <TableCell align="right">{completedCount}</TableCell>
                                                <TableCell align="right">
                                                    {valuablePercent}% ({week.completedHighRating})
                                                </TableCell>
                                                <TableCell align="right">
                                                    {notValuablePercent}% ({week.completedLowRating})
                                                </TableCell>
                                                <TableCell align="right">
                                                    {notRatedPercent}% ({week.completedNoRating})
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {/* Total row */}
                                    {weeklyStats.length > 0 && (
                                        <TableRow key="total" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                Total
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {weeklyStats.reduce((sum, week) => sum + week.total, 0)}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {weeklyStats.reduce((sum, week) =>
                                                    sum + week.completedHighRating + week.completedLowRating + week.completedNoRating, 0)}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    const totalCompleted = weeklyStats.reduce((sum, week) =>
                                                        sum + week.completedHighRating + week.completedLowRating + week.completedNoRating, 0);
                                                    const totalValuable = weeklyStats.reduce((sum, week) => sum + week.completedHighRating, 0);
                                                    const valuablePercent = totalCompleted > 0
                                                        ? ((totalValuable / totalCompleted) * 100).toFixed(1)
                                                        : '0.0';
                                                    return `${valuablePercent}% (${totalValuable})`;
                                                })()}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    const totalCompleted = weeklyStats.reduce((sum, week) =>
                                                        sum + week.completedHighRating + week.completedLowRating + week.completedNoRating, 0);
                                                    const totalNotValuable = weeklyStats.reduce((sum, week) => sum + week.completedLowRating, 0);
                                                    const notValuablePercent = totalCompleted > 0
                                                        ? ((totalNotValuable / totalCompleted) * 100).toFixed(1)
                                                        : '0.0';
                                                    return `${notValuablePercent}% (${totalNotValuable})`;
                                                })()}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    const totalCompleted = weeklyStats.reduce((sum, week) =>
                                                        sum + week.completedHighRating + week.completedLowRating + week.completedNoRating, 0);
                                                    const totalNotRated = weeklyStats.reduce((sum, week) => sum + week.completedNoRating, 0);
                                                    const notRatedPercent = totalCompleted > 0
                                                        ? ((totalNotRated / totalCompleted) * 100).toFixed(1)
                                                        : '0.0';
                                                    return `${notRatedPercent}% (${totalNotRated})`;
                                                })()}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        ) : (
                            <Table sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Project</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right">Completed</TableCell>
                                        <TableCell align="right">Valuable</TableCell>
                                        <TableCell align="right">Not Valuable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(projectStats).sort((a, b) => a[0].localeCompare(b[0])).map(([project, stats]) => {
                                        // Calculate percentages
                                        const completedCount = stats.total - stats.scheduled;
                                        const valuablePercent = completedCount > 0
                                            ? ((stats.completedHighRating / completedCount) * 100).toFixed(1)
                                            : '0.0';
                                        const notValuablePercent = completedCount > 0
                                            ? ((stats.completedLowRating / completedCount) * 100).toFixed(1)
                                            : '0.0';

                                        return (
                                            <TableRow key={project}>
                                                <TableCell component="th" scope="row">
                                                    {project}
                                                </TableCell>
                                                <TableCell align="right">{stats.total}</TableCell>
                                                <TableCell align="right">{stats.total - stats.scheduled}</TableCell>
                                                <TableCell align="right">
                                                    {valuablePercent}% ({stats.completedHighRating})
                                                </TableCell>
                                                <TableCell align="right">
                                                    {notValuablePercent}% ({stats.completedLowRating})
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {/* Total row */}
                                    {Object.keys(projectStats).length > 0 && (
                                        <TableRow key="total" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                Total
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {Object.values(projectStats).reduce((sum, stats) => sum + stats.total, 0)}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {Object.values(projectStats).reduce((sum, stats) => sum + (stats.total - stats.scheduled), 0)}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    const totalCompleted = Object.values(projectStats).reduce(
                                                        (sum, stats) => sum + (stats.total - stats.scheduled), 0
                                                    );
                                                    const totalValuable = Object.values(projectStats).reduce(
                                                        (sum, stats) => sum + stats.completedHighRating, 0
                                                    );
                                                    const valuablePercent = totalCompleted > 0
                                                        ? ((totalValuable / totalCompleted) * 100).toFixed(1)
                                                        : '0.0';
                                                    return `${valuablePercent}% (${totalValuable})`;
                                                })()}
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    const totalCompleted = Object.values(projectStats).reduce(
                                                        (sum, stats) => sum + (stats.total - stats.scheduled), 0
                                                    );
                                                    const totalNotValuable = Object.values(projectStats).reduce(
                                                        (sum, stats) => sum + stats.completedLowRating, 0
                                                    );
                                                    const notValuablePercent = totalCompleted > 0
                                                        ? ((totalNotValuable / totalCompleted) * 100).toFixed(1)
                                                        : '0.0';
                                                    return `${notValuablePercent}% (${totalNotValuable})`;
                                                })()}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </Paper>
                </>
            )}
        </Box>
    );
}; 