import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { AdminTouchpoint } from "./AdminTouchpoint";

const typeLabel = {
    "default": "Default",
    "manual": "Manual",
    "auto": "Automated"
};

const statusLabel = {
    "draft": "Draft",
    "scheduled": "Scheduled",
    "active": "Active",
    "completed": "Completed"
};

export const AdminTouchpointsList = ({ project, touchpoints, sectionName, reloadProjectData }: any) => {
    if (!touchpoints?.length) {
        return null;
    }

    return (
        <Box mb={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>{sectionName}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>id</TableCell>
                        <TableCell className="center">Type</TableCell>
                        <TableCell className="center">Status</TableCell>
                        <TableCell className="center">Scheduled For</TableCell>
                        <TableCell className="center">Started</TableCell>
                        <TableCell className="center">Completed</TableCell>
                        <TableCell className="center"># Viewpoints</TableCell>
                        <TableCell className="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {touchpoints.map((touchpoint: any) => (
                        <AdminTouchpoint 
                            key={touchpoint.uuid}
                            project={project}
                            touchpoint={touchpoint}
                            reloadProjectData={reloadProjectData}
                        />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}; 