import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getViewpoints, loadStats } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Typography } from "@mui/material";
import { AdminViewpointsList } from "./components/ViewpointsList";

const interestFields = [
    {
        key: "interest_demo",
        text: "Demo",
        count: 0,
        countText: "want a"
    },
    {
        key: "interest_beta",
        text: "Beta",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_investor",
        text: "Co-Invest",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_advisor",
        text: "Advise",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_employment",
        text: "Job",
        count: 0,
        countText: "want a"
    },
    {
        key: "interest_followup",
        text: "Follow Up",
        count: 0,
        countText: 'open to'
    },
];


export const AdminViewpoints = () => {

    const { project, reloadProjectData } = useOutletContext<any>();
    const { getAccessTokenSilently } = useAuth0();

    const [viewpoints, setViewpoints] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [ reloadError, setReloadError ] = useState<string | null>(null);

    const fetchViewpoints = async () => {
        setLoading(true);

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getViewpoints(project.id, accessToken);

        if (data) {
            setViewpoints(data.viewpoints);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (project) {
            fetchViewpoints();
        }
    }, []);


    const handleReloadStats = async (event: any) => {
        event.preventDefault();
        setReloadError(null);

        const origText = event.target.innerText;

        event.target.innerText = "Reloading...";
        const accessToken = await getAccessTokenSilently();
        const { data, error} = await loadStats(project.id, accessToken);
        console.log(data, error);
        if (data) {
            const { data, error } = await getViewpoints(project.id, accessToken);

            
            if (data) {
                setViewpoints(data.viewpoints);
                setLoading(false);
                reloadProjectData();
            } else if (error) {
                console.error(error);
            }
        } else if (error) {
            console.error(error);
            setReloadError(error.message);
        }
        event.target.innerText = origText;
    }


    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Viewpoints</h5>
                <Stack direction="row" spacing={2} alignItems="center">
                    {reloadError && <Typography variant="body2" color="error">{reloadError}</Typography>}
                    <div className="button btn btn-secondary" onClick={handleReloadStats} >Reload Stats</div>
                </Stack>
            </div>
            <AdminViewpointsList
                project={project}
                sectionName="Review Queue" 
                viewpoints={viewpoints.filter((viewpoint: any) => !viewpoint.is_reviewed)}
                reloadProjectData={fetchViewpoints}
            />

            <AdminViewpointsList
                project={project}
                sectionName="Approved"
                viewpoints={viewpoints.filter((viewpoint: any) => { return (viewpoint.is_reviewed && viewpoint.is_approved)} )}
                reloadProjectData={fetchViewpoints}
            />

            <AdminViewpointsList
                project={project}
                sectionName="Rejected"
                viewpoints={viewpoints.filter((viewpoint: any) => viewpoint.is_reviewed && !viewpoint.is_approved)}
                reloadProjectData={fetchViewpoints}
            />

        </div>
    );
}