import { CompanyConfig } from "./config/CompanyConfig"
import { Box, Button, Stepper, Typography } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { ScoringList } from "../components/scoring/ScoringList";
import { CompanyTitle } from "../components/CompanyTitle";
import { ScoringGraph } from "../components/scoring/ScoringGraph";
import { useState, useEffect } from "react";
import { Step, StepLabel, StepContent } from "@mui/material";
import { OutreachBlocklist } from "./outreach/OutreachBlocklist";
import { CalendarConfig } from "./config/CalendarConfig";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { SetupInvite } from "../components/config/SetupInvite";
import { useUserContext } from "../../../features/users/context";
import { styled } from "@mui/material/styles";

const SetupInviteBox = styled(Box)(({ theme }) => ({
    minWidth: '300px',
    maxWidth: '300px',
    backgroundColor: '#ffffff',
    zIndex: 1,
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    alignSelf: 'flex-start',
    top: '20px',
    '@media (max-width: 1200px)': {
        minWidth: '250px',
        maxWidth: '250px',
    }
}));

const SetupContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    width: '100%',
    '@media (max-width: 992px)': {
        flexDirection: 'column-reverse',
    }
}));

const SetupContent = styled(Box)(({ theme }) => ({
    flex: 1,
    minWidth: 0, // This prevents flex items from overflowing
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .MuiButton-root.next-button": {
        marginTop: '20px',
        alignSelf: "flex-end",
        paddingLeft: "60px",
        paddingRight: "60px"
    }
}));

const SetupStepper = styled(Stepper)(({ theme }) => ({
    marginBottom: '40px',
    '& .MuiStepLabel-label': {
        fontWeight: '700 !important',
        fontSize: '1.2rem',
    },
}));

export const CompanySetup = () => {

    const { dealContext, setDealContext } = useDealContext();
    const { userContext } = useUserContext();
    const user = userContext.dbUser;
    const project = dealContext.project;
    const viewerRole = dealContext.role;

    const [started, setStarted] = useState(false);
    const [step, setStep] = useState(-1);
    const [submitError, setSubmitError] = useState("");
    const [starting, setStarting] = useState(false);
    const [ canProceed, setCanProceed ] = useState(true);
    const [ errorMessage, setErrorMessage ] = useState("");
    const projectClient = ClientProvider.provideProjectClient();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();


    useEffect(() => {
        if (starting && project.status === "evaluation") {
            setStarting(false);
            navigate(`/c`);
        }
    }, [starting, dealContext.project.status]);

    const handleStart = () => {
        setStarted(true);
        setStep(0);
    }

    const handleNext = () => {
        if (!canProceed) {
            setErrorMessage("Please save your changes before proceeding.");
            return;
        }
        setStep(step + 1);
        setErrorMessage("");  // Clear any existing error message
    }

    useEffect(() => {
        if (step === 2) {  // Calendar setup step
            setCanProceed(true);  // Start with true until changes are made
        } else {
            setCanProceed(true);  // Other steps don't need validation
        }
    }, [step]);

    useEffect(() => {
        if (canProceed) {
            setErrorMessage("");
        }
    }, [canProceed]);

    const handleBeginOutreach = async () => {
        setStarting(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.start(project.id, accessToken);
        //loadDealContext(project.tag);
        if (data && data.project.status === "evaluation") {
            const projectResponse = await projectClient.getProjectData(project.tag, accessToken);
            if (projectResponse.data) {
                setDealContext({
                    ...dealContext,
                    project: projectResponse.data.project,
                    fetched: true
                });
            }
        } else if (error) {
            setSubmitError(error.message);
            setStarting(false);
        }
    }

    if (!started) {
        return (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 2 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "flex-start",
                    alignItems: 'left',
                    gap: 0
                }}>
                    <CompanyTitle viewerRole={viewerRole} project={project} section="Welcome" subheading={
                        <>
                            <p>Setup your advisor network to start generating new customer insights, intros and opportunities. Hit the plus button to invite any team members you might need to help complete these three steps:</p>
                        </>
                    } />

                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box className="display-box" sx={{ width: "70%" }}>
                            <SetupStepper orientation="vertical">

                                <Step expanded active>
                                    <StepLabel>Company Info</StepLabel>
                                    <StepContent>
                                        <p>Describe your ICP or target audience, upload a demo of your product, and provide any questions you'd like answered by advisors.</p>
                                    </StepContent>
                                </Step>
                                <Step expanded active>
                                    <StepLabel>Outreach</StepLabel>
                                    <StepContent>
                                        <p>Optionally upload a list of companies that you're already engaged with so they aren't recruited as part of the outreach.</p>
                                    </StepContent>
                                </Step>
                                <Step expanded active>
                                    <StepLabel>Scheduling</StepLabel>
                                    <StepContent>
                                        <p>Provide your calendar details so advisors can easily schedule a demo or follow up call once their ready to trial your product.</p>
                                    </StepContent>
                                </Step>
                            </SetupStepper>
                        </Box>
                        <Button variant="contained" onClick={handleStart}>
                            Start Setup
                        </Button>
                    </Box>

                </Box>
                <SetupInviteBox className="display-box white">
                    <h6>Need help completing this?</h6>
                    <p>Invite a {project.name} founder or team member to fill this out.</p>
                    <SetupInvite project={project} user={user} />
                </SetupInviteBox>
            </Box>
        );
    }



    return (
        <Box>
            <Stepper activeStep={step} orientation="horizontal" sx={{ marginBottom: '40px', "& .MuiStep-root": { cursor: "pointer" } }}>
                <Step onClick={() => step > 0 && setStep(0)}>
                    <StepLabel>Company Info</StepLabel>
                </Step>
                <Step onClick={() => step > 1 && setStep(1)}>
                    <StepLabel>Outreach</StepLabel>
                </Step>
                <Step onClick={() => step > 2 && setStep(2)}>
                    <StepLabel>Scheduling</StepLabel>
                </Step>
            </Stepper>
            {step > 2 && <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                py: 4
            }}>
                <h1>Setup Complete!</h1>
                <p>Ready to start building your light-touch advisor network?</p>
                <Button variant="contained" onClick={handleBeginOutreach}>
                    Let's Go!
                </Button>
            </Box>}
            {step < 3 && <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: "20px" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "flex-start",
                    alignItems: 'left',
                    gap: 0,
                    flex: 1
                }}>


                    <>
                        {step === 0 && (
                            <SetupContainer>
                                <SetupContent>
                                    <CompanyConfig setup={true} />
                                    <Button variant="contained" className="next-button" onClick={handleNext}>Next</Button>
                                </SetupContent>
                            </SetupContainer>
                        )}
                        {step === 1 && (
                            <SetupContainer>
                                <SetupContent>
                                    <Box sx={{ width: "100%" }}>
                                        <OutreachBlocklist setup={true} />
                                    </Box>
                                    <Button variant="contained" className="next-button" onClick={handleNext}>Next</Button>
                                </SetupContent>
                            </SetupContainer>
                        )}
                        {step === 2 && (
                            <SetupContainer>
                                <SetupContent>
                                    <Box sx={{ width: "100%" }}>
                                        <CalendarConfig setup={true} setCanProceed={setCanProceed} canProceed={canProceed}/>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                                        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                                        <Button variant="contained" className="next-button" sx={{ marginTop: "0 !important" }} onClick={handleNext}>Next</Button>
                                    </Box>
                                </SetupContent>
                            </SetupContainer>
                        )}
                    </>
                </Box>
                <SetupInviteBox className="display-box white">
                    <h6>Need help completing this?</h6>
                    <p>Invite a {project.name} team member or {project.organization.name} team member to fill this out.</p>
                    <SetupInvite project={project} user={user} />
                </SetupInviteBox>
            </Box>}
        </Box>
    );
}