import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ModalBox } from "../../../../components/ModalBox";
import { styled } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../../modules/ClientProvider";
import useAnalytics from "../../../../hooks/useAnalytics";
import { AdvisorPreview } from "../../../advisor";
import { IAdvisor, IProject, IUser } from "../../../../types";

const PreviewBox = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "1rem 0",
    backgroundColor: "#ffffff",
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    overflowY: "auto",
    maxHeight: "300px",
    fontFamily: "Arial, sans-serif",
    "& .logo-bar": {
        backgroundColor: "#f8f9fa",
        borderTop: "1px solid #c9c9c9",
        borderBottom: "1px solid #c9c9c9",
        padding: "15px 0",
        marginBottom: "30px",
        "& .logo-wrapper": {
            maxWidth: "600px",
            margin: "0 auto",
        },
        "& img": {
            maxHeight: "40px",
        }
    },
    "& .invitation-message": {
        maxWidth: "600px",
        margin: "0 auto",
        "& h2": {
            fontSize: "24px",
            lineHeight: "32px",
            marginBottom: "1rem"
        },
        "& blockquote": {
            fontStyle: "normal",
            borderLeft: "4px solid #c9c9c9",
            fontFamily: "Arial, sans-serif",
            fontWeight: 500,
        },
        "& .mock-buttom": {
            backgroundColor: "#1a5dbd",
            color: "#ffffff",
            padding: "10px 40px",
            borderRadius: "5px",
            fontWeight: "bold",
            marginTop: "20px",
            display: "inline-block"
        }
    }
}));



const EditView = ({ angelForm, setAngelForm }: any) => {
    return (
        <>
            <p>Provide a personal note inviting {angelForm.advisor.user?.profile?.name.split(" ")[0]} to co-invest in {angelForm.project.name}</p>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <AdvisorPreview advisor={angelForm.advisor} />
                <TextField
                    id="outlined-multiline-static"
                    label="Personal Note"
                    multiline
                    rows={10}
                    fullWidth
                    value={angelForm.message}
                    onChange={(e) => setAngelForm({ ...angelForm, message: e.target.value })}
                />
            </Box>
        </>

    )
}

const PreviewView = ({ angelForm, setAngelForm }: any) => {
    return (
        <>
            <p>Preview your personal note to {angelForm.advisor.user?.profile?.name.split(" ")[0]} before sending.</p>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <AdvisorPreview advisor={angelForm.advisor} />
                <Box sx={{ width: "100%"}}>
                <p><strong>Subject:</strong> {angelForm.subject}</p>
                <PreviewBox>
                    <Box className="logo-bar">
                        <Box className="logo-wrapper">
                            { angelForm.project.organization.logo_url && angelForm.project.organization.logo_url !== "" ?
                                <img src={angelForm.project.organization.logo_url} alt={angelForm.project.organization.name} /> :
                                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }} />
                            }
                        </Box>
                    </Box>
                    <Box className="invitation-message">
                        <h2>{angelForm.headline}</h2>

                        {angelForm.message.split("\n").map((line: string, index: number) => (
                            <Typography variant="body1" key={index} sx={{ marginBottom: "1rem"}}>{line}</Typography>
                        ))}

                        <Box className="mock-buttom">View Docs</Box>

                    </Box>

                </PreviewBox>
                </Box>
            </Box>

        </>

    )
}

const NotReadyView = ({ angelForm}: any ) => {
    return (
        <>
            <p>Sorry, you can only send investment invitations to Executives when there is an open syndicate for them to join.</p>
        </>
    )
}

export const InvestorInvitation = ({ project, advisor, user, updateAdvisor, open, setOpen }: {project: IProject, advisor: IAdvisor, user: IUser, updateAdvisor: (advisor: IAdvisor) => void, open: boolean, setOpen: (open: boolean) => void}) => {

    const { getAccessTokenSilently } = useAuth0();
    const { trackEvent } = useAnalytics();
    const projectClient = ClientProvider.provideProjectClient();

    const defaultMessage = `${advisor.user?.profile?.name},
    
Thanks for helping ${project.organization.name} evaluate my company ${project.name}. The team shared parts of your feedback with me. It's been helpful and I'm happy to report we are moving forward with investment.

You indicated an interest in potentially co-investing in the round. We think you could be a great collaborator and would like to extend an invitation for you to join the round.

The docs and details are available at the link below. If you'd like to join the round, please reserve a spot so we can carve out room for you.

Thanks!

${user.name}`;

    const [angelForm, setAngelForm] = useState<any>({
        project: project,
        advisor: advisor,
        subject: `Personal invite from ${user?.name?.split(" ")[0]} at ${project.name}`,
        headline: `Message from ${project.organization.name} and ${user?.name?.split(" ")[0]} at ${project.name}`,
        message: defaultMessage,
        stage: ["approved", "open"].includes(project.spv_stage ?? "") ? "EDIT" : "NOT_READY",
        errorMessage: ""
    });

    const handleInvitationPreview = () => {
        setAngelForm({ ...angelForm, stage: "PREVIEW" });
    }

    const handleInvitationSubmit = async () => {
        setAngelForm({ ...angelForm, errorMessage: "", stage: "SENDING" });
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.sendAdvisorAngelMessage(project.id, advisor.uuid, angelForm, accessToken);

        if (error) {
            setAngelForm({ ...angelForm, errorMessage: error.message });
            return;
        }

        setAngelForm({ ...angelForm, stage: "COMPLETE" });
        advisor.last_angel_message_at = new Date().toISOString();
        updateAdvisor(advisor);
    }

    const handleCancel = () => {
        setAngelForm({ ...angelForm, stage: "EDIT" });
        setOpen(false);
    }

    if (!advisor) return <></>;

    return (
        <Modal className="invitation-container" open={open} onClose={() => setOpen(false)}>
            <ModalBox sx={{ width: "900px" }}>
                <h5>Invite {advisor.user?.profile?.name ?? advisor.user?.email_address} to Co-invest</h5>

                { angelForm.stage === "NOT_READY" && <NotReadyView angelForm={angelForm} /> }
                { angelForm.stage === "EDIT" && <EditView angelForm={angelForm} setAngelForm={setAngelForm} /> }
                { (angelForm.stage === "PREVIEW" || angelForm.stage === "SENDING") && <PreviewView angelForm={angelForm} setAngelForm={setAngelForm} /> }
                { angelForm.stage === "COMPLETE" && <Typography>Your message has been sent to {advisor.user?.profile?.name ?? advisor.user?.email_address}</Typography> }

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    "& button": {
                        width: "200px"
                    }
                }}>
                    {angelForm.errorMessage !== "" && <Box sx={{ color: "red" }}>{angelForm.errorMessage}</Box>}

                    {angelForm.stage === "COMPLETE" &&
                        <>
                            <Typography sx={{ color: "green" }} className="success">Success! Message sent.</Typography>
                            <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Close</Button>
                        </>
                    }

                    {angelForm.stage === "EDIT" && <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Cancel</Button>}
                    {angelForm.stage === "EDIT" && <Button variant="contained" size="large" color="primary" className="btn active" onClick={handleInvitationPreview}>Preview</Button>}

                    {angelForm.stage === "PREVIEW" && <Button variant="outlined" size="large" color="primary" onClick={() => setAngelForm({ ...angelForm, stage: "EDIT" })}>Back</Button>}
                    {angelForm.stage === "PREVIEW" && <Button variant="contained" size="large" color="primary" onClick={handleInvitationSubmit}>Send</Button>}

                    {angelForm.stage === "SENDING" && <Typography>Sending...</Typography>}
                </Box>
            </ModalBox>

        </Modal>
    )
}