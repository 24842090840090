import { Box, Stack, Typography } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { OverviewBox } from "../../company/styles/AdvisorStyles";
import { Link } from "react-router-dom";
import { useAdvisor } from "../context/AdvisorContext";
import Loading from "../../../components/Loading";
import { RecentAdvisors } from "../components/RecentAdvisors";
export const AdvisorHome = () => {
    const { dealContext } = useDealContext();
    const { advisor, loading, error, fetchAdvisor, updateAdvisor } = useAdvisor();
    const project = dealContext.project;
    const viewerRole = dealContext.role;

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <AdvisorTitle project={project} section={`Help make ${project.name} more successful`} subheading="Give insight and get access - to equity opportunities, industry insight, and other top executives." />
            <Stack spacing={2} direction="row" sx={{
                marginBottom: "20px",
                paddingBottom: "30px",
                position: "relative"
            }}>
                <OverviewBox className={`display-box white`} onClick={() => { }}>
                    <Typography variant="h5">
                        <Link to="feedback">{advisor?.viewpoints?.length}</Link>
                    </Typography>
                    <Typography>Feedback Session{advisor?.viewpoints?.length === 1 ? "" : "s"}</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white`} onClick={() => { }}>
                    <Typography variant="h5">
                        <Link to="meet">{advisor?.meetings?.filter((meeting: any) => meeting.status !== "cancelled")?.length}</Link>
                    </Typography>
                    <Typography>Meeting{advisor?.meetings?.filter((meeting: any) => meeting.status !== "cancelled")?.length === 1 ? "" : "s"}</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white`} onClick={() => { }}>
                    <Typography variant="h5">
                        <Link to="refer">0</Link>
                    </Typography>
                    <Typography>Referrals</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white`} onClick={() => { }}>
                    <Typography variant="h5">
                        <Link to="invest">${advisor?.funded_amount ?? advisor?.reserved_amount ?? 0}</Link>
                    </Typography>
                    <Typography>Reserved for Investment</Typography>
                </OverviewBox>
            </Stack>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "40px" }}>
                <Box sx={{ flex: "1 1" }}>
                    <Box sx={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "20px" }}>
                        <h5>About {project.name}</h5>
                    </Box>
                    <p>{project.public_description}</p>
                    <Stack direction="column" spacing={1} sx={{ marginBottom: "40px" }}>
                        {project.company?.website && <Box><strong>Website:</strong> <a href={project.company?.website} target="_blank" rel="noopener noreferrer">{project.company?.website}</a></Box>}
                        {project.founder_name && <Box><strong>Founder:</strong> <a href={project.founder_link} target="_blank" rel="noopener noreferrer">{project.founder_name}</a></Box>}
                    </Stack>
                    <Box sx={{ marginBottom: "20px", borderBottom: "1px solid #ccc", paddingBottom: "20px" }}>
                        <h5>About {project.organization?.name}</h5>
                    </Box>
                    <p>{project.organization?.description}</p>
                    <Stack direction="column" spacing={1} sx={{ marginBottom: "40px" }}>
                        {project.company?.website && <Box><strong>Website:</strong> <a href={project.organization?.website} target="_blank" rel="noopener noreferrer">{project.company?.website}</a></Box>}
                    </Stack>
                </Box>
                <Box sx={{ flex: "0 1 250px" }}>
                    <RecentAdvisors advisor={advisor} project={project} />
                </Box>

            </Box>
        </Box>
    );
}