import { Typography } from "@mui/material"

import { Box, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Type, Volume2, Film } from "lucide-react";
import { useState, useEffect, useReducer } from "react";
import { useUserContext } from "../../../users/context";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { FormLabel, Checkbox } from "@mui/material";
import { AdvisorTable, DemandOptions } from "../../styles/AdvisorStyles";
import dayjs from "dayjs";
import { AdvisorCompany, AdvisorProfileImage } from "../../../advisor";


const formatOptions = [
    {
        value: "has_video", label: "", icon: <Film size={20} />
    },
    {
        value: "has_audio", label: "", icon: <Volume2 size={20} />
    },
    {
        value: "has_text", label: "", icon: <Type size={20} />
    }
];

const filterLabelStyle = {
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    backgroundColor: "#ffffff",
    "&:hover": {
        cursor: "pointer"
    }
};

const filterLabelCheckedStyle = {
    border: "1px solid #1A5DBD",
    backgroundColor: "#F0F6FF",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    color: "#000000",
    "&:hover": {
        cursor: "pointer"
    }
};

type TViewpointsState = {
    viewpoints: any[];
    filteredViewpoints: any[];
};

const viewpointsReducer = (state: TViewpointsState, action: any) => {
    switch (action.type) {
        case 'FILTER':
            const { searchText, filterCriteria, sortCriteria } = action.payload;
            let filteredViewpoints = state.viewpoints.filter((viewpoint: any) => {
                const matchesSearchText = viewpoint.name?.toLowerCase().includes(searchText.toLowerCase());
                const matchesFormat = filterCriteria.format.length === 0 || filterCriteria.format.some((format: string) => {
                    if (format === "has_text") {
                        return !(viewpoint.has_video || viewpoint.has_audio);
                    } else if (format === "has_audio") {
                        return viewpoint.has_audio && !viewpoint.has_video;
                    } else {
                        return viewpoint[format]
                    }
                });
                const matchesDemand = filterCriteria.demand.length === 0 || filterCriteria.demand.includes(viewpoint.demand);
                return matchesSearchText && matchesFormat && matchesDemand;
            });

            // Add sorting
            filteredViewpoints.sort((a: any, b: any) => {
                let aVal, bVal;
                switch (sortCriteria.field) {
                    case 'name':
                        aVal = a.name?.toLowerCase() || '';
                        bVal = b.name?.toLowerCase() || '';
                        break;
                    case 'company':
                        aVal = a.company?.name?.toLowerCase() || '';
                        bVal = b.company?.name?.toLowerCase() || '';
                        break;
                    case 'created_at':
                        aVal = new Date(a.created_at).getTime();
                        bVal = new Date(b.created_at).getTime();
                        break;
                    case 'demand':
                        aVal = DemandOptions.findIndex((option: any) => option.value === a.demand);
                        bVal = DemandOptions.findIndex((option: any) => option.value === b.demand);
                        break;
                    default:
                        aVal = a[sortCriteria.field];
                        bVal = b[sortCriteria.field];
                }
                return sortCriteria.direction === 'asc' ? 
                    (aVal > bVal ? 1 : -1) : 
                    (aVal < bVal ? 1 : -1);
            });

            return {
                ...state,
                filteredViewpoints
            };
        default:
            return state;
    }
};

export const NurtureResponses = ({ touchpoint }: any) => {

    const [state, dispatch] = useReducer(viewpointsReducer, { 
        viewpoints: touchpoint.viewpoints || [], 
        filteredViewpoints: touchpoint.viewpoints || [] 
    });
    const [filterCriteria, setFilterCriteria] = useState<{ format: string[], demand: string[] }>({
        format: [],
        demand: []
    });
    const [searchText, setSearchText] = useState('');
    const [sortCriteria, setSortCriteria] = useState<{ field: string, direction: 'asc' | 'desc' }>({ 
        field: "name", 
        direction: "asc" 
    });
    const { userContext } = useUserContext();
    const user = userContext.dbUser;

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        if (checked) {
            setFilterCriteria(prev => ({ 
                ...prev, 
                [name]: [...prev[name as keyof typeof prev], value] 
            }));
        } else {
            setFilterCriteria(prev => ({ 
                ...prev, 
                [name]: prev[name as keyof typeof prev].filter(v => v !== value) 
            }));
        }
    };

    useEffect(() => {
        dispatch({
            type: 'FILTER',
            payload: {
                searchText,
                filterCriteria,
                sortCriteria
            }
        });
    }, [filterCriteria, sortCriteria, searchText]);

    const handleSort = (column: string) => {
        const isAsc = sortCriteria.field === column && sortCriteria.direction === 'asc';
        setSortCriteria({
            field: column,
            direction: isAsc ? 'desc' : 'asc'
        });
    };

    return (
        <>
            <Box className="" sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "start",
                padding: "10px 20px 5px",
                border: "1px solid #c9c9c9",
                borderRadius: "10px",
                position: "relative"
            }}>
                <Typography 
                component="legend"
                sx={{
                    position: "absolute",
                    top: "-0.8em",
                    left: "10px",
                    padding: "0 5px",
                    backgroundColor: "#fff",
                    fontSize: "0.8rem",
                    color: "#666",
                    width: "unset"
                }}
            >
                FILTERS
            </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>FORMAT</Typography>
                        {formatOptions.map((option) => (
                            <FormLabel 
                                key={option.value} 
                                sx={filterCriteria.format.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}
                            >
                                <Checkbox
                                    name="format"
                                    value={option.value}
                                    onChange={handleCriteriaChange}
                                    checked={filterCriteria.format.includes(option.value)}
                                    sx={{ display: "none" }}
                                />
                                {option.icon}
                            </FormLabel>
                        ))}
                    </fieldset>
                    <fieldset>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>PURCHASING INTEREST</Typography>
                        {DemandOptions.map((option: any) => (
                            <FormLabel 
                                key={option.value} 
                                sx={filterCriteria.demand.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}
                            >
                                <Checkbox
                                    name="demand"
                                    value={option.value}
                                    onChange={handleCriteriaChange}
                                    checked={filterCriteria.demand.includes(option.value)}
                                    sx={{ display: "none" }}
                                />
                                {option.label}
                            </FormLabel>
                        ))}
                    </fieldset>
                </Box>
            </Box>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="icon"><Film size={20} /></TableCell>
                        <TableCell className="profile">
                            <TableSortLabel
                                active={sortCriteria.field === 'name'}
                                direction={sortCriteria.field === 'name' ? sortCriteria.direction : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Executive
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="company">
                            <TableSortLabel
                                active={sortCriteria.field === 'company'}
                                direction={sortCriteria.field === 'company' ? sortCriteria.direction : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortCriteria.field === 'created_at'}
                                direction={sortCriteria.field === 'created_at' ? sortCriteria.direction : 'asc'}
                                onClick={() => handleSort('created_at')}
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="center">
                            <TableSortLabel
                                active={sortCriteria.field === 'demand'}
                                direction={sortCriteria.field === 'demand' ? sortCriteria.direction : 'asc'}
                                onClick={() => handleSort('demand')}
                            >
                                Purchasing Interest
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="quote">Summary Quote</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.filteredViewpoints?.map((viewpoint: any) => (
                        <CompanyAdvisorLink uuid={viewpoint.advisor?.uuid}>
                            <TableRow key={viewpoint?.uuid} sx={{ cursor: "pointer" }}>
                                <TableCell className="icon">
                                    <Typography sx={{ fontSize: "1.2rem", color: "#c9c9c9" }}>
                                        {viewpoint.has_video ?
                                            <Film size={20} /> :
                                            viewpoint.has_audio ? <Volume2 size={20} />
                                                : <Type size={20} />
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell className="profile">
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "1rem", position: "relative" }}>
                                        <AdvisorProfileImage advisor={viewpoint.advisor} size="small" />
                                        <Box>
                                            <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.advisor?.user?.profile?.name}</Typography>
                                            <Typography sx={{
                                                fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "2",
                                                WebkitBoxOrient: "vertical"
                                            }}>{viewpoint.advisor?.user?.profile?.title}</Typography>
                                        </Box>
                                        {user?.prior_visit && viewpoint.published_at > user.prior_visit &&
                                            <Box sx={{ position: "absolute", top: 0, left: 0, backgroundColor: "#F0DE42", color: "black", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                                                New
                                            </Box>
                                        }
                                    </Box>
                                </TableCell>
                                <TableCell className="company"><AdvisorCompany advisor={viewpoint.advisor} /></TableCell>
                                <TableCell className="center">{dayjs(viewpoint.created_at).format('MMM D, YYYY')}</TableCell>
                                <TableCell className="center">{DemandOptions.find((option: any) => option.value === viewpoint.advisor?.demand)?.label}</TableCell>
                                <TableCell className="quote">
                                    <Typography sx={{
                                        fontStyle: "italic",
                                        fontSize: "0.9rem",
                                        fontWeight: "300"
                                    }}>
                                        "{viewpoint.highlight_quote}"
                                    </Typography>
                                </TableCell>
                            </TableRow >
                        </CompanyAdvisorLink>
                    ))}
                </TableBody>
            </AdvisorTable>
        </>
    )
}