import { Box, styled, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useGetProjectMembers } from "../../../../hooks/useGetProjectMembers";
import { useGetProjectInvitations } from "../../../../hooks/useGetProjectInvitations";
import Loading from "../../../../components/Loading";
import { InvitationControl } from "../sharing/InvitationControl";
import { MemberTable } from "./MemberTable";

const DealUsersBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
});


export const UsersList = ({ project, viewerRole }: any) => {

    const [inviteActive, setInviteActive] = useState(false);

    const membersData = useGetProjectMembers(project.id ?? "");
    const invitationsData = useGetProjectInvitations(project.id ?? "");

    const members = membersData?.members?.filter((member: any) => !(member.role === "guest"));
    const invitations = invitationsData?.invitations?.filter((invitation: any) => !invitation.is_redeemed && !(invitation.invitee_role === "guest"));

    const handleOpenInvite = () => {
        setInviteActive(true);
    }


    return (
        <DealUsersBox>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px", gap: "20px" }}>
                <Box>
                    <Typography variant="body1">Team Members can interact with advisors. Admins can manage Team Members.</Typography>
                </Box>
                <Box>
                    <Button 
                        variant="contained" 
                        size="small" 
                        color="primary" 
                        sx={{ whiteSpace: "nowrap", padding: "10px 20px" }}
                        onClick={() => setInviteActive(true)}
                    >
                        <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Team Members
                    </Button>
                </Box>
            </Box>

            {(!membersData.fetched || !invitationsData.fetched) &&
                <Box sx={{ paddingTop: "80px"}}>
                    <Loading />
                </Box>
            }

            {membersData.fetched && invitationsData.fetched &&
                <MemberTable membersType="members" viewerRole={viewerRole} project={project} members={members.filter((member: any) => member.user.role !== "admin")} invitees={invitations} reloadMembers={membersData.reloadProjectMembers} reloadInvitations={invitationsData.reloadProjectInvitations} />
            }
            <InvitationControl project={project} role="collaborator" active={inviteActive} setActive={setInviteActive} reloadInvitations={invitationsData.reloadProjectInvitations} />
        </DealUsersBox>
    );
}
