import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const UserNotFound = () => {
  const { logout } = useAuth0();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    // Set up countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // When countdown reaches 0, log out the user
    if (countdown === 0) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }

    // Clean up timer on unmount
    return () => clearInterval(timer);
  }, [countdown, logout]);

  return (
    <div className="text-center hero my-5">
      <div className="display-box white">
        <h3 className="mb-3">Account not found</h3>

        <p className="">
          There is no account associated with this email address.<br />.
        </p>
        
        <div className="mt-4 d-flex flex-column align-items-center">
          <div className="progress mb-2" style={{ width: '200px', height: '8px' }}>
            <div 
              className="progress-bar bg-warning" 
              role="progressbar" 
              style={{ width: `${(countdown / 5) * 100}%` }}
              aria-valuenow={countdown} 
              aria-valuemin={0} 
              aria-valuemax={5}
            ></div>
          </div>
          <p className="text-muted">
            <small>Redirecting in {countdown} seconds...</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserNotFound;
