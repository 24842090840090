import { Typography, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircle, Circle, Timer, Copy, Check } from "lucide-react";
import Loading from "../../../components/Loading";
import ClientProvider from "../../../modules/ClientProvider";
import { useOutletContext } from "react-router-dom";
import { getOrganizationDomains, provisionOrganizationDomain } from "../services/gutcheck-admin-api.service";

const getSuggestedDomainNames = (website: string | null): { domainName: string, emailDomainName: string } => {
    if (!website) return { domainName: "", emailDomainName: "" };

    // Remove protocol if present
    let domain = website.replace(/^https?:\/\//, '');

    // Extract the main domain by taking the last two parts (or three for co.uk style domains)
    const parts = domain.split('.');
    if (parts.length >= 2) {
        // Handle special cases like .co.uk, .com.au, etc.
        if (parts.length > 2 && parts[parts.length - 2] === 'co') {
            domain = parts.slice(-3).join('.');
        } else {
            domain = parts.slice(-2).join('.');
        }
    }

    // If domain is empty after replacements, return empty string
    if (!domain) return { domainName: "", emailDomainName: "" };

    // Add advisors subdomain
    return { domainName: `advisors.${domain}`, emailDomainName: `advisor.${domain}` };
};

export const AdminOrganizationDomains = () => {
    const { organization } = useOutletContext<{ organization: any }>();
    const { getAccessTokenSilently } = useAuth0();
    const [domains, setDomains] = useState<any[]>([]);
    const [domainName, setDomainName] = useState("");
    const [emailDomainName, setEmailDomainName] = useState("");
    const organizationClient = ClientProvider.provideOrganizationClient();
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const [copiedText, setCopiedText] = useState("");
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchDomains = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getOrganizationDomains(organization.tag, accessToken);
        if (data) {
            setDomains(data.domains);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDomains();
        // Set initial domain name suggestion if no domains exist
        if (domains.length === 0 && organization?.website) {
            const { domainName, emailDomainName } = getSuggestedDomainNames(organization.website);
            setDomainName(domainName);
            setEmailDomainName(emailDomainName);
        }
    }, [organization]);

    const handleAddDomain = async () => {
        if (domainName === emailDomainName) {
            setErrorMessage("Landing page domain and email domain must be different");
            setShowError(true);
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.addOrganizationDomain(
            organization.tag,
            { domain_name: domainName, email_domain_name: emailDomainName },
            accessToken
        );
        if (data) {
            setDomains([...domains, data.domain]);
        }
    };

    const handleVerifyDomain = async (domain: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data } = await organizationClient.verifyOrganizationDomain(organization.tag, domain.uuid, accessToken);
        if (data) {
            fetchDomains();
        }
    };

    const handleProvisionDomain = async (domain: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await provisionOrganizationDomain(organization.id, domain.uuid, accessToken);
        if (data) {
            fetchDomains();
        }
    };

    const handleCopyRecordValue = async (text: string) => {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        setShowCopySuccess(true);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <h5>Custom Domains</h5>
            {!domains.length && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box className="display-box white" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Typography variant="h6">Add custom domains</Typography>
                        <Typography>Adding custom domains will allow the organization to host advisor landing pages on their own domain and send outreach from their own subdomain.</Typography>
                        
                        <Typography sx={{ fontWeight: "bold" }}>Landing Page Domain</Typography>
                        <TextField 
                            label="Landing Page Domain" 
                            value={domainName} 
                            onChange={(e) => setDomainName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box component="span" sx={{ color: '#c9c9c9', whiteSpace: "nowrap" }}>
                                        https://
                                    </Box>
                                ),
                            }}
                        />

                        <Typography sx={{ fontWeight: "bold" }}>Email Domain</Typography>
                        <TextField 
                            label="Email Domain" 
                            value={emailDomainName} 
                            onChange={(e) => setEmailDomainName(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box component="span" sx={{ color: '#c9c9c9', whiteSpace: "nowrap" }}>
                                        no-reply@
                                    </Box>
                                ),
                            }}
                        />
                        {showError && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
                        <Button variant="contained" color="primary" onClick={handleAddDomain}>Add Domain</Button>
                    </Box>
                </Box>
            )}

            {domains.map((domain: any) => {
                const hostname = domain.domain_name.split(".")[0];
                const domainName = domain.domain_name.replace(hostname, "");
            

                return (
                    <Box key={domain.uuid} sx={{ marginTop: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
                            {domain.is_verified ?
                                <Circle fill="#4CAF50" color="#4CAF50" size={16} /> :
                                <Circle fill="#9e9e9e" color="#9e9e9e" size={16} />
                            }
                            <Typography variant="h6">{domain.domain_name}</Typography>
                            <Button variant="contained" color="primary" onClick={() => handleProvisionDomain(domain)}>Push to Cloudflare</Button>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Hostname</TableCell>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Proxy</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>Verified</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {domain.records.map((record: any) => (
                                    <TableRow key={record.uuid}>
                                        <TableCell sx={{ fontWeight: "bold" }}>{record.description}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box sx={{ fontFamily: "monospace", wordBreak: "break-all" }}>
                                                    {record.hostname.replace(domainName, "")}
                                                </Box>
                                                <IconButton onClick={() => handleCopyRecordValue(record.hostname.replace(domainName, ""))}>
                                                    {copiedText === record.hostname.replace(domainName, "") ? <Check size={16} /> : <Copy size={16} />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{domainName}</TableCell>
                                        <TableCell>{record.proxied_cname}</TableCell>
                                        <TableCell>{record.record_type}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box sx={{ fontFamily: "monospace", maxWidth: "300px", wordBreak: "break-all" }}>
                                                    {record.record_value}
                                                </Box>
                                                <IconButton onClick={() => handleCopyRecordValue(record.record_value)}>
                                                    {copiedText === record.record_value ? <Check size={16} /> : <Copy size={16} />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {record.is_verified ? <CheckCircle color="#4CAF50" /> : <Timer />}
                                            <br />
                                            {record.is_verified ? 
                                                <span>Verified</span> : 
                                                <span className="link" onClick={() => handleVerifyDomain(domain)}>Check Now</span>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                );
            })}

            <Snackbar
                open={showCopySuccess}
                autoHideDuration={2000}
                onClose={() => setShowCopySuccess(false)}
                message="Copied to clipboard"
            />
        </>
    );
};