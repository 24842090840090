import { useAuth0 } from "@auth0/auth0-react";
import { getProjectAdvisorSuggestions } from "../services/gutcheck-admin-api.service";
import { useEffect, useState } from "react";
import { IAdvisorSuggestion } from "../../../types";

type AdvisorSuggestionsData = {
    fetched: boolean;
    advisorSuggestions: IAdvisorSuggestion[];
    error: {message: any} | null;
}

const initState: AdvisorSuggestionsData = {
    fetched: false,
    advisorSuggestions: [],
    error: null
}

export const useAdvisorSuggestions = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadAdvisorSuggestions = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getProjectAdvisorSuggestions(projectId, accessToken);

      if (data) {        
        const fetchedAdvisorSuggestions: AdvisorSuggestionsData = {
          fetched: true,
          advisorSuggestions: data.advisorSuggestions ?? [],
          error: error
        };
        
        setState(fetchedAdvisorSuggestions);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchAdvisorSuggestions = async () => {
          if (!isMounted) return;    
          reloadAdvisorSuggestions();
        };
    
        fetchAdvisorSuggestions(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadAdvisorSuggestions };
}
