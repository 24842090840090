import { useDealContext } from "../../deal/context";
import { useState, useEffect } from "react";
import ClientProvider from "../../../modules/ClientProvider";
import { Button, Dialog, TableBody, TableCell, TableHead, TableRow, TextField, Stack, TableSortLabel, Link } from "@mui/material";
import { Box } from "@mui/material";
import { AdvisorTable, ExecTabs, ExecTab, EmptyList } from "../../company/styles/AdvisorStyles";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../../users/context";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as LinkIcon, UserPlus } from "lucide-react";
import { useAdvisor } from "../context/AdvisorContext";

interface AdvisorSuggestion {
    id: number;
    uuid: string;
    name: string;
    email: string;
    linkedin_url: string;
    company_name: string;
    job_title: string;
    company_website: string;
    company_linkedin_url: string;
    status: string;
    created_at: string;
    created_by: {
        uuid: string;
        name: string;
    };
    personal_note: string;
}

const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Invited', value: 'invited' },
    { label: 'Active', value: 'active' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deleted', value: 'deleted' }
];

type SortColumn = 'name' | 'email' | 'linkedin' | 'company' | 'title' | 'suggestedBy' | 'dateSuggested' | 'status';
type SortDirection = 'asc' | 'desc';


const AdvisorSuggestionForm = ({ open, onClose, fetchSuggestions }: {
    open: boolean,
    onClose: () => void,
    fetchSuggestions: () => void
}) => {

    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        linkedin_url: '',
        company_name: '',
        job_title: '',
        personal_note: `I'm helping ${project.organization.name} as a light-touch advisor for ${project.name}. I think you'd be a great fit, too.`,
    });

    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        try {
            const advisorClient = ClientProvider.provideAdvisorClient();
            const accessToken = await getAccessTokenSilently();

            const { error } = await advisorClient.addAdvisorSuggestion(
                project.tag,
                formData,
                accessToken
            );

            if (error) {
                setError(error.message);
                return;
            }

            setFormData({
                name: '',
                email: '',
                linkedin_url: '',
                company_name: '',
                job_title: '',
                personal_note: `I'm helping ${project.organization.name} as a light-touch advisor for ${project.name}. I think you'd be a great fit, too.`,
            });
            onClose();
            fetchSuggestions();
        } catch (err) {
            setError('Failed to submit suggestion. Please try again.');
        }
    };

    const handleClose = () => {
        setFormData({
            name: '',
            email: '',
            linkedin_url: '',
            company_name: '',
            job_title: '',
            personal_note: `I'm helping ${project.organization.name} as a light-touch advisor for ${project.name}. I think you'd be a great fit, too.`,
        });
        setError(null);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box>
                <h5>Suggest an Advisor</h5>
                <p>Know a colleague who would be a great fit for this project? Suggest them as an advisor and we'll send them a personalized invite.</p>
                {error && (
                    <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>
                )}
                <form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={2}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            label="LinkedIn URL"
                            name="linkedin_url"
                            value={formData.linkedin_url}
                            onChange={handleChange}
                        />
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField
                                label="Job Title"
                                name="job_title"
                                value={formData.job_title}
                                onChange={handleChange}
                        />
                        <TextField
                            label="Company Name"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                        />
                        </Box>
                        <TextField
                            label="Personal Note"
                            name="personal_note"
                            multiline
                            rows={4}
                            value={formData.personal_note}
                            onChange={handleChange}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type="submit">Submit</Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Dialog>
    );
};


export const AdvisorReferals = () => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [suggestions, setSuggestions] = useState<AdvisorSuggestion[]>([]);
    const [openAdvisorSuggestionForm, setOpenAdvisorSuggestionForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedTab, setSelectedTab] = useState<string>("all");
    const [filteredSuggestions, setFilteredSuggestions] = useState<AdvisorSuggestion[]>([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>('dateSuggested');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [copiedLink, setCopiedLink] = useState(false);
    const { advisor } = useAdvisor();

    const advisorClient = ClientProvider.provideAdvisorClient();
    const { userContext } = useUserContext();

    const user = userContext?.dbUser;

    const fetchSuggestions = async () => {
        setLoading(true);
        setError(null);
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await advisorClient.getAdvisorSuggestions(project.tag, accessToken);
            if (error) {
                setError(error.message);
                return;
            }
            if (data) {
                setSuggestions(data.suggestions);
                setFilteredSuggestions(selectedTab === "your" ? data.suggestions.filter((suggestion: AdvisorSuggestion) => suggestion.created_by.uuid === user.uuid) : data.suggestions);
            }
        } catch (err) {
            setError('Failed to fetch suggestions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSuggestions();
    }, [project.id]);

    // Add sort handler
    const handleSort = (column: SortColumn) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    // Update useEffect to include sorting
    useEffect(() => {
        const sortedSuggestions = [...filteredSuggestions].sort((a, b) => {
            const direction = sortDirection === 'asc' ? 1 : -1;

            switch (sortColumn) {
                case 'name':
                    return (a.name || '').localeCompare(b.name || '') * direction;
                case 'email':
                    return (a.email || '').localeCompare(b.email || '') * direction;
                case 'linkedin':
                    return (a.linkedin_url || '').localeCompare(b.linkedin_url || '') * direction;
                case 'company':
                    return (a.company_name || '').localeCompare(b.company_name || '') * direction;
                case 'title':
                    return (a.job_title || '').localeCompare(b.job_title || '') * direction;
                case 'suggestedBy':
                    return (a.created_by.name || '').localeCompare(b.created_by.name || '') * direction;
                case 'dateSuggested':
                    return ((new Date(a.created_at).getTime() - new Date(b.created_at).getTime())) * direction;
                case 'status':
                    return (a.status || '').localeCompare(b.status || '') * direction;
                default:
                    return 0;
            }
        });
        setFilteredSuggestions(sortedSuggestions);
    }, [suggestions, selectedTab, sortColumn, sortDirection]);

    const link = project.organization.custom_domain_enabled ?
    `${process.env.REACT_APP_APP_PROTOCOL || 'https'}://${project.organization.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ''}/${project.tag}?ar=${advisor?.referrer_code}` :
    `${project._origin}/advise/${project.tag}?ar=${advisor?.referrer_code}`;


    const handleCopy = () => {
        navigator.clipboard.writeText(link);
        setCopiedLink(true);

        setTimeout(() => {
            setCopiedLink(false);
        }, 5000);
    }

    return (
        <Box>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleCopy}
                        color={copiedLink ? "success" : "primary"}
                    >
                        <LinkIcon size={12} />&nbsp;&nbsp;
                        {copiedLink ? "Link Copied" : "Get Link"}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setOpenAdvisorSuggestionForm(true)}
                    >
                        <UserPlus size={12} />&nbsp;&nbsp;
                        Suggest an Advisor
                    </Button>
                </Box>
            </Box>

            {error && (
                <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>
            )}

            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'name'}
                                direction={sortColumn === 'name' ? sortDirection : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'company'}
                                direction={sortColumn === 'company' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'email'}
                                direction={sortColumn === 'email' ? sortDirection : 'asc'}
                                onClick={() => handleSort('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'linkedin'}
                                direction={sortColumn === 'linkedin' ? sortDirection : 'asc'}
                                onClick={() => handleSort('linkedin')}
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'status'}
                                direction={sortColumn === 'status' ? sortDirection : 'asc'}
                                onClick={() => handleSort('status')}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredSuggestions.map((suggestion) => (
                        <TableRow key={suggestion.id}>
                            <TableCell>{suggestion.name}</TableCell>
                            <TableCell>{suggestion.company_name}</TableCell>
                            <TableCell>{suggestion.email}</TableCell>
                            <TableCell>{suggestion.linkedin_url && <Link href={suggestion.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></Link>}</TableCell>
                            <TableCell>{statusOptions.find(option => option.value === suggestion.status)?.label}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </AdvisorTable>
            {loading && (
                <EmptyList>
                    <p>Loading...</p>
                </EmptyList>
            )}
            {!loading && filteredSuggestions.length === 0 && (
                <EmptyList>
                    <p>No suggestions yet</p>
                </EmptyList>
            )}

            <AdvisorSuggestionForm
                open={openAdvisorSuggestionForm}
                onClose={() => setOpenAdvisorSuggestionForm(false)}
                fetchSuggestions={fetchSuggestions}
            />

        </Box>
    );
};