import { Box, Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Button, TextField } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useBrandContext } from '../../../hooks/useBrandContext';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAdvisor, getAdvisorByProjectAndEmail, getCompanyLanding, initEvaluation, updateAdvisor } from '../../../core/services/gutcheck-api.service';
import { useDealContext } from '../../../features/deal/context';
import Loading from '../../../components/Loading';
import { DocumentTitle } from '../../../components/DocumentTitle';
export const CompanyExit = () => {

    const { brandContext } = useBrandContext();
    const { dealContext, setDealContext } = useDealContext();
    const { companyTag, advisorId } = useParams();

    const [project, setProject] = useState<any>(null);
    const [organization, setOrganization] = useState<any>(null);
    const [link, setLink] = useState<any>(null);
    const [surveyLink, setSurveyLink] = useState<any>(null);
    const [advisor, setAdvisor] = useState<any>(null);
    const [advisorLink, setAdvisorLink] = useState<any>(null);
    const [email, setEmail] = useState<any>(null);
    const { brand } = brandContext;
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {

        const fetchCompanyLanding = async () => {
            if (companyTag) {
                const { data, error } = await getCompanyLanding(companyTag);
                if (data) {
                    setProject(data.project);
                    setLink(data.surveyLink?.link);
                    setSurveyLink(data.surveyLink);

                    setDealContext({
                        ...dealContext,
                        project: data.project,
                        fetched: true
                    });
                }
            }
        }

        const fetchAdvisor = async () => {
            if (advisorId) {
                const { data, error } = await getAdvisor(advisorId);
                if (data) {
                    setAdvisor(data.advisor);
                    setAdvisorLink(data.advisor.link);
                }
            }
        }

        if (companyTag) {
            fetchCompanyLanding();
        }
        if (advisorId) {
            fetchAdvisor();
        }
    }, [companyTag, advisorId]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (advisor) {
            const { data, error } = await updateAdvisor(advisor.public_id, {
                advisor_opt_in: true
            });
            if (data) {
                setAdvisor(data.advisor);
                setSubmitted(true);
            }
        }
    }


    if (!project) {
        return <Loading />;
    }

    return (
        <>
            <DocumentTitle pageTitle={`${project?.organization?.name} Advisor Network for ${project?.name}`} showAttribution={false} />

            <Box sx={{
                width: '100%'
            }}>
                <Box sx={{
                    background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#ffffff',
                    minHeight: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: "30px",
                        maxWidth: '1200px',
                        width: '100%',
                        margin: '0 auto',
                        padding: "40px 60px"
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flex: '1 1 460px',
                            minWidth: 0,
                        }}>
                            {advisor?.advisor_opt_in ?
                                <Typography variant="h3" fontWeight="bold">Thank you for your interest in advising {project?.name}!</Typography>
                                :
                                <Typography variant="h3" fontWeight="bold">Based on what you've seen, do you want to advise {project?.name}?</Typography>
                            }
                        </Box>
                        <Box className="display-box white"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                gap: 2,
                                color: '#000000',
                                flex: '0 0 300px',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '8px',
                                marginBottom: '0px'
                            }}
                        >
                            {advisor?.advisor_opt_in ?
                                <>
                                    <h5>We'll be in touch...</h5>
                                    <p>We're reviewing your feedback and will be in touch soon with next steps.</p>
                                </>

                                :
                                <>
                                    <h5>Thank you!</h5>
                                    <p> Let us know if you're ready to be an advisor for {project?.name}. Details on the program are below.</p>
                                    {submitted ?
                                        <Box sx={{ width: "100%", textAlign: "center", border: "1px solid #c9c9c9", backgroundColor: "#f8f9fa", padding: "10px", borderRadius: "8px" }}>Thank you for your interest!<br />We'll be in touch soon.</Box> :
                                        <Button
                                            sx={{ width: "100%" }}
                                            variant="contained" color="primary" onClick={handleSubmit}>Yes, I'll help</Button>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ backgroundColor: "#e9e9e9", padding: "20px 40px", textAlign: "center" }}>
                    <Typography fontSize={"1.5rem"} sx={{ fontStyle: "italic" }}>The {project.suppress_organization ? project?.name : project.organization?.name} Advisor network builds more successful startups by pairing senior executives with top startups in their wheelhouse.</Typography>
                </Box>


                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: '0 auto',
                    padding: "20px 40px",
                    flexWrap: "wrap",
                    gap: "30px"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flext-start",
                        margin: '0 auto',
                        padding: "20px 20px"
                    }}>
                        <Box sx={{
                            flex: '1 1 200px',
                            minWidth: 0,
                            height: "fit-content",
                            borderRight: { xs: 'none', md: '1px solid #e0e0e0' },
                            marginTop: { xs: '20px', md: '20px' },
                            padding: { xs: '0 20px 30px 0', md: '0 20px 0 0' },
                        }}>
                            <Typography variant="body1" fontSize={"1.5rem"} mb={2} mt={1} fontWeight={700}>What you'll do...</Typography>
                            <Typography variant="body1">As an advisor, you'll leverage your extensive industry knowledge to help {project?.name} grow.</Typography>
                            <List sx={{
                                listStyleType: 'disc',
                                pl: 2,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                    padding: '8px 8px 8px 8px',
                                }
                            }}>
                                <ListItem><strong>Advise</strong> - Provide monthly feedback on new products and initiatives</ListItem>
                                <ListItem><strong>Refer</strong> - Refer colleagues who could also be good advisors or customers for {project?.name}</ListItem>
                                <ListItem><strong>Demo</strong> - Raise your hand for a demo or trial if and when the product fits your needs</ListItem>
                                <ListItem><strong>Suggest</strong> - Share ides about features, products, or initiatives that could help {project?.name} grow.</ListItem>
                            </List>
                        </Box>
                        <Box sx={{
                            flex: '1 1 200px',
                            minWidth: 0,
                            height: "fit-content",
                            marginTop: { xs: '20px', md: '20px' },
                            padding: { xs: '0 20px 30px 0', md: '0 0 0 20px' },
                        }}>
                            <Typography variant="body1" fontSize={"1.5rem"} mb={2} mt={1} fontWeight={700}>What you'll get...</Typography>
                            <Typography variant="body1">We're developing the program, but key benefits for executives will include:</Typography>
                            <List sx={{
                                listStyleType: 'disc',
                                pl: 2,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                    padding: '8px 8px 8px 8px',
                                }
                            }}>
                                <ListItem><strong>Impact</strong> - Influence and shape emerging products in your domain</ListItem>
                                <ListItem><strong>People</strong> - Grow your network by connecting with investors, founders, and other advisors</ListItem>
                                <ListItem><strong>Equity</strong> - The most effective advisors have opportunies to earn meaningful equity in early stage companies</ListItem>
                                <ListItem><strong>Information and Intel</strong> - Get access to ideas, emerging trends, and other insights before anyone else</ListItem>
                            </List>
                        </Box>
                        <Box
                            sx={{
                                flex: '1 1 200px',
                                width: { xs: '100%', md: 'auto' },
                                minWidth: 0,
                                padding: { xs: '0 0 0 20px', md: '0 0 0 40px' },
                            }}
                        >
                            <Box className="display-box" sx={{ width: '100%', "& h6": { marginTop: "20px" } }} >
                                <Typography variant="body1" fontWeight={900} mb={2}>Frequently Asked Questions</Typography>
                                <Typography variant="body1" fontWeight={900}>How can I get an invite?</Typography>
                                <Typography variant="body1" mb={2}>The advisor network is invitation-only - you'll need an invitation from {!project.suppress_organization ? `${brand?.name}, ${project.name},` : `${project?.name}`} or an existing advisor to participate.</Typography>
                                <Typography variant="body1" fontWeight={900}>What's the time commitment?</Typography>
                                <Typography variant="body1" mb={2}>Advisorsgive 5-15 minutes of feedback per month and provide ad-hoc suggestions along the way.</Typography>
                                <Typography variant="body1" fontWeight={900}>How do I get my initial shares?</Typography>
                                <Typography variant="body1" mb={2}>The company reviews each new potential advisor. If you're accepted, you'll receive instructions in email.</Typography>
                                <Typography variant="body1">If you have any more questions, please <a href={`mailto:info@${project?.organization?.custom_domain_enabled ? project?.organization?.custom_email_domain : 'brightloop.com'}`}>email us</a>.</Typography>
                            </Box>
                        </Box>
                    </Box>


                </Box>
            </Box>
        </>
    );
}