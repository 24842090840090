import { Rating, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { AdvisorTable } from "../../styles/AdvisorStyles";
import { DateDisplay } from "../../../meeting/components/DateDisplay";
import { IAdvisor, IProject, IMeeting } from "../../../../types";
import { AdvisorCompany, AdvisorProfileSummary } from "../../../advisor";

export const OpportunitiesList = ({ project, advisors, reloadData }: { project: IProject, advisors: IAdvisor[], reloadData: () => void }) => {
    const projectHttpClient = new ProjectHttpClient();
    const { getAccessTokenSilently } = useAuth0();

    const handleMarkAsCustomer = async (advisorUuid: string, event: React.MouseEvent) => {
        event.stopPropagation();
        
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectHttpClient.updateAdvisorStage(project.id, advisorUuid, "customer", accessToken);
        if (data) {
            reloadData();
        }
    }

    return (
        <TableContainer sx={{ marginBottom: "60px", marginTop: "20px" }}>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="company">Company</TableCell>
                        <TableCell className="profile">Advisor</TableCell>
                        <TableCell className="center">Last Meeting</TableCell>
                        <TableCell className="center">Rating</TableCell>
                        <TableCell className="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {advisors.map((advisor: IAdvisor) => {
                        const lastMeeting = advisor.meetings?.sort((a: IMeeting, b: IMeeting) => new Date(b.event_start!).getTime() - new Date(a.event_start!).getTime())[0];
                        if (!lastMeeting) {
                            return null;
                        }
                        return (
                            <CompanyAdvisorLink uuid={advisor.uuid} key={`link-${advisor.uuid}`}>
                                <TableRow key={advisor.uuid}>
                                    <TableCell className="company"><AdvisorCompany advisor={advisor} /></TableCell>
                                    <TableCell className="profile"><AdvisorProfileSummary advisor={advisor} /></TableCell>
                                    <TableCell className="center">
                                        <DateDisplay meeting={lastMeeting} showTime={true} />
                                    </TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()}>
                                        <Rating value={lastMeeting?.ratings?.length ? lastMeeting?.ratings[0]?.rating : 0} max={4} readOnly/>
                                    </TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()}>
                                        <span className="link" onClick={(e) => handleMarkAsCustomer(advisor.uuid, e)}>Mark as Customer</span>
                                    </TableCell>
                                </TableRow>
                            </CompanyAdvisorLink>
                )
                    })}
            </TableBody>
            </AdvisorTable>
        </TableContainer >


    )
}