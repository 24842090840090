import { useAuth0 } from "@auth0/auth0-react";
import { Box, DialogProps, FormControlLabel, MenuItem, Modal, Select, Stack, styled, Switch, TextField, Table, TableBody, TableRow, TableCell, Typography, Button, Autocomplete } from "@mui/material";
import { useState } from "react";
import { updateViewpoint, updateMediaResponse, updateViewpointAdvisor } from "../../services/gutcheck-admin-api.service";
import { AdvisorProfileImage, AdvisorProfileSummary } from "../../../advisor";
import { useAdvisors } from "../../hooks/useAdvisors";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

const AdvisorForm = ({ project, advisors, viewpoint, advisor, setAdvisor, setEditAdvisor }: any) => {
    const { getAccessTokenSilently } = useAuth0();
    const [advisorForm, setAdvisorForm] = useState(advisor);
    const [ originalAdvisor, setOriginalAdvisor ] = useState(advisor);

    // Filter out advisors who already have viewpoints in this touchpoint
    const availableAdvisors = advisors.filter((a: any) => {
        // Skip the current advisor of this viewpoint (if editing)
        if (a.id === viewpoint.advisor?.id) {
            return false;
        }
        
        console.log("viewpoint touchpoint", viewpoint.touchpoint.uuid);
        console.log("advisor uuids", a.viewpoints.map((v: any) => v.touchpoint?.uuid));
        // Check if advisor has any viewpoints in this touchpoint
        const hasViewpointInTouchpoint = a.viewpoints?.some((v: any) => 
            v.touchpoint?.uuid === viewpoint.touchpoint?.uuid
        );
        
        return !hasViewpointInTouchpoint;
    });

    const handleChange = (event: any, selectedAdvisor: any) => {
        setAdvisorForm(selectedAdvisor);
        setAdvisor(selectedAdvisor);
    };

    const handleCancel = () => {
        setAdvisorForm(originalAdvisor);
        setAdvisor(originalAdvisor);
        setEditAdvisor(false);
    }

    const handleSave = async () => {
        const accessToken = await getAccessTokenSilently();
        await updateViewpointAdvisor(project.id, viewpoint.id, advisorForm.id, accessToken);
        setEditAdvisor(false);
    }

    console.log(originalAdvisor);

    return (
        <Box>
            <Autocomplete
                fullWidth
                value={advisorForm}
                onChange={handleChange}
                options={availableAdvisors}
                getOptionLabel={(option) =>
                    option ? `${option.user?.profile?.name || ''} (${option.user?.email_address || ''})` : ''
                }
                renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <AdvisorProfileImage advisor={option} size="small" />
                            <Typography variant="body1">
                                <strong>{option.user?.profile?.name}</strong><br />
                                &lt;{option.user?.email_address}&gt;
                            </Typography>
                        </Stack>
                    </MenuItem>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Search by name or email"
                        variant="outlined"
                    />
                )}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
            />
            <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
            </Stack>
        </Box>
    );
}


export const ViewpointForm = ({ viewpoint, project, user, setViewpoint, editMode, setEditMode, reloadProjectData }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const [originalViewpoint, setOriginalViewpoint] = useState(viewpoint);
    const [viewpointForm, setViewpointForm] = useState(viewpoint);
    const [editAdvisor, setEditAdvisor] = useState(false);
    const { fetched, advisors, error, reloadAdvisors } = useAdvisors(project.id ?? "");
    const [ advisor, setAdvisor ] = useState(viewpoint.advisor);


    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = async (event: any) => {
        event.preventDefault();
        if (originalViewpoint !== viewpoint || originalViewpoint.advisor?.id !== advisor?.id) {
            await reloadProjectData();
        }
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? !viewpointForm[event.target.name] : event.target.value;
        setViewpointForm({ ...viewpointForm, [event.target.name]: value });
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const newViewpoint = viewpointForm;

        const { data, error } = await updateViewpoint(project.id, viewpoint.id, newViewpoint, accessToken);

        setViewpointForm(data.viewpoint);
        setOriginalViewpoint(data.viewpoint);
        //await reloadProjectData();
        //setEditMode(false);
    }

    const handleMediaResponseChange = async (event: any) => {
        const value = event.target.value;
        const mediaResponse = viewpoint.media_responses.find((mediaResponse: any) => mediaResponse.uuid === event.target.name);

        // Call the API to update the media response
        const accessToken = await getAccessTokenSilently();
        await updateMediaResponse(mediaResponse.uuid, { display_as: value }, accessToken);

        // Update local state
        mediaResponse.display_as = value;
        setViewpointForm({ ...viewpointForm, media_responses: [...viewpointForm.media_responses, mediaResponse] });
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack sx={style} gap={2}>
                    <h5>Edit Viewpoint</h5>

                <Box mb={2}>
                    <h5 style={{ paddingBottom: "10px" , marginBottom: "10px" , borderBottom: "1px solid #c9c9c9" }}>Advisor</h5>
                    
                    {advisor && (
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <AdvisorProfileSummary advisor={advisor} />
                            <Button variant="text" color="primary" onClick={() => {
                                setEditAdvisor(true);
                            }}>Change</Button>
                        </Box>
                    )}
                    {!advisor && !editAdvisor && (
                        <Button variant="contained" color="primary" onClick={() => {
                            setEditAdvisor(true);
                        }}>Add Advisor</Button>
                    )}
                    {editAdvisor && (
                        <Box sx={{ mt: 2 }}>
                            <AdvisorForm project={project} advisors={advisors} viewpoint={viewpoint} advisor={advisor} setAdvisor={setAdvisor} setEditAdvisor={setEditAdvisor} />
                        </Box>
                    )}
                </Box>
                <Box mb={2}>
                    <h5 style={{ paddingBottom: "10px" , marginBottom: "10px" , borderBottom: "1px solid #c9c9c9" }}>Media Responses</h5>
                    {viewpoint.media_responses.length > 0 ? (
                        <Table sx={{ marginBottom: "20px" }}>
                            <TableBody>
                            {viewpoint.media_responses.map((mediaResponse: any) => (
                                <TableRow key={mediaResponse.uuid}>
                                    <TableCell>{mediaResponse.title}</TableCell>
                                    <TableCell>
                                        <Select name={mediaResponse.uuid} onChange={handleMediaResponseChange} value={mediaResponse.display_as}>
                                            <MenuItem value="video">Video</MenuItem>
                                            <MenuItem value="audio">Audio</MenuItem>
                                            <MenuItem value="text">Text</MenuItem>
                                            <MenuItem value="hide">Hide</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    ) : <Typography variant="body1">No media responses found</Typography>}
                </Box>
                <Box mb={2}>
                    <h5 style={{ paddingBottom: "10px" , marginBottom: "10px" , borderBottom: "1px solid #c9c9c9" }}>Visibility Settings</h5>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} mt={2}>
                            <div>
                                <FormControlLabel name="is_published" control={<Switch checked={viewpointForm?.is_published} onChange={handleChange} />} label="Visible to Company" />
                                <FormControlLabel name="hidden_from_guest" control={<Switch checked={!viewpointForm?.hidden_from_guest} onChange={handleChange} />} label="Visible to Guest" />
                                <FormControlLabel name="included_in_analysis" control={<Switch checked={viewpointForm?.included_in_analysis} onChange={handleChange} />} label="Included in Analysis" />
                                <FormControlLabel name="is_stale" control={<Switch checked={viewpointForm?.is_stale} onChange={handleChange} />} label="Stale Profile" />
                            </div>
                            <button type="submit" disabled={originalViewpoint === viewpointForm}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>Close</button>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Modal>
    )
}