import { useEffect } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";
import { IProject } from "../../../types";

export const UserTitle = ({ section, subheading }: {section: string, subheading?: string | React.ReactNode}) => {

    const { trackPage } = useAnalytics();

    useEffect(() => {
        trackPage(`User - ${section}`, {
            section: section
        });
    }, [section]);

    return (
        <Box sx={{ marginBottom: "40px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Box>
                <DocumentTitle pageTitle={section} />
                <h2>{section}</h2>
                {subheading && <div>{subheading}</div>}
            </Box>
        </Box>
    );
}