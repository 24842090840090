import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { Modal, Box, TextField, Stack, MenuItem, Autocomplete } from "@mui/material";
import type { DialogProps } from "@mui/material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { IMeeting, MeetingStatus, IProject, IAdvisor, IUser } from "../../../types";
import { createMeeting, updateMeeting } from "../services/gutcheck-admin-api.service";

dayjs.extend(utc);

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

interface MeetingFormProps {
    meeting?: IMeeting;
    projects: IProject[];
    advisors: IAdvisor[];
    reloadMeetings: () => Promise<void>;
    editMode: boolean;
    setEditMode: (mode: boolean) => void;
}

export const MeetingForm = ({ 
    meeting, 
    projects, 
    advisors, 
    reloadMeetings, 
    editMode, 
    setEditMode 
}: MeetingFormProps) => {
    const { getAccessTokenSilently } = useAuth0();
    console.log("meeting",meeting);

    const initialMeeting = {
        project: meeting?.project ?? null,
        advisor: meeting?.advisor ?? null,
        description: meeting?.description ?? "",
        status: meeting?.status ?? MeetingStatus.REQUESTED,
        event_start: meeting?.event_start ? 
            dayjs(meeting.event_start).format('YYYY-MM-DDTHH:mm') : 
            dayjs().format('YYYY-MM-DDTHH:mm'),
        event_duration: meeting?.event_duration ?? 30,
        requested: meeting?.requested ?? true,
        requested_at: meeting?.requested_at ?? new Date(),
        request_note: meeting?.request_note ?? ""
    };

    useEffect(() => {
        setMeetingForm(initialMeeting);
    }, [meeting]);

    const [meetingForm, setMeetingForm] = useState(initialMeeting);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const newMeeting = {
            ...meetingForm,
            event_start: new Date(meetingForm.event_start).toISOString(),
            requested_at: new Date().toISOString(),
        };

        try {
            if (meeting?.id) {
                await updateMeeting(meeting.uuid, newMeeting, accessToken);
            } else {
                await createMeeting(newMeeting, accessToken);
            }
            setMeetingForm(initialMeeting);
            await reloadMeetings();
            setEditMode(false);
        } catch (error) {
            console.error('Error saving meeting:', error);
        }
    };

    const handleChange = (event: any) => {
        setMeetingForm({ ...meetingForm, [event.target.name]: event.target.value });
    };

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setEditMode(false);
    };

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    };

    return (
        <Modal
            open={editMode}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="admin-modal-intro">
                    <h5>{meeting?.id ? "Edit Meeting" : "Schedule New Meeting"}</h5>
                    <p>Schedule a meeting between an advisor and a company.</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            name="project"
                            label="Company"
                            value={meetingForm.project?.name}
                            required
                            disabled={true}
                        />


                        <TextField
                            name="advisor"
                            label="Advisor"
                            value={meetingForm.advisor?.user?.profile?.name}
                            required
                            disabled={true}
                        />

                        <TextField
                            select
                            name="status"
                            label="Status"
                            value={meetingForm.status}
                            onChange={handleChange}
                            required
                        >
                            {Object.values(MeetingStatus).map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status.charAt(0).toUpperCase() + status.slice(1)}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            type="datetime-local"
                            name="event_start"
                            label="Start Time"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={meetingForm.event_start}
                            required
                        />

                        <TextField
                            type="number"
                            name="event_duration"
                            label="Duration (minutes)"
                            value={meetingForm.event_duration}
                            onChange={handleChange}
                            required
                        />

                        <TextField
                            name="description"
                            label="Description"
                            value={meetingForm.description}
                            onChange={handleChange}
                        />

                        <button type="submit">Submit</button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            Cancel
                        </button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}; 