import { Box, Button, Modal, Tooltip, Typography, useMediaQuery, Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useUserContext } from "../../users";
import useAnalytics from "../../../hooks/useAnalytics";
import Loading from "../../../components/Loading";
import { useDealContext } from "../../deal/context";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { AdvisorBox, ScoreCircle, SummaryContainer } from "../styles";
import { MeetingRequest } from "./meetings/MeetingRequest";
import { CalendarPlus } from "lucide-react";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DateDisplay } from "../../meeting";
import { ExecTabs, ExecTab } from "../styles/AdvisorStyles";
import { SelectChangeEvent } from "@mui/material";
import { AdvisorProfileImage, AdvisorCompany } from "../../advisor";
import { IAdvisor, IProject, IMeeting, IViewpoint } from "../../../types";

const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]


const MediaAnswer = ({ mediaResponse }: { mediaResponse: any }) => {
    const [showFullSummary, setShowFullSummary] = useState(false);

    if (mediaResponse.display_as === "text") {
        return (
            <Typography sx={{
                marginTop: "0.25rem",
                fontSize: "1rem"
            }}>
                {mediaResponse.text}
            </Typography>
        )
    } else {

        return (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "top", justifyContent: "center", flexWrap: "wrap", gap: "40px" }}>
                <Box sx={{ maxHeight: "225px", maxWidth: "400px", marginBottom: "20px", flex: "0 1 400px", marginLeft: "20px" }}>
                    {mediaResponse.display_as === "video" ? (
                        <video controls preload="none" poster={mediaResponse.thumbnail_url} style={{ width: "400px", height: "auto", maxHeight: "225px" }}>
                            <source src={mediaResponse.video_url} type="video/mp4" />
                        </video>
                    ) : (
                        <audio controls>
                            <source src={mediaResponse.audio_url} type="audio/mp3" />
                        </audio>
                    )}
                </Box>
                <Box sx={{ width: "640px", height: "auto", flex: "1 1" }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", fontSize: "1.2rem", color: "#000000", marginBottom: "10px" }}>Key Points:</Typography>
                    <div dangerouslySetInnerHTML={{ __html: mediaResponse.summary_html }} />
                    <span className="link" onClick={() => { setShowFullSummary(!showFullSummary) }}>Show/hide full transcript</span>
                    {showFullSummary && <blockquote>
                        {mediaResponse.text}
                    </blockquote>}
                </Box>
            </Box>
        )
    }
}

export const AdvisorProfile = ({ advisor, project, updateAdvisor }: { advisor: IAdvisor, project: IProject, updateAdvisor: () => void }) => {

    const { userContext } = useUserContext();
    const { trackPage } = useAnalytics();
    const [requestDemo, setRequestDemo] = useState(false);
    const [activeTouchpoint, setActiveTouchpoint] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [touchpoints, setTouchpoints] = useState(advisor.viewpoints?.map((viewpoint: IViewpoint) => viewpoint.touchpoint));
    const [viewpoint, setViewpoint] = useState(advisor.viewpoints?.[0]); // @TODO: Add multiple viewpoints
    const [expandedDescription, setExpandedDescription] = useState(false);

    dayjs.extend(relativeTime);

    const nextScheduledMeeting = advisor.meetings?.filter((meeting: IMeeting) => meeting.status === "scheduled")?.sort((a: IMeeting, b: IMeeting) => dayjs(a.created_at!).diff(dayjs(b.created_at!)))?.[0];
    const lastMeeting = advisor.meetings?.filter((meeting: IMeeting) => meeting.status !== "cancelled")?.sort((a: IMeeting, b: IMeeting) => dayjs(b.created_at!).diff(dayjs(a.created_at!)))?.[0];

    const displayMeeting = nextScheduledMeeting || lastMeeting;

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - viewpoints - executive`, { organization: project.organization.tag, project: project.tag, role: userContext.projectMemberships.role, viewpoint: viewpoint?.uuid, viewpoint_name: viewpoint?.name });
        }
    }, [userContext]);

    const handleTouchpointChange = (event: SelectChangeEvent<number>) => {
        setActiveTouchpoint(Number(event.target.value));
        setViewpoint(advisor.viewpoints?.find((viewpoint: IViewpoint) => viewpoint.touchpoint?.uuid === touchpoints?.[Number(event.target.value)]?.uuid));
        setActiveTab(0); // Reset section tab when changing touchpoint
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const responses = viewpoint?.response_json;
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    // Add handler for dropdown
    const handleDropdownChange = (event: SelectChangeEvent) => {
        setActiveTab(Number(event.target.value));
    };

    const mediaResponses = viewpoint?.media_responses?.filter((response: any) => response.display_as === "video" || response.display_as === "audio" || response.display_as === "text" || response.display_as === "hide");
    const highlights = mediaResponses?.filter((response: any) => response.display_as !== "text" && response.display_as !== "hide");

    if (!advisor) return null;
    return (
        <AdvisorBox>
            <Box className="executive-content" sx={{ overflowY: "scroll", height: "100%" }}>
                <Box className="executive-profile">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: "20px",
                            padding: "0 20px",
                        }}
                    >
                        <AdvisorProfileImage advisor={advisor} size="medium" />

                        <Box className="viewpoint-profile-details"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "left",

                            }}>
                            <Typography sx={{ fontSize: "1.1rem", textAlign: "left", fontWeight: "700" }}>{advisor?.user?.profile?.name}</Typography>
                            <Typography sx={{
                                fontSize: "1.1rem", textAlign: "left",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                marginBottom: "0.5rem"
                            }}>{advisor?.user?.profile?.title}</Typography>
                            <AdvisorCompany advisor={advisor} style="left" />

                        </Box>
                    </Box>
                    <SummaryContainer>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ padding: "0.5rem", border: "1px solid #ccc", borderRadius: "50px" }}>
                            <Typography variant="body1" sx={{ fontWeight: 900, flexGrow: 1, textAlign: "center", fontSize: "0.875em" }}>
                                Purchasing Interest: {demandOptions.find((option: any) => option.value === advisor?.demand)?.label}
                            </Typography>
                            <ScoreCircle score={advisor?.score || 0}>
                                {advisor?.score || '-'}
                            </ScoreCircle>
                        </Box>
                        {!displayMeeting && <Box>
                            {(!advisor.last_prospect_message_at || dayjs().diff(dayjs(advisor.last_prospect_message_at), 'days') > 30) ? (
                                <Typography className="link" textAlign="center" onClick={() => setRequestDemo(true)} fontSize={14} fontWeight={900}>
                                    <CalendarPlus size={16} strokeWidth={2} /> Request Meeting
                                </Typography>
                            ) : (
                                <Tooltip title={`Meeting can be requested again again in ${30 - dayjs().diff(dayjs(advisor.last_prospect_message_at), 'days')} days`}>
                                    <Typography sx={{ textAlign: "center", fontSize: "1rem", color: "gray" }}>
                                        <CalendarPlus size={16} strokeWidth={2} /> Request Meeting
                                    </Typography>
                                </Tooltip>
                            )}
                            {advisor.last_prospect_message_at ? <Typography sx={{ textAlign: "center", fontSize: "1rem" }}>
                                Last requested <strong>{dayjs(advisor.last_prospect_message_at).fromNow()}</strong></Typography> : null}
                        </Box>
                        }
                        {displayMeeting && <Box>
                            <Box sx={{ textAlign: "center", fontSize: "1rem" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: "10px" }}>
                                    <Box>{nextScheduledMeeting ? "Upcoming Meeting" : "Last Meeting"}</Box>
                                    <DateDisplay meeting={displayMeeting} showTime={false} />
                                </Box>
                            </Box>
                        </Box>}
                    </SummaryContainer>
                </Box>
                {responses ? (
                    <Box sx={{ padding: "20px" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", gap: "10px" }}>
                        {touchpoints?.length && touchpoints.length > 1 && (
                            <TextField
                                select
                                value={activeTouchpoint}
                                onChange={(event: any) => handleTouchpointChange(event)}
                                label="Feedback Loop"
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    mb: 2,
                                    maxWidth: "200px"
                                }}
                            >
                                {touchpoints.map((touchpoint: any, index: number) => (
                                    <MenuItem
                                        key={index}
                                        value={index}
                                        sx={{
                                            fontWeight: 700,
                                            textTransform: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        {touchpoint.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}

                        {isSmallScreen ? (
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Section</InputLabel>
                                <Select
                                    value={activeTab.toString()}
                                    onChange={handleDropdownChange}
                                    label="Section"
                                >
                                    {mediaResponses?.length && (
                                        <MenuItem key={0} value={0}>
                                            Highlights
                                        </MenuItem>
                                    )}
                                    {responses?.sections?.map((section: any, index: number) => (
                                        <MenuItem key={index + (mediaResponses?.length ? 1 : 0)} value={index + (mediaResponses?.length ? 1 : 0)}>
                                            {section.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <ExecTabs
                                value={activeTab}
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    mb: 2
                                }}
                            >
                                {highlights?.length && (
                                    <ExecTab
                                        key={0}
                                        label="Highlights"
                                        sx={{
                                            fontWeight: 600,
                                            textTransform: 'none',
                                            minWidth: "100px",
                                            width: "auto"
                                        }}
                                    />
                                )}
                                {responses.sections.map((section: any, index: number) => (
                                    <ExecTab
                                        key={index + (highlights?.length ? 1 : 0)}
                                        label={section.name}
                                        sx={{
                                            fontWeight: 600,
                                            textTransform: 'none',
                                            minWidth: "100px",
                                            width: "auto"
                                        }}
                                    />
                                ))}
                            </ExecTabs>
                        )}
                        </Box>
                        {
                            highlights?.length && highlights.length > 0 ? (
                                <Box key={0} role="tabpanel" hidden={activeTab !== 0}>
                                    {highlights
                                        .sort((a: any, b: any) => {
                                            // Find the sections and questions that match these media responses
                                            const findQuestionIndex = (response: any) => {
                                                for (const section of responses.sections) {
                                                    const questionIndex = section.questions.findIndex(
                                                        (q: any) => q.answers[0]?.includes(response.uuid) || q.answers[1]?.includes(response.uuid)
                                                    );
                                                    if (questionIndex !== -1) {
                                                        return questionIndex;
                                                    }
                                                }
                                                return Infinity; // Put items without matching questions at the end
                                            };

                                            return findQuestionIndex(a) - findQuestionIndex(b);
                                        })
                                        .map((response: any, index: number) => (
                                            <Box key={index} sx={{ marginBottom: "1.5rem" }}>
                                                <Typography sx={{
                                                    fontWeight: "900",
                                                    fontSize: "1.2rem",
                                                    color: "#000000"
                                                }}>
                                                    {response.title}
                                                </Typography>
                                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "20px", margin: "20px 0" }}>
                                                    <MediaAnswer mediaResponse={response} />
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            ) : <></>
                        }
                        {responses.sections.map((section: any, sectionIndex: number) => (
                            <Box
                                key={sectionIndex + (highlights?.length ? 1 : 0)}
                                role="tabpanel"
                                hidden={activeTab !== sectionIndex + (highlights?.length ? 1 : 0)}
                            >
                                {activeTab === sectionIndex + (highlights?.length ? 1 : 0) && (
                                    <Box>
                                        {section.name === viewpoint?.touchpoint?.product_description_section && viewpoint?.touchpoint?.product_description && (
                                            <Box sx={{ marginBottom: "40px" }}>
                                                <Typography sx={{
                                                    fontWeight: "900",
                                                    fontSize: "1.2rem",
                                                    color: "#000000"
                                                }}>
                                                    Please read the following product description
                                                </Typography>
                                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0", position: "relative" }}>
                                                    <Typography sx={{
                                                        marginTop: "0.25rem",
                                                        fontSize: "1rem",
                                                        maxHeight: expandedDescription ? "none" : "6em",
                                                        overflow: "hidden",
                                                        position: "relative",
                                                    }}>
                                                        <div dangerouslySetInnerHTML={{ __html: viewpoint.touchpoint.product_description }} />
                                                    </Typography>
                                                    {!expandedDescription && (
                                                        <Box sx={{
                                                            position: "absolute",
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            height: "100px",
                                                            background: "linear-gradient(transparent, white)",
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "center",
                                                            paddingBottom: "10px"
                                                        }}>
                                                            <Button
                                                                onClick={() => setExpandedDescription(true)}
                                                                variant="outlined"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    "&:hover": { backgroundColor: "#f5f5f5" }
                                                                }}
                                                            >
                                                                Expand
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                        {section.questions.map((item: any, questionIndex: number) => {
                                            const mediaMatch = item.answers[0]?.match(/^videoUUID:(.*)$/) || item.answers[0]?.match(/^audioUUID:(.*)$/) || item.answers[1]?.match(/^audioUUID:(.*)$/) || item.answers[0]?.match(/^textUUID:(.*)$/);
                                            const mediaResponse = mediaMatch ? mediaResponses?.find((response: any) => response.uuid === mediaMatch[1] && (response.display_as === "video" || response.display_as === "audio" || response.display_as === "text" || response.display_as === "hide")) : null;
                                            if (mediaResponse?.display_as === "hide") return null;
                                            if (!viewpoint?.touchpoint?.suppressed_questions?.includes(item.question) && (item.answers.length > 0 && item.answers[0] !== "")) {
                                                return (
                                                    <Box key={questionIndex} sx={{ marginBottom: "40px" }}>
                                                        <Typography sx={{
                                                            fontWeight: "900",
                                                            fontSize: "1.2rem",
                                                            color: "#000000"
                                                        }}>
                                                            {item.question}
                                                        </Typography>
                                                        <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0" }}>
                                                            {mediaResponse ?
                                                                <MediaAnswer mediaResponse={mediaResponse} /> :
                                                                <Typography sx={{
                                                                    marginTop: "0.25rem",
                                                                    fontSize: "1rem"
                                                                }}>
                                                                    {Array.isArray(item.answers)
                                                                        ? item.answers.join(", ")
                                                                        : item.answers}
                                                                </Typography>
                                                            }
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        })}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Loading />
                )}
            </Box>
            <MeetingRequest project={project} advisor={advisor} open={requestDemo} setOpen={setRequestDemo} updateAdvisor={updateAdvisor} />
        </AdvisorBox>
    );
}

export const CompanyAdvisorModal = ({ uuid, onClose }: { uuid: string, onClose?: () => void }) => {
    const [isModalOpen, setIsModalOpen] = useState(uuid ? true : false);
    const { dealContext } = useDealContext();
    const [advisor, setAdvisor] = useState(null);
    const project = dealContext.project;
    const { getAccessTokenSilently } = useAuth0();

    const client = new ProjectHttpClient();

    const fetchAdvisor = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await client.getAdvisor(uuid, project.id, accessToken);
        if (error) return;
        setAdvisor(data.advisor);
    }

    useEffect(() => {
        if (!uuid || !project) return;
        fetchAdvisor();
    }, [uuid, project]);

    const handleClose = () => {
        setIsModalOpen(false);
        if (onClose) onClose();
        window.location.hash = '';
    }

    if (!project) return null;
    if (!advisor) return null;

    return (
        <Modal open={isModalOpen} onClose={handleClose}>
            <Box>
                <AdvisorProfile project={project} advisor={advisor} updateAdvisor={fetchAdvisor} />
            </Box>
        </Modal>
    );
}   