import { useEffect, useState } from "react";
import { useDealContext } from "../../deal/context";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { CompanyTitle } from "../components/CompanyTitle";
import { Box } from "@mui/material";
import { ExecTab } from "../styles/AdvisorStyles";
import { ExecTabs } from "../styles/AdvisorStyles";
import { UpcomingList } from "../components/meetings/UpcomingList";
import { CompletedList } from "../components/meetings/CompletedList";
import { CustomersList } from "../components/meetings/CustomersList";
import { OpportunitiesList } from "../components/meetings/OpportunitiesList";
import { useNavigate, useParams } from "react-router-dom";
import { IProject, IAdvisor, IMeeting } from "../../../types";
import { useAdvisors } from "../../advisor/context/AdvisorsContext";

const meetingStates = ["upcoming", "completed", "opportunities", "customers"];

export const CompanyMeetings = () => {

    const [upcomingMeetings, setUpcomingMeetings] = useState<IMeeting[]>([]);
    const [completedMeetings, setCompletedMeetings] = useState<IMeeting[]>([]);
    const { advisors, loading, fetchAdvisors } = useAdvisors();
    const { meetingState, meetingUuid } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [value, setValue] = useState(meetingStates.indexOf(meetingState || "upcoming"));
    const { dealContext } = useDealContext();
    const project = dealContext.project as IProject;
    const viewerRole = dealContext.role;
    const projectHttpClient = new ProjectHttpClient();
    const navigate = useNavigate();

    if (meetingUuid) {
        //set hash to meetingUuid
        window.location.hash = meetingUuid;
    }

    const fetchMeetings = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectHttpClient.getMeetings(project.id, accessToken);
        if (data) {
            setUpcomingMeetings(data.meetings.filter((meeting: IMeeting) => 
                meeting.advisor.stage === "advisor" && meeting.event_start && new Date(meeting.event_start) > new Date()
            ));
            setCompletedMeetings(data.meetings.filter((meeting: IMeeting) => 
                meeting.advisor.stage === "advisor" && meeting.event_start && new Date(meeting.event_start) < new Date()
            ));
        }
    }

    const loadMeetings = async () => {
        await fetchMeetings();
    }

    const loadData = async () => {
        await fetchAdvisors(project.id);
        await loadMeetings();
    }

    useEffect(() => {
        loadMeetings();
    }, []);


    const handleTabSwitch = (event: any, newValue: any) => {
        setValue(newValue);
        navigate(`/c/meetings/${meetingStates[newValue]}`);
    }

    const opportunities = advisors.filter((advisor: IAdvisor) => advisor.stage === "opportunity");
    const customers = advisors.filter((advisor: IAdvisor) => advisor.stage === "customer");

    return (
        <Box>
            <CompanyTitle viewerRole={viewerRole} project={project} section="Meetings" subheading="Schedule and prep for meetings with advisors, and track them through to opportunities and customers." />
            <Box>
                <ExecTabs variant="fullWidth" value={value} onChange={handleTabSwitch}>
                    <ExecTab label={<div><span className="count">{upcomingMeetings.length}</span> Upcoming Meetings</div>}></ExecTab>
                    <ExecTab label={<div><span className="count">{completedMeetings.length}</span> Completed Meetings</div>}></ExecTab>
                    <ExecTab label={<div><span className="count">{opportunities.length}</span> Opportunities</div>}></ExecTab>
                    <ExecTab label={<div><span className="count">{customers.length}</span> Customers</div>}></ExecTab>
                </ExecTabs>
                {value === 0 && <Box>
                    <UpcomingList meetings={upcomingMeetings} />
                </Box>}
                {value === 1 && <Box>
                    <CompletedList project={project} meetings={completedMeetings} reloadData={loadData}/>
                </Box>}
                {value === 2 && <Box>
                    <OpportunitiesList project={project} advisors={opportunities} reloadData={loadData}/>
                </Box>}
                {value === 3 && <Box>
                    <CustomersList advisors={customers} />
                </Box>}
            </Box>
        </Box>
    )
}