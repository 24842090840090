import { getOrganizationActivity } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import ClientProvider from "../../../modules/ClientProvider";
import { useUserContext } from "../../../features/users/context";
import { FirmAdvisorGridItem } from "./FirmAdvisorGridItem";
import { Box } from "@mui/material";
import { gridStyle } from "../../company/styles/AdvisorStyles";

const ActivityList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    gap: '10px',
    padding: "10px",
    '&::-webkit-scrollbar': {
        height: '8px',
        display: 'none',
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#e9e9e9',
        borderRadius: '4px',
    },
    "& .MuiListItem-root": {
        padding: "0 10px 0 0",
        flex: '0 0 auto',
        width: 'auto',
        minWidth: '100px',
        maxWidth: '200px',
        overflow: 'ellipsis',
        "& .MuiTypography-root": {
            fontSize: "0.75rem",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        "& img": {
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            border: "1px solid #c9c9c9",
            marginRight: "10px",
        },
        "& strong": {
            fontWeight: "900",
        }
    }

}));  


export const FirmActivity = ({ organization, projects }: any) => {

    const [advisors, setAdvisors] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const { userContext } = useUserContext();
    const user = userContext.dbUser;

    const navigate = useNavigate();
    const organizationClient = ClientProvider.provideOrganizationClient();

    const defaultIconSvg = '<svg width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_847_348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="187" height="187"><circle cx="93.5" cy="93.5" r="93.5" fill="#F8F9FA"/></mask><g mask="url(#mask0_847_348)"><circle cx="93" cy="94" r="93" fill="#F8F9FA"/><circle cx="93.5" cy="191.5" r="85.5" fill="#667788"/><circle cx="94" cy="80" r="41" fill="#C9C9C9"/></g></svg>';


    useEffect(() => {

        let isMounted = true;
        const fetchActivityData = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await organizationClient.getOrganizationAdvisorActivity(organization.id, accessToken);
            if (isMounted) {
                setAdvisors(data.advisors);
            }
        }

        if (organization.id !== undefined) {
            fetchActivityData();
        }

        return () => {
            isMounted = false;
        };
    }, [organization]);

    const handleClick = (projectTag: string, projectExperience: string, viewpointUuid: string, advisorUuid: string) => {
        if (projectExperience === "advisor") {
            const url = `https://${projectTag}.${process.env.REACT_APP_APP_ORIGIN}/c/scoring/#${advisorUuid}`;
            window.location.href = url;
        } else if (projectExperience === "winning") {
            const url = `/o/${organization.tag}/d/${projectTag}/viewpoints#${viewpointUuid}`;
            return navigate(url);
        } else {
            const url = `/o/${organization.tag}/project/${projectTag}?tab=viewpoints&pid=${viewpointUuid}`;
            return navigate(url);
        }
    }

    const handleImageError = (e: any) => {
        const srcString = `data:image/svg+xml,${encodeURIComponent(defaultIconSvg)}`;
        e.target.src = srcString 
    }


    if (advisors?.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Box className="viewpoints-facegrid" sx={gridStyle}>
            {advisors?.slice(0, 20).map((advisor: any) => (
                <FirmAdvisorGridItem
                    key={advisor?.uuid}
                    advisor={advisor}
                    user={user}
                    style="face-grid"
                    isVisible={true}
                    type="expert"
                    handleClick={handleClick}
                />
            ))}
        </Box>
    )
}