import { Outlet, NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const Admin = () => {

  const { user } = useAuth0();

  return (
    <>
      <aside className="page-sidebar sidebar-light">
        <div className="sidebar-tab-container">
          <NavLink to={`/admin/dashboard`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Dashboard</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/projects`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Projects</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/organizations`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Organizations</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/users`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Users</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/smartlead`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Smartlead (beta)</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/brands`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Brands</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/messages`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Messages</span></p>
            </div>
          </NavLink>
          <NavLink to={`/admin/meetings`}>
            <div className="sidebar-tab">
              <p><span className="sidebar-label">Meetings</span></p>
            </div>
          </NavLink>
        </div>
      </aside>
      <main className="page-main admin-page sidebar">
        <div className="gc_view">
          <Outlet />
        </div>
      </main>
    </>
  );
}

