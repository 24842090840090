import { CompanyLogoBase, CompanyNameBase } from "../../../components/shared/StyledComponents";

export const CompanyLogo = ({ project, size="medium", style="centered" }: any) => {

    const logoUrl = project?.logoUrl || project?.logo_url || project.company?.logo_url || project.company?.logoUrl;

    if (logoUrl) {
        return (
            <CompanyLogoBase className={`${size} ${style}`}>
                <img src={logoUrl} alt={project?.name} />
            </CompanyLogoBase>
        );
    }
    return (
        <CompanyNameBase className={`${size} ${style}`}>
            {project?.name ?? project.name}
        </CompanyNameBase>
    );
}