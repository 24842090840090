import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAdvisor, getScheduler, advisorCreateMeeting, getAdvisorMeetings, getCalendlyMeetingDetails, advisorRateMeeting, getAdvisorMeeting } from "../../../core/services/gutcheck-api.service";
import { IAdvisor } from "../../../types";
import { useUserContext } from "../../users/context";
import { InlineWidget, useCalendlyEventListener, PopupModal } from "react-calendly";
import Loading from "../../../components/Loading";
import useAnalytics from "../../../hooks/useAnalytics";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { DateDisplay } from "../components/DateDisplay";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { AdvisorTable } from "../../company/styles";

const ratingLabels: { [index: number]: string } = {
    0: "NA - I did not attend this meeting",
    1: 'No-show or complete miss',
    2: 'Not the best use of my time',
    3: 'Worthwhile, I\'d do more of these',
    4: 'Great call, with next steps',
};

const MeetingLinks = ({ advisor, meeting, handleMeetingEvent, fetchMeetingDetails }: { advisor: any, meeting: any, handleMeetingEvent: (e: any, meetingUuid: string) => void, fetchMeetingDetails: () => void }) => {
    const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
    const [isCancelOpen, setIsCancelOpen] = useState(false);

    const handleReschedule = (e: any, rescheduleUrl: string) => {
        e.preventDefault();
        setIsRescheduleOpen(true);
    }

    const handleCancel = (e: any, cancelUrl: string) => {
        e.preventDefault();
        setIsCancelOpen(true);
    }

    const handleCloseCancel = (e: any, meetingUuid: string) => {
        setIsCancelOpen(false);
        fetchMeetingDetails();
        handleMeetingEvent(e, meetingUuid);
    }

    const handleCloseReschedule = (e: any, meetingUuid: string) => {
        setIsRescheduleOpen(false);
        fetchMeetingDetails();
        handleMeetingEvent(e, meetingUuid);
    }

    if (meeting) {
        const invitee = meeting?.calendly_invitee_uri?.split("/invitees/")[1];
        const cancelUrl = `https://calendly.com/cancellations/${invitee}`;
        const rescheduleUrl = `https://calendly.com/reschedulings/${invitee}`;
        return (
            <div>
                <Link onClick={(e) => handleCancel(e, cancelUrl)} to={cancelUrl}>Cancel</Link> | <Link onClick={(e) => handleReschedule(e, rescheduleUrl)} to={rescheduleUrl}>Reschedule</Link>
                <PopupModal
                    url={`${rescheduleUrl}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    onModalClose={(e) => handleCloseReschedule(e, meeting.uuid)}
                    prefill={{
                        name: advisor.user?.profile?.name || advisor.name,
                        email: advisor.user?.email_address || advisor.email_address,

                    }}
                    open={isRescheduleOpen}
                    rootElement={document.getElementById("evaluation-content") as HTMLElement}
                />
                <PopupModal
                    url={`${cancelUrl}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    onModalClose={(e) => handleCloseCancel(e, meeting.uuid)}
                    prefill={{
                        name: advisor.user?.profile?.name || advisor.name,
                        email: advisor.user?.email_address || advisor.email_address,
                    }}
                    open={isCancelOpen}
                    rootElement={document.getElementById("evaluation-content") as HTMLElement}
                />
            </div>
        )
    } else {
        return (
            <div>
                Scheduled
            </div>
        )
    }
}

export const Schedule = () => {
    const { userContext } = useUserContext();
    const { dealContext, setDealContext, loadDealContext } = useDealContext();
    const [advisor, setAdvisor] = useState<IAdvisor | null>(null);
    const [project, setProject] = useState<any | null>(null);
    const { advisorPublicId } = useParams();
    const { trackAdvisorPage, trackAdvisorEvent } = useAnalytics();
    const [scheduler, setScheduler] = useState<any | null>(null);
    const [inlineWidgetHeight, setInlineWidgetHeight] = useState<string>("0");
    const [meetings, setMeetings] = useState<any[]>([]);
    const [selectedMeeting, setSelectedMeeting] = useState<any | null>(null);
    const [isDemoSchedulerOpen, setIsDemoSchedulerOpen] = useState(false);
    const [isCancelOpen, setIsCancelOpen] = useState(false);
    const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
    const [meetingDetails, setMeetingDetails] = useState<any | null>(null);

    useEffect(() => {
        const fetchAdvisor = async () => {
            if (advisorPublicId) {
                const { data, error } = await getAdvisor(advisorPublicId);
                if (data) {
                    setAdvisor(data.advisor);
                    setProject(data.advisor.survey_link.project);
                }
            }
        }

        if (advisorPublicId) {
            fetchAdvisor();
        }
    }, [advisorPublicId]);

    useEffect(() => {
        if (advisor) {
            fetchScheduler();
            fetchMeetings();
            trackAdvisorPage(advisor, 'Advisor - Public Scheduler');
        }
    }, [advisor]);

    useEffect(() => {
        if (project) {
            setDealContext({
                ...dealContext,
                project: project,
                organization: project.organization,
                surveyLink: project.survey_link,
                role: "guest"
            });
        }
    }, [project]);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            if (!advisor) {
                return;
            }
            const { data, error } = await advisorCreateMeeting(advisor.public_id, { scheduler: { uuid: scheduler.uuid }, calendly_event_uri: e.data.payload.event.uri, calendly_invitee_uri: e.data.payload.invitee.uri });
            trackAdvisorEvent(advisor, 'Scheduled demo');
            if (data) {
                //loadAdvisor();
                //fetchMeetings();
            }
        },
        onPageHeightResize(e) {
            setInlineWidgetHeight(e.data.payload.height);
        }
    });

    const fetchMeetingDetails = async () => {
        if (advisor?.prospect_event_invitee_uri) {
            const inviteeId = advisor.prospect_event_invitee_uri.split("/invitees/")[1];
            const { data, error } = await getCalendlyMeetingDetails(advisor.public_id, inviteeId);
            if (data) {
                if (data.invitee.canceled && !data.invitee.rescheduled) {
                    setMeetingDetails(null);
                } else {
                    setMeetingDetails(data.invitee);
                }
            } else {
                console.error('Error fetching meeting details:', error);
            }
        }
    };

    const fetchScheduler = async () => {
        if (advisor) {
            const { data, error } = await getScheduler(advisor.public_id, "prospect");
            if (error) {
                console.error("Error fetching scheduler:", error);
            } else {
                setScheduler(data.scheduler);
            }
        }
    };

    const fetchMeetings = async () => {
        if (advisor) {
            const { data, error } = await getAdvisorMeetings(advisor.public_id);
            if (data) {
                setMeetings(data.meetings.filter((meeting: any) => new Date(meeting.event_start) > new Date()));
            }
        }
    }

    const handleCloseDemoScheduler = () => {
        setIsDemoSchedulerOpen(false);
        fetchMeetingDetails();
    }

    const handleMeetingEvent = async (e: any, meetingUuid: string) => {
        if (!advisor) {
            return;
        }
        const { data, error } = await getAdvisorMeeting(advisor.public_id, meetingUuid);
        if (data) {
            const meeting = data.meeting;
            setMeetings(meetings.map((m: any) => m.id === meeting.id ? meeting : m));
        }
    }

    const handleRating = async (e: any, meetingUuid: string, rating: number | null) => {
        if (!advisor) {
            return;
        }
        if (rating === null) {
            return;
        }

        const { data, error } = await advisorRateMeeting(advisor.public_id, meetingUuid, rating);
        if (data) {
            fetchMeetings();
        }
    }

    if (!advisor || !scheduler || !project) {
        return <Loading />;
    }

    return (
        <div className="page-main" id="evaluation-content">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    bgcolor: 'background.default',
                    width: '100%',
                    maxWidth: '1200px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: "40px auto",

                }}
            >
                <h3 style={{ textAlign: 'center' }}>{advisor.user?.profile?.name || advisor.name} &lt;&gt; {advisor.survey_link.project.name}</h3>
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                    Use this page to schedule a meeting between {advisor.user?.profile?.name || advisor.name} and {advisor.survey_link.project.name}
                </Typography>

                {meetings?.length > 0 && (
                    <TableContainer sx={{ marginTop: '30px', padding: "0 40px" }}>
                        <p><strong>Upcoming meetings</strong></p>
                        <AdvisorTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="center">
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Time
                                    </TableCell>
                                    <TableCell>
                                        Description
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell className="center">
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {meetings?.map((meeting: any) => (
                                    <TableRow key={meeting.uuid}>
                                        <TableCell className="center">
                                            <DateDisplay meeting={meeting} />
                                        </TableCell>
                                        <TableCell>
                                            {new Date(meeting.event_start).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}
                                        </TableCell>
                                        <TableCell>
                                            {meeting.description}
                                        </TableCell>

                                        <TableCell>
                                            {meeting.status.charAt(0).toUpperCase() + meeting.status.slice(1).toLowerCase()}
                                        </TableCell>
                                        <TableCell className="center">
                                            {(meeting.status === "completed" || new Date(meeting.event_start) < new Date()) && (
                                                <Box sx={{
                                                    minHeight: '60px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    flexDirection: 'column',
                                                    gap: '5px'
                                                }}
                                                    onClick={() => setSelectedMeeting(meeting)}>
                                                    <Rating
                                                        value={meeting.ratings?.length > 0 ? meeting.ratings[0].rating : null}
                                                        max={4}
                                                        readOnly
                                                    />
                                                    <Typography component="legend" variant="body2">
                                                        {meeting.ratings?.length > 0 ? (
                                                            ratingLabels[meeting.ratings[0].rating]
                                                        ) : "Please rate the meeting"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {(meeting.status === "scheduled" && new Date(meeting.event_start) > new Date()) && <MeetingLinks advisor={advisor} meeting={meeting} handleMeetingEvent={handleMeetingEvent} fetchMeetingDetails={fetchMeetingDetails} />}
                                            {meeting.status === "requested" && <button onClick={() => setIsDemoSchedulerOpen(true)}>Schedule a Demo</button>}

                                            {false && scheduler && (
                                                <PopupModal
                                                    url={`${scheduler.scheduling_url}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                                                    onModalClose={handleCloseDemoScheduler}
                                                    open={isDemoSchedulerOpen}
                                                    prefill={{
                                                        name: advisor?.user?.profile?.name || advisor?.name,
                                                        email: advisor?.user?.email_address || advisor?.email_address,
                                                        guests: [...scheduler?.attendees?.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])],
                                                        customAnswers: {
                                                            "a1": `https://${advisor?.survey_link?.project?.organization?.custom_domain}/${advisor?.survey_link?.project?.tag}/review/${advisor?.uuid}`
                                                        }
                                                    }}
                                                    rootElement={document.getElementById("evaluation-content") as HTMLElement}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </AdvisorTable>
                    </TableContainer>
                )}

                <InlineWidget
                    url={`${scheduler?.scheduling_url}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    prefill={{
                        name: advisor.user?.profile?.name || advisor.name,
                        email: advisor.user?.email_address || advisor.email_address,
                        guests: [...scheduler?.attendees?.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])],
                        customAnswers: {
                            "a1": `https://${advisor.survey_link.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/meeting/${advisor.uuid}`
                        }
                    }}
                    styles={{
                        height: inlineWidgetHeight,
                        width: '100%',
                        minWidth: '320px',
                        marginTop: '20px'
                    }}
                />
            </Box>
        </div>
    )
}