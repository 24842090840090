import { Box, styled, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useGetOrganizationMembers } from "../../../hooks/useGetOrganizationMembers";
import { useGetOrganizationInvitations } from "../../../hooks/useGetOrganizationInvitations";
import Loading from "../../../components/Loading";
import { InvitationControl } from "./team/InvitationControl";
import { MemberTable } from "../../company/components/collaboration/MemberTable";
import { TeamTable } from "./TeamTable";

const DealUsersBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
});


export const UsersList = ({ organization, viewerRole }: any) => {

    const [inviteActive, setInviteActive] = useState(false);

    const membersData = useGetOrganizationMembers(organization.id ?? "");
    const invitationsData = useGetOrganizationInvitations(organization.id ?? "");
    const members = membersData?.members?.filter((member: any) => !(member.role === "guest"));
    const invitations = invitationsData?.invitations?.filter((invitation: any) => !invitation.is_redeemed && !(invitation.invitee_role === "guest"));

    const handleOpenInvite = () => {
        setInviteActive(true);
    }


    return (
        
        <DealUsersBox>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px", gap: "20px" }}>
                <Box>
                </Box>
                <Box>
                    <Button 
                        variant="contained" 
                        size="small" 
                        color="primary" 
                        sx={{ whiteSpace: "nowrap", padding: "10px 20px" }}
                        onClick={() => setInviteActive(true)}
                    >
                        <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Team Members
                    </Button>
                </Box>
            </Box>

            {(!membersData.fetched || !invitationsData.fetched) &&
                <Box sx={{ paddingTop: "80px"}}>
                    <Loading />
                </Box>
            }

            {membersData.fetched && invitationsData.fetched &&
                <TeamTable membersType="members" viewerRole={viewerRole} organization={organization} members={members.filter((member: any) => member.user.role !== "admin")} invitees={invitations} reloadMembers={membersData.reloadOrganizationMembers} />
            }
            <InvitationControl organization={organization} role="collaborator" active={inviteActive} setActive={setInviteActive} reloadInvitations={invitationsData.reloadOrganizationInvitations} />
        </DealUsersBox>
    );
}
