import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { getBranding, getBrandByDomain } from "../core/services/gutcheck-api.service";
import { useLocation } from "react-router-dom";

export interface iBrandContext {
    brandContext: {
        brand: any;
        fetched: boolean;
    };
    setBrandContext: React.Dispatch<React.SetStateAction<any>>;
    loadBrandContext: () => void;
}

const BrandContext = createContext<iBrandContext>({
    brandContext: {
        brand: null,
        fetched: false
    },
    setBrandContext: () => {},
    loadBrandContext: () => {}
});

export const BrandProvider = ({ children }: { children: React.ReactNode }) => {
    const [brandState, setBrandState] = useState({
        brand: null,
        fetched: false
    });
    const location = useLocation();
    
    const hostname = useMemo(() => window.location.hostname, []);
    const appOrigin = useMemo(() => 
        process.env.REACT_APP_APP_ORIGIN?.replace(':3000',"") || 'brightloop.com',
        []
    );

    const loadBrandContext = async () => {
        const parts = hostname.split('.');
        const tag = parts[0];
    
        if (hostname.endsWith(appOrigin) && tag !== 'app') {
            const { data } = await getBranding(tag);
            if (data) {
                setBrandState({
                    brand: data.brand,
                    fetched: true
                });
            }
        } else if (!hostname.endsWith(appOrigin)) {
            const { data } = await getBrandByDomain(hostname);
            if (data) {
                setBrandState({
                    brand: data.brand,
                    fetched: true
                });
            }
        } else if (location.pathname.indexOf("/o/") === 0) {
            const organizationTag = location.pathname.replace(/^\/o\//, "").replace(/\/.*/, "");
            const { data } = await getBranding(organizationTag);
            if (data) {
                setBrandState({
                    brand: data.brand,
                    fetched: true
                });
            }
        } else {
            setBrandState({
                brand: null,
                fetched: true
            });
        }
    };

    useEffect(() => {
        loadBrandContext();
    }, [hostname, location.pathname]);

    const contextValue = useMemo(() => ({
        brandContext: brandState,
        setBrandContext: setBrandState,
        loadBrandContext
    }), [brandState]);

    return (
        <BrandContext.Provider value={contextValue}>
            {children}
        </BrandContext.Provider>
    );
};

export const useBrandContext = () => {
    return useContext(BrandContext);
}