import { useParams } from "react-router-dom/dist";
import { useBrandContext } from "../../../hooks/useBrandContext";
import { useDealContext } from "../../deal/context";
import { useSubdomain } from "../../../hooks/useSubdomain";
import { useUserContext } from "../../users";
import NotFound from "../../../components/NotFound";
import Loading from "../../../components/Loading";

export const LandingLogin = () => {

    const { brandContext } = useBrandContext();
    const { userContext } = useUserContext();
    const { fetched, dbUser } = userContext;

    if (!fetched || !brandContext.fetched) {
        return <Loading />
    }

    if (!userContext.dbUser) {
        return <NotFound />
    }

    window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${brandContext.brand.tag}.${process.env.REACT_APP_APP_ORIGIN}/login/silent`;
    return null;
}