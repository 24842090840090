import { Box, Dialog } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { ScoringList } from "../components/scoring/ScoringList";
import { CompanyTitle } from "../components/CompanyTitle";
import { ScoringGraph } from "../components/scoring/ScoringGraph";
import { useState } from "react";
import Loading from "../../../components/Loading";
import { useAdvisors } from "../../advisor/context/AdvisorsContext";

const ScoringExplanation = ({ showScoringExplanation, setShowScoringExplanation }: { showScoringExplanation: boolean, setShowScoringExplanation: (showScoringExplanation: boolean) => void }) => {
    return (
        <Dialog open={showScoringExplanation} onClose={() => setShowScoringExplanation(false)}>
            <h5>How is the score calculated?</h5>
            <p>
                The score is based on a number of factors, including:
            </p>
            <ul>
                <li>The advisor's purchasing interest</li>
                <li>The role they play in the buying process</li>
                <li>The amount they are likely to spend</li>
                <li>Their timing in the buying cycle</li>
            </ul>
            <p>
                Their score is updated everytime they provide additional feedback in a nurture cycle or through other activity on the platform.
            </p>
        </Dialog>
    );
}

export const CompanyScoring = () => {

    const [showScoringExplanation, setShowScoringExplanation] = useState(false);
    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const viewerRole = dealContext.role;
    const { advisors, loading, fetchAdvisors } = useAdvisors();

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <CompanyTitle viewerRole={viewerRole} project={project} section="Scoring" subheading="See which advisors are most engaged and likely to take your meeting." />
            <Box mb={5} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <span className="link" onClick={() => setShowScoringExplanation(true)}>How is the scoring calculated?</span>
                <ScoringGraph advisors={advisors} />
            </Box>
            <ScoringList advisors={advisors} />
            <ScoringExplanation showScoringExplanation={showScoringExplanation} setShowScoringExplanation={setShowScoringExplanation} />
        </Box>
    );
}