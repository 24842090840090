import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import ClientProvider from '../../../modules/ClientProvider';
import { IProject, IOrganization } from '../../../types';

export interface iDealContext {
    dealContext: {
        project: IProject;
        organization: IOrganization;
        surveyLink: any;
        role: any;
        fetched: boolean;
    };
    setDealContext: React.Dispatch<React.SetStateAction<any>>;
    loadDealContext: (projectTag: string) => Promise<void>;
}

export const DealContext = createContext<iDealContext>({
    dealContext: {
        project: {} as IProject,
        organization: {} as IOrganization,
        surveyLink: {},
        role: "",
        fetched: false
    },
    setDealContext: () => { },
    loadDealContext: async () => { }
});

export const useDealContext = () => {
    const context = useContext(DealContext);
    if (!context) {
        throw new Error('useDealContext must be used within a DealProvider');
    }
    return context;
};

export const DealProvider = ({ children }: { children: ReactNode }) => {
    const [dealContext, setDealContext] = useState<any>({
        project: {},
        organization: {},
        surveyLink: {},
        role: "",
        fetched: false
    });

    const { getAccessTokenSilently } = useAuth0();

    const loadDealContext = async (projectTag: string) => {
        const accessToken = await getAccessTokenSilently();
        const projectResponse = await ClientProvider.provideProjectClient().getProjectData(projectTag, accessToken);
        if (projectResponse.data) {
            setDealContext({
                ...dealContext,
                project: projectResponse.data.project,
                organization: projectResponse.data.project.organization,
                surveyLink: null,
                fetched: true
            });
        }
    };

    return (
        <DealContext.Provider value={{ dealContext, setDealContext, loadDealContext }}>
            {children}
        </DealContext.Provider>
    );
}