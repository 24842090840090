import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

interface ViewportManagerProps {
  routes: {
    pattern: string;
    content: string;
  }[];
  defaultContent?: string;
}

export const ViewportManager: React.FC<ViewportManagerProps> = ({ 
  routes, 
  defaultContent = "width=device-width, initial-scale=0.5, shrink-to-fit=no" 
}) => {
  const location = useLocation();
  
  useEffect(() => {
    // Find if current location matches any of our responsive routes
    const currentRoute = routes.find(route => 
      matchPath(route.pattern, location.pathname)
    );
    
    // Get the viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    
    // If no viewport tag exists, create one
    if (!viewport) {
      const metaElement = document.createElement('meta');
      metaElement.setAttribute('name', 'viewport');
      document.head.appendChild(metaElement);
      viewport = metaElement;
    }
    
    // Set the content based on the current route or default
    viewport.setAttribute(
      'content', 
      currentRoute ? currentRoute.content : defaultContent
    );
    
    // Cleanup - reset to default when component unmounts
    return () => {
      if (viewport) {
        viewport.setAttribute('content', defaultContent);
      }
    };
  }, [location.pathname, routes, defaultContent]);
  
  return null; // This component doesn't render anything
};
