import { Box, BoxProps, Button, Modal, Tooltip, Typography, Tabs, Tab, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import useGetRespondentHtml from "../../../hooks/useGetRespondentHtml";
import { useUserContext } from "../../users";
import useAnalytics from "../../../hooks/useAnalytics";
import { ViewpointCompany, ViewpointProfileImage } from "../../viewpoints/components";
import Loading from "../../../components/Loading";
import { useDealContext } from "../../deal/context";
import { CalendarPlus } from "lucide-react";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DateDisplay } from "../../meeting";
import { ExecTabs, ExecTab} from "../../company/styles/AdvisorStyles";
import { SelectChangeEvent } from "@mui/material";
import { AdvisorBox, DemandSummary, ScoreCircle, SummaryContainer, ViewpointSummary } from "../../company/styles/AdvisorStyles";
const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]


const MediaAnswer = ({ mediaResponse }: { mediaResponse: any }) => {
    const [showFullSummary, setShowFullSummary] = useState(false);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "top", justifyContent: "center", flexWrap: "wrap", gap: "40px" }}>
            <Box sx={{ maxHeight: "225px", maxWidth: "400px", marginBottom: "20px", flex: "0 1 400px", marginLeft: "20px" }}>
                {mediaResponse.display_as === "video" ? (
                <video controls preload="none" poster={mediaResponse.thumbnail_url} style={{ width: "400px", height: "auto", maxHeight: "225px" }}>
                        <source src={mediaResponse.video_url} type="video/mp4" />
                    </video>
                ) : (
                    <audio controls>
                        <source src={mediaResponse.audio_url} type="audio/mp3" />
                    </audio>
                )}
            </Box>
            <Box sx={{ width: "640px", height: "auto", flex: "1 1" }}>
                <Typography variant="body1" sx={{ fontWeight: "700", fontSize: "1.2rem", color: "#000000", marginBottom: "10px" }}>Key Points:</Typography>
                <div dangerouslySetInnerHTML={{ __html: mediaResponse.summary_html }} />
                <span className="link" onClick={() => {setShowFullSummary(!showFullSummary)}}>Show/hide full transcript</span>
                {showFullSummary && <blockquote>
                    {mediaResponse.text}
                </blockquote>}
            </Box>
        </Box>
    )
}

export const AdvisorProfile = ({ viewpoint, project }: any) => {

    const [activeTouchpoint, setActiveTouchpoint] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [touchpoints, setTouchpoints] = useState([{name: "Introductory Demo", sections: []}]);
    const [expandedDescription, setExpandedDescription] = useState(false);

    const handleTouchpointChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTouchpoint(newValue);
        setActiveTab(0); // Reset section tab when changing touchpoint
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const responses = viewpoint.response_json;
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    // Add handler for dropdown
    const handleDropdownChange = (event: SelectChangeEvent) => {
        setActiveTab(Number(event.target.value));
    };

    const mediaResponses = viewpoint.media_responses?.filter((response: any) => response.display_as === "video" || response.display_as === "audio");

    return (
        <AdvisorBox>
            <Box className="executive-content" sx={{ overflowY: "scroll", height: "100%" }}>
                <Box className="executive-profile">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: "20px",
                            padding: "0 20px",
                        }}
                    >
                        <ViewpointProfileImage viewpoint={viewpoint} size="medium" />

                        <Box className="viewpoint-profile-details"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "left",

                            }}>
                            <Typography sx={{ fontSize: "1.1rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                            <Typography sx={{
                                fontSize: "1.1rem", textAlign: "left",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                marginBottom: "0.5rem"
                            }}>{viewpoint.title}</Typography>
                            <ViewpointCompany viewpoint={viewpoint} style="left" />

                        </Box>
                    </Box>
                    <SummaryContainer>
                    </SummaryContainer>
                </Box>
                { responses ? (
                    <Box sx={{ padding: "20px" }}>
                        { touchpoints.length > 1 && (
                        <ExecTabs
                            value={activeTouchpoint}
                            onChange={handleTouchpointChange}
                            variant="standard"
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                mb: 2
                            }}
                        >
                            {touchpoints.map((touchpoint: any, index: number) => (
                                <ExecTab
                                    key={index}
                                    label={touchpoint.name}
                                    sx={{
                                        fontWeight: 700,
                                        textTransform: 'none',
                                        fontSize: '1rem'
                                    }}
                                />
                                ))}
                            </ExecTabs>
                        )}
                        {/* Conditional rendering based on screen size */}
                        {isSmallScreen ? (
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel>Section</InputLabel>
                                <Select
                                    value={activeTab.toString()}
                                    onChange={handleDropdownChange}
                                    label="Section"
                                    >
                                        {mediaResponses?.length > 0 && (
                                            <MenuItem key={0} value={0}>
                                                Highlights
                                            </MenuItem>
                                        )}
                                        {responses.sections.map((section: any, index: number) => (
                                        <MenuItem key={index + (mediaResponses?.length > 0 ? 1 : 0)} value={index + (mediaResponses?.length > 0 ? 1 : 0)}>
                                            {section.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <ExecTabs 
                                value={activeTab} 
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    mb: 3
                                }}
                            >
                                {mediaResponses?.length > 0 && (
                                    <ExecTab 
                                        key={0} 
                                        label="Highlights"
                                        sx={{
                                            fontWeight: 600,
                                            textTransform: 'none',
                                            minWidth: "100px",
                                            width: "auto"   
                                        }}
                                    />
                                )}
                                {responses.sections.map((section: any, index: number) => (
                                    section.name !== "Notes" && (
                                    <ExecTab 
                                        key={index + (mediaResponses?.length > 0 ? 1 : 0)} 
                                        label={section.name}
                                        sx={{
                                            fontWeight: 600,
                                            textTransform: 'none',
                                            minWidth: "100px",
                                            width: "auto"
                                        }}
                                    />
                                    )
                                ))}
                            </ExecTabs>
                        )}
                        {
                            mediaResponses.length > 0 && (
                                <Box key={0} role="tabpanel" hidden={activeTab !== 0}>
                                    {mediaResponses
                                        .sort((a: any, b: any) => {
                                            // Find the sections and questions that match these media responses
                                            const findQuestionIndex = (response: any) => {
                                                for (const section of responses.sections) {
                                                    const questionIndex = section.questions.findIndex(
                                                        (q: any) => q.answers[0]?.includes(response.uuid) || q.answers[1]?.includes(response.uuid)
                                                    );
                                                    if (questionIndex !== -1) {
                                                        return questionIndex;
                                                    }
                                                }
                                                return Infinity; // Put items without matching questions at the end
                                            };
                                            
                                            return findQuestionIndex(a) - findQuestionIndex(b);
                                        })
                                        .map((response: any, index: number) => (
                                            <Box key={index} sx={{ marginBottom: "1.5rem" }}>
                                                <Typography sx={{ 
                                                    fontWeight: "900",
                                                    fontSize: "1.2rem",
                                                    color: "#000000"
                                                }}>
                                                    {response.title}
                                                </Typography>
                                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "20px", margin: "20px 0" }}>
                                                    <MediaAnswer mediaResponse={response} />
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            )
                        }
                        {responses.sections.map((section: any, sectionIndex: number) => (
                            <Box 
                                key={sectionIndex + (mediaResponses?.length > 0 ? 1 : 0)}
                                role="tabpanel"
                                hidden={activeTab !== sectionIndex + (mediaResponses?.length > 0 ? 1 : 0)}
                            >
                                {activeTab === sectionIndex + (mediaResponses?.length > 0 ? 1 : 0) && section.name !== "Notes" && (
                                    <Box>
                                        {(section.name === "Demand" || section.name === "Buyer" || section.name === viewpoint.touchpoint.product_description_section) && viewpoint.touchpoint.product_description && (
                                            <Box sx={{ marginBottom: "40px" }}>
                                                <Typography sx={{ 
                                                    fontWeight: "900",
                                                    fontSize: "1.2rem",
                                                    color: "#000000"
                                                }}>
                                                    Please read the following product description
                                                </Typography>
                                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0", position: "relative" }}>
                                                    <Typography sx={{
                                                        marginTop: "0.25rem",
                                                        fontSize: "1rem",
                                                        maxHeight: expandedDescription ? "none" : "6em",
                                                        overflow: "hidden",
                                                        position: "relative",
                                                    }}> 
                                                        <div dangerouslySetInnerHTML={{ __html: viewpoint.touchpoint.product_description }} />
                                                    </Typography>
                                                    {!expandedDescription && (
                                                        <Box sx={{
                                                            position: "absolute",
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            height: "100px",
                                                            background: "linear-gradient(transparent, white)",
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "center",
                                                            paddingBottom: "10px"
                                                        }}>
                                                            <Button 
                                                                onClick={() => setExpandedDescription(true)}
                                                                variant="outlined"
                                                                sx={{ 
                                                                    backgroundColor: "white",
                                                                    "&:hover": { backgroundColor: "#f5f5f5" }
                                                                }}
                                                            >
                                                                Expand
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                        {section.questions.map((item: any, questionIndex: number) => { 
                                            const mediaMatch = item.answers[0]?.match(/^videoUUID:(.*)$/) || item.answers[1]?.match(/^audioUUID:(.*)$/);
                                            const mediaResponse = mediaMatch ? mediaResponses?.find((response: any) => response.uuid === mediaMatch[1] && (response.display_as === "video" || response.display_as === "audio")) : null;
                                            if (!viewpoint.touchpoint.suppressed_questions?.includes(item.question) && (item.answers.length > 0 && item.answers[0] !== "")) {
                                            return (
                                            <Box key={questionIndex} sx={{ marginBottom: "40px" }}>
                                                <Typography sx={{ 
                                                    fontWeight: "900",
                                                    fontSize: "1.2rem",
                                                    color: "#000000"
                                                }}>
                                                    {item.question}
                                                </Typography>
                                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0" }}>
                                                { mediaResponse ? 
                                                    <MediaAnswer mediaResponse={mediaResponse} /> :
                                                <Typography sx={{ 
                                                    marginTop: "0.25rem",
                                                    fontSize: "1rem"
                                                }}> 
                                                    {Array.isArray(item.answers) 
                                                        ? item.answers.join(", ") 
                                                            : item.answers}
                                                    </Typography>
                                                }
                                                </Box>
                                            </Box>
                                            )
                                            }
                                        })}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Loading />
                )}
            </Box>
        </AdvisorBox>
    );
}

export const AdvisorViewpoint = ({ viewpoint }: { viewpoint: any }) => {
    const { dealContext } = useDealContext();
    const project = dealContext.project;

    if (!project) return null;
    if (!viewpoint) return null;

    return (
            <Box>
                <AdvisorProfile project={project} viewpoint={viewpoint} />
            </Box>
    );
}   