import { useState, useEffect, useRef } from "react";
import { Box, Typography, Chip } from "@mui/material";
import { AdvisorProfileImage } from "../../advisor/components/AdvisorProfileImage";

export const FirmAdvisorGridItem = ({ advisor, user, style, isVisible, type = "expert", handleClick }: any) => {
    const [isRemoved, setIsRemoved] = useState(false);
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isVisible) {
            const timer = setTimeout(() => {
                if (itemRef.current) {
                    setIsRemoved(true);
                }
            }, 100);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setIsRemoved(false);
        }
    }, [isVisible]);

    return (
        <div 
            ref={itemRef}
            style={{ 
                opacity: 1,
                transform: 'scale(1)',
                transition: 'all 300ms ease-in-out',
                position: 'relative',
                width: 'auto',
                height: 'auto',
                pointerEvents: 'auto',
                zIndex: 1,
                visibility: 'visible'
            }}
        >
                <div>
                        <Box key={advisor?.viewpoint?.uuid}
                            className={`viewpoint-profile face-grid`}
                            onClick={() => handleClick(advisor?.survey_link?.project?.tag, advisor?.survey_link?.project?.experience, advisor?.viewpoints[0]?.uuid, advisor?.uuid)}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "1rem",
                                cursor: "pointer",
                                position: "relative",
                                width: "110px",
                                gap: "1rem",
                                "& img": {
                                    opacity: 1,
                                    filter: "none"
                                },
                                "& img.li-icon": {
                                    filter: "none",
                                    opacity: 1
                                },
                                "&.excluded": {
                                    filter: "grayscale(1)",
                                    opacity: 0.5
                                }
                            }}
                        >
                            <AdvisorProfileImage advisor={advisor} />
                            <Box className="viewpoint-profile-details"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "110px"
                                }}>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.name}</Typography>
                                <Typography sx={{
                                    fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical"
                                }}>{advisor?.user?.profile?.title}</Typography>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.company?.name}</Typography>
                                <Chip size="small" label={advisor?.survey_link?.project?.name} />
                            </Box>

                            {user?.priorVisit && advisor?.created_at > user.priorVisit &&
                                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#F0DE42", color: "black", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                                    New
                                </Box>
                            }
                        </Box>
                </div>
        </div>
    );
};

