import { Box, styled, TableContainer, Button, Table, TableHead, TableRow, TableBody, TableCell, Menu, MenuItem, Typography, Fade } from "@mui/material";
import { UserProfileImage } from "../../../users";
import questionMarkSvg from "../../../../assets/icons/question-mark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import ClientProvider from "../../../../modules/ClientProvider";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { approveInvitation, rejectInvitation } from "../../../../core/services/gutcheck-api.service";


const StyledMembersTable = styled(TableContainer)(({ theme }) => ({
    width: "100%",
    overflow: "auto",
    //maxHeight: "400px",
    borderRadius: "4px",
    marginTop: "10px",
    marginBottom: "40px",
    "& th": {
        fontWeight: "bold",
        "&.center": {
            textAlign: "center",
        },
        "&.sortable": {
            cursor: "pointer",
        }
    },
    "& td.center": {
        textAlign: "center",
    },
    "& .profile-info": {
        display: "flex",
        alignItems: "center",
        gap: "10px",

    }
}));

const InviteeProfileBox = styled('div')({
    position: 'relative',
    '& img.envelope': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '18px',
    }
});

const userRoles: { [key: string]: string } = {
    admin: "Admin",
    contributor: "Team Member",
    guest: "Guest"
}

const DealUsersBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
});

const InviteeActions = ({ project, invitation, reloadInvitations, removeInvitation }: any) => {

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);
    const projectClient = ClientProvider.provideProjectClient();

    const { getAccessTokenSilently } = useAuth0();

    const [confirmation, setConfirmation] = useState({
        open: false,
        success: false,
        onClose: () => { },
        onConfirm: () => { },
        title: "",
        message: "",
    })


    const handleRejectClick = () => {
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Reject access for ${invitation.invitee_name} (${invitation.invitee_email})?`,
            onClose: handleConfirmationClose,
            onConfirm: handleRejectConfirmation,
            title: "Reject Request"
        });
        setMenuAnchor(null);
    }

    const handleRejectConfirmation = async () => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await rejectInvitation(invitation.invitation_code, accessToken);

        if (error) throw error;

        if (data) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Request rejected successfully",
            });

            removeInvitation(invitation);
        }
    }

    const handleResendClick = () => {
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Resend invitation to ${invitation.invitee_name} (${invitation.invitee_email})?`,
            onClose: handleConfirmationClose,
            onConfirm: handleResendConfirmation,
            title: "Resend Invitation"
        });
        setMenuAnchor(null);
    }

    const handleResendConfirmation = async () => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.resendInvitation(project.id, invitation.id, accessToken);

        if (error) {
            console.log(error);
        }

        if (data) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Invitation resent successfully",
            });
        }
    }

    const handleConfirmationClose = () => {
        setConfirmation({
            ...confirmation,
            open: false,
            success: false,
            onClose: () => { },
            onConfirm: () => { },
            title: "",
            message: "",
        });
    }

    return (
        <Box>
            <Button onClick={(e) => setMenuAnchor(e.currentTarget)} sx={{ fontSize: "28px" }}><FontAwesomeIcon icon="ellipsis" /></Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={handleResendClick}>Re-send Invitation</MenuItem>
                <MenuItem onClick={handleRejectClick} sx={{ color: "red", borderTop: "1px solid #ccc" }}>Remove</MenuItem>

            </Menu>
            <ConfirmationModal {...confirmation} />
        </Box>
    )
}

const InviteeRoleActions = ({ project, invitation, updateInvitation }: any) => {

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);
    const projectClient = ClientProvider.provideProjectClient();
    const [inviteeRole, setInviteeRole] = useState(invitation.invitee_role);

    const { getAccessTokenSilently } = useAuth0();

    const [confirmation, setConfirmation] = useState({
        open: false,
        success: false,
        onClose: () => { },
        onConfirm: () => { },
        title: "",
        message: "",
    })

    const handleRoleClick = (role: string) => {

        if (role === inviteeRole) {
            return;
        }
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Change ${invitation.invitee_name} (${invitation.invitee_email}) to ${role}?`,
            onClose: handleConfirmationClose,
            onConfirm: () => handleRoleConfirmation(role),
            title: "Change Role"
        });
        setMenuAnchor(null);
    }

    const handleRoleConfirmation = async (role: string) => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.updateInvitationRole(project.id, invitation.id, role, accessToken);

        if (error) {
            console.log(error);
        }

        if (data.invitation) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Role updated successfully",
            });
            setInviteeRole(role);
            updateInvitation(data.invitation);
        }
    }

    const handleConfirmationClose = () => {
        setConfirmation({
            ...confirmation,
            open: false,
            success: false,
        });
    }

    if (inviteeRole === "guest") {
        return (
            <Box>
                <span>{userRoles[inviteeRole]}</span>
            </Box>
        )
    }


    return (
        <Box>
            <Box sx={{ cursor: "pointer" }} onClick={(e) => setMenuAnchor(e.currentTarget)}>{userRoles[inviteeRole]} <FontAwesomeIcon icon="angle-down" /></Box>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem selected={inviteeRole === "admin"} onClick={() => handleRoleClick("admin")}>Admin</MenuItem>
                <MenuItem selected={inviteeRole === "contributor"} onClick={() => handleRoleClick("contributor")}>Team Member</MenuItem>
                <MenuItem selected={inviteeRole === "guest"} onClick={() => handleRoleClick("guest")}>Guest</MenuItem>
            </Menu>
            <ConfirmationModal {...confirmation} />
        </Box>
    )
}

const UserActions = ({ project, user, reloadMembers, removeMember }: any) => {

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);

    const projectClient = ClientProvider.provideProjectClient();

    const [confirmation, setConfirmation] = useState({
        open: false,
        success: false,
        onClose: () => { },
        onConfirm: () => { },
        title: "",
        message: "",
    })

    const handleRemoveClick = () => {
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Remove ${user.name} (${user.email_address}) from ${project.name}?`,
            onClose: handleConfirmationClose,
            onConfirm: handleRemoveConfirmation,
            title: "Remove User"
        });
        setMenuAnchor(null);
    }

    const handleRemoveConfirmation = async () => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.removeMemberFromProject(project.id, user.id, accessToken);

        if (error) {
            console.log(error);
        }

        if (data.project) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "User removed successfully",
            });
            removeMember(user);
        }
    }

    const handleConfirmationClose = () => {
        setConfirmation({
            ...confirmation,
            open: false,
            success: false,
            onClose: () => { },
            onConfirm: () => { },
            title: "",
            message: "",
        });
    }

    const { getAccessTokenSilently } = useAuth0();


    return (
        <Box>
            <Button onClick={(e) => setMenuAnchor(e.currentTarget)} sx={{ fontSize: "28px" }}><FontAwesomeIcon icon="ellipsis" /></Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem sx={{ color: "red" }} onClick={handleRemoveClick}>Remove</MenuItem>

            </Menu>
            <ConfirmationModal {...confirmation} />
        </Box>
    )
}

const UserRoleActions = ({ project, member, updateMember }: any) => {

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);

    const projectClient = ClientProvider.provideProjectClient();

    const [memberRole, setMemberRole] = useState(member.role);

    const [confirmation, setConfirmation] = useState({
        open: false,
        success: false,
        onClose: () => { },
        onConfirm: () => { },
        title: "",
        message: "",
    })

    const handleRoleClick = (role: string) => {

        if (role === memberRole) {
            return;
        }
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Change ${member.user.name} (${member.user.email_address}) to ${role}?`,
            onClose: handleConfirmationClose,
            onConfirm: () => handleRoleConfirmation(role),
            title: "Change Role"
        });
        setMenuAnchor(null);
    }

    const handleRoleConfirmation = async (role: string) => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.updateMemberRole(project.id, member.user.id, role, accessToken);

        if (error) {
            console.log(error);
        }

        if (data.membership) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Role updated successfully",
            });
            setMemberRole(role);
            updateMember(data.membership);
        }
    }

    const handleConfirmationClose = () => {
        setConfirmation({
            ...confirmation,
            open: false,
            onClose: () => { },
            onConfirm: () => { },
            title: "",
            message: "",
            success: false,
        });
    }

    const { getAccessTokenSilently } = useAuth0();

    if (member.role === "guest") {
        return (
            <Box>
                <span>{userRoles[member.role]}</span>
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{ cursor: "pointer" }} onClick={(e) => setMenuAnchor(e.currentTarget)}>{userRoles[memberRole]} <FontAwesomeIcon icon="angle-down" /></Box>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem selected={memberRole === "admin"} onClick={() => handleRoleClick("admin")}>Admin</MenuItem>
                <MenuItem selected={memberRole === "contributor"} onClick={() => handleRoleClick("contributor")}>Team Member</MenuItem>
                <MenuItem selected={memberRole === "guest"} onClick={() => handleRoleClick("guest")}>Guest</MenuItem>

            </Menu>
            <ConfirmationModal {...confirmation} />
        </Box>
    )
}

const PendingActions = ({ project, invitation, reloadInvitations }: any) => {

    const [action, setAction] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    const [confirmation, setConfirmation] = useState({
        open: false,
        success: false,
        onClose: () => { },
        onConfirm: () => { },
        title: "",
        message: "",
    })

    const handleApproveClick = () => {
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Approve access for ${invitation.invitee_name} (${invitation.invitee_email})?`,
            onClose: handleConfirmationClose,
            onConfirm: handleApproveConfirmation,
            title: "Approve Request"
        });
    }

    const handleApproveConfirmation = async () => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await approveInvitation(invitation.invitation_code, accessToken);

        if (error) throw error;

        if (data) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Request approved successfully",
            });
            reloadInvitations();

        }
    }

    const handleRejectClick = () => {
        setConfirmation({
            ...confirmation,
            open: true,
            message: `Reject access for ${invitation.invitee_name} (${invitation.invitee_email})?`,
            onClose: handleConfirmationClose,
            onConfirm: handleRejectConfirmation,
            title: "Reject Request"
        });
    }

    const handleRejectConfirmation = async () => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await rejectInvitation(invitation.invitation_code, accessToken);

        if (error) throw error;

        if (data) {
            setConfirmation({
                ...confirmation,
                open: false,
                message: "Request rejected successfully",
            });

            reloadInvitations();
        }
    }

    const handleConfirmationClose = () => {
        setConfirmation({
            ...confirmation,
            open: false,
        });
        reloadInvitations();
    }

    return (
        <Box className="flex-row" sx={{ gap: "10px" }}>
            <Button size="small" variant="contained" color="success" sx={{ color: "#ffffff" }} onClick={handleApproveClick}>Approve</Button>
            <Button size="small" variant="outlined" color="error" onClick={handleRejectClick}>Reject</Button>
            <ConfirmationModal {...confirmation} />
        </Box>
    )
}

export const MemberTable = ({ membersType, viewerRole, project, members, pendingInvitations, invitees, reloadMembers, reloadInvitations }: any) => {


    const [currentSort, setCurrentSort] = useState({
        column: "last_seen",
        direction: "desc"
    });

    const users = members.map((member: any) => {
        return {
            email_address: member.user.email_address?.toLowerCase() ?? "",
            name: member.user.name?.toLowerCase() ?? "",
            role: member.role?.toLowerCase() ?? "",
            company: member.user?.company?.name?.toLowerCase() ?? member.user.email_address.split("@")[1]?.toLowerCase(),
            first_seen: new Date(member.created_at),
            last_seen: new Date(member.latest_visit ?? member.created_at),
            num_visits: member.num_visits ?? 0,
            type: "member",
            image_url: member.user.image_url,
            member: member
        }
    });

    const invitations = invitees.map((invitation: any) => {
        return {
            email_address: invitation.invitee_email?.toLowerCase() ?? "",
            name: invitation.invitee_name?.toLowerCase() ?? "",
            role: invitation.invitee_role?.toLowerCase() ?? "",
            company: invitation?.invitee_organization?.toLowerCase() ?? invitation.invitee_email.split("@")[1],
            first_seen: new Date("1970-01-01"),
            last_seen: new Date("1970-01-01"),
            num_visits: 0,
            type: "invitation",
            image_url: null,
            invitation: invitation
        }
    });

    const allUsers = [...users, ...invitations];
    const [sortedUsers, setSortedUsers] = useState(allUsers.sort((a, b) => {

        return a.last_seen < b.last_seen ? 1 : -1

    }));


    const handleSort = (column: string, defaultDirection: string) => {

        let direction = defaultDirection;
        if (currentSort.column === column) {
            currentSort.direction === "asc" ? direction = "desc" : direction = "asc";
        }

        setCurrentSort({
            column: column,
            direction: direction
        });

        let sorted = [...sortedUsers];

        sorted = sorted.sort((a, b) => {

            if (column === "name" || column === "role" || column === "company") {
                if (direction === "asc") {
                    if (!a[column]) {
                        return 1;
                    } else if (!b[column]) {
                        return -1;
                    } else {
                        return a[column] > b[column] ? 1 : -1;
                    }
                } else {
                    if (!a[column]) {
                        return -1;
                    } else if (!b[column]) {
                        return 1;
                    } else {
                        return a[column] < b[column] ? 1 : -1;
                    }
                }
            } else {
                if (direction === "asc") {
                    return a[column] > b[column] ? 1 : -1;
                } else {
                    return a[column] < b[column] ? 1 : -1;
                }
            }
        });

        setSortedUsers(sorted);
    }

    const updateMember = (member: any) => {
        const index = sortedUsers.findIndex((user: any) => {
            return user.email_address === member.user.email_address;
        });

        if (index > -1) {
            let updated = [...sortedUsers];
            updated[index] = {
                email_address: member.user.email_address,
                name: member.user.name,
                role: member.role,
                company: member.user.company,
                first_seen: member.user.created_at,
                last_seen: member.user.updated_at,
                type: "member",
                image_url: member.user.image_url,
                member: member
            };
            setSortedUsers(updated);
        }
    }

    const removeMember = (member: any) => {
        const index = sortedUsers.findIndex((user: any) => {
            return user.email_address === member.email_address;
        });

        if (index > -1) {
            let updated = [...sortedUsers];
            updated.splice(index, 1);
            setSortedUsers(updated);
        }
    }

    const updateInvitation = (invitation: any) => {
        const index = sortedUsers.findIndex((user: any) => {
            return user.email_address === invitation.invitee_email;
        });

        if (index > -1) {
            let updated = [...sortedUsers];
            updated[index] = {
                email_address: invitation.invitee_email,
                name: invitation.invitee_name,
                role: invitation.invitee_role,
                company: null,
                first_seen: null,
                last_seen: null,
                type: "invitation",
                image_url: null,
                invitation: invitation
            };
            setSortedUsers(updated);
        }
    }

    const removeInvitation = (invitation: any) => {
        const index = sortedUsers.findIndex((user: any) => {
            return user.email_address === invitation.invitee_email;
        });

        if (index > -1) {
            const updated = [...sortedUsers];
            updated.splice(index, 1);
            setSortedUsers(updated);
        }
    }


    return (
        <StyledMembersTable>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="sortable" sx={{ width: "25%" }} onClick={() => handleSort("name", "asc")}>User {currentSort.column === "name" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        <TableCell className="sortable" sx={{ width: "15%" }} onClick={() => handleSort("company", "asc")}>Company {currentSort.column === "company" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        <TableCell className="sortable" sx={{ width: "20%" }} onClick={() => handleSort("role", "asc")}>Role {currentSort.column === "role" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        <TableCell className="center sortable" sx={{ width: "15%" }} onClick={() => handleSort("first_seen", "desc")}>First Seen {currentSort.column === "first_seen" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        <TableCell className="center sortable" sx={{ width: "15%" }} onClick={() => handleSort("last_seen", "desc")}>Last Seen {currentSort.column === "last_seen" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        <TableCell className="center sortable" sx={{ width: "10%" }} onClick={() => handleSort("num_visits", "desc")}>Visits {currentSort.column === "num_visits" && <FontAwesomeIcon icon="sort" />}</TableCell>
                        {viewerRole === "admin" && <TableCell className="center" sx={{ width: "50%" }}>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pendingInvitations?.map((invitation: any) => (

                        <TableRow key={invitation.id}>
                            <TableCell>
                                <Box className="profile-info">
                                    <InviteeProfileBox>
                                        <UserProfileImage size="small" user={{ email_address: invitation.invitee_email, name: invitation.invitee_name, image_url: null }} key={invitation.invitee_email} />
                                    </InviteeProfileBox>

                                    <Box>
                                        {invitation.invitee_name ?? "---"}<br />
                                        {invitation.invitee_email}
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell>{invitation.invitee_organization ?? invitation.invitee_email.split("@")[1]}</TableCell>
                            <TableCell>{userRoles[invitation.invitee_role]}</TableCell>
                            <TableCell className="center">{new Date(invitation.created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</TableCell>
                            <TableCell className="center">---</TableCell>
                            {viewerRole === "admin" &&
                                <TableCell colSpan={2} sx={{ textAlign: "right" }}>
                                    <PendingActions project={project} invitation={invitation} reloadInvitations={reloadInvitations} />
                                </TableCell>
                            }
                            {viewerRole !== "admin" &&
                                <TableCell colSpan={2} sx={{ textAlign: "right" }}>
                                    <span>Awaiting<br />Approval</span>
                                </TableCell>
                            }
                        </TableRow>
                    ))}
                    {sortedUsers?.map((user: any) => {
                        if (user.type === "member") {
                            const member = user.member;
                            return (
                                <TableRow key={member.id}>
                                    <TableCell>
                                        <Box className="profile-info">
                                            <InviteeProfileBox>
                                                <UserProfileImage size="small" user={member.user} key={member.user.email_address} />
                                            </InviteeProfileBox>
                                            <Box>
                                                {member.user.name && member.user.name !== "" ? member.user.name : "---"}<br />
                                                {member.user.email_address}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{member.user?.company?.name ?? member.user.email_address.split("@")[1]}</TableCell>
                                    <TableCell>
                                        {viewerRole === "admin" && <UserRoleActions project={project} member={member} reloadMembers={reloadMembers} updateMember={updateMember} />}
                                        {viewerRole !== "admin" && userRoles[member.role]}
                                    </TableCell>
                                    <TableCell className="center">{new Date(member.created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</TableCell>
                                    <TableCell className="center">{new Date(member.latest_visit ?? member.created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</TableCell>
                                    <TableCell className="center">{member.num_visits ?? "---"}</TableCell>
                                    {viewerRole === "admin" &&
                                        <TableCell className="center">
                                            <UserActions project={project} user={member.user} reloadMembers={reloadMembers} updateMember={updateMember} removeMember={removeMember} />
                                        </TableCell>
                                    }
                                </TableRow>
                            )
                        } else if (user.type === "invitation") {
                            const invitation = user.invitation;

                            return (
                                <TableRow key={invitation.id}>
                                    <TableCell>
                                        <Box className="profile-info">
                                            <InviteeProfileBox>
                                                <UserProfileImage pending={true} size="small" user={{ email_address: invitation.invitee_email, name: invitation.invitee_name, image_url: null }} key={invitation.invitee_email} />
                                                <img className="envelope" src={questionMarkSvg.toString()} />
                                            </InviteeProfileBox>

                                            <Box>
                                                {invitation.invitee_name && invitation.invitee_name !== "" ? invitation.invitee_name : "---"}<br />
                                                {invitation.invitee_email}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{invitation.invitee_organization ?? invitation.invitee_email.split("@")[1]}</TableCell>
                                    <TableCell>
                                        {viewerRole === "admin" && <InviteeRoleActions project={project} invitation={invitation} reloadInvitations={reloadInvitations} updateInvitation={updateInvitation} />}
                                        {viewerRole !== "admin" && invitation.invitee_role}
                                    </TableCell>
                                    <TableCell className="center" >---</TableCell>
                                    <TableCell className="center" >---</TableCell>
                                    <TableCell className="center">---</TableCell>
                                    {viewerRole === "admin" &&
                                        <TableCell className="center" >
                                            <InviteeActions project={project} invitation={invitation} reloadInvitations={reloadInvitations} removeInvitation={removeInvitation} />
                                        </TableCell>
                                    }
                                </TableRow>
                            )
                        }
                    })}


                </TableBody>
            </Table>
            {sortedUsers.length === 0 &&
                <Fade in={true}>
                    <Box className="display-box" sx={{ marginTop: "40px", maxWidth: "400px", margin: "40px auto" }}>
                        { membersType === "guests" && <Typography sx={{ textAlign: "center" }}>No external guests have access to this deal.</Typography>}
                        { membersType === "members" && <Typography sx={{ textAlign: "center" }}>No team members have been added to this deal.</Typography>}
                    </Box>
                </Fade>
            }
        </StyledMembersTable>
    );
}