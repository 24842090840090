import TextViewer from "../../../components/TextViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { EvaluationSummary } from "../components/EvaluationSummary";
import { EvaluationActivity } from "../components/EvaluationActivity";
import { updateAdvisor } from "../../../core/services/gutcheck-api.service";
import { v4 } from "uuid";
import { useGetViewpointHtml } from "../hooks";
import { DocumentTitle } from "../../../components/DocumentTitle";
import useAnalytics from "../../../hooks/useAnalytics";
import { DemoScheduler } from "../components/DemoScheduler";
import { ExecutiveViewpoint } from "../../../features/deal/components/DealExecutive";
import { AdvisorViewpoint } from "../components/AdvisorViewpoint";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 700,
    width: '90%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '3px solid #feb70f',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflowX: 'auto',
    padding: 0
};

const viewpointStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    maxWidth: 1200,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '80%',
    overflow: 'scroll'
}

const interestFields = [
    {
        key: "interest_demo",
        text: "Demo",
        count: 0,
        countText: "want a"
    },
    {
        key: "interest_beta",
        text: "Beta",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_investor",
        text: "Co-Invest",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_advisor",
        text: "Advise",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_followup",
        text: "Follow Up",
        count: 0,
        countText: 'open to'
    },
];

const EvaluateModal = ({ advisor }: any) => {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    }

    const project = advisor?.survey_link?.project;
    const organization = project?.organization;

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style} className="expert-overview-modal">
                <div className="modal-text">
                {advisor.survey_link.overview_text ? <TextViewer content={advisor.survey_link.overview_text} /> : <>
                    <p>BrightLoop helps venture firms gather expert viewpoints on startups they are considering funding and invest alongside them.</p>

                    <p>We've partnered with <a href={organization.website} target="_blank">{organization.name}</a>, to evaluate an investment in a new {project.public_title} and we thought your viewpoint would be valuable.</p>

                    <p>If this is up your alley, the video survey in the Evaluate section will take approximately 15 minutes to complete. It will ask a few background questions, describe the new product, then get your feedback on the product.</p>

                    <p>The deal is currently in diligence. This means that investor has expressed an intent to invest in the company and seeks confirmatory feedback. You'll learn the name immediately after you add your viewpoint and will likely have an option to co-invest or advise if they win the deal. Both of these are optional, of course, and at your discretion.</p>

                    <p>Want more info? <a href="https://www.brightloop.ai/" target="_blank">Visit our website</a>.</p>

                    <p>Ready to start? Click the button to add your viewpoint.</p>
                </>}
                </div>
                <div className="modal-button-wrapper">
                    <div className="btn" onClick={handleClose}>Get Started</div>
                </div>
            </Box>
        </Modal>
    )
}

const ContentInit = ({ advisor }: any) => {

    const project = advisor.survey_link.project;
    const { trackAdvisorPage, trackAdvisorEvent } = useAnalytics();

    useEffect(() => {
        trackAdvisorPage(advisor, 'expert flow - evaluate - initial', { passthrough: true });
    }, []);

    const handleClick = async () => {
        trackAdvisorEvent(
            advisor,
            'Started expert evaluation',
            { passthrough: false },
            () => { window.location.replace(`${advisor.survey_link.survey_url}?blid=${advisor.public_id}&intro=skip&${advisor.params}`) }
        );
        return <></>;
    }

    const linkDescription = [];
    if (advisor.survey_link.duration_description) {
        linkDescription.push(advisor.survey_link.duration_description);
    }
    if (advisor.survey_link.reward_description) {
        linkDescription.push(advisor.survey_link.reward_description);
    }

    return (
        <>
            <div className="display-box white">
                <h4>Evaluation</h4>
                <div className="launch-evaluation">
                    {linkDescription.length > 0 && <p className="evaluation-expectations">({linkDescription.join(", ")})</p>}
                    <div className="btn" onClick={handleClick}>Start Evaluation</div>
                </div>
            </div>
            <div className="evaluation-intro">
                <p>Help BrightLoop and {project.organization.name} vet a time-sensitive investment in a {project.public_title}. You'll get:</p>
                <div className="content-highlights">
                    <div className="highlight">
                        <div className="highlight-icon"><FontAwesomeIcon icon="magnifying-glass-chart" /></div>
                        <p><strong>An early look at new technology</strong><br />
                            being developed by top founders and funded by top venture firms.</p>
                    </div>
                    <div className="highlight">
                        <div className="highlight-icon"><FontAwesomeIcon icon="money-bill-trend-up" /></div>
                        <p><strong>An opportunity to co-invest</strong><br />
                            if {project.organization.name} invests, and your viewpoint helps the investment decision.</p>
                    </div>
                    <div className="highlight">
                        <div className="highlight-icon"><FontAwesomeIcon icon="hand-holding-medical" /></div>
                        <p><strong>New opportunities to advise</strong><br />
                            if {project.organization.name} invets, and you hit it off with the founders.</p>
                    </div>
                </div>
            </div>
            <EvaluateModal advisor={advisor} />
        </>
    );
}

const ContentScreened = ({ advisor }: any) => {

    const { trackAdvisorPage } = useAnalytics();

    useEffect(() => {
        trackAdvisorPage(advisor, 'expert flow - evaluate - screened out', { passthrough: true });
    }, []);

    return (
        <div className="display-box white">
            <p>Thank you for your time. Unfortunately, you do not qualify for this survey. Please check back for future opportunities.</p>
        </div>
    );
}

const ContentPending = ({ advisor }: any) => {

    const [emailAddress, setEmailAddress] = useState(advisor.email_address);
    const [showEmailForm, setShowEmailForm] = useState(!advisor.email_address || advisor.email_address === "");
    const [showSuccess, setShowSuccess] = useState(false);
    const { trackAdvisorPage } = useAnalytics();

    const project = advisor.survey_link.project;

    useEffect(() => {
        trackAdvisorPage(advisor, 'expert flow - evaluate - completed', { passthrough: true });
    }, []);

    const handleEmailSubmit = async () => {
        const { data, error } = await updateAdvisor(advisor.public_id, { email_address: emailAddress });
        setShowEmailForm(false);
        setShowSuccess(true);
    }

    const handleEmailChange = (e: any) => {
        setEmailAddress(e.target.value);
    }

    return (
        <div className="display-box white">
            <div className="evaluation-complete">
                <h5>Thank you for your viewpoint!</h5>
                <p>{project.organization.name} will review it shortly. We'll show you the
                    name of the company once an investment decision is made. If your viewpoint
                    helped inform the investment decision or set company strategy, you'll get
                    an invite to co-invest or advise.</p>
                <p>We'll also let you know:</p>
                <ul>
                    <li>When your viewpoint is available for review</li>
                    <li>When there are important updates to the deal</li>
                    <li>If and when you are granted access to investment details</li>
                </ul>
                {showEmailForm &&
                    <div className="evaluation-email-wrapper">
                        <p>Want to stay in the loop? Add your email address to receive updates.</p>
                        <div>
                            <TextField className="evaluation-email" type="text" id="email_address" label="Email Address" variant="outlined" name="email_address" value={emailAddress} onChange={handleEmailChange} />
                        </div>
                        <div className="btn" onClick={handleEmailSubmit} >Add Email</div>
                    </div>
                }
                {showSuccess && <p>Your email has been added. Thank you!</p>}
            </div>
        </div>
    );
}

const ContentComplete = ({ advisor }: any) => {
    return (
        <>
            {!advisor.viewpoints[0] && <ContentPending advisor={advisor} />}
            {advisor.viewpoints[0] && <ContentProcessed advisor={advisor} />}
            {advisor.survey_link.project.expert_analysis_url &&
                <div className="evaluation-analysis">
                    <h5>Sneak Peek of Viewpoints</h5>
                    <p>While we're collecting viewpoints, here's a sneak peek into what your peers are saying.</p>
                    <iframe src={advisor.survey_link.project.expert_analysis_url} width="100%" height="3500" frameBorder="0"></iframe>
                </div>
            }
        </>
    );
}

const ContentProcessed = ({ advisor }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const viewpoint = advisor.viewpoints[0];

    const [viewpointHtml] = useGetViewpointHtml(advisor.public_id);

    return (
        <>
            <h5>Your Viewpoint</h5>
            <p>Here's how you answered questions on whether you would...</p>
            <div className="display-box evaluation-viewpoint white">
                <div className="evaluation-complete">
                    <TableContainer className="gc-respondent-table-container">
                        <Table stickyHeader className="gc-respondent-table">
                            <TableHead key={v4()}>
                                <TableRow key={v4()}>
                                    <TableCell className="pill pill-lg" key={v4()}>Purchase?</TableCell>

                                    {interestFields.map((interestField) => {
                                        return (
                                            <TableCell className="pill pill-sm" key={v4()}>
                                                <div className="pill-detail">
                                                    {interestField.text}?
                                                </div>
                                            </TableCell>
                                        )
                                    })}

                                </TableRow>
                            </TableHead>
                            <TableBody key={v4()}>

                                <TableRow hover key={v4()}>
                                    <TableCell key={v4()} className="pill pill-lg">
                                        <div className={viewpoint.demand.replace(/(Yes|No), /g, "").replace(/ /g, "").toLowerCase()}>{viewpoint.demand.replace(/(Yes|No), /g, "")}</div>
                                    </TableCell>

                                    {interestFields.map((interestField) => {
                                        //if (respondent.interests[interestField.key] === "Y") interestField.count++;
                                        return (
                                            <TableCell key={v4()} className="pill pill-sm">
                                                <div className={viewpoint[interestField.key] ? "y" : "n"}>{viewpoint[interestField.key] ? "Y" : "N"}</div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="view-viewpoint link bold" onClick={() => setIsOpen(true)}>Review Your Viewpoint</div>
                </div>
            </div>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            { advisor.survey_link.project.experience === "advisor" ?
                <AdvisorViewpoint viewpoint={viewpoint} />
            :
                <Box>
                    <ExecutiveViewpoint 
                        project={advisor.survey_link.project} 
                        viewpoint={viewpoint} 
                        initiator="evaluation"
                        viewpointHtml={viewpointHtml}
                    />
                </Box>
            }
            </Modal>
        </>
    );
}

const ContentInactive = ({ advisor }: any) => {

    return (
        <>
            <h5>We're no longer collecting viewpoints for this evaluation.</h5>
            <p>Thanks for your willingness to help us out. Unfortunately we're no longer looking for viewpoints for this evaluation. We're sorry for wasting a few minutes of your time and don't want to take more of it with the whole survey.</p>
        </>

    );
}

export const EvaluationEvaluate = () => {
    const { advisor, investmentUnlocked, loading } = useOutletContext<any>();
    const { trackAdvisorPage } = useAnalytics();
    const [isDemoSchedulerOpen, setIsDemoSchedulerOpen] = useState(false); // State for DemoScheduler

    useEffect(() => {
        if (advisor) {
            trackAdvisorPage(advisor, 'expert flow - evaluate');
        }
    }, [advisor]);

    useEffect(() => {
        if (window.location.hash === "#calendar") {
            setIsDemoSchedulerOpen(true);
        }
    }, []);

    const isActive = advisor.survey_link.is_active || advisor.status === "complete" || advisor.status === "approved";


    return (
        <>
            <DocumentTitle pageTitle={`Evaluate a ${advisor?.survey_link?.project?.public_title}`} />
            <div className="flex-column left-column">
                <div className="evaluation-content">
                    {!isActive && <ContentInactive advisor={advisor} />}
                    {isActive && advisor?.status === "init" && <ContentInit advisor={advisor} />}
                    {isActive && advisor?.status === "screened" && advisor.survey_link.is_active && <ContentScreened advisor={advisor} />}
                    {isActive && advisor?.status === "complete" && <ContentComplete advisor={advisor} />}
                    {isActive && advisor?.status === "approved" && <ContentComplete advisor={advisor} />}
                </div>
            </div>
            <div className="flex-column right-column">
                <EvaluationSummary advisor={advisor} investmentUnlocked={investmentUnlocked} />
                <EvaluationActivity advisor={advisor} />
            </div>
            <DemoScheduler advisor={advisor} type="prospect" open={isDemoSchedulerOpen} onClose={() => setIsDemoSchedulerOpen(false)} /> {/* Render DemoScheduler */}
        </>
    );
}
