import { IUser } from "./User";
import { IProject } from "./Project";
import { IViewpoint } from "./Viewpoint";
import { IMeeting } from "./Meeting";
import { ISurveyLink } from "./SurveyLink";

export interface IAdvisor {
    id: number;
    uuid: string;
    public_id: string;
    survey_link: ISurveyLink;
    params?: string;
    status: string;
    alchemer_id?: number;
    alchemer_session_id?: string;
    created_at: string;
    updated_at: string;
    email_address?: string;
    name?: string;
    investment_interest?: boolean;
    investment_amount_low?: number;
    investment_amount_high?: number;
    invited_to_invest?: boolean;
    accepted_nda?: boolean;
    user?: IUser;
    completed_at?: string;
    reserved_spot?: boolean;
    reserved_amount?: number;
    last_retained_at?: string;
    retained_count?: number;
    funded?: boolean;
    funded_amount?: number;
    notifications?: Notification[];
    last_angel_message_at?: string;
    last_prospect_message_at?: string;
    prospect_scheduled: boolean;
    prospect_scheduled_at?: string;
    prospect_event_uri?: string;
    prospect_event_invitee_uri?: string;
    meetings?: IMeeting[];
    project?: IProject;
    stage: AdvisorStage;
    advisor_opt_in: boolean;
    viewpoints?: IViewpoint[];
    score?: number;
    demand?: string;
    role?: string;
    max_spend?: number;
    linkedin_url?: string;
    preview?: boolean;
    referred_by_code?: string;
    latest_survey_url?: string;
    progress?: string;
    referrer_code?: string;
    is_test?: boolean;
}

export enum AdvisorStage {
    ADVISOR = "advisor",
    OPPORTUNITY = "opportunity",
    CUSTOMER = "customer",
}
    