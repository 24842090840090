import { useParams } from "react-router-dom/dist";
import { useAdvisorSuggestions } from "../hooks/useAdvisorSuggestions";
import { useEffect, useState } from "react";
import { Button, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Select, Snackbar, Alert } from "@mui/material";
import { AdvisorTable } from "../../company/styles/AdvisorStyles";
import { updateAdvisorSuggestion } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

 export const AdminProjectSuggestions = () => {
    const { projectId } = useParams();
    const { advisorSuggestions, reloadAdvisorSuggestions } = useAdvisorSuggestions(Number(projectId));
    const { getAccessTokenSilently } = useAuth0();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    useEffect(() => {
        reloadAdvisorSuggestions();
    }, [projectId]);

    const handleApprove = async (advisorSuggestionUuid: string) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateAdvisorSuggestion(Number(projectId), advisorSuggestionUuid, { status: "approved" }, accessToken);
        if (data) {
            reloadAdvisorSuggestions();
        }
    }

    const handleReject = async (advisorSuggestionUuid: string) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateAdvisorSuggestion(Number(projectId), advisorSuggestionUuid, { status: "rejected" }, accessToken);
        if (data) {
            reloadAdvisorSuggestions();
        }
    }

    const handleDownloadCSV = () => {
        // Define CSV headers
        const headers = [
            'Name',
            'Email',
            'LinkedIn',
            'Company',
            'Job Title',
            'Referral Type',
            'Referred By',
            'Status'
        ];
        
        // Map data to CSV rows
        const csvRows = advisorSuggestions.map(suggestion => [
            suggestion.name,
            suggestion.email,
            suggestion.linkedin_url,
            suggestion.company_name,
            suggestion.job_title,
            suggestion.advisor ? 'Advisor' : 'Company',
            suggestion.advisor ? suggestion.advisor.name : suggestion.created_by?.name,
            suggestion.status
        ]);
        
        // Combine headers and rows
        const csvContent = [
            headers.join(','),
            ...csvRows.map(row => row.map(cell => `"${cell || ''}"`).join(','))
        ].join('\n');
        
        // Create download link
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `advisor-suggestions-project-${projectId}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleStatusChange = async (advisorSuggestionUuid: string, status: string) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateAdvisorSuggestion(Number(projectId), advisorSuggestionUuid, { status }, accessToken);
        if (data) {
            reloadAdvisorSuggestions();
            setSnackbarMessage("Status updated successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } else if (error) {
            setSnackbarMessage("Failed to update status");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>Suggested Advisors</h3>
                <Button 
                    variant="contained" 
                    startIcon={<DownloadIcon />} 
                    onClick={handleDownloadCSV}
                >
                    Download CSV
                </Button>
            </div>
            <AdvisorTable>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>LinkedIn</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Job Title</TableCell>
                        <TableCell>Referral Type</TableCell>
                        <TableCell>Referred By</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {advisorSuggestions.map((advisorSuggestion) => (
                        <TableRow key={advisorSuggestion.uuid}>
                            <TableCell>{dayjs(advisorSuggestion.created_at).format('MMM D, YYYY')}</TableCell>
                            <TableCell>{advisorSuggestion.name}</TableCell>
                            <TableCell>{advisorSuggestion.email}</TableCell>
                            <TableCell>{advisorSuggestion.linkedin_url && <Link to={advisorSuggestion.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></Link>}</TableCell>
                            <TableCell>{advisorSuggestion.company_name}</TableCell>
                            <TableCell>{advisorSuggestion.job_title}</TableCell>
                        <TableCell>{advisorSuggestion.advisor ? "Advisor" : "Company"}</TableCell>
                        <TableCell>{advisorSuggestion.advisor ? advisorSuggestion.advisor.name : advisorSuggestion.created_by?.name}</TableCell>
                        <TableCell>
                            <Select
                                value={advisorSuggestion.status}
                                onChange={(e: any) => handleStatusChange(advisorSuggestion.uuid, e.target.value)}
                                size="small"
                                sx={{ width: '100%' }}
                            >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="invited">Invited</MenuItem>
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                                <MenuItem value="deleted">Deleted</MenuItem>
                            </Select>
                        </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </AdvisorTable>
            
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={4000} 
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
 }