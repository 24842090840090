import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, ResponsiveContainer } from 'recharts';
import { ViewpointProfileSummary, ViewpointCompany } from '../../../viewpoints/components';
import { useState, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { ScoreCircle } from '../../styles/AdvisorStyles';
import { IAdvisor, IProject, IViewpoint } from '../../../../types';
import { AdvisorCompany, AdvisorProfileSummary } from '../../../advisor';

const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]
const AdvisorTooltip = ({ advisor }: { advisor: IAdvisor }) => {
    return (
        <Stack spacing={1} sx={{ width: "275px" }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={1}>
                <AdvisorProfileSummary advisor={advisor} showCompany={false} />
                <Box sx={{ minWidth: "100px" }}>
                    <AdvisorCompany advisor={advisor} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ padding: "0.5rem", border: "1px solid #ccc", borderRadius: "50px" }}>
                <Typography variant="body1" sx={{ fontWeight: 900, flexGrow: 1, textAlign: "center", fontSize: "0.8rem" }}>
                    Purchasing Interest: {demandOptions.find((option: any) => option.value === advisor.demand)?.label}
                </Typography>
                <ScoreCircle score={advisor.score || 0} className="small">
                    {advisor.score || '-'}
                </ScoreCircle>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <Typography variant="body1">
                    <Link to={`#${advisor.uuid}`}>
                        <span className="link">View Details</span>
                    </Link>
                </Typography>
            </Box>
        </Stack>
    )
}

export const ScoringGraph = ({ advisors }: { advisors: IAdvisor[] }) => {

    // Transform data into format required by recharts
    const data = advisors.map((advisor: IAdvisor) => ({
        completed_at: new Date( advisor.viewpoints?.sort((a: IViewpoint, b: IViewpoint) => new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime())[0].created_at!)?.getTime(),
        score: advisor.score,
        advisor: advisor
    }));

    // Calculate the earliest and latest dates from the data
    const earliestDate = Math.min(...data.map((point: any) => point.completed_at));
    //const latestDate = Math.max(...data.map((point: any) => point.completed_at));
    const latestDate = new Date().getTime();

    // Calculate the midpoint
    const midpointDate = earliestDate + (latestDate - earliestDate) / 2;

    // Color scale based on score (red for low scores, green for high scores)
    const getColor = (score: number) => {
        switch (true) {
            case (score >= 70): return '#7FBF61'; // green
            case (score >= 40): return '#FABB00'; // orange
            case (score >= 10): return '#F58025'; // red
            default: return '#757575'; // grey for very low scores
        }
    };

    // Add state for tooltip visibility and position
    const [tooltipData, setTooltipData] = useState<any>(null);

    // Modify the click handler to be more specific
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Check if the click is on a scatter dot (which should have a fill color matching our getColor function)
            const target = event.target as SVGElement;
            const isScatterDot = target.tagName === 'circle' && target.getAttribute('fill');
            
            if (!isScatterDot) {
                setTooltipData(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <ResponsiveContainer width="100%" height={500}>
            <ScatterChart
                width={600}
                height={400}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid />
                <ReferenceArea
                    x1={latestDate - (60 * 24 * 60 * 60 * 1000)}
                    x2={latestDate}
                    y1={50}
                    y2={100}
                    fill="#E1E6EB"
                    isFront={false}
                />


                <XAxis
                    dataKey="completed_at"
                    type="number"
                    domain={[earliestDate, latestDate]}
                    tickFormatter={(unixTime) => {
                        const today = new Date().getTime();
                        const diffDays = Math.round((today - unixTime) / (1000 * 60 * 60 * 24));

                        if (diffDays <= 1) return 'Today';
                        if (diffDays % 30 === 0) return `${diffDays} days ago`;
                        return '';
                    }}
                    label={{ value: "Date", position: "bottom", style: { color: '#000', fontWeight: 900, fontSize: '1.2rem' } }}
                    ticks={[
                        latestDate,
                        latestDate - (30 * 24 * 60 * 60 * 1000),
                        latestDate - (60 * 24 * 60 * 60 * 1000),
                        latestDate - (90 * 24 * 60 * 60 * 1000),
                        latestDate - (120 * 24 * 60 * 60 * 1000),
                        latestDate - (150 * 24 * 60 * 60 * 1000),
                        latestDate - (180 * 24 * 60 * 60 * 1000)
                    ]}
                />
                <YAxis
                    dataKey="score"
                    type="number"
                    label={{ value: "Score", angle: -90, position: "left", style: { color: '#000', fontWeight: 900, fontSize: '1.2rem' } }}
                    max={100}
                    domain={[0, 100]}
                />
                <Tooltip
                    content={({ active, payload }) => {
                        // If hovering over a point, show that point's data regardless of clicked state
                        if (active && payload && payload.length) {
                            return (
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        pointerEvents: 'all'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <AdvisorTooltip advisor={payload[0].payload.advisor} />
                                </div>
                            );
                        }
                        
                        // If not hovering but we have clicked data, show that
                        if (tooltipData) {
                            return (
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        pointerEvents: 'all'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <AdvisorTooltip advisor={tooltipData.advisor} />
                                </div>
                            );
                        }

                        return null;
                    }}
                    isAnimationActive={false}
                    cursor={{ strokeDasharray: '3 3' }}
                    wrapperStyle={{ pointerEvents: 'all' }}
                />

                <Scatter
                    data={data}
                    fill="#8884d8"
                    fillOpacity={0.6}
                    onClick={(props) => {
                        // Toggle tooltip data - if clicking same point, clear it
                        setTooltipData(tooltipData?.completed_at === props.payload.completed_at ? null : props.payload);
                    }}
                    onMouseLeave={() => {
                        // Only clear if not clicked
                        if (!tooltipData) {
                            setTooltipData(null);
                        }
                    }}
                    shape={(props: any) => {
                        const { cx, cy, fill } = props;
                        return <circle cx={cx} cy={cy} r={6} fill={getColor(props.payload.score)} />;
                    }}
                />
            </ScatterChart>
        </ResponsiveContainer>
    );
}