import { Container, Grid } from "@mui/material";

import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserContext } from "../context";
import { UserProfileUpdate } from "../components/UserProfileUpdate";
import { UserTitle } from "../components/UserTitle";

export const ProfileIndex = () => {
  const { user } = useAuth0();
  const { userContext, loadUserContext } = useUserContext();

  return user ? userContext.fetched ? (
    <Container maxWidth="md">
      <UserTitle section="Your Profile" />
      <UserProfileUpdate user={userContext.dbUser} onUpdate={loadUserContext} />
    </Container>
  ) : <Loading /> : '';
};
