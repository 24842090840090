import { useEffect } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";

export const OrganizationTitle = ({ organization, section, subheading }: any) => {

    const { trackPage } = useAnalytics();

    useEffect(() => {
        trackPage(`Firm - ${section}`, {
            organization: organization?.tag,
            section: section
        });
    }, [organization, section]);

    return (
        <Box sx={{ marginBottom: "40px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Box>
                <DocumentTitle pageTitle={`${organization?.name} - ${section}`} />
                <h2>{organization?.name} {section}</h2>
                {subheading && <div>{subheading}</div>}
            </Box>
        </Box>
    );
}