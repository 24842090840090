import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLinkedinSearches } from "../hooks/useLinkedinSearches";
import { LinkedinSearchForm } from "../projects/components/LinkedinSearchForm";

export const AdminProjectLinkedIn = () => {
    const { project } = useOutletContext<any>();
    const { fetched, linkedinSearches = [], error, reloadLinkedinSearches } = useLinkedinSearches(project.id);
    const [linkedinSearch, setLinkedinSearch] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const handleEdit = (search: any) => {
        setLinkedinSearch(search);
        setEditMode(true);
    }

    if (!fetched) return <></>;

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>LinkedIn Searches</h5>
                <div className="btn btn-primary" onClick={() => handleEdit(null)}>Add New Search</div>
            </div>

            <TableContainer>
                <Table>
                    <TableBody>
                        {(linkedinSearches || []).map((search: any) => (
                            <TableRow key={search.uuid} className={search.is_active ? "published" : "not-published"}>
                                <TableCell>
                                    <h5>{search.title}</h5>
                                    <p>{search.description}</p>
                                    <Link to={search.linkedin_url} target="_blank">Link</Link>
                                </TableCell>
                                <TableCell className="center">{search.is_active ? "Active" : "Inactive"}</TableCell>
                                <TableCell className="admin-action link" onClick={() => handleEdit(search)}>
                                    <FontAwesomeIcon icon="edit"/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {editMode && (
                <LinkedinSearchForm 
                    project={project}
                    linkedinSearch={linkedinSearch}
                    linkedinSearches={linkedinSearches || []}
                    reloadLinkedinSearches={reloadLinkedinSearches}
                    editMode={editMode}
                    setEditMode={setEditMode}
                />
            )}
        </div>
    );
}