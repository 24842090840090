import { useState, useEffect, useMemo } from 'react';

export const useSubdomain = () => {
  const [subdomainState, setSubdomainState] = useState<{
    subdomain: string | null;
    isCustomDomain: boolean;
    subdomainFetched: boolean;
  }>({
    subdomain: null,
    isCustomDomain: false,
    subdomainFetched: false
  });

  const currentHost = useMemo(() => window.location.host, []);

  useEffect(() => {
    const parts = currentHost.split('.');
    const appOrigin = process.env.REACT_APP_APP_ORIGIN;
    
    if (parts.length > 1 && appOrigin) {
      const potentialSubdomain = parts[0];
      if (currentHost.endsWith(appOrigin) && potentialSubdomain !== 'app') {
        setSubdomainState({
          subdomain: potentialSubdomain,
          isCustomDomain: true,
          subdomainFetched: true
        });
        return;
      }
    }
    
    setSubdomainState({
      subdomain: null,
      isCustomDomain: false,
      subdomainFetched: true
    });
  }, [currentHost]);

  return subdomainState;
}; 