import React, { useState } from 'react';

interface CompanyAdvisorProps {
    children: React.ReactNode;
    uuid: string;
}

export const CompanyAdvisorLink: React.FC<CompanyAdvisorProps> = ({ children, uuid }) => {

    const enhancedChildren = React.cloneElement(
        children as React.ReactElement,
        {
            onClick: (e: any) => {
                e.preventDefault();
                window.location.hash = uuid;
            },
            style: { cursor: 'pointer' }
        }
    );

    return enhancedChildren;
}