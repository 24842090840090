import { useEffect, useState } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import { useFirmContext } from "../context";
import { OrganizationTitle } from "../components/OrganizationTitle";
import { UsersList } from "../components/UsersList";

export const FirmTeam = () => {
    const { firmContext } = useFirmContext();
    const viewerRole = firmContext.role;
    const organization = firmContext.organization;
    const { userContext } = useUserContext();
    const [ value, setValue ] = useState(0);


    const { trackPage } = useAnalytics();

    /*
    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - collaboration`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);
*/


    return (
        <div>
            <OrganizationTitle organization={organization} section="Team" subheading="Invite team members to join your organization. They'll be able to view all companies and add their own." />
            <UsersList organization={organization} viewerRole={viewerRole} />
        </div>
    );
}   