import { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Tabs,
    Tab,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert
} from "@mui/material";
import { getOrganizations, getUser, updateUser, updateMembership, deleteMembership } from '../services/gutcheck-admin-api.service';
import Membership from '../../users/types/Membership';
import { UserProfileImage } from "../../users/components/UserProfileImage";
import Loading from "../../../components/Loading";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { ProfileUpdate } from "../projects/components/ProfileUpdate";
import { ExecTabs, ExecTab, AdvisorTable } from "../../company/styles/AdvisorStyles";

type TAdminUserEdit = {
    email: string,
    name: string,
    role: any,
    organizations: any | null,
    memberships: any[],
    // Add new fields
    profile_picture?: string,
    created_at?: string,
    updated_at?: string,
    latest_visit?: string,
    num_visits?: number
};

type TMembership = {
    id: string;
    organizationId: string;
    organizationName: string;
    projectId?: string;
    projectName?: string;
    role: string;
};

const initState: TAdminUserEdit = {
    email: '',
    name: '',
    role: '',
    organizations: null,
    memberships: [],
    profile_picture: '',
    created_at: '',
    updated_at: '',
    latest_visit: '',
    num_visits: 0
};

export const AdminUserEdit = () => {
    const [form, setForm] = useState(initState);
    const [memberships, setMemberships] = useState<TMembership[]>([]);
    const [orgMemberships, setOrgMemberships] = useState<TMembership[]>([]);
    const [projectMemberships, setProjectMemberships] = useState<TMembership[]>([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState<{ open: boolean; membershipId: string; isProject: boolean } | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [user, setUser] = useState<any>(null);
    const [profileUpdateOpen, setProfileUpdateOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { userId } = useParams();

    const fetchData = async () => {
        const accessToken = await getAccessTokenSilently();

        const [userData] = await Promise.all([
            getUser(userId ?? "-1", accessToken)
        ]);

        if (userData) {
            setForm({
                email: userData.data.user.email_address,
                name: userData.data.user.name,
                role: userData.data.user.role,
                organizations: userData.data.user.memberships.filter((membership: Membership) => membership.organization)
                    .map((membership: Membership) => ({ ...membership.organization, value: membership.organization.id, label: membership.organization.name })),
                memberships: userData.data.user.memberships,
                profile_picture: userData.data.user.profile_picture,
                created_at: userData.data.user.created_at,
                updated_at: userData.data.user.updated_at,
                latest_visit: userData.data.user.latest_visit,
                num_visits: userData.data.user.num_visits
            });
            setUser(userData.data.user);

            // Separate memberships into organizations and projects
            const allMemberships = userData.data.user.memberships.map((membership: Membership) => ({
                id: membership.id,
                organizationId: membership.organization?.id || '',
                organizationName: membership.organization?.name || membership.project?.organization?.name || '',
                projectId: membership.project?.id,
                projectName: membership.project?.name,
                role: membership.role,
            }));

            setOrgMemberships(allMemberships.filter((m: TMembership) => !m.projectId).sort((a: TMembership, b: TMembership) => a.organizationName.localeCompare(b.organizationName)));
            setProjectMemberships(allMemberships.filter((m: TMembership) => m.projectId).sort((a: TMembership, b: TMembership) => a?.projectName?.localeCompare(b?.projectName ?? '') || 0));
        }



    };

    useEffect(() => {
        fetchData();

        return;
    }, []);

    const handleChange = (event: any) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleRoleChange = (event: any) => {
        setForm({
            ...form,
            role: event
        });
    };

    const handleOrganizationsChange = (event: any) => {
        setForm({
            ...form,
            organizations: event
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const user = {
            email_address: form.email,
            name: form.name,
            role: form.role.value,
            organizations: form.organizations.map((organization: any) => { return organization.id })
        }
        if (userId) {
            const { data, error } = await updateUser(userId, user, accessToken);
            if (error) {
                setErrorMessage(error.message);
                return;
            }

            setSuccessMessage("User updated successfully");
            setIsEditMode(false);
        }
    };

    const membershipRoles = [
        { value: "admin", label: "admin" },
        { value: "contributor", label: "team member" },
        { value: "guest", label: "guest" }
    ];

    const userRoles = [
        { value: "admin", label: "admin" },
        { value: "user", label: "user" },
        { value: "guest", label: "guest" }
    ];

    const handleMembershipRoleChange = async (membershipId: string, newRole: string, isProject: boolean) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateMembership(membershipId, { role: newRole }, accessToken);
        if (error) {
            setErrorMessage(error.message);
            return;
        }

        const updateMemberships = (memberships: TMembership[]) =>
            memberships.map(m => m.id === membershipId ? { ...m, role: newRole } : m);

        if (isProject) {
            setProjectMemberships(updateMemberships);
        } else {
            setOrgMemberships(updateMemberships);
        }
    };

    const handleMembershipDelete = async (membershipId: string, isProject: boolean) => {
        const accessToken = await getAccessTokenSilently();
        await deleteMembership(membershipId, accessToken);

        if (isProject) {
            setProjectMemberships(projectMemberships.filter(m => m.id !== membershipId));
        } else {
            setOrgMemberships(orgMemberships.filter(m => m.id !== membershipId));
        }

        setDeleteConfirmation(null);
        setSuccessMessage("Membership successfully deleted");
    };

    const openDeleteConfirmation = (membershipId: string, isProject: boolean) => {
        setDeleteConfirmation({ open: true, membershipId, isProject });
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    if (!user) return <Loading />;
    return (
        <>
            <DocumentTitle pageTitle={`Admin - User: ${userId}`} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4">
                    User: {userId}
                </Typography>
                <Button
                    variant="contained"
                    onClick={toggleEditMode}
                >
                    {isEditMode ? 'Cancel' : 'Edit User'}
                </Button>
            </Box>

            {isEditMode ? (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        value={form.email || ''}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role"
                            value={form.role}
                            onChange={handleRoleChange}
                        >
                            {userRoles.map((role) => (
                                <MenuItem key={role.value} value={role.value}>
                                    {role.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Save Changes
                        </Button>
                    </FormControl>

                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between', alignItems: 'flex-start' }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <UserProfileImage user={user} size="large" />
                        <Box>
                            <Typography variant="h6">
                                <strong>{user.profile?.name}</strong> ({user.email_address})
                            </Typography>
                            <Typography>
                                {user.profile?.title}
                            </Typography>
                            <Typography>
                                <strong>{user.profile?.company?.name}</strong>
                            </Typography>
                            <span className="link" onClick={() => setProfileUpdateOpen(true)}>Edit Profile</span>
                        </Box>
                    </Box>
                    <Box className="display-box">
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Role:</strong> {form.role}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Created:</strong> {new Date(form.created_at || '').toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Last Updated:</strong> {new Date(form.updated_at || '').toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Latest Visit:</strong> {form.latest_visit ? new Date(form.latest_visit).toLocaleDateString() : 'Never'}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            <strong>Number of Visits:</strong> {form.num_visits || 0}
                        </Typography>
                    </Box>
                </Box>
            )}

            <Box>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                    Memberships
                </Typography>

                <Box sx={{  }}>
                    <ExecTabs value={activeTab} onChange={handleTabChange}>
                        <ExecTab label="Firms" />
                        <ExecTab label="Companies" />
                    </ExecTabs>
                </Box>

                {activeTab === 0 && (

                        <AdvisorTable stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Organization</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orgMemberships.map((membership) => (
                                    <TableRow key={membership.id}>
                                        <TableCell>{membership.organizationName}</TableCell>
                                        <TableCell>
                                            <Select
                                                value={membership.role}
                                                size="small"
                                                onChange={(e) => handleMembershipRoleChange(membership.id, e.target.value as string, false)}
                                            >
                                                {membershipRoles.map((role) => (
                                                    <MenuItem key={role.value} value={role.value}>
                                                        {role.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => openDeleteConfirmation(membership.id, false)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </AdvisorTable>
                )}

                {activeTab === 1 && (
                        <AdvisorTable stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Organization</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectMemberships.map((membership) => (
                                    <TableRow key={membership.id}>
                                        <TableCell>{membership.projectName}</TableCell>
                                        <TableCell>{membership.organizationName}</TableCell>
                                        <TableCell>
                                            {membership.role === "advisor" ? "Advisor" :
                                                <Select
                                                    value={membership.role}
                                                    size="small"
                                                    onChange={(e) => handleMembershipRoleChange(membership.id, e.target.value as string, true)}
                                                >
                                                    {membershipRoles.map((role) => (
                                                        <MenuItem key={role.value} value={role.value}>
                                                            {role.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => openDeleteConfirmation(membership.id, true)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </AdvisorTable>
                )}

            </Box>

            <Dialog
                open={Boolean(deleteConfirmation?.open)}
                onClose={() => setDeleteConfirmation(null)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this membership? This action cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
                    <Button
                        onClick={() => deleteConfirmation && handleMembershipDelete(deleteConfirmation.membershipId, deleteConfirmation.isProject)}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={Boolean(successMessage)}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage(null)}
            >
                <Alert
                    onClose={() => setSuccessMessage(null)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <ProfileUpdate user={user} onUpdate={fetchData} open={profileUpdateOpen} setOpen={setProfileUpdateOpen} />
        </>
    );
}
