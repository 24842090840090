import { Box, BoxProps, styled, Tab, TableProps, Table, Tabs, Button } from "@mui/material";

export const AdvisorBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    overflowY: "hidden",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "20px",
    "& #responseMeta": {
        display: "none"
    },
    "& #navTabs": {
        borderBottom: "1px solid #c9c9c9",
        "& .navTab": {
            border: "1px solid #ffffff",
            borderBottom: "none",
            color: "rgba(0, 0, 0, 0.6)",
            "&:hover": {
                color: "#000000"
            }
        },
        "& .navTab.selected": {
            backgroundColor: "#f8f9fa",
            border: "1px solid #c9c9c9",
            borderBottom: "none",
            color: "#000000",
        },
    },
    "& #contentContainer": {
        backgroundColor: "#ffffff",
        "& .answerSummary": {
            padding: 0
        }
    },
    "& .executive-profile": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "start",
        gap: "20px",
        padding: "20px",
    },
    [theme.breakpoints.down('md')]: {
        "& #navTabs .navTab": {
            borderLeft: "1px solid #c9c9c9",
            borderRight: "1px solid #c9c9c9",
        }
    },
}));

export const SummaryContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "250px"
}));

export const DemandSummary = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    padding: 0,
    gap: "0",
    width: "100%",
    "& .summary-pill": {
        borderRadius: "18px",
        backgroundColor: "#c9c9c9",
        border: "1px solid #c9c9c9",
        color: "#000000",
        fontSize: "0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        width: "100%",
        "&.large": {
            padding: "0.4rem 0.75rem",
        },
        "&.small": {
            width: "80px",
            padding: "0.4rem 0",
        },
        "&.dark-green": {
            backgroundColor: theme.palette.success.main,
            border: `1px solid #${theme.palette.success.main}`,
            fontWeight: 700,
            color: "#ffffff",
        },
        "&.light-green": {
            backgroundColor: theme.palette.success.light,
            border: `1px solid #${theme.palette.success.light}`,
            color: "#000000",
        }
    },
}));

export const ViewpointSummary = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    padding: 0,
    gap: "0",
    width: "100%",
    "& .summary-header": {
        fontWeight: "500",
        fontSize: "0.9rem",
        display: "none"
    },
    "& .summary-pills": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "5px",
        width: "100%",
        flexWrap: "nowrap",
        "& .summary-pill": {
            padding: "0.4rem 0.75rem",
            borderRadius: "18px",
            backgroundColor: "#f8f9fa",
            border: "1px dashed #c9c9c9",
            color: "#c9c9c9",
            fontSize: "0.75rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            "&.highlighted": {
                backgroundColor: "#c9c9c9",
                border: "1px solid #c9c9c9",
                color: "#000000",
            }
        },
    },
}));

export const ExecTabs = styled(Tabs)({
    width: "100%",
    display: "flex",
    borderBottom: "1px solid #c9c9c9",
    marginBottom: "40px",
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        borderBottom: "3px solid #feb70f",
        color: "#000000"
    }
});

export const ExecTab = styled(Tab)({
    //borderLeft: "1px solid #c9c9c9",
    //borderTop: "1px solid #c9c9c9",
    //borderRight: "1px solid #c9c9c9",
    borderBottom: "none",
    fontWeight: "500",
    fontSize: "1rem",
    margin: "0 10px",
    width: "300px",
    maxWidth: "300px",
    color: "#000",
    "&.Mui-selected": {
        color: "#000000",
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #c9c9c9",
        borderTop: "1px solid #c9c9c9",
        borderRight: "1px solid #c9c9c9",
        borderBottom: "none",
        "&:hover": {
            backgroundColor: "#f8f9fa",
            color: "#000000"
        },

    },
    "&.Mui-disabled": {
        color: "#c9c9c9",
        backgroundColor: "#ffffff",
        border: "none",
    },
    "&:hover": {
        backgroundColor: "#ffffff",
        color: "#000000"
    },
    "& div": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
    },
    "& .count": {
        borderRadius: "50%",
        backgroundColor: "#c9c9c9",
        border: "1px solid #c9c9c9",
        color: "#000000",
        width: "1.5rem",
        height: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.75rem",
    }
});

export const ScoreCircle = styled('div')<{ score: number }>(({ score }) => ({
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor:
        score >= 70 ? '#7FBF61' : // green
        score >= 40 ? '#FABB00' : // orange
        score >= 10 ? '#F58025' : // red
        score >= 0 ? '#F58025' : // red
        '#757575', // grey for very low scores
    "&.small": {
        width: "2rem",
        height: "2rem",
        fontSize: "0.85rem",
    }
}
)
);

export const AdvisorTable = styled(Table)<TableProps>(({ theme }) => ({
    "& tr": {
        "&:hover": {
            backgroundColor: "#f8f9fa"
        },
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff"
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fa"
        },
        "&:last-child": {
            "& td": {
                borderBottom: "none"
            }
        },
        "& td, th": {
            borderBottom: "1px solid #c9c9c9",
            padding: "0.5rem 0.75rem",
            fontSize: "0.9rem",
            color: "#000000",
            "&.icon": {
                width: "40px",
                textAlign: "center"
            },
            "&.profile": {
                paddingTop: "1rem",
                paddingBottom: "1rem",
            },
            "&.company": {
                //width: "100px",
                textAlign: "center",

            },
            "&.center": {
                //width: "75px",
                textAlign: "center",
                "& div": {
                    margin: "0 auto",
                }
            },
            "&.right": {
                textAlign: "right",
            },
            "&.quote": {
                minWidth: "250px",
                "& p": {
                    fontStyle: "italic",
                    fontWeight: "300"
                }
            },
        },
        "& td .summary-pill": {
            display: "inline-block",
            padding: "0.5rem 1rem",
            backgroundColor: "#c9c9c9",
            borderRadius: "20px",
            color: "#000000",
            fontSize: "0.8rem",
            marginRight: "5px",
            marginBottom: "5px",
            "&.green": {
                backgroundColor: theme.palette.success.main,
                color: "#ffffff"
            },
        },
        "& th": {
            position: "sticky",
            top: "-40px",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #000000",
            zIndex: 100,
            fontWeight: 700,
            "&.sortable": {
                cursor: "pointer",
                "&:hover": {
                    color: "#000000"
                }
            }
        }
    }
}));

export const SuccessButton = styled(Button)(( { theme }) => ({
    borderColor: "#4caf50 !important",
    color: "#4caf50 !important",
    backgroundColor: "#ffffff !important",
    "&:hover": {
        backgroundColor: "#388e3c",
    }
}));

export const DemandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]

export const EmptyList = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

export const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    gap: "1rem",
    padding: "1rem",
    position: "relative",
    transition: "all 300ms ease-in-out"
};

export const OverviewBox = styled(Box)({
    flex: 1,
    cursor: "pointer",
    position: "relative",
    border: "1px solid #c9c9c9",
    " a": {
        fontWeight: 700,
    },
    "&.selected": {
        boxShadow: "0 0 5px #1a5dbd",
        border: "1px solid #1a5dbd",
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-31px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "1px",
            height: "30px",
            backgroundColor: "#1a5dbd",
        }
    },
    "&.blocked": {
        opacity: 0,
        pointerEvents: "none"
    }
});


export const RecruitmentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    border: '1px solid #c9c9c9',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    width: "100%",
    '&:hover': {
        boxShadow: '0 0 5px #1a5dbd',
        border: '1px solid #1a5dbd',
        textDecoration: "none !important",
        "& a, & span, & p, & h6": {
            textDecoration: "none !important"
        }
    },
    '& .icon': {
        fontSize: '24px',
        marginBottom: '15px',
        color: '#1a5dbd'
    },
    '& h6': {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    '& p': {
        fontSize: '0.9rem',
        color: '#666'
    }
});