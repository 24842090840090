import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IAdvisor, IMeeting, IProject } from "../../../types";
import { getAllMeetings, updateMeetingStatus } from "../services/gutcheck-admin-api.service";
import { Box, Table, TableRow, TableCell, Paper, TableContainer, TableHead, TableBody, Rating, Typography, Stack, MenuItem, Autocomplete, TableSortLabel, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { DateDisplay } from "../../meeting/components/DateDisplay";
import { CompanyLogo } from "../../company/components/CompanyLogo";
import { AdvisorProfileSummary } from "../../advisor/components/AdvisorProfileSummary";
import dayjs from "dayjs";
import { AdvisorTable } from "../../company/styles/AdvisorStyles";
import { MeetingsReport } from "../reports/MeetingsReport";
import { CheckCircle, XCircle, Circle, Timer } from "lucide-react";
import { MeetingForm } from "../components/MeetingForm";

const MeetingTable = ({ meetings, setMeetings, onEditMeeting }: { 
    meetings: IMeeting[], 
    setMeetings: (meetings: IMeeting[]) => void,
    onEditMeeting: (meeting: IMeeting) => void
}) => {
    const [sortField, setSortField] = useState<string>('event_start');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const { getAccessTokenSilently } = useAuth0();

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const handleCancelMeeting = async (meetingId: string) => {
        const accessToken = await getAccessTokenSilently();
        const { data } = await updateMeetingStatus(meetingId, "cancelled", accessToken);
        if (data && data.meeting) {
            const updatedMeeting = data.meeting;
            const updatedMeetings = meetings.map((meeting: IMeeting) => {
                if (meeting.id === updatedMeeting.id) {
                    return updatedMeeting;
                }
                return meeting;
            });
            setMeetings(updatedMeetings);
        }
    }

    const sortedMeetings = [...meetings].sort((a, b) => {
        let aValue, bValue;

        switch (sortField) {
            case 'event_start':
                aValue = a.event_start ? new Date(a.event_start).getTime() : 0;
                bValue = b.event_start ? new Date(b.event_start).getTime() : 0;
                break;
            case 'company':
                aValue = a.project?.name || '';
                bValue = b.project?.name || '';
                break;
            case 'advisor':
                aValue = a.advisor?.name || '';
                bValue = b.advisor?.name || '';
                break;
            case 'company_rating':
                const aCompanyRating = a.ratings?.find((rating: any) => rating.user);
                const bCompanyRating = b.ratings?.find((rating: any) => rating.user);
                aValue = aCompanyRating?.rating || 0;
                bValue = bCompanyRating?.rating || 0;
                break;
            case 'advisor_rating':
                const aAdvisorRating = a.ratings?.filter((rating: any) => rating.advisor)?.sort((x: any, y: any) => x.rating - y.rating)[0];
                const bAdvisorRating = b.ratings?.filter((rating: any) => rating.advisor)?.sort((x: any, y: any) => x.rating - y.rating)[0];
                aValue = aAdvisorRating?.rating || 0;
                bValue = bAdvisorRating?.rating || 0;
                break;
            default:
                aValue = a[sortField as keyof IMeeting] || '';
                bValue = b[sortField as keyof IMeeting] || '';
        }

        if (sortDirection === 'asc') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    return (
        <TableContainer sx={{ marginBottom: "60px", marginTop: "20px" }}>
            <AdvisorTable sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'event_start'}
                                direction={sortField === 'event_start' ? sortDirection : 'asc'}
                                onClick={() => handleSort('event_start')}
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'company'}
                                direction={sortField === 'company' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'advisor'}
                                direction={sortField === 'advisor' ? sortDirection : 'asc'}
                                onClick={() => handleSort('advisor')}
                            >
                                Advisor
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'company_rating'}
                                direction={sortField === 'company_rating' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company_rating')}
                            >
                                Company Rating
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'advisor_rating'}
                                direction={sortField === 'advisor_rating' ? sortDirection : 'asc'}
                                onClick={() => handleSort('advisor_rating')}
                            >
                                Advisor Rating
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            Valuable?
                        </TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                {sortedMeetings.length > 0 ? (
                    <TableBody>
                        {sortedMeetings.map((meeting) => {
                            const companyRating = meeting.ratings?.sort((a: any, b: any) => a.rating - b.rating).find((rating: any) => rating.user);
                            const advisorRating = meeting.ratings?.sort((a: any, b: any) => a.rating - b.rating).find((rating: any) => rating.advisor);
                            const value = !companyRating || !advisorRating ? "" :
                                companyRating.rating >= 3 && advisorRating.rating >= 3 ? "yes" : "no";
                            return (
                                <TableRow key={meeting.id}>
                                    <TableCell>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            {meeting.event_start ? <DateDisplay meeting={meeting} /> : ""}
                                            <Typography variant="caption">{meeting.event_start ? dayjs(meeting.event_start).format("h:mm a") : ""}</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell><CompanyLogo project={meeting.project} style="left" /></TableCell>
                                    <TableCell>
                                        <AdvisorProfileSummary advisor={meeting.advisor} />
                                    </TableCell>
                                    <TableCell>
                                        {meeting.ratings?.filter((rating: any) => rating.user)?.map((rating: any) => (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Rating value={rating.rating} max={4} readOnly />
                                                <Typography variant="caption">({rating.user.name})</Typography>
                                            </Stack>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        { advisorRating ? <Rating value={advisorRating.rating} max={4} readOnly /> : <></>}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                        { value === "yes" ? <CheckCircle color="green" size={20} /> : value === "no" ? <XCircle color="red" size={20} /> : <Timer color="lightgray" size={20} />}
                                    </TableCell>
                                    <TableCell>
                                        <Button 
                                            variant="text" 
                                            color="primary" 
                                            onClick={() => onEditMeeting(meeting)}
                                        >
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={7} align="center" sx={{ backgroundColor: "#f8f9fa" }}>
                                <Box sx={{ display: "inline-block", backgroundColor: "#ffffff", padding: "20px", borderRadius: "5px", margin: "20px", border: "1px solid #c9c9c9" }}>No meetings found</Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </AdvisorTable>
        </TableContainer>
    )
}
export const AdminMeetings = () => {

    const [meetings, setMeetings] = useState<IMeeting[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const [company, setCompany] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [projects, setProjects] = useState<IProject[]>([]);
    const [filteredMeetings, setFilteredMeetings] = useState<IMeeting[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [editMode, setEditMode] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState<IMeeting | undefined>(undefined);
    const [advisors, setAdvisors] = useState<IAdvisor[]>([]);

    const fetchMeetings = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data } = await getAllMeetings(accessToken, "advisor");
        if (data.meetings.length > 0) {
            const sortedMeetings = data.meetings.sort((a: any, b: any) => new Date(b.event_start).getTime() - new Date(a.event_start).getTime());
            setMeetings(sortedMeetings);
            setProjects(sortedMeetings.map((meeting: any) => meeting.project).filter((project: IProject, index: number, self: IProject[]) => self.findIndex(p => p.id === project.id) === index).sort((a: IProject, b: IProject) => a.name.localeCompare(b.name)));
            setFilteredMeetings(sortedMeetings);

            // Set startDate to January 29, 2025
            setStartDate(new Date('2025-01-29'));

            // Set endDate to the latest of current date or the latest event_start date
            const latestMeetingDate = new Date(sortedMeetings[0].event_start);
            const currentDate = new Date();
            setEndDate(latestMeetingDate > currentDate ? latestMeetingDate : currentDate);
        }
    }
    useEffect(() => {
        fetchMeetings();
    }, []);

    const handleCompanyChange = (value: string) => {
        setCompany(value);
        if (value === "") {
            setFilteredMeetings(meetings);
        } else {
            setFilteredMeetings(meetings.filter((meeting: IMeeting) => meeting.project.id === parseInt(value)));
        }
    };

    const handleCreateMeeting = () => {
        setSelectedMeeting(undefined);
        setEditMode(true);
    };

    const handleEditMeeting = (meeting: IMeeting) => {
        setSelectedMeeting(meeting);
        setEditMode(true);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>Advisor Intro Meetings</h3>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between', marginTop: "20px" }}>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <Autocomplete
                            options={projects}
                            getOptionLabel={(option: IProject) => option.name}
                            value={projects.find((project: IProject) => project.id === parseInt(company)) || null}
                            onChange={(event, newValue) => {
                                handleCompanyChange(newValue ? newValue.id.toString() : "");
                            }}
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Company Name"
                                    sx={{ minWidth: 200 }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
            <MeetingForm
                meeting={selectedMeeting}
                projects={projects}
                advisors={advisors}
                reloadMeetings={fetchMeetings}
                editMode={editMode}
                setEditMode={setEditMode}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'space-between', marginTop: "20px", marginBottom: "40px" }}>
                <MeetingsReport startDate={startDate} endDate={endDate} selectedOrg={parseInt(organization)} selectedProject={parseInt(company)} showTable={false} fullWidth={true} />
            </Box>
            <h5>Upcoming Meetings</h5>
            <MeetingTable 
                meetings={filteredMeetings.filter((meeting: IMeeting) => dayjs(meeting.event_start).isAfter(dayjs()))} 
                setMeetings={setMeetings}
                onEditMeeting={handleEditMeeting}
            />
            <h5>Completed Meetings</h5>
            <MeetingTable 
                meetings={filteredMeetings.filter((meeting: IMeeting) => dayjs(meeting.event_start).isBefore(dayjs()))} 
                setMeetings={setMeetings}
                onEditMeeting={handleEditMeeting}
            />

        </div>
    );
};