import { Box, BoxProps, styled, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

const SidebarBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "210px",
    position: "fixed",
    top: 0,
    left: 0,
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: "#e1e6eb",
    color: "#000000",
    height: "100%",
    padding: "110px 0 20px",
    zIndex: "999",
    transition: "width 0.5s",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    "& p, a": {
        display: "flex",
        alignItems: "center",
        padding: "10px 20px 10px 40px",
        fontSize: "1rem",
        margin: 0,
        textDecoration: "none",
        color: "#000000",
        marginBottom: "5px",
        "&:hover": {
            backgroundColor: "#d3d8dd",
        },
        "&.disabled": {
            color: "#667788",
            cursor: "not-allowed",
            "&:hover": {
                backgroundColor: "#e1e6eb",
            },
        },
        "&.active": {
            backgroundColor: "#667788",
            color: "#ffffff",
            "& span": {
                color: "#ffffff",
            }
        },
        "& .link-stats": {
            marginLeft: "5px",
            color: "#667788",
        },
        "& span": {
            lineHeight: "1rem",
        }
    },
    "& .tab-section": {
        marginTop: "20px",
        "&:first-of-type": {
            marginTop: "0",
        }
    },
    "& .tab-section-header": {
        padding: "10px 20px 10px 40px",
        color: "#667788",
        fontWeight: 500,
        display: "flex",
        flexDirection: "row",
        "&::after": {
            borderBottom: "1px solid #667788",
            content: '""',
            flex: "1 1",
            margin: "auto",
            marginLeft: "10px",
        },
    },
    [theme.breakpoints.down('md')]: {
        width: "60px",
        "& .link-label": {
            display: "none"
        },
        "& .tab-section-header": {
            padding: "10px 0",
            margin: "0 15px",
            "& .header-label": {
                display: "none"
            },
            "&::after": {
                margin: 0,
            }
        },
        "& p, a": {
            padding: "10px 0",
            textAlign: "center",
            justifyContent: "center",
            "& span": {
                display: "none"
            }
        }
    },
    "& .bottom-row": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        margin: "10px auto",
        //width: "100%",
        //marginTop: "1rem",
        //zIndex: 100,
        //position: "relative",
        "& button": {
            padding: "0.5rem 1.5rem",
            borderRadius: "0.375rem",
            backgroundColor: "#1a5dbd",
            border: "2px solid #1a5dbd",
            color: "#ffffff",
            "&:hover": {
                backgroundColor: "#4d8ae0",
                color: "#ffffff",
            },
        },
        "& p": {
            padding: "0.5rem 0",
            margin: 0,
            fontSize: "0.8rem",
            color: "#000000",
        },
        "& img.logo-image": {
            maxHeight: "34px",
            zIndex: 1, // Ensure it is above the background image
            "&.brightloop": {
                maxHeight: "24px",
            },
        },
    },
}));

const SectionLink = ({ to, component, icon, iconComponent, text, stats, enabled, end = false }: any) => {
    if (component) {
        return (
            <Box sx={{ display: 'contents' }}>
                {component}
            </Box>
        );
    }
    if (!enabled) {
        return (
            <p className="disabled">
                {iconComponent ? iconComponent : <FontAwesomeIcon icon={icon} />}<span className="link-label">&nbsp;&nbsp;{text}</span>
            </p>
        );
    }

    return (
        <Box sx={{ display: 'contents' }}>
            {/* Only show tooltip on screens < md breakpoint */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Tooltip title={<Typography variant="body1" mb={0}>{text}</Typography>} placement="right" arrow>
                    <NavLink to={to} end>
                        {iconComponent ? iconComponent : <FontAwesomeIcon icon={icon} />}<span className="link-label">&nbsp;&nbsp;{text} {false && stats && <span className="link-stats">({stats})</span>}</span>
                    </NavLink>
                </Tooltip>
            </Box>
            {/* Show without tooltip on larger screens */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <NavLink to={to} end={end}>
                    {iconComponent ? iconComponent : <FontAwesomeIcon icon={icon} />}<span className="link-label">&nbsp;&nbsp;{text} {false && stats && <span className="link-stats">({stats})</span>}</span>
                </NavLink>
            </Box>
        </Box>
    );
}

export interface SidebarSection {
    header?: string;
    links: SidebarLink[];
    visible?: boolean;
}

interface SidebarLink {
    to: string;
    component?: React.ReactNode;
    icon?: any;
    iconComponent?: React.ReactNode;
    text: string;
    stats?: string | number;
    enabled: boolean;
    end?: boolean;
    visible?: boolean;
}

interface SidebarProps {
    sections: SidebarSection[];
    bottomRow?: React.ReactNode;
}

export const Sidebar = ({ sections, bottomRow }: SidebarProps) => {
    return (
        <SidebarBox>
            <div className="deal-tabs">
                {sections.map((section, sectionIndex) => (
                    section.visible && (
                        <Box className="tab-section" key={sectionIndex}>
                            {section.header && (
                                <div className="tab-section-header">
                                    <span className="header-label">{section.header}</span>
                                </div>
                            )}
                            {section.links.filter((link) => link.visible).map((link, linkIndex) => (
                                <SectionLink
                                    key={linkIndex}
                                    to={link.to}
                                    component={link.component}
                                    icon={link.icon}
                                    iconComponent={link.iconComponent}
                                    text={link.text}
                                    stats={link.stats}
                                    enabled={link.enabled}
                                    end={link.end}
                                />
                            ))}
                        </Box>
                    )
                ))}
            </div>
            {bottomRow}
        </SidebarBox>
    );
};