import React, { useState, Fragment, useEffect } from "react";
import { NavLink as RouterNavLink, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginButton } from "./buttons/login-button";
import logo from "../assets/images/brightloop-logo-web.svg"
import { UserProfileImage, useUserContext } from "../features/users";
import Membership from "../features/users/types/Membership";
import { AppBar, Toolbar, Menu, MenuItem, Box, Typography, IconButton, Stack, styled, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useDealContext } from "../features/deal";
import { useBrandContext } from "../hooks/useBrandContext";
import { useFirmContext } from "../features/firm/context/FirmContext";

interface DisplayCompanyLogoProps {
    company: any;
}

const MenuItemOrgSelector = styled(MenuItem)(({ theme }) => ({
    "&.nav-dropdown-org-selector": {
        backgroundColor: "#f0f8ff",
        "& a": {
            width: "100%",
            display: "block",
            color: "inherit",
            textDecoration: "none",
        }
    },
    "&.has-link": {
        padding: 0,
    }
}));

const DisplayLogo = React.memo(() => {
    const { userContext } = useUserContext();
    const { brandContext } = useBrandContext();
    const { firmContext } = useFirmContext();
    const { dealContext } = useDealContext();

    const location = useLocation();

    if (!brandContext.fetched) {
        return <></>;
    }
    const organizationTag = brandContext.brand?.tag;
    const logoUrl = dealContext.project?.suppress_organization ? dealContext.project.logo_url || dealContext.project.company?.logo_url : brandContext.brand?.logo_url ?? logo.toString();

    const organization = userContext.organizationMemberships?.find((membership: Membership) => membership.organization.tag === organizationTag);
    // Determine the link based on user's role and context
    let link: string | null = null;

    if (dealContext.role === "guest") {
        link = null;
    } else if (userContext.dbUser?.external_id && organization?.organization?.tag) {
        // If user is a member of the organization, link to the organization's portfolio
        link = organization.organization.experience === "advisor" ?
            `https://${organization.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/portfolio` :
            `/o/${organization.organization.tag}`;
    } else if (userContext.dbUser?.external_id && dealContext.project?.tag) {
        // If user is a member of the project, link to the project's page
        link = dealContext.project.experience === "advisor" ?
            `https://${dealContext.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c` :
            `/o/${dealContext.project.organization.tag}/d/${dealContext.project.tag}`;
    } else if (userContext.dbUser?.external_id && location.pathname.indexOf("/admin/") === 0) {
        // If user is an admin, link to the admin page
        link = "/admin";
    } else if (userContext.dbUser?.external_id) {
        link = "/dashboard";
    }

    const LogoWrapper = ({ children }: { children: React.ReactNode }) => (
        <Box sx={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            maxHeight: '30px',
            alignItems: 'center'
        }}>
            {children}
        </Box>
    );

    const LogoContent = ({ link, withLink = true }: { link: string | null, withLink?: boolean }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Box>
                {withLink && link ? (
                    <RouterNavLink
                        to={userContext.dbUser ? link : "/"}
                        style={{ textDecoration: 'none' }}
                    >
                        {logoUrl ? <Box component="img" className="nav-logo-img" src={logoUrl} /> : <Typography sx={{ fontWeight: 700, fontSize: "2rem" }}>{brandContext.brand?.name}</Typography>}
                    </RouterNavLink>
                ) : (
                    <img className="nav-logo-img" src={logoUrl} />
                )}
            </Box>
        </Box>
    );

    // Handle admin links
    if (document.location.pathname.indexOf("/admin") === 0) {
        return <LogoContent link={link} withLink={link ? true : false} />;
    }

    // Handle evaluation links, guest links, and default case
    if (document.location.pathname.indexOf("/e/") === 0 ||
        userContext?.dbUser?.role === "guest" ||
        true) {

        return (
            <LogoWrapper>
                <LogoContent link={link} withLink={link ? true : false} />
                {dealContext.project?.tag && brandContext.brand?.tag && !dealContext.project?.suppress_organization && <DisplayCompanyLogo project={dealContext.project} />}
            </LogoWrapper>
        );
    }

    // Note: The following cases from the original code appear to be unimplemented:
    // - Handle external links
    // - Handle invitation links
});

const DisplayCompanyLogo = React.memo(({ project }: any) => {

    if (!project) {
        return <></>;
    }

    const logoUrl = project.logo_url || project.company?.logo_url;

    const CompanyLogoWrapper = ({ children }: { children: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", height: "100%", alignItems: "center" }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );

    if (logoUrl && logoUrl !== "") {
        return (
            <CompanyLogoWrapper>
                <Typography>for</Typography>
                <Box sx={{ height: "100%" }}><img className="nav-logo-img" src={logoUrl} /></Box>
            </CompanyLogoWrapper>
        );
    } else if (project?.name && project?.name !== "") {
        return (
            <CompanyLogoWrapper>
                <Typography>for</Typography>
                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>{project?.name}</Typography>
            </CompanyLogoWrapper>
        );
    }

    return <></>;
});

const NavBar = React.memo(({ hideUser = false }: { hideUser?: boolean }) => {

    const { userContext } = useUserContext();
    const { brandContext } = useBrandContext();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        user,
        isAuthenticated,
        logout,
    } = useAuth0();


    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    if (hideUser) {
        return (
            <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#f8f9fa', position: 'absolute', top: 0, height: '70px', zIndex: 1200, boxShadow: '0 0 5px #c9c9c9', width: '100%' }}>
                <Toolbar>
                    <DisplayLogo />
                </Toolbar>
            </AppBar>
        );

    }

    const organizationMemberships = (userContext?.organizationMemberships || []).sort((a: Membership, b: Membership) => {
        const nameComparison = a.organization.name.localeCompare(b.organization.name);
        if (nameComparison === 0) {
            return a.organization.id - b.organization.id;
        }
        return nameComparison;
    });

    const projectMemberships = (userContext?.projectMemberships || []).filter((projectMembership: any) => projectMembership.role === "contributor" || projectMembership.role === "admin").sort((a: any, b: any) => {
        const nameComparison = a.project.name.localeCompare(b.project.name);
        if (nameComparison === 0) {
            return a.project.id - b.project.id;
        }
        return nameComparison;
    });

    const isAdvisor = (window.location.hostname === brandContext.brand?.custom_domain || location.pathname === "advisor") && !location.pathname.includes("/invitation");
    const isInvitation = location.pathname.indexOf("invitation/") > 0;

    return (
        <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#f8f9fa', position: 'absolute', top: 0, height: '70px', zIndex: 1200, boxShadow: '0 0 5px #c9c9c9', width: '100%' }}>
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <DisplayLogo />
                    <Stack direction="row" spacing={2} alignItems="center">
                        {!hideUser && !isAuthenticated && isAdvisor &&
                            <Fragment>
                                <Box key="1">
                                    <Button variant="text" onClick={() => {
                                        window.location.href = `https://${brandContext.brand?.tag}.${process.env.REACT_APP_APP_ORIGIN}/dashboard?theme=advisor`;
                                    }}>
                                        Login
                                    </Button>
                                </Box>
                            </Fragment>
                        }

                        {!hideUser && !isAuthenticated && window.location.hostname !== brandContext.brand?.custom_domain && !(location.pathname.indexOf("invitation/") > 0) && (
                            <Fragment>
                                <Box key="2">
                                    <LoginButton />
                                </Box>
                            </Fragment>
                        )}
                        {!hideUser && isAuthenticated && user && userContext.dbUser && (
                            <Box className="navbar-profile-info" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Typography className="nav-company-name">{userContext.dbUser?.profile?.name || userContext.dbUser?.email_address}</Typography>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    className="dropdown-toggle"
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    <UserProfileImage user={userContext.dbUser} sx={{ cursor: "pointer" }} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    sx={{
                                        zIndex: 2001,
                                        "& li": { padding: 0 },
                                        "& li a": {
                                            color: "#000000",
                                            textDecoration: "none",
                                            padding: "7px 25px",
                                            width: "100%",
                                            display: "block",
                                            "&:hover": { color: "#000000", textDecoration: "none" }
                                        },
                                        "& li.active a": { color: "#ffffff" }
                                    }}
                                    className="nav-dropdown-menu"
                                >
                                    {/* User name - no link needed */}
                                    <MenuItem sx={{ fontWeight: 'bold', backgroundColor: 'white', padding: '7px 25px' }}>{user.name}</MenuItem>

                                    {/* Organization memberships */}
                                    {userContext && organizationMemberships.map((organizationMembership: Membership) => (
                                        <MenuItemOrgSelector
                                            key={organizationMembership.organization.tag}
                                            className={`nav-dropdown-org-selector has-link ${brandContext.brand?.tag === organizationMembership.organization.tag ? "active" : ""}`}
                                        >
                                            <RouterNavLink to={
                                                organizationMembership.organization.experience === "advisor" ?
                                                    `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${organizationMembership.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}/portfolio` :
                                                    `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://app.${process.env.REACT_APP_APP_ORIGIN}/o/${organizationMembership.organization.tag}`
                                            }>
                                                {organizationMembership.organization.name}
                                            </RouterNavLink>
                                        </MenuItemOrgSelector>
                                    ))}
                                    { userContext?.organizationMemberships.length === 0 && projectMemberships.length > 1 && projectMemberships.map((projectMembership: any) => (
                                        <MenuItemOrgSelector
                                            key={projectMembership.project.tag}
                                            className={`nav-dropdown-org-selector has-link ${brandContext.brand?.tag === projectMembership.project.tag ? "active" : ""}`}
                                        >
                                            <RouterNavLink to={
                                                projectMembership.project.experience === "advisor" ?
                                                    `https://${projectMembership.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c` :
                                                    `/o/${projectMembership.project.organization.tag}/d/${projectMembership.project.tag}`
                                            }>
                                                {projectMembership.project.name} ({projectMembership.project.tag})
                                            </RouterNavLink>
                                        </MenuItemOrgSelector>
                                    ))}

                                    {/* Profile link */}
                                    <MenuItem className="dropdown-profile has-link">
                                        <RouterNavLink to="/profile">
                                            <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                                        </RouterNavLink>
                                    </MenuItem>

                                    {/* Admin link */}
                                    {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                                        <MenuItem className="dropdown-admin has-link">
                                            <RouterNavLink to={`//app.${process.env.REACT_APP_APP_ORIGIN}/admin`} end={false}>
                                                <FontAwesomeIcon icon="lock" className="mr-3" /> Admin
                                            </RouterNavLink>
                                        </MenuItem>
                                    )}

                                    {/* Logout button */}
                                    <MenuItem
                                        id="qsLogoutBtn"
                                        onClick={logoutWithRedirect}
                                        sx={{ padding: '7px 25px' }}
                                    >
                                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Stack>

                </Box>
            </Toolbar>
        </AppBar>
    );
});

export default NavBar;
