import { useState, useEffect } from "react";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";

type SmartleadCampaignsData = {
    fetched: boolean;
    campaigns: any[];
    error: any;
}   

const initState: SmartleadCampaignsData = {
    fetched: false,
    campaigns: [],
    error: null,
}

export const useSmartleadCampaigns = () => {
    const { getAccessTokenSilently } = useAuth0();
    const adminClient = ClientProvider.provideAdminClient();

    const [state, setState] = useState(initState);

    const reloadCampaigns = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await adminClient.fetchSmartleadCampaigns(accessToken);

        if (data) {
            const fetchedCampaigns: SmartleadCampaignsData = {
                fetched: true,
                campaigns: data.campaigns?.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) ?? [],
                error: error
            };
            
            setState(fetchedCampaigns);
        } else {
            console.error(error);
            setState({
                fetched: true,
                campaigns: [],
                error: error
            });
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchCampaigns = async () => {
            if (!isMounted) return;
            reloadCampaigns();
        };

        fetchCampaigns();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadCampaigns };
}   