import { useEffect } from "react";

interface DocumentHeadProps {
  pageTitle: string;
  showAttribution?: boolean;
  faviconDomain?: string;
  metaDescription?: string;
}

export const DocumentTitle = ({ pageTitle, showAttribution = true, faviconDomain, metaDescription }: DocumentHeadProps) => {

    useEffect(() => {
        document.title = `${pageTitle} ${showAttribution ? '- BrightLoop' : ''}`;

        if (metaDescription) {
            let metaDesc = document.querySelector('meta[name="description"]');
            if (!metaDesc) {
                metaDesc = document.createElement('meta');
                metaDesc.setAttribute('name', 'description');
                document.head.appendChild(metaDesc);
            }
            metaDesc.setAttribute('content', metaDescription);
        }

        const sourceDomain = faviconDomain ?? window.location.hostname;
        const rootDomain = sourceDomain.replace(/^[^\.]+\./, "");
        if (rootDomain) {
            // Update favicon
            const linkElements = document.getElementsByTagName('link');
            for (let i = 0; i < linkElements.length; i++) {
                if (linkElements[i].rel === 'icon' || linkElements[i].rel === 'shortcut icon') {
                    linkElements[i].href = `https://www.google.com/s2/favicons?domain=${rootDomain}&sz=128`;
                }
            }
        }
    }, [pageTitle, faviconDomain, metaDescription]);

    return null;
};