import { CompanyTitle } from "../components/CompanyTitle";
import { useEffect, useState } from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import { GuestList } from "../components/collaboration/GuestList";
import { styled, Tab, Tabs } from "@mui/material";
import { UsersList } from "../components/collaboration/UsersList";
import { useDealContext } from "../../deal/context";
import { ExecTab, ExecTabs } from "../styles";


export const CompanyCollaboration = () => {
    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const viewerRole = dealContext.role;
    const organization = dealContext.organization;
    const { userContext } = useUserContext();
    const [ value, setValue ] = useState(0);


    const { trackPage } = useAnalytics();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - collaboration`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
        }
    }, [userContext]);

    const handleTabSwitch = (event: any, newValue: any) => {

        setValue(newValue);
    };

    return (
        <div>
            <CompanyTitle viewerRole={viewerRole} project={project} section={`${project.name} Team`} />
            <UsersList project={project} viewerRole={viewerRole} />
        </div>
    );
}   