import { useDealContext } from "../../deal/context";
import { Box } from "@mui/material";
import { CompanyTitle } from "../components/CompanyTitle";
import { InvestmentList } from "../components/investment/InvestmentList";
import { useUserContext } from "../../users/context";
import Loading from "../../../components/Loading";
import { useAdvisors } from "../../advisor/context/AdvisorsContext";

export const CompanyInvestment = () => {

    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const viewerRole = dealContext.role;
    const { userContext } = useUserContext();
    const user = userContext.dbUser;
    const { advisors, loading, fetchAdvisors } = useAdvisors();

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <CompanyTitle viewerRole={viewerRole} project={project} section="Investment" subheading="Invite your strategic investors to participate in your round." />
            <InvestmentList project={project} advisors={advisors} user={user} angelStatus="Funded" />
            <InvestmentList project={project} advisors={advisors} user={user} angelStatus="Reserved" />
            <InvestmentList project={project} advisors={advisors} user={user} angelStatus="Soft-Circled" />
        </Box>
    );
}