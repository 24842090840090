import ProjectHttpClient from "../core/infrastructure/clients/ProjectHttpClient";
import GuestHttpClient from "../core/infrastructure/clients/GuestHttpClient";
import OrganizationHttpClient from "../core/infrastructure/clients/OrganizationHttpClient";
import AdvisorHttpClient from "../core/infrastructure/clients/AdvisorHttpClient";
import UserHttpClient from "../core/infrastructure/clients/UserHttpClient";
import CompanyHttpClient from "../core/infrastructure/clients/CompanyHttpClient";
import AdminHttpClient from "../core/infrastructure/clients/AdminHttpClient";

const ClientProvider = {
    provideProjectClient: () => {
        return new ProjectHttpClient();
    },

    provideGuestClient: () => {
        return new GuestHttpClient();
    },
    provideOrganizationClient: () => {
        return new OrganizationHttpClient();
    },

    provideAdvisorClient: () => {
        return new AdvisorHttpClient();
    },

    provideUserClient: () => {
        return new UserHttpClient();
    },

    provideCompanyClient: () => {
        return new CompanyHttpClient();
    },

    provideAdminClient: () => {
        return new AdminHttpClient();
    }
}

export default ClientProvider;