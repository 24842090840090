import { useEffect, useState } from "react";
import { getAdvisorByProjectAndUuid, getAdvisorByUuid, getCompanyLanding } from "../../../core/services/gutcheck-api.service";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../features/users/context";
import { Box, Paper, Typography, Button, Stack, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useDealContext } from "../../../features/deal/context";
import { useSubdomain } from "../../../hooks/useSubdomain";


const RequestLink = ({ advisor, project, open, onClose }: { 
    advisor: any; 
    project: any; 
    open: boolean; 
    onClose: () => void; 
}) => {

    const [ state, setState ] = useState<string>("");

    const handleRequestLink = async () => {
        setState("loading");
        const { data, error } = await getAdvisorByProjectAndUuid(project.tag, advisor.uuid);
        if (data) {
            setState("success");
        }
    }

    const handleClose = () => {
        setState("");
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <h5>Are you {advisor.name}?</h5>
            <p>
                We'll send a link to your advisor profile to view the details of the meeting.
            </p>
            
            <Button disabled={state === "loading" || state === "success"} variant="contained" color={state === "success" ? "success" : "primary"} onClick={handleRequestLink}>
                {state === "loading" ? "Requesting......" : state === "success" ? "Link Sent" : "Request Link"}
            </Button>
        </Dialog>
    );
}

export const Meeting = () => {
    const { userContext } = useUserContext();
    const { dealContext, setDealContext } = useDealContext();
    const { subdomain, subdomainFetched } = useSubdomain();
    const { advisorUuid } = useParams();
    const [advisor, setAdvisor] = useState<any>(null);
    const [project, setProject] = useState<any>(null);
    const [requestLinkOpen, setRequestLinkOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchAdvisor = async () => {
            if (advisorUuid) {
                const { data, error } = await getAdvisorByUuid(advisorUuid);
                if (data) {
                    setAdvisor(data.advisor);
                }
            }
        }

        const fetchProject = async () => {
            if (subdomain) {
                const { data, error } = await getCompanyLanding(subdomain);
                if (data) {
                    setProject(data.surveyLink.project);
                    setDealContext({
                        project: data.surveyLink.project,
                        organization: data.surveyLink.project.organization,
                        role: data.role,
                        fetched: true
                    });
                }
            }
        }

        if (advisorUuid && subdomainFetched) {
            fetchAdvisor();
            fetchProject();
        }
    }, [advisorUuid, subdomainFetched]);

    if (!advisor) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                bgcolor: 'background.default',
                width: '100%',
                paddingTop: '80px'
            }}
        >
            <Box className="display-box white"
                sx={{
                    p: 4,
                    maxWidth: 400,
                    width: '100%',
                }}
            >
                <Typography
                    variant="h4"
                    component="h2"
                    textAlign="center"
                    gutterBottom
                    fontWeight="bold"
                    fontSize="1.2rem"
                    marginBottom="20px"
                >
                    View details for the upcoming meeting between {advisor.name} and {project?.name}
                </Typography>
                <Typography
                    variant="h5"
                    component="h5"
                    textAlign="center"
                    gutterBottom
                    fontWeight="bold"
                    fontSize="1rem"
                >
                    Select Your Role
                </Typography>
                
                <Stack spacing={2} mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href={`/c/meetings/upcoming/${advisor.uuid}`}
                        fullWidth
                    >
                        I'm with {project?.name}
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={() => setRequestLinkOpen(true)}
                    >
                        I'm {advisor.name}
                    </Button>
                </Stack>
            </Box>
            <RequestLink 
                advisor={advisor} 
                project={project} 
                open={requestLinkOpen} 
                onClose={() => setRequestLinkOpen(false)} 
            />
        </Box>
    );
}