import { TableBody, TableCell, TableHead, TableRow, TableProps, Typography, TableContainer, Rating, Button, Box, Dialog } from "@mui/material"
import { ViewpointCompany, ViewpointProfileSummary } from "../../../viewpoints";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { AdvisorTable } from "../../styles/AdvisorStyles";
import { DoNotDisturb, Star, StarBorder } from '@mui/icons-material';
import { DateDisplay } from "../../../meeting";
import { AdvisorPreview } from "../../../advisor";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { IMeeting, IProject } from "../../../../types";
import { AdvisorCompany, AdvisorProfileSummary } from "../../../advisor";

const ratingLabels: { [index: number]: string } = {
    0: "NA - I did not attend this meeting",
    1: 'No-show or complete miss',
    2: 'Not the best use of my time',
    3: 'Worthwhile, I\'d do more of these',
    4: 'Great call, with next steps',
};

const RatingDialog = ({ meeting, setIsRatingDialogOpen, onRate, onClose }: { meeting: IMeeting, setIsRatingDialogOpen: (open: boolean) => void, onRate: (meetingUuid: string, rating: number | null, markAsOpportunity: boolean) => void, onClose: () => void }) => {
    const [rating, setRating] = useState<number | null>(meeting.ratings?.[0]?.rating || null);
    const [isOpportunity, setIsOpportunity] = useState(false);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);

    const handleRatingChange = (_event: any, newValue: number | null) => {
        setRating(newValue);
        if (newValue !== null) {
            onRate(meeting.uuid, newValue, isOpportunity);
        }
    };

    if (!meeting.advisor.user || !meeting.advisor.viewpoints) {
        return null;
    }

    return (
        <Dialog 
            open={true} 
            onClose={onClose} 
            maxWidth={false}
            PaperProps={{
                sx: {
                    width: '550px',
                    maxWidth: '550px'
                }
            }}
        >
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                        <h5>Please rate your meeting with {meeting.advisor.user.profile.name.split(' ')[0]} at {meeting.advisor.user.profile?.company?.name}</h5>
                        <p>We hope you had a great meeting with {meeting.advisor.user.profile.name.split(' ')[0]} at {meeting.advisor.user.profile?.company?.name}. Please rate this meeting so that we can steer more of the right introductions your way.</p>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', backgroundColor: "#ffffff", borderRadius: "20px", padding: "20px", border: "1px solid #c9c9c9" }}>
                            <AdvisorPreview advisor={meeting.advisor} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: "40px", width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <DoNotDisturb
                                            onClick={() => {
                                                onRate(meeting.uuid, 0, isOpportunity);
                                                setIsRatingDialogOpen(false);
                                            }}
                                            onMouseOver={(e) => {
                                                setHoveredRating(0);
                                            }}
                                            onMouseLeave={(e) => {
                                                setHoveredRating(-1);
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                width: '50px',
                                                height: '50px',
                                                paddingBottom: '3px',
                                                color: rating === 0 ? 'error.main' : 'action.disabled',
                                                '&:hover': { color: 'error.main' }
                                            }}
                                        />
                                        <Rating
                                            value={rating}
                                            max={4}
                                            size="large"
                                            icon={<Star sx={{ width: '50px', height: '50px' }} />}
                                            emptyIcon={<StarBorder sx={{ width: '50px', height: '50px' }} />}
                                            onChange={handleRatingChange}
                                            onChangeActive={(_event, newHover) => {
                                                setHoveredRating(newHover);
                                            }}
                                        />
                                    </Box>
                                    <Typography>
                                        {hoveredRating !== null && hoveredRating !== -1
                                            ? ratingLabels[hoveredRating]
                                            : rating !== null
                                                ? ratingLabels[rating]
                                                : "Please rate the meeting"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", paddingTop: "20px", paddingBottom: "20px", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "1.2rem", fontWeight: "700" }}>Did this turn into an opportunity?</Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                                
                                <Box
                                    sx={{ 
                                        display: 'inline-flex',
                                        backgroundColor: 'background.paper',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        overflow: 'hidden'
                                    }}
                                >
                                    <Button
                                        sx={{
                                            borderRadius: 0,
                                            borderRight: '1px solid',
                                            borderColor: 'divider',
                                            backgroundColor: !isOpportunity ? 'grey.700' : 'transparent',
                                            color: !isOpportunity ? 'primary.contrastText' : 'text.primary',
                                            '&:hover': {
                                                backgroundColor: !isOpportunity ? 'grey.800' : 'action.hover',
                                            }
                                        }}
                                        onClick={() => {
                                            setIsOpportunity(false);
                                            if (rating !== null) {
                                                onRate(meeting.uuid, rating, false);
                                            }
                                        }}
                                    >
                                        Not Yet
                                    </Button>
                                    <Button
                                        sx={{
                                            borderRadius: 0,
                                            backgroundColor: isOpportunity ? 'primary.dark' : 'transparent',
                                            color: isOpportunity ? 'primary.contrastText' : 'text.primary',
                                            '&:hover': {
                                                backgroundColor: isOpportunity ? 'primary.900' : 'action.hover',
                                            }
                                        }}
                                        onClick={() => {
                                            setIsOpportunity(true);
                                            if (rating !== null) {
                                                onRate(meeting.uuid, rating, true);
                                            }
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <p>Understanding if there's an opportunity helps us nurture them more effectively.</p>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export const CompletedList = ({ project, meetings, reloadData }: { project: IProject, meetings: IMeeting[], reloadData: () => void }) => {

    const projectHttpClient = new ProjectHttpClient();
    const { getAccessTokenSilently } = useAuth0();
    const [hoveredMeeting, setHoveredMeeting] = useState<string | null>(null);
    const [isRatingDialogOpen, setIsRatingDialogOpen] = useState(false);

    const { meetingUuid } = useParams();

    useEffect(() => {
        if (meetingUuid) {
            setHoveredMeeting(meetingUuid);
            setIsRatingDialogOpen(true);
        }
    }, [meetingUuid]);

    const handleRateAndOpportunity = async (meetingUuid: string, rating: number | null, markAsOpportunity: boolean) => {
        if (rating === null) return;

        const accessToken = await getAccessTokenSilently();

        // Rate the meeting
        const ratingResult = await projectHttpClient.rateMeeting(project.id, meetingUuid, rating, accessToken);

        // Update advisor stage based on opportunity status
        if (ratingResult.data) {
            const meeting = meetings.find((m: IMeeting) => m.uuid === meetingUuid);
            const stage = markAsOpportunity ? "opportunity" : "advisor";
            if (meeting?.advisor.uuid) {
                await projectHttpClient.updateAdvisorStage(project.id, meeting.advisor.uuid, stage, accessToken);
            }
        }

        if (!isRatingDialogOpen) {
            reloadData();
        }
    };

    const handleMarkAsOpportunity = async (advisorUuid: string, event: React.MouseEvent) => {
        event.stopPropagation();

        // If the meeting hasn't been rated, open the rating dialog
        if (meetings.find((meeting: IMeeting) => meeting.advisor.uuid === advisorUuid)?.ratings?.length === 0) {
            setIsRatingDialogOpen(true);
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectHttpClient.updateAdvisorStage(project.id, advisorUuid, "opportunity", accessToken);
        if (data) {
            reloadData();
        }
    }

    const handleCloseRatingDialog = () => {
        setIsRatingDialogOpen(false);
        reloadData();
    }

    if (!meetings) {
        return <Loading />;
    }

    const selectedMeeting = meetings.find((m: IMeeting) => m.uuid === hoveredMeeting);

    return (
        <>
            {isRatingDialogOpen && hoveredMeeting && selectedMeeting && (
                <RatingDialog
                    meeting={selectedMeeting}
                    setIsRatingDialogOpen={setIsRatingDialogOpen}
                    onRate={handleRateAndOpportunity}
                    onClose={handleCloseRatingDialog}
                />
            )}
            <TableContainer sx={{ marginBottom: "60px", marginTop: "20px" }}>
                <AdvisorTable>
                    <TableHead>
                        <TableRow key="header">
                            <TableCell className="company">Company</TableCell>
                            <TableCell className="profile">Advisor</TableCell>
                            <TableCell className="center">Meeting Info</TableCell>
                            <TableCell className="center">Rating</TableCell>
                            <TableCell className="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meetings.sort((a: IMeeting, b: IMeeting) => new Date(b.event_start!).getTime() - new Date(a.event_start!).getTime()).map((meeting: IMeeting) => (
                            <CompanyAdvisorLink uuid={meeting.advisor.uuid} key={`link-${meeting.uuid}`}>
                                <TableRow key={meeting.uuid}>
                                    <TableCell className="company"><AdvisorCompany advisor={meeting.advisor} /></TableCell>
                                    <TableCell className="profile"><AdvisorProfileSummary advisor={meeting.advisor} /></TableCell>
                                    <TableCell className="center">
                                        <DateDisplay meeting={meeting} showTime={false} />
                                    </TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()} sx={{ width: '200px' }}>
                                        <Box sx={{
                                            minHeight: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            flexDirection: 'column',
                                            gap: '5px'
                                        }}
                                            onClick={() => {
                                                setHoveredMeeting(meeting.uuid);
                                                setIsRatingDialogOpen(true);
                                            }}>
                                            <Rating
                                                value={meeting.ratings?.length && meeting.ratings[0].rating ? meeting.ratings[0].rating : null}
                                                max={4}
                                                readOnly
                                            />
                                            <Typography component="legend" variant="body2">

                                                {meeting.ratings?.length && meeting.ratings[0].rating ? (
                                                    ratingLabels[meeting.ratings[0].rating]
                                                ) : "Please rate the meeting"}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="center" onClick={(e) => e.stopPropagation()}>
                                        <span className="link" onClick={(e) => {
                                            setHoveredMeeting(meeting.uuid);
                                            handleMarkAsOpportunity(meeting.advisor.uuid, e);
                                        }}>
                                            Mark as Opportunity
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </CompanyAdvisorLink>
                        ))}
                    </TableBody>
                </AdvisorTable>
            </TableContainer>
        </>
    )
}