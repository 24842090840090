import { useEffect, useState } from "react";
import { getAdvisorViewpointHtml } from "../../../core/services/gutcheck-api.service";

export const useGetViewpointHtml = (respondentId: string) => {

    const [state, setState] = useState({
        viewpointHtml: {
            __html: ""
        },
    });

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            if (!respondentId) {
                setState({
                    ...state,
                    viewpointHtml: { __html: '' }
                });
                return;
            }
        
            const { data } = await getAdvisorViewpointHtml(respondentId);

            if (!isMounted) return;

            if (data) {
                setState({
                    ...state,
                    viewpointHtml: { __html: data.html }
                });
            };
        };

        fetchData(); 

        return () => { isMounted = false };
    }, [respondentId]);

    return [state.viewpointHtml];
};