import { Box, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { Link } from "react-router-dom";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { useDealContext } from "../../deal/context";
import { useAdvisor } from "../context/AdvisorContext";
import { AdvisorTable } from "../../company/styles/AdvisorStyles";
import { IViewpoint } from "../../../types";
import dayjs from "dayjs";
import Loading from "../../../components/Loading";
import { ActiveAdvisors } from "../components/ActiveAdvisors";

export const AdvisorFeedback = () => {
    const { dealContext } = useDealContext();
    const { advisor, loading } = useAdvisor();
    const project = dealContext.project;
    const viewerRole = dealContext.role;

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <AdvisorTitle project={project} section="Feedback" subheading={`React to product demos, participate in market surveys, and provide tips and insights along the way.`} />

            <h5>Demos and Surveys</h5>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "40px" }}>
                <Box sx={{ flex: "1 1" }}>
                    <AdvisorTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Date Completed</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {advisor?.viewpoints?.map((viewpoint: IViewpoint) => (
                                <TableRow key={viewpoint.touchpoint?.uuid}>
                                    <TableCell><Link to={`/advisor/feedback/${viewpoint.touchpoint?.uuid}`}>{viewpoint.touchpoint?.name}</Link></TableCell>
                                    <TableCell>{dayjs(viewpoint.created_at).format('MMM D, YYYY')}</TableCell>
                                    <TableCell>Completed</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </AdvisorTable>
                </Box>
                <Box sx={{ flex: "0 1 250px" }}>
                    <ActiveAdvisors advisor={advisor} project={project} />
                </Box>
            </Box>
        </Box>
    );
}