import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons/faClock';


export const EvaluationSummary = ({ advisor, investmentUnlocked }: any) => {

    const project = advisor?.survey_link?.project;
    const organization = project?.organization;

    const displayCompany = investmentUnlocked || project.show_company || (advisor.invited_to_invest && project.reveal_name_on_approval);

    return (
        <>
            <div className="display-box evaluation-details">
                <h5>Lead Investor</h5>
                {organization?.logo_url ? <img className="organization-logo" src={organization.logo_url} alt={organization.name} /> : <p>{organization.name}</p>}
                <p>{organization.description}</p>
            </div>
            <div className="display-box evaluation-details">
                <h5>Company</h5>
                {displayCompany && project?.logo_url ? <img className="organization-logo" src={project?.logo_url} alt={project?.name} /> : displayCompany && project?.name ? <p>{project.name}</p> : <div className="name-tba flex-row"><div className="tba-icon"><FontAwesomeIcon icon={faClockRegular} /></div><div>Not Yet Disclosed</div></div>}
                {displayCompany && project?.public_description && <p>{project.public_description}</p>}
            </div>
            {investmentUnlocked && false &&
                <div className="display-box evaluation-details">
                    <h5>Expert Lead</h5>
                    <div className="expert-lead-head">
    
                        <div className="activity-photo-wrapper">
                            <img className="activity-photo" src="https://video-upload-svpvc-1.s3.us-east-1.amazonaws.com/media/FileGroup1/7874240a-15cb-4bc5-90cb-3ffbed18f50b.0000000.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Credential=AKIAQ5CGWV24C5VXNHR4%2F20240514%2Fus-east-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20240514T224104Z&amp;X-Amz-Expires=3600&amp;X-Amz-Signature=caaa6b8a6895a772b5ad10404d363ae676ea4eb51f138c21717b28f0db3f2cca&amp;X-Amz-SignedHeaders=host&amp;x-id=GetObject" alt="user" height="30" />
                        </div>
                        <p><strong>Rhonda SMith</strong><br />
                            Director of Marketing</p>
                    </div>
                    <p>Rhonda Smith has participated as an angel in over 12 companies and has worked for Airbnb and Uber.</p>
                </div>
            }
        </>
    )
}