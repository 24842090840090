import { IUser } from "./User";
import { IAdvisor } from "./Advisor";
import { IProject } from "./Project";
import { IRating } from "./Rating";
import { IScheduler } from "./Scheduler";

export interface IMeeting {
    id: number;
    uuid: string;
    project: IProject;
    advisor: IAdvisor;
    description?: string;
    status: MeetingStatus;
    calendly_id?: string;
    calendly_uri?: string;
    calendly_invitee_uri?: string;
    event_start?: Date;
    event_duration?: number;
    requested: boolean;
    requested_at?: Date;
    scheduled: boolean;
    scheduled_at?: Date;
    requested_by: IUser;
    request_note?: string;
    created_at: Date;
    updated_at: Date;
    ratings?: IRating[];
    scheduler?: IScheduler;
}

export enum MeetingStatus {
    REQUESTED = "requested",
    SCHEDULED = "scheduled",
    COMPLETED = "completed",
    CANCELLED = "cancelled"
}