import { OrganizationInviteControl } from "../../workspaces/components/organization-invite";
import { FirmActivity } from "../components/FirmActivity";
import { useFirmContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, Fragment } from "react";
import { AddCompany } from "../components/AddCompany";
import { Link } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ProjectLogo } from "../components/ProjectLogo";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../modules/ClientProvider";
import { getOrganizationStats } from "../../../core/services/gutcheck-api.service";
import { OrganizationTitle } from "../components/OrganizationTitle";
import { InvitationControl } from "../components/team/InvitationControl";
import { RecruitmentBox } from "../../company/styles/AdvisorStyles";
import { Plus, AlertCircle } from "lucide-react";

const StatsBox = styled(Box)({
    padding: "20px 10px",
    fontSize: "1rem",
    textAlign: "right",

});

interface ProjectsTableProps {
    stats?: any;
}

const ProjectsTable = styled(Table)<ProjectsTableProps>({
    "& .MuiTableRow-root": {
        paddingTop: "8px",
        "&:nth-of-type(4n+4), &:nth-of-type(4n+5)": {
            backgroundColor: "#f8f9fa",  // Light gray background for every other pair of rows
        },
        "& .MuiTableCell-root": {
            padding: "0 15px 0 10px",
            borderRight: "1px solid #e0e0e0",
            borderBottom: "0",
            borderTop: "0",
            borderLeft: "0",
            width: "20%",
            textAlign: "right",
            "&:nth-of-type(1)": {
                width: "120px",
                borderRight: "0",
                textAlign: "left",
            }
        },

        "&.attribution .MuiTableCell-root": {
            paddingTop: "0",
            fontSize: "0.875rem",
            color: "#000",
            paddingBottom: "10px",
            fontWeight: "900",
            "&:first-child": {
                fontWeight: "500",
            }
        },
        "&.project-stats .MuiTableCell-root": {
            fontWeight: "900",
            fontSize: "1.5rem",
            paddingBottom: "0",
            paddingTop: "8px",
            color: "#2196f3",  // Blue color for numbers
            "& a": {
                textDecoration: "none",
                fontSize: "1.2rem",
                fontWeight: "900",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                "&:hover": {
                    textDecoration: "underline"
                }
            },
            "& span.not-activated": {
                color: "#999",
                fontSize: "1rem",
                fontWeight: "500",
            }
        },
        "&.overall-stats .MuiTableCell-root": {
            paddingBottom: "0",
            "& span": {
                fontSize: "1.2rem",
                color: "#000",
                fontWeight: "900",
            },
            "& strong": {
                color: "#2196f3"  // Blue color for numbers

            },
            "& h5": {
                fontWeight: "900",
                fontSize: "4rem",
                margin: "10px 0 0 0",
                color: "#2196f3"  // Blue color for numbers
            }
        },
        "&.add-company .MuiTableCell-root": {
            paddingTop: "20px",
            fontSize: "1rem",
        }
    }
});
export const FirmPortfolio = () => {

    const { firmContext } = useFirmContext();

    const { organization, role } = firmContext;
    const [projects, setProjects] = useState<any[]>([]);

    const [filteredProjects, setFilteredProjects] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [inviteActive, setInviteActive] = useState(false);
    const projectClient = ClientProvider.provideProjectClient();

    const [stats, setStats] = useState<any>({
        totalAdvisors: 0,
        totalMeetings: 0,
        totalOpportunities: 0,
        totalCustomers: 0,
        projects: []
    });

    useEffect(() => {
        const loadStats = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getOrganizationStats(organization.tag, accessToken);
            if (data) {
                setStats(data.stats);
            }
        };

        loadStats();
    }, [organization]);

    useEffect(() => {
        if (organization?.projects && stats.projects) {
            const sortedProjects = organization.projects.sort((a: any, b: any) => {
                // First sort by experience (advisor first)
                if (a.experience !== b.experience) {
                    return a.experience === "advisor" ? -1 : 1;
                }
                // Then sort by totalAdvisors within each group
                const statsA = stats.projects.find((p: any) => p.key === a.tag);
                const statsB = stats.projects.find((p: any) => p.key === b.tag);
                return (statsB?.totalAdvisors || 0) - (statsA?.totalAdvisors || 0);
            });
            setFilteredProjects(sortedProjects);
        }
    }, [organization?.projects, stats.projects]);

    const handleAddDeal = () => {
        setModalOpen(true);
    }

    const calculateColumnWidth = () => {
        if (!stats) return "20%";

        const visibleColumns = [
            true, // First column (company name) is always visible
            true, // Advisors column is always visible
            stats.totalMeetings > 0,
            stats.totalOpportunities > 0,
            stats.totalCustomers > 0
        ].filter(Boolean).length;
        return `${100 / (visibleColumns - 1)}%`;
    };

    if (!organization) {
        return <div>Loading...</div>;
    }

    return (
        <div className="dashboard">

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                <OrganizationTitle organization={organization} section="Dashboard" subheading="Intros, opportunities and deals generated for portfolio companies" />
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={handleAddDeal}
                >
                    <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Company
                </Button>
            </Box>

            <Box className="flex-column workspace-dashboard" sx={{ gap: "40px"}}>
                {filteredProjects.length > 0 && (
                    <TableContainer sx={{ marginBottom: "40px" }}>
                        <ProjectsTable stats={stats}>
                            <TableBody>
                                <TableRow className="overall-stats">
                                    <TableCell sx={{ width: "120px" }}></TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <span>Light Touch<br /><strong>ADVISORS</strong></span>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <span>Customer<br /><strong>INTROS</strong></span>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <span>Customer<br /><strong>OPPORTUNITIES</strong></span>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <span>Customer<br /><strong>DEALS</strong></span>
                                    </TableCell>
                                </TableRow>
                                <TableRow className="overall-stats">
                                    <TableCell sx={{ width: "120px", verticalAlign: "bottom", paddingBottom: "10px", fontSize: "1.2rem", fontWeight: "900" }}>TOTAL</TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <h5>{stats.totalAdvisors > 0 ? stats.totalAdvisors : "-"}</h5>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <h5>{stats.totalMeetings > 0 ? stats.totalMeetings : "-"}</h5>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <h5>{stats.totalOpportunities > 0 ? stats.totalOpportunities : "-"}</h5>
                                    </TableCell>
                                    <TableCell sx={{ width: calculateColumnWidth() }}>
                                        <h5>{stats.totalCustomers > 0 ? stats.totalCustomers : "-"}</h5>
                                    </TableCell>
                                </TableRow>
                                <TableRow className="attribution">
                                    <TableCell>from {organization.name}</TableCell>
                                    <TableCell>{stats.totalAdvisors > 0 ? stats.totalAdvisors : "-"}</TableCell>
                                    <TableCell>{stats.totalMeetings > 0 ? stats.totalMeetings : "-"}</TableCell>
                                    <TableCell>{stats.totalOpportunities > 0 ? stats.totalOpportunities : "-"}</TableCell>
                                    <TableCell>{stats.totalCustomers > 0 ? stats.totalCustomers : "-"}</TableCell>
                                </TableRow>
                                {filteredProjects.map((project: any) => {
                                    const projectStats = stats.projects.find((p: any) => p.key === project.tag);
                                    return (
                                        <Fragment key={project.id}>
                                            <TableRow key={`${project.id}-stats`} className="project-stats">
                                                <TableCell>
                                                    <Link to={project.experience === "advisor" ? `//${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c` : `/o/${project.organization.tag}/d/${project.tag}`}>
                                                        {project.name}
                                                        {project.experience !== "advisor" && (
                                                            <Tooltip title="Introductions are not activated for this company" placement="top" arrow>
                                                                <span style={{ marginLeft: '8px', color: '#999999', display: 'inline-flex', verticalAlign: 'middle' }}>
                                                                    <AlertCircle size={16} style={{ marginBottom: '5px' }} />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {projectStats?.totalAdvisors > 0 ? projectStats?.totalAdvisors : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalMeetings > 0 ? projectStats?.totalMeetings : "-" : <span className="not-activated">Not Activated</span>}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalOpportunities > 0 ? projectStats?.totalOpportunities : "-" : <span className="not-activated">Not Activated</span>}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalCustomers > 0 ? projectStats?.totalCustomers : "-" : <span className="not-activated">Not Activated</span>}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${project.id}-attribution`} className="attribution">
                                                <TableCell>from {organization.name}</TableCell>
                                                <TableCell>
                                                    {projectStats?.totalAdvisors > 0 ? projectStats?.totalAdvisors : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalMeetings > 0 ? projectStats?.totalMeetings : "-" : <span className="not-activated"></span>}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalOpportunities > 0 ? projectStats?.totalOpportunities : "-" : <span className="not-activated"></span>}
                                                </TableCell>
                                                <TableCell>
                                                    {project.experience === "advisor" ? projectStats?.totalCustomers > 0 ? projectStats?.totalCustomers : "-" : <span className="not-activated"></span>}
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    )
                                })}

                            </TableBody>
                        </ProjectsTable>
                    </TableContainer>
                )}

                {filteredProjects.length === 0 && (
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px", width: "100%" }}>
                        <Link to="" style={{ width: "100%" }} onClick={handleAddDeal}>
                            <RecruitmentBox>
                                <Plus className="icon" size={24} />
                                <Typography variant="h6">Add a Company</Typography>
                                <Typography>
                                    Get started by adding and inviting a portfolio company
                                </Typography>
                            </RecruitmentBox>
                        </Link>
                    </Box>
                )}

            </Box>
            {stats.totalAdvisors > 4 && (
                <Box className="" sx={{ margin: "60px 0 40px 0", }}>
                    <h2>Recent Advisors</h2>
                    <FirmActivity organization={organization} projects={organization.projects} />
                </Box>
            )}
            <AddCompany modalOpen={modalOpen} setModalOpen={setModalOpen} organization={organization} />
            <InvitationControl organization={organization} role={role} active={inviteActive} setActive={setInviteActive} reloadInvitations={() => { }} />

        </div>
    );
}