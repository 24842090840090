import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";

export const InvitationReview = () => {
  const { invitation, loading } = useOutletContext<{ invitation: any, loading: boolean }>();
  const { trackEvent } = useAnalytics();

  const navigate = useNavigate();

  const {
    isLoading,
    isAuthenticated,
    user,
  } = useAuth0();

  useEffect(() => {
    if (!loading && invitation) {
      trackEvent("Viewed invitation", { organization: invitation?.organization?.name, project: invitation?.project?.name, invitation_code: invitation.invitation_code, invitee_email: invitation.invitee_email });
    }

    if (!loading && invitation.is_redeemed && !isLoading && user?.email?.toLowerCase() === invitation?.invitee_email.toLowerCase()) {

      if (invitation.project) {
        if (invitation.project.experience === "advisor") {
          window.location.href = `https://${invitation.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c`;
          return;
        } else {
          const projectUrl = invitation.project.experience === "winning" ? `/o/${invitation.organization.tag}/d/${invitation.project.tag}` : `/o/${invitation.organization.tag}/project/${invitation.project.tag}`;
          return navigate(projectUrl, { replace: true, state: { invitationAccepted: true } })
        }
      } else if (invitation.organization) {
        return navigate(`/o/${invitation.organization.tag}`, { replace: true, state: { invitationAccepted: true } })
      }
    }
  }, [loading, invitation, user]);

  const handleAccept = () => {
    return navigate(`/invitation/${invitation?.invitation_code}/accept`);
  }

  if (loading || isLoading) {
    return <></>;
  }



  if ((!loading && !invitation) || (!loading && invitation.is_redeemed) || (!loading && !isLoading && user?.email?.toLowerCase() !== invitation?.invitee_email.toLowerCase())) {
    return (
      <main className="page-main">
        <div className="gc_view">
          <div className="text-center hero my-5">
            <p className="invitation-text">This invitaion is no longer valid or has already been used.</p>
          </div>
        </div>
      </main>
    )
  }

  return (
    <main className="page-main">
      <div className="gc_view">
        <div className="text-center hero my-5">

          {invitation.project ?
            invitation.project.suppress_organization ?
            <p className="invitation-intro">
              You've been invited to review insights and opportunities for<br />
              <span className="invitation-organization">{invitation.project.name}</span><br />
            </p> :
            <p className="invitation-intro">
              You've been invited to review insights and opportunities<br />
              <span className="invitation-organization">{invitation.organization.name}</span> has collected for<br />
              <span className="invitation-organization">{invitation.project.name}</span><br />
            </p>
            :
            <p className="invitation-intro">
              Ready to collaborate with the<br />
              <span className="invitation-organization">{invitation.organization.name}</span><br />
              team on BrightLoop?
            </p>
          }
          <Button
            id="qsSignupBtn"
            color="primary"
            variant="contained"
            className="invitation-button"
            onClick={handleAccept}>
            Accept Invitation
          </Button>
        </div>
      </div>
    </main>
  )
};
