import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CompanySidebar } from "../components/CompanySidebar";
import { CompanyCollaborationModal } from "../components/CompanyCollaborationModal";
import { CompanyAdvisorModal } from "../components/CompanyAdvisorModal";
import { useDealContext } from "../../deal/context";
import { useAdvisors } from "../../advisor/context/AdvisorsContext";
import Loading from "../../../components/Loading";
import { Dialog } from "@mui/material";
import { UserProfileUpdate } from "../../users/components/UserProfileUpdate";
import { useUserContext } from "../../users/context";
import { useFirmContext } from "../../firm/context";

export const Company = () => {
    const [openAdvisorId, setOpenAdvisorId] = useState<string | null>(null);
    const location = useLocation();
    const { dealContext } = useDealContext();
    const navigate = useNavigate();
    const { advisors, fetchAdvisors } = useAdvisors();
    const { userContext, loadUserContext } = useUserContext();
    const { firmContext } = useFirmContext();
    // Sets the initial viewpoint from the URL hash
    useEffect(() => {
        const hashValue = location.hash.slice(1);
        setOpenAdvisorId(hashValue || null);
    }, [location.hash]);

    useEffect(() => {
        if (dealContext.project?.status === "draft" && !/\/setup$/.test(location?.pathname)) {
            navigate("setup");
        }
    }, [location.pathname, dealContext.project?.status]);

    useEffect(() => {
        if (dealContext.project?.id) {
            fetchAdvisors(dealContext.project.id);
        }
    }, [dealContext.project?.id]);

    if (!dealContext.fetched) {
        return <Loading />;
    }

    if (dealContext.role === "advisor") {
        navigate("/advisor");
        return;
    }

    return (
        <>
            <aside className="page-sidebar">
                <CompanySidebar />
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet context={{ openViewpointId: openAdvisorId }} />
            </main>
            <CompanyCollaborationModal />
            {openAdvisorId && <CompanyAdvisorModal uuid={openAdvisorId} />}
            {userContext.dbUser?.profile && !userContext.dbUser?.profile?.user_reviewed &&
                <Dialog open={true} onClose={() => {}}>
                    <h5>Welcome to the {dealContext.project.name} Advisor Network</h5>
                    <p>Please take a moment to review and update your profile.</p>
                    <UserProfileUpdate user={userContext.dbUser} onUpdate={loadUserContext} />
                </Dialog>
            }
        </>
    );
}