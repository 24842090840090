import { Box, Stack } from "@mui/material";
import { useDealContext } from "../../../deal/context";
import { CompanyTitle } from "../../components/CompanyTitle"
import { InvitationTemplates } from "../../components/outreach/InvitationTemplates";

export const OutreachLinks = () => {
    const { dealContext } = useDealContext();
    const { project, role } = dealContext;

    return (
        <>
            <CompanyTitle 
                viewerRole={role} 
                project={project} 
                section="Outreach > Invitation Links" 
                subheading="Copy and paste any of the links in emails and messages contacts and connections." 
            />
            <Stack direction="row" spacing="40px">
                <InvitationTemplates />
                <Box className="display-box" sx={{ maxWidth: "250px" }}>
                    <h5>Tips</h5>
                    <p>
                        Use these links in emails and messages to your contacts and connections.
                    </p>
                    <p>
                        Simply click the "Copy Link" button to copy the link to your clipboard. Then use Ctrl-V on Windows or Cmd-V on Mac to paste the link into your email or message.
                    </p>
                </Box>
            </Stack>
        </>
    )
}