import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { AddUserForm } from "../components/form-add-user";
import { getAllUsers } from "../../../core/services/gutcheck-api.service";
import { UserProfileImage } from "../../users/components/UserProfileImage";
import { DocumentTitle } from "../../../components/DocumentTitle";
// Add new MUI imports
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Box,
    Chip
} from "@mui/material";

type TAdminUsers = {
    users: any | null,
    filteredUsers: any | null,
    highlightedUser: any | null,
    addUserMessage: any | null,
    sortField: string,
    sortDirection: 'asc' | 'desc'
};

const initState: TAdminUsers = {
    users: null,
    filteredUsers: null,
    highlightedUser: null,
    addUserMessage: "",
    sortField: 'id',
    sortDirection: 'asc'
};

export const AdminUsers = () => {
    const [state, setState] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const [respUsers] = await Promise.all([
                getAllUsers(accessToken)
            ]);

            if (respUsers) {
                setState({
                    ...state,
                    users: respUsers.data.users,
                    filteredUsers: respUsers.data.users
                });
            };
        };

        fetchData();

        return () => { };
    }, []);

    if (!state.users) return <></>;

    const handleAddUserClick = async (event: any) => {
        event.target.parentElement.parentElement.classList.add("show-form");
        event.preventDefault();
        setState({
            ...state,
            addUserMessage: ""
        })
    };

    const handleCallback = async (user: any, message: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getAllUsers(accessToken);

        if (user !== null) {
            setState({
                ...state,
                highlightedUser: user.id,
                users: data.users,
                filteredUsers: data.users,
                addUserMessage: message
            });
        } else {
            setState({
                ...state,
                addUserMessage: message
            })
        }

        document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
    };

    const handleSearch = (event: any) => {
        const queryString = event.target.value;
        const filteredUsers = state.users.filter((user: any) => {
            return user.email_address.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
        });

        setState({
            ...state,
            filteredUsers: filteredUsers
        });
    };

    const handleSort = (field: string) => {
        const newDirection = state.sortField === field && state.sortDirection === 'asc' ? 'desc' : 'asc';
        
        const sortedUsers = [...state.filteredUsers].sort((a, b) => {
            let valueA = field === 'created_at' || field === 'latest_visit' 
                ? new Date(a[field] || '1900-01-01').getTime()
                : field === "id" ? a[field] : a[field]?.toString().toLowerCase();
            let valueB = field === 'created_at' || field === 'latest_visit'
                ? new Date(b[field] || '1900-01-01').getTime()
                : field === "id" ? b[field] : b[field]?.toString().toLowerCase();

            if (newDirection === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        });

        setState({
            ...state,
            sortField: field,
            sortDirection: newDirection,
            filteredUsers: sortedUsers
        });
    };

    return (
        <>
            <DocumentTitle pageTitle="Admin - Users" />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 3 }}>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TextField
                        label="Search"
                        size="small"
                        onChange={handleSearch}
                        sx={{ minWidth: 200 }}
                    />
                </Box>
                <Box className="add-object-wrapper">
                    <Box className="add-object-toggle">
                        <Box className="add-object-message">{state.addUserMessage}</Box>
                        <Button
                            variant="contained"
                            onClick={handleAddUserClick}
                        >
                            Add User
                        </Button>
                    </Box>
                    <Box className="add-object-form">
                        <AddUserForm parentCallback={handleCallback} />
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} sx={{ maxHeight: '100%', marginBottom: '20px' }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell 
                                onClick={() => handleSort('id')} 
                                sx={{ 
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    width: '80px'
                                }}
                            >
                                Id {state.sortField === 'id' && (state.sortDirection === 'asc' ? '↑' : '↓')}
                            </TableCell>
                            <TableCell sx={{ width: '50px' }}></TableCell>
                            <TableCell 
                                onClick={() => handleSort('name')} 
                                sx={{ 
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    width: '150px',
                                    maxWidth: '150px'
                                }}
                            >
                                Name {state.sortField === 'name' && (state.sortDirection === 'asc' ? '↑' : '↓')}
                            </TableCell>
                            <TableCell 
                                onClick={() => handleSort('email_address')} 
                                sx={{ 
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    width: '200px',
                                    maxWidth: '200px'                                
                                }}
                            >
                                Email {state.sortField === 'email_address' && (state.sortDirection === 'asc' ? '↑' : '↓')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '25%' }}>Member of</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '200px' }}>Advisor for</TableCell>
                            <TableCell 
                                onClick={() => handleSort('created_at')} 
                                sx={{ 
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    width: '120px'
                                }}
                            >
                                Created {state.sortField === 'created_at' && (state.sortDirection === 'asc' ? '↑' : '↓')}
                            </TableCell>
                            <TableCell 
                                onClick={() => handleSort('latest_visit')} 
                                sx={{ 
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    width: '120px'
                                }}
                            >
                                Seen {state.sortField === 'latest_visit' && (state.sortDirection === 'asc' ? '↑' : '↓')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.filteredUsers.map((user: any) => {
                            const url = `/admin/user/${user.id}/edit`;
                            return (
                                <TableRow
                                    key={user.id}
                                    hover
                                    sx={{
                                        ...(state.highlightedUser && user.id === state.highlightedUser && {
                                            backgroundColor: 'action.hover'
                                        }),
                                        '& td': { py: 1 }
                                    }}
                                >
                                    <TableCell><Link to={url}>{user.id}</Link></TableCell>
                                    <TableCell><UserProfileImage user={user} size="small" /></TableCell>
                                    <TableCell sx={{ maxWidth: '150px' }}>
                                        <Box sx={{ 
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <Link to={url}>{user.profile?.name}</Link>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: '200px' }}>
                                        <Box sx={{ 
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <Link to={url}>{user.email_address || "no email"}</Link>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                            {user.role !== "admin" && user.memberships
                                                .filter((membership: any) => 
                                                    membership.role === "contributor" || membership.role === "admin")
                                                .sort((a: any, b: any) => {
                                                    if (a.organization && b.organization) {
                                                        return a.organization?.name.localeCompare(b.organization?.name);
                                                    }
                                                    return a.project?.name.localeCompare(b.project?.name);
                                                })
                                                .map((membership: any, index: number) => (
                                                    <Chip 
                                                        key={index}
                                                        label={membership.organization 
                                                            ? `F: ${membership.organization?.name}`
                                                            : `C: ${membership.project?.name}`}
                                                        size="small"
                                                        sx={{ m: 0.25 }}
                                                    />
                                                ))
                                            }
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                            {user.advisors?.length > 0 && user.advisors.map((advisor: any) => (
                                                <Chip 
                                                    key={advisor.id}
                                                    label={`${advisor.project?.name}`}
                                                    size="small"
                                                    sx={{ m: 0.25 }}
                                                />
                                            ))}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {user.created_at ? new Date(user.created_at).toLocaleDateString('en-US',
                                            { month: 'short', day: 'numeric', year: 'numeric' }) : "---"}
                                    </TableCell>
                                    <TableCell>
                                        {user.latest_visit ? new Date(user.latest_visit).toLocaleDateString('en-US',
                                            { month: 'short', day: 'numeric', year: 'numeric' }) : "---"}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
