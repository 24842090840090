import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getAllDataForSelf } from '../../../core/services/gutcheck-api.service';
import Membership from '../types/Membership';
import { IUser } from '../../../types/database/User';
import useAnalytics from "../../../hooks/useAnalytics";

export interface iUserContext {
    userContext: {
        auth0User: any;
        dbUser: IUser;
        organizationMemberships: any;
        projectMemberships: any;
        fetched: boolean;
    };
    setUserContext: React.Dispatch<React.SetStateAction<any>>;
    loadUserContext: () => void;
}

export const UserContext = React.createContext<iUserContext>({
    userContext: {
        auth0User: {},
        dbUser: {} as IUser,
        organizationMemberships: [],
        projectMemberships: [],
        fetched: false
    },
    setUserContext: () => { },
    loadUserContext: () => { }
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { trackUser } = useAnalytics();
    const [userContext, setUserContext] = useState<any>({
        auth0User: {},
        dbUser: {},
        organizationMemberships: [],
        projectMemberships: [],
        fetched: false
    });

    const loadUserContext = async (invitationCode?: string | null) => {
        if (user && isAuthenticated && !isLoading) {
            try {
                setUserContext({
                    ...userContext,
                    fetched: false
                });
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getAllDataForSelf(accessToken, invitationCode);
                setUserContext({
                    ...userContext,
                    dbUser: data.user,
                    projectMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.project }) || [],
                    organizationMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.organization }) || [],
                    fetched: true
                });
                trackUser(data.user);
            } catch (error) {
                console.error(error);
            }
        } else if (!user && !isAuthenticated && !isLoading) {
            setUserContext({
                auth0User: {},
                dbUser: {},
                organizationMemberships: [],
                projectMemberships: [],
                fetched: true
            });
        }
    };

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            loadUserContext();
        }
    }, [isAuthenticated, isLoading]);

    return (
        <UserContext.Provider value={{ userContext, setUserContext, loadUserContext }}>
            {children}
        </UserContext.Provider>
    );
}

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};




