import { CompanyTitle } from "../../components/CompanyTitle";
import { useDealContext } from "../../../deal/context";
import { AdvisorSuggestions } from "../../components/outreach/AdvisorSuggestions";
import { PaidCampaigns } from "../../components/outreach/PaidCampaigns";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
export const OutreachPaidCampaign = () => {
    const { dealContext } = useDealContext();
    const { project, role } = dealContext;

    return (
        <>
            <CompanyTitle 
                viewerRole={role} 
                project={project} 
                section="Outreach > Run a Paid Campaign" 
                subheading={<Box>Launch paid campaigns to recruit advisors in the <strong><Link to="/c/company">{project.name} ICP</Link></strong>.</Box>} 
            />
            <PaidCampaigns />

        </>
    )
}