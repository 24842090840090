import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, TextField, Button, Modal } from "@mui/material";
import { addLinkedinSearch, updateLinkedinSearch, deleteLinkedinSearch } from "../../services/gutcheck-admin-api.service";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export const LinkedinSearchForm = ({ project, linkedinSearch, linkedinSearches, reloadLinkedinSearches, editMode, setEditMode }: any) => {
    const [formData, setFormData] = useState({
        title: linkedinSearch?.title || "",
        description: linkedinSearch?.description || "",
        linkedin_url: linkedinSearch?.linkedin_url || "",
        is_active: linkedinSearch?.is_active ?? true
    });
    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const accessToken = await getAccessTokenSilently();

        if (linkedinSearch) {
            await updateLinkedinSearch(project.id, linkedinSearch.uuid, { ...formData }, accessToken);
        } else {
            await addLinkedinSearch(project.id, formData, accessToken);
        }

        reloadLinkedinSearches();
        setEditMode(false);
    };

    const handleDelete = async () => {
        if (!linkedinSearch) return;
        
        const accessToken = await getAccessTokenSilently();
        await deleteLinkedinSearch(project.id, linkedinSearch.id, accessToken);
        reloadLinkedinSearches();
        setEditMode(false);
    };

    return (
        <Modal open={editMode} onClose={() => setEditMode(false)}>
            <Box sx={modalStyle}>
                <h4>{linkedinSearch ? "Edit LinkedIn Search" : "Add LinkedIn Search"}</h4>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Title"
                        value={formData.title}
                        onChange={(e) => setFormData({...formData, title: e.target.value})}
                        margin="normal"
                    />
                    <TextField
                        multiline
                        rows={4}
                        fullWidth
                        label="Description"
                        value={formData.description}
                        onChange={(e) => setFormData({...formData, description: e.target.value})}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="LinkedIn URL"
                        value={formData.linkedin_url}
                        onChange={(e) => setFormData({...formData, linkedin_url: e.target.value})}
                        margin="normal"
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        {linkedinSearch && (
                            <Button variant="outlined" color="error" onClick={handleDelete}>
                                Delete
                            </Button>
                        )}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button variant="outlined" onClick={() => setEditMode(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit">
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}; 