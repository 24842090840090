import { useState, useEffect, useRef } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { CompanyAdvisorLink } from "../CompanyAdvisorLink";
import { AdvisorProfileImage } from "../../../advisor";
import { IAdvisor, IUser } from "../../../../types";

const GridItemProfile = ({ advisor, user, type = "expert" }: { advisor: IAdvisor, user: IUser, style: string, isVisible: boolean, type?: string }) => {
    return (
        <Box key={advisor?.uuid}
            className={`viewpoint-profile face-grid`}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                padding: "1rem",
                cursor: "pointer",
                position: "relative",
                width: "110px",
                gap: "1rem",
                "& img": {
                    opacity: type === "lead" || advisor.preview ? 0.5 : 1,
                    filter: type === "lead" || advisor.preview ? "grayscale(1)" : "none"
                },
                "& img.li-icon": {
                    filter: "none",
                    opacity: 1
                },
                "&.excluded": {
                    filter: "grayscale(1)",
                    opacity: 0.5
                }
            }}
        >
            <AdvisorProfileImage advisor={advisor} />
            <Box className="viewpoint-profile-details"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "110px"
                }}>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.name}</Typography>
                <Typography sx={{
                    fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{advisor?.user?.profile?.title}</Typography>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.company?.name}</Typography>
            </Box>

            {user?.prior_visit && advisor.created_at > user.prior_visit && !advisor.preview &&
                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#F0DE42", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                    New
                </Box>
            }
            {advisor.preview &&
                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#c9c9c9", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                    Upcoming
                </Box>
            }
        </Box>
    );
};


export const AdvisorGridItem = ({ advisor, user, style, isVisible, type = "expert" }: { advisor: IAdvisor, user: IUser, style: string, isVisible: boolean, type?: string }) => {
    const [isRemoved, setIsRemoved] = useState(false);
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isVisible) {
            const timer = setTimeout(() => {
                if (itemRef.current) {
                    setIsRemoved(true);
                }
            }, 100);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setIsRemoved(false);
        }
    }, [isVisible]);

    return (
        <div
            ref={itemRef}
            style={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'scale(1)' : 'scale(0.95)',
                transition: isVisible ? 'all 300ms ease-in-out' : 'all 100ms ease-in-out',
                position: isRemoved ? 'absolute' : 'relative',
                width: isRemoved ? '0' : 'auto',
                height: isRemoved ? '0' : 'auto',
                pointerEvents: isVisible ? 'auto' : 'none',
                zIndex: isVisible ? 1 : 0,
                visibility: isRemoved ? 'hidden' : 'visible'
            }}
        >
            <div>
                {advisor.preview ?
                    <Tooltip title="This advisor is in the application process." placement="top" arrow>
                        <Box key={advisor?.uuid}
                            className={`viewpoint-profile face-grid`}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "1rem",
                                position: "relative",
                                width: "110px",
                                gap: "1rem",
                                "& img": {
                                    opacity: type === "lead" || advisor.preview ? 0.5 : 1,
                                    filter: type === "lead" || advisor.preview ? "grayscale(1)" : "none"
                                },
                                "& img.li-icon": {
                                    filter: "none",
                                    opacity: 1
                                },
                                "&.excluded": {
                                    filter: "grayscale(1)",
                                    opacity: 0.5
                                }
                            }}
                        >
                            <AdvisorProfileImage advisor={advisor} />
                            <Box className="viewpoint-profile-details"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "110px"
                                }}>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.name}</Typography>
                                <Typography sx={{
                                    fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical"
                                }}>{advisor?.user?.profile?.title}</Typography>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.company?.name}</Typography>
                            </Box>

                            {user?.prior_visit && advisor.created_at > user.prior_visit && !advisor.preview &&
                                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#F0DE42", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                                    New
                                </Box>
                            }

                        </Box>
                    </Tooltip>
                    :
                    <CompanyAdvisorLink uuid={advisor?.uuid}>
                        <Box key={advisor?.uuid}
                            className={`viewpoint-profile face-grid`}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "1rem",
                                cursor: "pointer",
                                position: "relative",
                                width: "110px",
                                gap: "1rem",
                                "& img": {
                                    opacity: type === "lead" || advisor.preview ? 0.5 : 1,
                                    filter: type === "lead" || advisor.preview ? "grayscale(1)" : "none"
                                },
                                "& img.li-icon": {
                                    filter: "none",
                                    opacity: 1
                                },
                                "&.excluded": {
                                    filter: "grayscale(1)",
                                    opacity: 0.5
                                }
                            }}
                        >
                            <AdvisorProfileImage advisor={advisor} />
                            <Box className="viewpoint-profile-details"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "110px"
                                }}>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.name}</Typography>
                                <Typography sx={{
                                    fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical"
                                }}>{advisor?.user?.profile?.title}</Typography>
                                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor?.user?.profile?.company?.name}</Typography>
                            </Box>

                            {user?.prior_visit && advisor.created_at > user.prior_visit && !advisor.preview &&
                                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#F0DE42", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                                    New
                                </Box>
                            }
                            {advisor.preview &&
                                <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#c9c9c9", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                                    Upcoming
                                </Box>
                            }
                        </Box>
                    </CompanyAdvisorLink>
                }
            </div>
        </div>
    );
};

