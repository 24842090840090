import { useDealContext } from "../../deal/context";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import { useEffect, useState } from "react";
import { CompanyTitle } from "../components/CompanyTitle";
import { useOutletContext, Link } from "react-router-dom";
import { DealLeads } from "../../deal/components/DealLeads";
import { Box, styled, Stack, Typography } from "@mui/material";
import { AdvisorGrid } from "../components/advisor/AdvisorGrid";
import { useAuth0 } from "@auth0/auth0-react";
import { useAdvisors } from "../../advisor";
import Loading from "../../../components/Loading";
import ClientProvider from "../../../modules/ClientProvider";
import { IAdvisor } from "../../../types/database/Advisor";

export const CompanyAdvisors = () => {

    // get deal from dealContext
    const { dealContext } = useDealContext();
    const { trackPage } = useAnalytics();
    const { userContext } = useUserContext();
    const { advisors, loading, fetchAdvisors } = useAdvisors();
    const [previewAdvisors, setPreviewAdvisors] = useState<IAdvisor[]>([]);

    const project = dealContext.project;
    const organization = dealContext.organization;
    const viewerRole = dealContext.role;
    const user = userContext.dbUser;
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchPreviewAdvisors = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await ClientProvider.provideProjectClient().getPreviewAdvisors(project.id, accessToken);
            if (data) {
                setPreviewAdvisors(data.advisors);
            }
        };

        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - executives`, { organization: organization.tag, project: project.tag, role: userContext.projectMemberships.role });
            fetchPreviewAdvisors();
        }
    }, [userContext]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            { advisors.length > 0 ?
                <CompanyTitle viewerRole={viewerRole} project={project} section={`${project.name} Advisors`} subheading={<span>So far, your <strong>{project.viewpoints.length}</strong> advisors have generated ...</span>} />
                :
                <CompanyTitle viewerRole={viewerRole} project={project} section={`${project.name} Advisors`} subheading="Start adding advisors to generate insight, intros and opportunities." />
            }
            <AdvisorGrid project={project} advisors={advisors} user={user} viewerRole={viewerRole} previewAdvisors={previewAdvisors}/>
            { false && <DealLeads project={project} /> }
        </Box>
    );
}