import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, TextField, Button, Container, Grid, Modal, Dialog, DialogTitle, Autocomplete } from "@mui/material";
import { UserProfileImage } from "./UserProfileImage";
import { updateDataForSelf } from '../../../core/services/gutcheck-api.service';
import { useUserContext } from "../context";
import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import { IUser, IProfile } from "../../../types";
import ClientProvider from "../../../modules/ClientProvider";
import StyledDropzone from "../../../components/AssetUpload";

interface CompanySuggestion {
    name: string;
    domain: string;
    confidence_score: number;
    logo_url: string;
    id?: string;
}

const ImageModal = ({ imageFile, open, onClose }: { imageFile?: string, open: boolean, onClose: () => void }) => {
    const [queuedFiles, setQueuedFiles] = useState<any[]>([]);
    const [preview, setPreview] = useState<any>(null);
    const { getAccessTokenSilently } = useAuth0();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleUpload = async () => {
        setIsUploading(true);
        const accessToken = await getAccessTokenSilently(); 
        const { data, error } = await ClientProvider.provideUserClient().uploadProfileImage(queuedFiles[0], queuedFiles[0].name, accessToken);

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        if (data) {
            setSuccessMessage("Profile image updated successfully");
            setIsUploading(false);
            onClose();
        }
    }

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            sx={{ 
                "& img": { 
                    maxWidth: "300px",
                    maxHeight: "300px",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                    borderRadius: "50%",
                    display: "block",
                    margin: "auto"
                } 
            }}
        >
            <h5>Upload a Profile Image</h5>
            <StyledDropzone
                queuedFiles={queuedFiles}
                setQueuedFiles={setQueuedFiles}
                fileTypes={{
                    "image/*": [".png", ".jpg", ".jpeg"]
                }}
                initialFile={imageFile || ""}
            />
            {queuedFiles.length > 0 && (
                <Button variant="contained" color="primary" onClick={handleUpload} disabled={isUploading}>{isUploading ? "Uploading..." : "Upload"}</Button>
            )}
        </Dialog>
    )
}

export const UserProfileUpdate = ({ user, onUpdate }: { user: IUser, onUpdate: () => void }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { userContext, setUserContext, loadUserContext } = useUserContext();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [formChanged, setFormChanged] = useState<boolean>(!userContext.dbUser?.profile?.user_reviewed);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [companySuggestions, setCompanySuggestions] = useState<CompanySuggestion[]>([]);
    const [companyInputValue, setCompanyInputValue] = useState(userContext.dbUser?.profile?.company_name || "");
    const [selectedCompany, setSelectedCompany] = useState<CompanySuggestion | null>(null);

    const [form, setForm] = useState<Partial<IProfile>>({
        name: userContext.dbUser?.profile?.name || "",
        email: userContext.dbUser?.email_address || "",
        title: userContext.dbUser?.profile?.title || "",
        company_name: userContext.dbUser?.profile?.company_name || "",
        location: userContext.dbUser?.profile?.location || "",
        linkedin_url: userContext.dbUser?.profile?.linkedin_url || "",
        previous_companies: userContext.dbUser?.profile?.previous_companies || "",
        seniority: userContext.dbUser?.profile?.seniority || "",
        user_reviewed: true,
    });

    if (!userContext.fetched) return <Loading />;
    if (userContext.fetched && !userContext.dbUser) return <UserNotFound />;

    const companyClient = ClientProvider.provideCompanyClient();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
        setFormChanged(true);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const accessToken = await getAccessTokenSilently();
            const submitData = {
                ...form,
                company_details: selectedCompany ? {
                    name: selectedCompany.name,
                    domain: selectedCompany.domain,
                    logo_url: selectedCompany.logo_url,
                    id: selectedCompany.id
                } : {
                    name: companyInputValue,
                    domain: '',
                    logo_url: '',
                    id: undefined
                }
            };
            
            const { data, error } = await ClientProvider.provideUserClient().updateProfile(submitData, accessToken);

            if (error) {
                setErrorMessage(error.message);
                return;
            }

            if (data) {
                setSuccessMessage("Profile updated successfully");
                setFormChanged(false);
                loadUserContext();
                onUpdate();
            }
        } catch (error) {
            setErrorMessage("An error occurred while updating your profile");
        }
    };
    const handleImageUpload = () => {
        setImageModalOpen(true);
    }

    const handleImageClose = () => {
        setImageModalOpen(false);
        setSuccessMessage(null);
        setErrorMessage(null);
        loadUserContext();
    }

    const fetchCompanySuggestions = async (query: string) => {
        if (!query) {
            setCompanySuggestions([]);
            return;
        }
        
        try {
            setCompanySuggestions([]);

            const [clearoutResponse, internalResponse] = await Promise.all([
                fetch(`https://api.clearout.io/public/companies/autocomplete?query=${encodeURIComponent(query)}`),
                companyClient.getCompanies(query)
            ]);

            const clearoutData = await clearoutResponse.json();
            const clearoutSuggestions = clearoutData.status === "success" ? clearoutData.data : [];

            const internalSuggestions = internalResponse.data || [];

            const combinedSuggestions = [...internalSuggestions];

            clearoutSuggestions.forEach((suggestion: CompanySuggestion) => {
                const exists = combinedSuggestions.some(item => item.domain === suggestion.domain);
                if (!exists) {
                    combinedSuggestions.push(suggestion);
                }
            });

            const sortedSuggestions = combinedSuggestions.sort((a, b) => 
                (b.confidence_score || 0) - (a.confidence_score || 0)
            );

            setCompanySuggestions(sortedSuggestions);
        } catch (error) {
            console.error("Error fetching company suggestions:", error);
            setCompanySuggestions([]);
            try {
                const internalResponse = await companyClient.getCompanies(query);
                setCompanySuggestions(internalResponse.data || []);
            } catch (internalError) {
                console.error("Error fetching from internal API:", internalError);
                setCompanySuggestions([]);
            }
        }
    };

    return (
        <Box maxWidth="md">
            <Grid container spacing={2} alignItems="center" className="profile-header mb-5">
                <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>

                    <UserProfileImage user={userContext.dbUser} size="large" />
                    <span className="link" onClick={handleImageUpload}>Upload Image</span>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Name"
                                value={form.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                value={form.email}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>

                        <TextField
                            fullWidth
                            id="title"
                            label="Title"
                            value={form.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            freeSolo
                            options={companySuggestions}
                            getOptionLabel={(option) => 
                                typeof option === 'string' ? option : option.name
                            }
                            value={form.company_name}
                            inputValue={companyInputValue}
                            onInputChange={(event, newInputValue) => {
                                setCompanyInputValue(newInputValue);
                                fetchCompanySuggestions(newInputValue);
                                setFormChanged(true);
                            }}
                            onChange={(event, newValue) => {
                                const companyName = typeof newValue === 'string' ? 
                                    newValue : 
                                    newValue?.name || '';
                                setForm(prev => ({
                                    ...prev,
                                    company_name: companyName
                                }));
                                setSelectedCompany(typeof newValue === 'string' ? null : newValue);
                                setFormChanged(true);
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    {option.logo_url && (
                                        <img 
                                            src={option.logo_url} 
                                            alt="" 
                                            style={{ 
                                                width: 20, 
                                                height: 20, 
                                                objectFit: 'contain' 
                                            }} 
                                        />
                                    )}
                                    <Box>
                                        <div>{option.name}</div>
                                        <div style={{ fontSize: '0.8em', color: '#666' }}>{option.domain}</div>
                                    </Box>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="company_name"
                                    label="Company"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="linkedin_url"
                            label="LinkedIn URL"
                            value={form.linkedin_url}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="location"
                            label="Location"
                            value={form.location}
                            onChange={handleChange}
                        />
                    </Grid>

                </Grid>

                {successMessage && (
                    <Box sx={{ mt: 2, color: 'success.main' }}>
                        {successMessage}
                    </Box>
                )}

                {errorMessage && (
                    <Box sx={{ mt: 2, color: 'error.main' }}>
                        {errorMessage}
                    </Box>
                )}

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!formChanged || isSubmitting}
                >
                    {isSubmitting ? "Saving..." : "Looks Good"}
                </Button>
            </Box>
            <ImageModal open={imageModalOpen} onClose={handleImageClose} />
        </Box>
    );
};
