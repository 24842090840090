import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { Box, Button, Checkbox, DialogProps, FormControlLabel, MenuItem, Modal, Select, Stack, Switch, TextField } from "@mui/material";
import { createAdvisor, getSurveyLinks, updateAdvisor } from "../../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { IAdvisor, IProject } from "../../../../types";

dayjs.extend(utc);

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

const statuses = [
    { value: "init", label: "Not Completed" },
    { value: "complete", label: "Completed" },
    { value: "approved", label: "Approved" },
    { value: "screened", label: "Screened Out" },
    { value: "over_quota", label: "Over Quota" },
    { value: "rejected", label: "Rejected" }
];

export const AdvisorForm = ({ project, advisor, reloadAdvisors, editMode, setEditMode }: { project: IProject, advisor: IAdvisor, reloadAdvisors: () => void, editMode: boolean, setEditMode: (editMode: boolean) => void }) => {

    const initialAdvisor = {
        name: advisor.user?.profile?.name ?? "",
        email_address: advisor.user?.email_address ?? "",
        invited_to_invest: advisor.invited_to_invest ?? false,
        completed_at: advisor.completed_at ? dayjs.utc(advisor.completed_at).local().format('YYYY-MM-DDTHH:mm') : null,
        survey_link_id: advisor.survey_link?.id ?? "",
        reserved_spot: advisor.reserved_spot ?? false,
        reserved_amount: advisor.reserved_amount ?? 0,
        status: advisor.status ?? "init",
        funded: advisor.funded ?? false,
        funded_amount: advisor.funded_amount ?? 0,
        preview: advisor.preview ?? false,
        referred_by_code: advisor.referred_by_code ?? "",
        latest_survey_url: advisor.latest_survey_url ?? "",
        progress: advisor.progress ?? "",
        referrer_code: advisor.referrer_code ?? "",
        is_test: advisor.is_test ?? false
    }

    const [advisorData, setAdvisorData] = useState<any>(initialAdvisor);
    const [surveyLinks, setSurveyLinks] = useState<any>([]);
    const [urlEditable, setUrlEditable] = useState<boolean>(false);
    const [copiedFields, setCopiedFields] = useState<Record<string, boolean>>({});
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchSurveyLinks = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getSurveyLinks(project.id, accessToken);
            if (data) {
                setSurveyLinks(data.surveyLinks);
            } else {
                console.error(error);
            }
        }

        if (project) {
            fetchSurveyLinks();
        }
    }, [project]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const newAdvisor = advisorData;
        newAdvisor.completed_at = advisorData.completed_at ? new Date(advisorData.completed_at).toISOString() : null;
        if (advisor.id) {
            await updateAdvisor(project.id, advisor.id, newAdvisor, accessToken);
        } else {
            await createAdvisor(project.id, newAdvisor, accessToken);
        }
        setAdvisorData(initialAdvisor);
        await reloadAdvisors();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setAdvisorData({ ...advisorData, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    const handleCopy = (text: string, field: string) => {
        navigator.clipboard.writeText(text);
        setCopiedFields(prev => ({ ...prev, [field]: true }));
        setTimeout(() => {
            setCopiedFields(prev => ({ ...prev, [field]: false }));
        }, 2000);
    };

    if (!surveyLinks) {
        return <></>
    }

    const linkDomain = project.experience === "advisor" ? `${advisor.survey_link?.project.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}` : `app.${process.env.REACT_APP_APP_ORIGIN}`;
    const url = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${linkDomain}/e/${advisor.survey_link?.public_id}/expert/${advisor.public_id}`

    const referrerCodeLink = project.organization.custom_domain_enabled ?
        `${process.env.REACT_APP_APP_PROTOCOL || 'https'}://${project.organization.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ''}/${project.tag}?ar=${advisor?.referrer_code}` :
        `${project._origin}/advise/${project.tag}?ar=${advisor?.referrer_code}`;

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <h4>{advisor.id ? "Edit Advisor" : "Add Advisor"}</h4>

                <form onSubmit={handleSubmit}>

                    <Stack spacing={2} sx={{ marginTop: '20px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={advisorData.preview}
                                    onChange={handleChange}
                                    name="preview"
                                />
                            }
                            label="Preview to Company"
                        />
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Evaluation URL"
                                name="evaluation_url"
                                value={url}
                                disabled
                                fullWidth
                                size="small"
                            />
                            <Button
                                size="small"
                                color={copiedFields['evaluation'] ? "success" : "primary"}
                                variant="text"
                                onClick={() => handleCopy(url, 'evaluation')}
                            >
                                {copiedFields['evaluation'] ? "Copied!" : "Copy"}
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Scheduling URL"
                                name="scheduling_url"
                                value={`${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${advisor.survey_link?.project?.tag}.${process.env.REACT_APP_APP_ORIGIN}/meet/${advisor.public_id}`}
                                disabled
                                fullWidth
                                size="small"
                            />
                            <Button
                                size="small"
                                color={copiedFields['scheduling'] ? "success" : "primary"}
                                variant="text"
                                onClick={() => handleCopy(`${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${advisor.survey_link?.project?.tag}.${process.env.REACT_APP_APP_ORIGIN}/meet/${advisor.public_id}`, 'scheduling')}
                            >
                                {copiedFields['scheduling'] ? "Copied!" : "Copy"}
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Co-Invest URL"
                                name="co_invest_url"
                                value={`${url}/invest`}
                                disabled
                                fullWidth
                                size="small"
                            />
                            <Button
                                size="small"
                                color={copiedFields['coinvest'] ? "success" : "primary"}
                                variant="text"
                                onClick={() => handleCopy(`${url}/invest`, 'coinvest')}
                            >
                                {copiedFields['coinvest'] ? "Copied!" : "Copy"}
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Referrer Link"
                                name="referrer_code"
                                value={referrerCodeLink}
                                disabled
                                fullWidth
                                size="small"
                            />
                            <Button
                                size="small"
                                color={copiedFields['referrer'] ? "success" : "primary"}
                                variant="text"
                                onClick={() => handleCopy(referrerCodeLink, 'referrer')}
                            >
                                {copiedFields['referrer'] ? "Copied!" : "Copy"}
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Survey URL"
                                name="survey_url"
                                value={advisorData.latest_survey_url}
                                fullWidth
                                size="small"
                            />
                            <Button
                                size="small"
                                color={copiedFields['survey'] ? "success" : "primary"}
                                variant="text"
                                onClick={() => handleCopy(advisorData.latest_survey_url, 'survey')}
                            >
                                {copiedFields['survey'] ? "Copied!" : "Copy"}
                            </Button>
                        </Stack>
                        <TextField
                            label="Survey Progress"
                            name="progress"
                            value={advisorData.progress}
                            disabled
                            size="small"
                        />
                        <TextField
                            label="Referred By Code"
                            name="referred_by_code"
                            value={advisorData.referred_by_code}
                            disabled
                            size="small"
                        />
                        <TextField
                            label="Survey Link"
                            name="survey_link_id"
                            value={advisorData.survey_link_id}
                            onChange={handleChange}
                            select
                            required
                            size="small"
                        >
                            {surveyLinks.map((surveyLink: any) => (
                                <MenuItem key={surveyLink.id} value={surveyLink.id}>{surveyLink.name}</MenuItem>
                            ))}
                        </TextField>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Viewpoint Status"
                                name="status"
                                value={advisorData.status}
                                onChange={handleChange}
                                select
                            required
                            size="small"
                        >
                            {statuses.map((status: any) => (
                                <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>
                            ))}
                            </TextField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={advisorData.is_test}
                                        onChange={handleChange}
                                        name="is_test"
                                    />
                                }
                                label="Test&nbsp;User"
                            />
                        </Stack>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={advisorData.invited_to_invest}
                                        onChange={handleChange}
                                        name="invited_to_invest"
                                    />
                                }
                                label="Invited to Invest"
                            />
                            <TextField
                                label="Completed At"
                                name="completed_at"
                                value={advisorData.completed_at || ''}
                                onChange={handleChange}
                                type="datetime-local"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ width: '65%' }}
                                size="small"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={advisorData.reserved_spot}
                                        onChange={handleChange}
                                        name="reserved_spot"
                                    />
                                }
                                label="Reserved Spot"
                            />
                            <TextField
                                label="Reserved Amount"
                                name="reserved_amount"
                                value={advisorData.reserved_amount}
                                onChange={handleChange}
                                required
                                size="small"
                                sx={{ width: '65%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={advisorData.funded}
                                        onChange={handleChange}
                                        name="funded"
                                    />
                                }
                                label="Funded"
                            />
                            <TextField
                                label="Funded Amount"
                                name="funded_amount"
                                value={advisorData.funded_amount}
                                onChange={handleChange}
                                required
                                size="small"
                                sx={{ width: '65%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                            <button type="submit">Submit</button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}