import { useDealContext } from "../../deal/context";
import Loading from "../../../components/Loading";
import { Outlet } from "react-router-dom/dist";
import { AdvisorSidebar } from "../components/AdvisorSidebar";
import { useAdvisor } from "../context/AdvisorContext";
import UnrecognizedUser from "../../../components/UncrecognizedUser";
import { useUserContext } from "../../users/context";
import { Dialog } from "@mui/material";
import { UserProfileUpdate } from "../../users/components/UserProfileUpdate";
import useAnalytics from "../../../hooks/useAnalytics";

export const Advisor = () => {
    const { dealContext } = useDealContext();
    const { advisor, loading, fetchAdvisor } = useAdvisor();
    const { userContext, loadUserContext } = useUserContext();
    const { trackPage } = useAnalytics();   

    if (!dealContext.fetched || loading) {
        return <Loading />;
    }

    if ((!loading && !advisor) || !advisor?.user) {
        trackPage("Advisor Not Found", {
            project_tag: dealContext.project.tag,
            advisor_id: advisor?.id,
            user_id: advisor?.user?.id,
            loading: loading,
        });
        return <UnrecognizedUser />;
    }

    const handleProfileUpdate = () => {
        fetchAdvisor(dealContext.project.tag);
    }

    if (advisor && dealContext.project.experience !== "advisor") {
        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://app.${process.env.REACT_APP_APP_ORIGIN}/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}`;
    }

    return (
        <>
            <aside className="page-sidebar">
                <AdvisorSidebar />
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet />
            </main>
            {advisor?.user?.profile && !advisor?.user?.profile?.user_reviewed &&
                <Dialog open={true} onClose={() => {}}>
                    <h5>Welcome to the {dealContext.project.name} Advisor Network</h5>
                    <p>Please take a moment to review and update your profile.</p>
                    <UserProfileUpdate user={advisor?.user} onUpdate={() => {
                        fetchAdvisor(dealContext.project.tag);
                    }} />
                </Dialog>
            }
        </>
    );
};