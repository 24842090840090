import { Box, Typography, Tooltip } from "@mui/material";
import { AdvisorProfileImage } from "./AdvisorProfileImage";
import { IAdvisor } from "../../../types";

export const AdvisorPreview = ({ advisor, type = "expert" }: { advisor: IAdvisor, type?: string }) => {

    return (
        <Box key={advisor?.uuid}
            className={`viewpoint-profile face-grid`}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                padding: "1rem",
                cursor: "pointer",
                position: "relative",
                width: "110px",
                gap: "1rem",
                "& img": {
                    opacity: type === "lead" ? 0.5 : 1,
                    filter: type === "lead" ? "grayscale(1)" : "none"
                },
                "& img.li-icon": {
                    filter: "none",
                    opacity: 1
                },
                "&.excluded": {
                    filter: "grayscale(1)",
                    opacity: 0.5
                }
            }}
        >
            <AdvisorProfileImage advisor={advisor} />
            <Box className="viewpoint-profile-details"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "110px"

                }}>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor.user?.profile?.name}</Typography>
                <Typography sx={{
                    fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{advisor.user?.profile?.title}</Typography>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{advisor.user?.profile?.company?.name}</Typography>

            </Box>
        </Box>
    )
}