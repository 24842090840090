import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { usePaidCampaigns } from "../hooks/usePaidCampaigns";
import { PaidCampaignTable } from "./components/PaidCampaignTable";

export const AdminProjectCampaigns = () => {
    const { project } = useOutletContext<any>();
    const { fetched, paidCampaigns = [], error, reloadPaidCampaigns } = usePaidCampaigns(project.id);
    const [paidCampaign, setPaidCampaign] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const handleEdit = (campaign: any) => {
        setPaidCampaign(campaign);
        setEditMode(true);
    }

    if (!fetched) return <></>;

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Paid Campaigns</h5>
            </div>

                <PaidCampaignTable 
                    project={project}
                    paidCampaigns={paidCampaigns}
                    reloadPaidCampaigns={reloadPaidCampaigns}
                    editMode={editMode}
                    setEditMode={setEditMode}
                />
=        </div>
    );
}
