import { Route, Routes } from "react-router-dom";
import { Company } from "./Company";
import { useSubdomain } from "../../../hooks/useSubdomain";
import { useDealContext } from "../../deal/context";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../modules/ClientProvider";
import Loading from "../../../components/Loading";
import { CompanyAdvisors } from "./CompanyAdvisors";
import { CompanyScoring } from "./CompanyScoring";
import { CompanyCollaboration } from "./CompanyCollaboration";
import { CompanyMeetings } from "./CompanyMeetings";
import { CompanyConfig } from "./config/CompanyConfig";
import { CompanyInvestment } from "./CompanyInvestment";
import { CompanyOutreach } from "./CompanyOutreach";
import { CompanyNurture } from "./CompanyNurture";
import { CompanyTouchpoint } from "./CompanyTouchpoint";
import { OutreachLinks } from "./outreach/OutreachLinks";
import { OutreachBlocklist } from "./outreach/OutreachBlocklist";
import { CalendarConfig } from "./config/CalendarConfig";
import { OutreachSuggest } from "./outreach/OutreachSuggest";
import { OutreachLinkedIn } from "./outreach/OutreachLinkedIn";
import { OutreachPaidCampaign } from "./outreach/OutreachPaidCampaign";
import { CompanySetup } from "./CompanySetup";
import { useNavigate } from "react-router-dom";
import { AdvisorsProvider } from "../../advisor/context/AdvisorsContext";

export const CompanyRoutes = () => {
    const { subdomain, isCustomDomain, subdomainFetched } = useSubdomain();
    const { dealContext, setDealContext } = useDealContext();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchDealData = async () => {
            if (subdomain && isCustomDomain) {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await ClientProvider.provideProjectClient().getProjectData(subdomain, accessToken);
                if (data) {
                    data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : {};
                    data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : {};
                    
                    setDealContext({
                        project: data.project,
                        organization: data.project.organization,
                        role: data.role,
                        fetched: true
                    });
                } else {
                    navigate('/');
                }
            }
        };

        if (subdomainFetched && !dealContext.fetched) {
            fetchDealData();
        }

        return () => {
            setDealContext({
                project: null,
                organization: null,
                role: null,
                fetched: false
            });
        };
    }, [subdomainFetched, subdomain]);

    if (!subdomainFetched || !dealContext.fetched) {
        return <Loading />;
    }

    return (
        <AdvisorsProvider>
            <Routes>
                <Route path="" element={<Company />}>
                    <Route index element={<CompanyAdvisors />} />
                    <Route path="setup" element={<CompanySetup />} />
                    <Route path="scoring" element={<CompanyScoring />} />
                    <Route path="collaboration" element={<CompanyCollaboration />} />
                <Route path="meetings">
                    <Route index element={<CompanyMeetings />} />
                    <Route path=":meetingState" element={<CompanyMeetings />} />
                    <Route path=":meetingState/:meetingUuid" element={<CompanyMeetings />} />
                </Route>
                <Route path="investment" element={<CompanyInvestment />} />
                <Route path="outreach" element={<CompanyOutreach />} />
                <Route path="nurture">
                    <Route index element={<CompanyNurture />} />
                    <Route path=":touchpointUuid" element={<CompanyTouchpoint />} />
                </Route>
                <Route path="links" element={<OutreachLinks />} />
                <Route path="blocklist" element={<OutreachBlocklist />} />
                <Route path="suggest" element={<OutreachSuggest />} />
                <Route path="linkedin" element={<OutreachLinkedIn />} />
                <Route path="campaigns" element={<OutreachPaidCampaign />} />
                <Route path="company" element={<CompanyConfig />} />
                    <Route path="calendar" element={<CalendarConfig />} />
                </Route>
            </Routes>
        </AdvisorsProvider>
    );
}