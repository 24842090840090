import { Box, Typography } from '@mui/material';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer, ZAxis, Line } from 'recharts';
import { ITouchpoint } from '../../../../types';
import dayjs from 'dayjs';

// Add this custom shape function before the component
const CustomArrow = (props: any) => {
    const { cx, cy, payload } = props;
    const radius = Math.sqrt(payload.z / 500) * 8;
    
    // End point of the arc
    const endX = -radius * Math.cos(Math.PI*0.75);
    const endY = -radius * Math.sin(Math.PI*0.75);
    
    // Arrow head with 45-degree rotation
    const arrowLength = radius * 0.2;
    const arrowAngle = Math.PI * 0.75 - Math.PI/4; // Subtract 45 degrees (π/4)
    
    return (
        <g transform={`translate(${cx},${cy})`}>
            <circle 
                r={radius} 
                fill="#1A5DBD"
                opacity={0.1}
                stroke="none" 
            />
            <circle
                r={"5px"}
                fill="#667788"
                opacity={1}
                stroke="none"
            />
            <path
                d={`
                    M ${radius} 0
                    A ${radius} ${radius} 0 1 1 ${endX} ${endY}
                    L ${endX + arrowLength * Math.cos(arrowAngle)} ${endY + arrowLength * (-1 * Math.sin(arrowAngle))}
                    M ${endX} ${endY}
                    L ${endX + arrowLength * Math.cos(arrowAngle + Math.PI/2)} ${endY + arrowLength * (-1 * Math.sin(arrowAngle + Math.PI/2))}
                `}
                fill="none"
                stroke="#feb70f"
                opacity={1}
                strokeWidth={3}
            />
        </g>
    );
};

const CustomArrowScheduled = (props: any) => {
    const { cx, cy, payload } = props;
    const radius = Math.sqrt(payload.z / 500) * 8;
    
    // End point of the arc
    const endX = -radius * Math.cos(Math.PI*0.75);
    const endY = -radius * Math.sin(Math.PI*0.75);
    
    // Arrow head with 45-degree rotation
    const arrowLength = radius * 0.2;
    const arrowAngle = Math.PI * 0.75 - Math.PI/4; // Subtract 45 degrees (π/4)
    
    return (
        <g transform={`translate(${cx},${cy})`}>
            <circle 
                r={radius} 
                fill="#1A5DBD"
                opacity={0.1}
                stroke="none" 
            />
            <circle
                r={"5px"}
                fill="#667788"
                opacity={1}
                stroke="none"
            />
            <path
                d={`
                    M ${radius} 0
                    A ${radius} ${radius} 0 1 1 ${endX} ${endY}
                    L ${endX + arrowLength * Math.cos(arrowAngle)} ${endY + arrowLength * (-1 * Math.sin(arrowAngle))}
                    M ${endX} ${endY}
                    L ${endX + arrowLength * Math.cos(arrowAngle + Math.PI/2)} ${endY + arrowLength * (-1 * Math.sin(arrowAngle + Math.PI/2))}
                `}
                fill="none"
                stroke="#667788"
                opacity={1}
                strokeWidth={2}
                strokeDasharray="4 4"
            />
        </g>
    );
};

export const NurtureGraph = ({ touchpoints }: { touchpoints: ITouchpoint[] }) => {

    const scale  = 1000;
    // Update the data mapping to include status
    const data = touchpoints?.map((touchpoint: ITouchpoint) => ({
        date: touchpoint.started_at ? new Date(touchpoint.started_at) : new Date(touchpoint.created_at!), // Convert to timestamp for X-axis
        y: 0, // Change this to 0 instead of 50
        z: touchpoint.status === "scheduled" ? 5 * scale : (touchpoint.viewpoints?.length || 0) * scale, // Increased from 20 to 40
        name: touchpoint.name, // For tooltip
        count: touchpoint.viewpoints?.length,
        status: touchpoint.status // Add this line to include status in the data
    })) || [];


    // Calculate padding based on the largest bubble
    const mostViewpoints = Math.max(...data.map((d: any) => d.z));
    const paddingDays = Math.ceil(Math.sqrt(mostViewpoints / 500) * .75); // Convert bubble radius to days
    const paddingMs = paddingDays * 24 * 60 * 60 * 1000; // Convert days to milliseconds

    const firstDate = Math.min(...data.map((d: any) => d.date));
    const lastDate = Math.max(
        Math.max(...data.map((d: any) => d.date)),
        firstDate + (120 * 24 * 60 * 60 * 1000)
    );
    
    const xDomain = [
        firstDate - paddingMs, // Add padding to start
        lastDate + paddingMs   // Add padding to end
    ];


    const formatDate = (date: string) => {
        return dayjs(date).utc().format("MMM DD, YYYY");
    }

    return (
        <div style={{ width: '100%', height: 200 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative' }}>
                <Typography 
                    variant="body1" 
                    sx={{ 
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'rotate(270deg) translateX(-50%)',
                        transformOrigin: '0 0',
                        fontSize: "1rem",
                        fontWeight: 900,
                    }}
                >
                    # Participants
                </Typography>
                <ResponsiveContainer>
                    <ScatterChart margin={{ top: 20, right: 20, bottom: 80, left: 40 }}>
                        <XAxis
                            dataKey="date"
                            domain={xDomain}
                            name="Date"
                            tickFormatter={(timestamp) => formatDate(new Date(timestamp).toISOString())}
                            type="number"
                            xAxisId={0}
                            ticks={data.map(point => point.date.getTime())}
                            textAnchor="middle"
                            interval={0}
                        />
                        <YAxis 
                            dataKey="y"
                            domain={[0, 50]}
                            hide={true}
                            label={{ value: 'Viewpoints', position: 'insideBottomRight', offset: -10 }}
                        />
                        <ZAxis 
                            dataKey="z"
                            domain={[0, 5000]}
                            range={[0, 5000]}
                            type="number"
                        />
                        <Tooltip
                            content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    // Get the date from the timestamp
                                    const formattedDate = formatDate(payload[0].payload.date);
                                    
                                    return (
                                        <Box sx={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', pointerEvents: 'all' }}>
                                            <Typography variant="body1" sx={{ fontWeight: 900, flexGrow: 1, textAlign: "center", fontSize: "1rem" }}>
                                                {payload[0].payload.name}
                                            </Typography>
                                            <Typography variant="body2" sx={{ flexGrow: 1, textAlign: "center", fontSize: "0.875rem" }}>
                                                {formattedDate}
                                            </Typography>
                                            <Typography variant="body1" sx={{ flexGrow: 1, textAlign: "center", fontSize: "1rem" }}>
                                                {payload[0].payload.status === "scheduled" ? "Scheduled" : `${payload[0].payload.count} Participants`}
                                            </Typography>
                                        </Box>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Scatter
                            data={data}
                            fill="#f8f9fa"
                            fillOpacity={0.6}
                            stroke="#c9c9c9"
                            strokeWidth={2}
                            name="Touchpoints"
                            shape={(props: any) => {
                                // Check the status and return appropriate shape component
                                return props.payload.status === "scheduled" 
                                    ? <CustomArrowScheduled {...props} />
                                    : <CustomArrow {...props} />;
                            }}
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </Box>
        </div>
    );
};