import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Plus, Link2, Repeat2 } from "lucide-react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { AdvisorGridItem } from "./AdvisorGridItem";
import { AdvisorLeads } from "./AdvisorLeads";
import { OverviewBox, RecruitmentBox, gridStyle } from "../../styles/AdvisorStyles";
import { IAdvisor, IProject, IUser } from "../../../../types";

export const AdvisorGrid = ({ advisors, project, user, viewerRole, previewAdvisors }: {advisors: IAdvisor[], project: IProject, user: IUser, viewerRole: string, previewAdvisors: IAdvisor[]}) => {
    const [visibleItems, setVisibleItems] = useState<{ [key: string]: boolean }>(() => {
        return advisors?.reduce((acc: any, advisor: any) => {
            acc[advisor.uuid] = true;
            return acc;
        }, {}) || {};
    });

    const [value, setValue] = useState(0);
    const [activeTab, setActiveTab] = useState("all");

    const advisorsAll = advisors;
    const advisorsMeeting = advisors?.filter((advisor: any) => advisor?.meetings?.length > 0);
    const advisorsOpportunities = advisors?.filter((advisor: any) => advisor?.stage === "opportunity" || advisor?.stage === "customer");
    const advisorsCustomers = advisors?.filter((advisor: any) => advisor?.stage === "customer");

    const shareLink = project.project_share_links[0];

    const advisorsEnabled = viewerRole === "guest" && !shareLink.display_advisors ? false : true;

    const handleTabSwitch = (tab: string) => {
        setActiveTab(tab);

        let newAdvisors: IAdvisor[] = [];
        switch (tab) {
            case "all":
                newAdvisors = advisorsAll;
                break;
            case "meetings":
                newAdvisors = advisorsMeeting;
                break;
            case "opportunities":
                newAdvisors = advisorsOpportunities;
                break;
            case "customers":
                newAdvisors = advisorsCustomers;
                break;
            default:
                newAdvisors = [];
        }

        // First fade out items that will be hidden
        const intermediateVisibleItems = { ...visibleItems };
        Object.keys(visibleItems).forEach(uuid => {
            intermediateVisibleItems[uuid] = newAdvisors.some((advisor: IAdvisor) => advisor.uuid === uuid);
        });
        setVisibleItems(intermediateVisibleItems);
    };


    return (
        <>
            <Stack spacing={2} direction="row" sx={{
                marginBottom: "20px",
                borderBottom: "1px solid #1a5dbd",
                paddingBottom: "30px",
                position: "relative"
            }}>
                <OverviewBox className={`display-box white ${activeTab === "all" ? "selected" : ""}`} onClick={() => handleTabSwitch("all")}>
                    <Typography variant="h5">
                        {advisorsEnabled ? <Link to="advisors">{advisors.length}</Link> : <span>{advisors.length}</span>}
                    </Typography>
                    <Typography>Light Touch Advisors</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white ${advisorsMeeting.length === 0 && "blocked"} ${activeTab === "meetings" ? "selected" : ""}`} onClick={() => handleTabSwitch("meetings")}>
                    <Typography variant="h5">
                        {advisorsMeeting.length ? <Link to="meetings">{advisorsMeeting.length}</Link> : <span>{advisorsMeeting.length}</span>}
                    </Typography>
                    <Typography>Meetings</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white ${advisorsOpportunities.length === 0 && "blocked"} ${activeTab === "opportunities" ? "selected" : ""}`} onClick={() => handleTabSwitch("opportunities")}>
                    <Typography variant="h5">
                        {advisorsOpportunities.length ? <Link to="opportunities">{advisorsOpportunities.length}</Link> : <span>{advisorsOpportunities.length}</span>}
                    </Typography>
                    <Typography>Opportunities</Typography>
                </OverviewBox>
                <OverviewBox className={`display-box white ${advisorsCustomers.length === 0 && "blocked"} ${activeTab === "customers" ? "selected" : ""}`} onClick={() => handleTabSwitch("customers")}>
                    <Typography variant="h5">
                        {advisorsCustomers.length ? <Link to="customers">{advisorsCustomers.length}</Link> : <span>{advisorsCustomers.length}</span>}
                    </Typography>
                    <Typography>Customers</Typography>
                </OverviewBox>
            </Stack>


            <Box className="viewpoints-facegrid" sx={gridStyle}>
                {advisors?.map((advisor: any) => (
                    <AdvisorGridItem
                        key={advisor?.uuid}
                        advisor={advisor}
                        user={user}
                        style="face-grid"
                        isVisible={visibleItems[advisor.uuid]}
                    />
                ))}
                {previewAdvisors?.map((advisor: any) => (
                    <AdvisorGridItem
                        key={advisor?.uuid}
                        advisor={advisor}
                        user={user}
                        style="face-grid"
                        isVisible={activeTab === "all"}
                    />
                ))}
            </Box>

                
                <Box sx={{ margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", "& a": {textDecoration: "none !important"} }}>
                    <Typography sx={{ textAlign: "center", fontWeight: "700" }}>
                        Start recruiting your own advisors...
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px", width: "100%" }}>
                        <Link to="suggest" style={{width: "100%"}}>
                            <RecruitmentBox>
                                <Plus className="icon" size={24} />
                                <Typography variant="h6">Suggest Advisors</Typography>
                                <Typography>
                                    Manually add potential advisors or upload a CSV of contacts
                                </Typography>
                            </RecruitmentBox>
                        </Link>

                        <Link to="links" style={{width: "100%"}}>
                            <RecruitmentBox>
                                <Link2 className="icon" size={24} />
                                <Typography variant="h6">Invitation Links</Typography>
                                <Typography>
                                    Get shareable links to invite advisors via email or messages
                                </Typography>
                            </RecruitmentBox>
                        </Link>

                        <Link to="linkedin" style={{width: "100%"}}>
                            <RecruitmentBox>
                                <FontAwesomeIcon icon={faLinkedin} className="icon" />
                                <Typography variant="h6">Scan LinkedIn</Typography>
                                <Typography>
                                    Search your LinkedIn network for potential advisors
                                </Typography>
                            </RecruitmentBox>
                        </Link>
                    </Box>
                    <Typography sx={{ textAlign: "center", fontWeight: "700" }}>
                        Or make it easier with...
                    </Typography>
                    <Link to="campaigns" style={{width: "100%", overflow: "hidden"}} >
                        <RecruitmentBox sx={{width: "100%"}}>
                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px"}}>
                                <Repeat2 className="icon" size={24} />
                                <Typography variant="h6">Paid Campaigns</Typography>
                            </Box>
                            <Typography>
                                Launch targeted outreach campaigns to find advisors
                            </Typography>
                            <AdvisorLeads project={project} />
                        </RecruitmentBox>
                    </Link>
                </Box>
            
        </>
    );
}