import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createProject, getAllDataForSelf } from "../../../core/services/gutcheck-api.service";
import { useUserContext } from "../../../features/users";
import { ModalBox } from "../../../components/ModalBox";

type AddCompanyProps = {
    organization: any,
    modalOpen: boolean,
    setModalOpen: (modalOpen: boolean) => void
}

type CompanyFormProps = {
    name: string,
    domain: string,
    goals: any[],
    type: string,
    admin_email: string,
    experience: string,
}

const initCompanyForm = {
    name: "",
    domain: "",
    goals: [
        { value: "Demand", title: "Maximize Demand", subtitle: "Maximize the percentage of buyers who would purchase or use the product", id: 0, default: true },
        { value: "Differentiation", title: "Improve Differentiation", subtitle: "Maximize the percentage of buyers who see the product as unique vs. other products", id: 1, default: true },
    ],
    type: "Product description",
    admin_email: "",
    experience: "advisor"
}

export const AddCompany = ({ organization, modalOpen, setModalOpen }: AddCompanyProps) => {

    const { getAccessTokenSilently } = useAuth0();
    const { loadUserContext } = useUserContext();
    const [ error, setError ] = useState<string | null>(null);

    const [ companyForm, setCompanyForm ] = useState<CompanyFormProps>(initCompanyForm);

    const handleChange = (e: any) => {
        setCompanyForm({
            ...companyForm,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {

        if (!companyForm.name) {
            setError("Please provide a company name");
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createProject(organization.id, companyForm, accessToken);

        if (data?.project) {
            loadUserContext();
            window.location.href = `https://${data.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/c`;
        }
    }

    const handleClose = () => {
        setCompanyForm(initCompanyForm);
        setError(null);
        setModalOpen(false);
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <h5>Add a New Company</h5>
                <p>Provide the name and domain of the company and we'll guide you through the setup process.</p>
                <TextField name="name" label="Company Name" variant="outlined" fullWidth onChange={(e) => handleChange(e)} />
                    { error && <Typography variant="caption" sx={{color: "red", marginBottom: "10px"}}>{error}</Typography> }
                    <TextField name="domain" label="Company Website (optional)" variant="outlined" fullWidth onChange={(e) => handleChange(e)}
                    InputProps={{
                        startAdornment: <Box sx={{color: "#999999"}}>https://</Box>
                    }}/>
                    <span>Please provide the email address of the person who will be managing this company's advisor network. They'll receive an invite.</span>
                    <TextField name="admin_email" label="Admin Email" variant="outlined" fullWidth onChange={(e) => handleChange(e)} />
                <button className="btn btn-primary" onClick={handleSubmit}>Next</button>
            </ModalBox>
        </Modal>
    );
}