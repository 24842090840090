import NavBar from "./NavBar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { ReactNode, useEffect } from "react";

type PageProps = {
    children: ReactNode
}

export const AppLayout = ({ children }: PageProps) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div id="app" className="d-flex flex-column h-100">
                <NavBar />
                <div className="page-wrapper">
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
};
