import { Box, TableRow, TableHead, TableContainer, TableCell, TableBody, Typography, Dialog, Rating, Button } from "@mui/material";
import { AdvisorTitle } from "../components/AdvisorTitle";
import { useDealContext } from "../../deal/context";
import { EvaluationSummary } from "../../evaluations/components/EvaluationSummary";
import { useAdvisor } from "../context/AdvisorContext";
import useAnalytics from "../../../hooks/useAnalytics";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { PopupModal, InlineWidget, useCalendlyEventListener } from "react-calendly";
import { AdvisorTable } from "../../company/styles/AdvisorStyles";
import { DateDisplay } from "../../meeting/components/DateDisplay";
import { StarBorder } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import { CompanyLogo } from "../../company/components/CompanyLogo";
import { DoNotDisturb } from "@mui/icons-material";
import { EvaluationActivity } from "../../evaluations/components/EvaluationActivity";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";

const MeetingLinks = ({ advisor, meeting, handleMeetingEvent, fetchMeetingDetails }: { advisor: any, meeting: any, handleMeetingEvent: (e: any, meetingUuid: string) => void, fetchMeetingDetails: () => void }) => {
    const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
    const [isCancelOpen, setIsCancelOpen] = useState(false);

    const handleReschedule = (e: any, rescheduleUrl: string) => {
        e.preventDefault();
        setIsRescheduleOpen(true);
    }

    const handleCancel = (e: any, cancelUrl: string) => {
        e.preventDefault();
        setIsCancelOpen(true);
    }

    const handleCloseCancel = (e: any, meetingUuid: string) => {
        setIsCancelOpen(false);
        fetchMeetingDetails();
        handleMeetingEvent(e, meetingUuid);
    }

    const handleCloseReschedule = (e: any, meetingUuid: string) => {
        setIsRescheduleOpen(false);
        fetchMeetingDetails();
        handleMeetingEvent(e, meetingUuid);
    }

    if (meeting) {
        const invitee = meeting?.calendly_invitee_uri?.split("/invitees/")[1];
        const cancelUrl = `https://calendly.com/cancellations/${invitee}`;
        const rescheduleUrl = `https://calendly.com/reschedulings/${invitee}`;
        return (
            <div>
                <Link onClick={(e) => handleCancel(e, cancelUrl)} to={cancelUrl}>Cancel</Link> | <Link onClick={(e) => handleReschedule(e, rescheduleUrl)} to={rescheduleUrl}>Reschedule</Link>
                <PopupModal
                    url={`${rescheduleUrl}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    onModalClose={(e) => handleCloseReschedule(e, meeting.uuid)}
                    prefill={{
                        name: advisor.name,
                        email: advisor.email_address,

                    }}
                    open={isRescheduleOpen}
                    rootElement={document.getElementById("evaluation-content") as HTMLElement}
                />
                <PopupModal
                    url={`${cancelUrl}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                    onModalClose={(e) => handleCloseCancel(e, meeting.uuid)}
                    prefill={{
                        name: advisor.name,
                        email: advisor.email_address,
                    }}
                    open={isCancelOpen}
                    rootElement={document.getElementById("evaluation-content") as HTMLElement}
                />
            </div>
        )
    } else {
        return (
            <div>
                Scheduled
            </div>
        )
    }
}

const ratingLabels: { [index: number]: string } = {
    0: "NA - I did not attend this meeting",
    1: 'No-show or complete miss',
    2: 'Not the best use of my time',
    3: 'Worthwhile, I\'d do more of these',
    4: 'Great call, with next steps',
};

const RatingDialog = ({ meeting, advisor, onRate, onClose }: any) => {
    const [rating, setRating] = useState<number | null>(meeting.ratings?.[0]?.rating || null);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);

    const handleRatingChange = (_event: any, newValue: number | null) => {
        setRating(newValue);
        if (newValue !== null) {
            onRate(_event, meeting.uuid, newValue);
        }
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth={false}
            PaperProps={{
                sx: {
                    width: '550px',
                    maxWidth: '550px'
                }
            }}
        >
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>

                        <h5>Please rate your meeting with {advisor.survey_link.project.name}</h5>
                        <p>We hope you had a great meeting with {advisor.survey_link.project.name}. Please rate this meeting so that we can steer more of the right introductions your way.</p>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', backgroundColor: "#ffffff", borderRadius: "20px", padding: "20px", border: "1px solid #c9c9c9" }}>
                            <CompanyLogo project={advisor.survey_link.project} size="large" />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: "40px", width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <DoNotDisturb
                                            onClick={() => {
                                                onRate(meeting.uuid, 0);
                                                onClose();
                                            }}
                                            onMouseOver={() => setHoveredRating(0)}
                                            onMouseLeave={() => setHoveredRating(-1)}
                                            sx={{
                                                cursor: 'pointer',
                                                width: '50px',
                                                height: '50px',
                                                paddingBottom: '5px',
                                                color: rating === 0 ? 'error.main' : 'action.disabled',
                                                '&:hover': { color: 'error.main' }
                                            }}
                                        />
                                        <Rating
                                            value={rating}
                                            max={4}
                                            size="large"
                                            icon={<Star sx={{ width: '50px', height: '50px' }} />}
                                            emptyIcon={<StarBorder sx={{ width: '50px', height: '50px' }} />}
                                            onChange={handleRatingChange}
                                            onChangeActive={(_event, newHover) => {
                                                setHoveredRating(newHover);
                                            }}
                                        />
                                    </Box>
                                    <Typography>
                                        {hoveredRating !== null && hoveredRating !== -1
                                            ? ratingLabels[hoveredRating]
                                            : rating !== null
                                                ? ratingLabels[rating]
                                                : "Please rate the meeting"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog >
    );
};

export const AdvisorMeet = () => {
    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const { advisor, loading, fetchAdvisor } = useAdvisor();
    const { trackAdvisorPage, trackAdvisorEvent } = useAnalytics();
    const [isDemoSchedulerOpen, setIsDemoSchedulerOpen] = useState(false); // State for DemoScheduler
    const [scheduler, setScheduler] = useState<any | null>(null);
    const [inlineWidgetHeight, setInlineWidgetHeight] = useState<string>("0");
    const [meetingDetails, setMeetingDetails] = useState<any>(null);
    const [meetings, setMeetings] = useState<any>([]);
    const [hoveredMeeting, setHoveredMeeting] = useState<string | null>(null);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);
    const [selectedMeeting, setSelectedMeeting] = useState<any>(null);

    const advisorHttpClient = ClientProvider.provideAdvisorClient();

    const { meetingUuid } = useParams();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (advisor && meetingUuid && meetings) {
            setSelectedMeeting(meetings.find((meeting: any) => meeting.uuid === meetingUuid));
            // remove uuid from url
            window.location.pathname.replace(`/expert/${advisor.uuid}/engage/${meetingUuid}`, `/expert/${advisor.uuid}/engage`);
        }
    }, [meetingUuid, meetings]);

    useEffect(() => {
        if (advisor) {
            trackAdvisorPage(advisor, 'expert flow - engage');
        }
    }, [advisor]);

    useEffect(() => {
        if (window.location.hash === "#calendar" && advisor && !advisor.prospect_scheduled) {
            setIsDemoSchedulerOpen(true);
            trackAdvisorEvent(advisor, 'Viewed calendar');
        }
    }, []);

    useEffect(() => {
        // Fetch the scheduler data
        fetchScheduler();

        return () => {
            setScheduler(null);
        }
    }, []);

    const fetchMeetingDetails = async () => {
        if (advisor?.prospect_event_invitee_uri) {
            const accessToken = await getAccessTokenSilently();
            const inviteeId = advisor.prospect_event_invitee_uri.split("/invitees/")[1];
            const { data, error } = await advisorHttpClient.getCalendlyMeetingDetails(project.tag, inviteeId, accessToken);
            if (data) {
                if (data.invitee.canceled && !data.invitee.rescheduled) {
                    setMeetingDetails(null);
                    fetchAdvisor(project.tag);
                } else {
                    setMeetingDetails(data.invitee);
                }
            } else {
                console.error('Error fetching meeting details:', error);
            }
        }
    };

    const handleMeetingEvent = async (e: any, meetingUuid: string) => {
        if (!advisor?.public_id) {
            return;
        }
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await advisorHttpClient.getAdvisorMeeting(project.tag, meetingUuid, accessToken);
        if (data) {
            const meeting = data.meeting;
            setMeetings(meetings.map((m: any) => m.id === meeting.id ? meeting : m));
        }
    }

    const fetchMeetings = async () => {
        if (!advisor?.public_id) {
            return;
        }
            const accessToken = await getAccessTokenSilently();
        const { data, error } = await advisorHttpClient.getAdvisorMeetings(project.tag, accessToken);
        if (data) {
            setMeetings(data.meetings);
            setIsDemoSchedulerOpen(data.meetings.filter((meeting: any) => meeting.status === "scheduled").length === 0);
        }
    }

    useEffect(() => {
        fetchMeetingDetails();
        fetchMeetings();
    }, [advisor?.prospect_event_invitee_uri]);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            if (!advisor?.public_id) {
                return;
            }
            //const { data, error } = await advisorScheduleEvent(advisor.public_id, { prospect_scheduled: true, prospect_event_uri: e.data.payload.event.uri, prospect_event_invitee_uri: e.data.payload.invitee.uri });
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await advisorHttpClient.advisorCreateMeeting(project.tag, { scheduler: { uuid: scheduler.uuid }, calendly_event_uri: e.data.payload.event.uri, calendly_invitee_uri: e.data.payload.invitee.uri }, accessToken);
            trackAdvisorEvent(advisor, 'Scheduled demo');
            if (data) {
                fetchAdvisor(project.tag);
                fetchMeetings();
            }
        },
        onPageHeightResize(e) {
            setInlineWidgetHeight(e.data.payload.height);
        }
    });

    const handleCloseDemoScheduler = () => {
        setIsDemoSchedulerOpen(false);
        fetchMeetingDetails();
    }

    const handleRating = async (e: any, meetingUuid: string, rating: number | null) => {
        if (rating === null) {
            return;
        }
        if (!advisor?.public_id) {
            return;
        }
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await advisorHttpClient.advisorRateMeeting(project.tag, meetingUuid, rating, accessToken);
        if (data) {
            fetchMeetings();
        }
    }


    const fetchScheduler = async () => {
        if (!advisor?.public_id) {
            return;
        }
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await advisorHttpClient.getScheduler(project.tag, "prospect", accessToken);
        if (error) {
            console.error("Error fetching scheduler:", error);
        } else if (data) {
            setScheduler(data.scheduler);
        }
    };

    const additionalGuests = scheduler ? [...scheduler.attendees.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])] : [];
    return (
        <>
            <AdvisorTitle project={project} section="Meet" subheading={`Schedule a demo with the ${project.name} team to explore a trial or partnership.`} />
            <div className="flex-column left-column">
                <div className="evaluation-content" id="evaluation-content">
                    {meetings?.length > 0 && (
                        <TableContainer>
                            <AdvisorTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="center">
                                            Date
                                        </TableCell>
                                        <TableCell>
                                            Time
                                        </TableCell>
                                        <TableCell>
                                            Description
                                        </TableCell>
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell className="center">
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {meetings?.map((meeting: any) => (
                                        <TableRow key={meeting.uuid}>
                                            <TableCell className="center">
                                                <DateDisplay meeting={meeting} />
                                            </TableCell>
                                            <TableCell>
                                                {new Date(meeting.event_start).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}
                                            </TableCell>
                                            <TableCell>
                                                {meeting.description}
                                            </TableCell>

                                            <TableCell>
                                                {meeting.status.charAt(0).toUpperCase() + meeting.status.slice(1).toLowerCase()}
                                            </TableCell>
                                            <TableCell className="center">
                                                {(meeting.status === "completed" || new Date(meeting.event_start) < new Date()) && (
                                                    <Box sx={{
                                                        minHeight: '60px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                        flexDirection: 'column',
                                                        gap: '5px'
                                                    }}
                                                        onClick={() => setSelectedMeeting(meeting)}>
                                                        <Rating
                                                            value={meeting.ratings?.length > 0 ? meeting.ratings[0].rating : null}
                                                            max={4}
                                                            readOnly
                                                        />
                                                        <Typography component="legend" variant="body2">
                                                            {meeting.ratings?.length > 0 ? (
                                                                ratingLabels[meeting.ratings[0].rating]
                                                            ) : "Please rate the meeting"}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {(meeting.status === "scheduled" && new Date(meeting.event_start) > new Date()) && <MeetingLinks advisor={advisor} meeting={meeting} handleMeetingEvent={handleMeetingEvent} fetchMeetingDetails={fetchMeetingDetails} />}
                                                {meeting.status === "requested" && <button onClick={() => setIsDemoSchedulerOpen(true)}>Schedule a Demo</button>}

                                                {false && scheduler && (
                                                    <PopupModal
                                                        url={`${scheduler.scheduling_url}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                                                        onModalClose={handleCloseDemoScheduler}
                                                        open={isDemoSchedulerOpen}
                                                        prefill={{
                                                            name: advisor?.name,
                                                            email: advisor?.email_address,
                                                            guests: [...scheduler.attendees.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])],
                                                            customAnswers: {
                                                                "a1": `https://${project.organization.custom_domain}/${project.tag}/review/${advisor?.uuid}`
                                                            }
                                                        }}
                                                        rootElement={document.getElementById("evaluation-content") as HTMLElement}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </AdvisorTable>
                        </TableContainer>
                    )}

                    {isDemoSchedulerOpen && scheduler && (
                        <InlineWidget
                            url={`${scheduler?.scheduling_url}?hide_gdpr_banner=1&hide_gdpr_banner_close_button=1`}
                            prefill={{
                                name: advisor?.name,
                                email: advisor?.email_address,
                                guests: [...scheduler?.attendees?.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])],
                                customAnswers: {
                                    "a1": `https://${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/meeting/${advisor?.uuid}`
                                }
                            }}
                            styles={{
                                height: inlineWidgetHeight,
                                minWidth: '320px',
                                marginTop: '20px'
                            }}
                        />
                    )}
                    {!isDemoSchedulerOpen && scheduler && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" onClick={() => setIsDemoSchedulerOpen(true)}>Schedule another Demo</Button>
                        </Box>
                    )}
                </div>
            </div>

            {selectedMeeting && (
                <RatingDialog
                    meeting={selectedMeeting}
                    advisor={advisor}
                    onRate={handleRating}
                    onClose={() => setSelectedMeeting(null)}
                />
            )}
        </>
    );
}