import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { LoginButton } from "../../../components/buttons/login-button";
import { IAdvisor } from "../../../types/database/Advisor";
import { IProject } from "../../../types/database/Project";
import { DocumentTitle } from "../../../components/DocumentTitle";
import logo from "../../../assets/images/brightloop-logo-web.svg";
export const AdvisorLogin = ({ project, advisor }: { project: IProject, advisor: IAdvisor }) => {
    localStorage.setItem("redirectUri", `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${project.tag}.${process.env.REACT_APP_APP_ORIGIN}/advisor`);
    return (
        <>
            <DocumentTitle pageTitle={`${project?.organization?.name} Advisor Network for ${project?.name}`} showAttribution={false} />
            <Box sx={{
                width: '100%'
            }}>
                <Box sx={{
                    background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#ffffff',
                    minHeight: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: "30px",
                        maxWidth: '1200px',
                        width: '100%',
                        margin: '0 auto',
                        padding: "40px 60px"
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flex: '1 1 460px',
                            minWidth: 0,
                        }}>
                            {advisor.status === "approved" ? (
                                <Typography variant="h3" fontSize={"2.3rem"} fontWeight="bold">
                                    Thanks for being a part of the {project?.organization?.name} Advisor Network for {project?.name}.
                                </Typography>
                            ) : (
                                <Typography variant="h3" fontSize={"2.3rem"} fontWeight="bold">
                                    Thanks for your interest in being a part of the {project.organization && !project.suppress_organization ? project.organization?.name : project?.name} Advisor Network{project.organization && !project.suppress_organization ? " for " + project?.name : ""}.
                                </Typography>
                            )}
                        </Box>
                        <Box className="display-box white"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                gap: 2,
                                color: '#000000',
                                flex: '0 0 300px',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '8px',
                                "& button": {
                                    width: '100%'
                                }
                            }}>
                            <h5>Welcome Back, {advisor?.user?.profile?.name?.split(" ")[0]}!</h5>
                            {advisor.status === "approved" ? (
                                <>
                                    <p>Click below to visit your advisor dashboard.</p>
                                    <LoginButton email={advisor?.user?.email_address} style="contained" theme="advisor" />
                                    <Box className="bl-attribution" sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant="body2" fontSize={"0.75rem"}>
                                            Powered by <img
                                                src={logo.toString()}
                                                alt="Brightloop Logo"
                                                style={{
                                                    height: '12px',
                                                    width: 'auto',
                                                    verticalAlign: 'middle',
                                                    marginLeft: '4px'
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <p>The team is reviewing your feedback and will let you know when you're approved.</p>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ backgroundColor: "#e9e9e9", padding: "20px 40px", textAlign: "center" }}>
                    <Typography fontSize={"1.5rem"} sx={{ fontStyle: "italic" }}>The {project.organization && !project.suppress_organization ? project.organization?.name : project?.name} Advisor network builds more successful startups by pairing senior executives with top startups in their wheelhouse.</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flext-start",
                    width: '100%',
                    margin: '0 auto',
                    padding: "40px 40px"
                }}>
                    {project.organization && !project.suppress_organization && (
                        <Box sx={{
                            flex: '1 1 300px',
                            minWidth: 0,
                            borderRight: { xs: 'none', md: '1px solid #e0e0e0' },
                            padding: { xs: '0 20px 30px 20px', md: '0 30px 0 20px' },
                        }}>
                            <Typography variant="body1" fontSize={"1.5rem"} mb={2}>About {project.organization?.name}</Typography>
                            <Typography variant="body1">{project.organization?.description}</Typography>
                        </Box>
                    )}
                    <Box sx={{
                        flex: '1 1 300px',
                        minWidth: 0,
                        padding: { xs: '30px 20px 0 20px', md: '0 20px 0 30px' },
                    }}>
                        <Typography variant="body1" fontSize={"1.5rem"} mb={2}>About {project?.name}</Typography>
                        <Typography variant="body1">{project.public_description}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
};
