import { Dialog, DialogContent, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getScheduler, advisorScheduleEvent } from "../../../core/services/gutcheck-api.service";
import { useCalendlyEventListener, PopupWidget } from "react-calendly";


export const DemoScheduler = ({ open, onClose, advisor, type }: { open: boolean, onClose: () => void, advisor: any, type: string }) => {
    const [scheduler, setScheduler] = useState<any | null>(null);

    useEffect(() => {
        // Fetch the scheduler data
        fetchScheduler();

        return () => {
            setScheduler(null);
        }
    }, []);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            const { data, error } = await advisorScheduleEvent(advisor.public_id, { prospect_scheduled: true, prospect_event_uri: e.data?.payload?.event?.uri });
            onClose();
        }
    });

    const fetchScheduler = async () => {
        const { data, error } = await getScheduler(advisor.public_id, type);
        if (error) {
            console.error("Error fetching scheduler:", error);
        } else {
            setScheduler(data.scheduler);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>

            <DialogContent className="sm:max-w-[800px]">
                {scheduler && (
                    <PopupWidget
                        url={scheduler.scheduling_url}
                        prefill={{
                            name: advisor.name,
                            email: advisor.email_address,
                            guests: scheduler.attendees.map((attendee: any) => attendee.email)
                        }}
                        text="Schedule a Demo"
                        rootElement={document.getElementById("root") as HTMLElement}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}