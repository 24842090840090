import { UserProfileImage } from "../../../users";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { Box, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvitationControl } from "../sharing/InvitationControl";
import { useGetProjectMembers } from "../../../../hooks/useGetProjectMembers";
import { useGetProjectInvitations } from "../../../../hooks/useGetProjectInvitations";
import envelopeSvg from "../../../../assets/icons/envelope.svg";
import questionMarkSvg from "../../../../assets/icons/question-mark.svg";
import Membership from "../../../users/types/Membership";


const MembersList = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    gap: '5px',
});

const ProfileImageBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#c9c9c9',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
});

const InviteeProfileBox = styled('div')({
    position: 'relative',
    '& img.envelope': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '12px',
    }
});

export const SetupInvite = ({ project, user }: any) => {

    const [inviteActive, setInviteActive] = useState(false);
    const membersData = useGetProjectMembers(project.id ?? "");
    const invitationsData = useGetProjectInvitations(project.id ?? "");


    const handleOpenInvite = () => {
        setInviteActive(true);
    }

    //if (!membersData.fetched || !invitationsData.fetched) return null;



    return (
        <MembersList>
            {membersData.members.filter((membership: Membership) => { return (membership.user && (membership.role === "contributor" || membership.role === "admin"))})?.map((member: Membership) => (
                <UserProfileImage size="small" user={member.user} key={member.user?.uuid} />
            ))}
            {invitationsData.invitations.filter((invitation: any) => invitation.invitee_role === "contributor" || invitation.invitee_role === "admin")?.map((invitation: any) => {
                return (
                    <InviteeProfileBox key={`invitation-${invitation.id}`}>
                        <UserProfileImage size="small" pending={true} user={{ email_address: invitation.invitee_email, name: invitation.invitee_name, image_url: null }} key={invitation.invitee_email} />
                        <img className="envelope" src={questionMarkSvg.toString()} />
                    </InviteeProfileBox>
                )
            })
            }
            <ProfileImageBox onClick={handleOpenInvite} sx={{ cursor: "pointer", border: "2px dashed #c9c9c9", backgroundColor: "#f8f9fa", color: "#c9c9c9"}}>
                <FontAwesomeIcon icon="plus" />
            </ProfileImageBox>
            <InvitationControl project={project} role="collaborator" active={inviteActive} setActive={setInviteActive} reloadInvitations={invitationsData.reloadProjectInvitations} />
        </MembersList>
    );
}