import { useEffect } from "react";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";
import { IProject } from "../../../types";

export const AdvisorTitle = ({ project, section, subheading }: {project: IProject, section: string, subheading?: string | React.ReactNode}) => {

    const { trackPage } = useAnalytics();

    useEffect(() => {
        trackPage(`Advisor - ${section}`, {
            project: project?.name,
            organization: project?.organization?.name,
            section: section
        });
    }, [project, section]);

    return (
        <Box sx={{ marginBottom: "40px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Box>
                <DocumentTitle pageTitle={`${project?.name} - ${section}`} />
                <h2>{section}</h2>
                {subheading && <div>{subheading}</div>}
            </Box>
        </Box>
    );
}