import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, TextField, Button, Container, Grid, Modal, Dialog, DialogTitle, Select, MenuItem } from "@mui/material";
import { UserProfileImage } from "../../../users/components/UserProfileImage";
import Loading from "../../../../components/Loading";
import { IUser, IProfile, ICompany } from "../../../../types";
import ClientProvider from "../../../../modules/ClientProvider";
import StyledDropzone from "../../../../components/AssetUpload";
import { getCompanyByDomain, updateCompany, createCompany, updateProfile } from "../../services/gutcheck-admin-api.service";
import { uploadLogo } from "../../../../core/services/gutcheck-api.service";
import { AdvisorCompany } from "../../../advisor/components/AdvisorCompany";
import { CompanyLogoBase, CompanyNameBase } from "../../../../components/shared/StyledComponents";
import { CompanyForm } from "./CompanyUpdate";
import { CompanyLogo } from "../../../../components/CompanyLogo";

const SeniorityLevels = [{
    value: -1,
    label: "Not Specified"
}, {
    value: 1,
    label: "Entry Level"
}, {
    value: 2,
    label: "Mid Level"
}, {
    value: 3,
    label: "Senior Level"
}, {
    value: 4,
    label: "Executive Level"
}, {
    value: 5,
    label: "C-suite / Top Executive"
}];

const ImageModal = ({ imageFile, open, onClose }: { imageFile?: string, open: boolean, onClose: () => void }) => {
    const [queuedFiles, setQueuedFiles] = useState<any[]>([]);
    const [preview, setPreview] = useState<any>(null);
    const { getAccessTokenSilently } = useAuth0();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleUpload = async () => {
        setIsUploading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await ClientProvider.provideUserClient().uploadProfileImage(queuedFiles[0], queuedFiles[0].name, accessToken);

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        if (data) {
            setSuccessMessage("Profile image updated successfully");
            setIsUploading(false);
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                "& img": {
                    maxWidth: "300px",
                    maxHeight: "300px",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                    borderRadius: "50%",
                    display: "block",
                    margin: "auto"
                }
            }}
        >
            <h5>Upload a Profile Image</h5>
            <StyledDropzone
                queuedFiles={queuedFiles}
                setQueuedFiles={setQueuedFiles}
                fileTypes={{
                    "image/*": [".png", ".jpg", ".jpeg"]
                }}
                initialFile={imageFile || ""}
            />
            {queuedFiles.length > 0 && (
                <Button variant="contained" color="primary" onClick={handleUpload} disabled={isUploading}>{isUploading ? "Uploading..." : "Upload"}</Button>
            )}
        </Dialog>
    )
}


export const ProfileUpdate = ({ user, onUpdate, open, setOpen }: { user: IUser, onUpdate: () => void, open: boolean, setOpen: (open: boolean) => void }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [companyFormOpen, setCompanyFormOpen] = useState<boolean>(false);

    const [form, setForm] = useState<Partial<IProfile>>({
        name: user?.profile?.name || "",
        email: user?.email_address || "",
        image_url: user?.profile?.image_url || "",
        title: user?.profile?.title || "",
        company_name: user?.profile?.company_name || "",
        location: user?.profile?.location || "",
        linkedin_url: user?.profile?.linkedin_url || "",
        previous_companies: user?.profile?.previous_companies || "",
        seniority: user?.profile?.seniority || "",
        company: user?.profile?.company, 
        level: user?.profile?.level || -1
    });

    if (!user) return <Loading />;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
        setFormChanged(true);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await updateProfile(user.id, form, accessToken);

            if (error) {
                setErrorMessage(error.message);
                return;
            }

            if (data) {
                setSuccessMessage("Profile updated successfully");
                setFormChanged(false);
                onUpdate();
                setOpen(false);
            }
        } catch (error) {
            setErrorMessage("An error occurred while updating your profile");
        }
    };
    const handleImageUpload = () => {
        setImageModalOpen(true);
    }

    const handleImageClose = () => {
        setImageModalOpen(false);
        setSuccessMessage(null);
        setErrorMessage(null);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCompanyChange = (company: ICompany) => {
        if (company !== form.company) {
            setForm({ ...form, company: company });
            setFormChanged(true);
        }
        setCompanyFormOpen(false);
    }

    const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, level: parseInt(event.target.value.toString()) });
        setFormChanged(true);
    }

    const handleCompanyFormClose = () => {
        setCompanyFormOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box maxWidth="md">
                <Grid container spacing={2} alignItems="center" className="profile-header mb-5">
                    <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>

                        <UserProfileImage user={{ ...user, profile: { ...user.profile, image_url: form.image_url } }} size="large" />
                        <span className="link" onClick={handleImageUpload}>Upload Image</span>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={form.name}
                                    onChange={handleChange}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    value={form.email}
                                    disabled
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="image_url"
                                    label="Image URL"
                                    value={form.image_url}
                                    size="small"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>

                            <TextField
                                fullWidth
                                id="title"
                                label="Title"
                                value={form.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                                <CompanyLogo company={form.company as ICompany} size="large" />
                                <span className="link" onClick={() => setCompanyFormOpen(true)}>Edit Company</span>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="linkedin_url"
                                label="LinkedIn URL"
                                value={form.linkedin_url}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="location"
                                label="Location"
                                value={form.location}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                id="seniority"
                                label="Seniority Level"
                                value={form.level}
                                onChange={handleLevelChange}
                            >
                                {SeniorityLevels.map((level) => (
                                    <MenuItem key={level.value} value={level.value}>{level.label}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    </Grid>

                    {successMessage && (
                        <Box sx={{ mt: 2, color: 'success.main' }}>
                            {successMessage}
                        </Box>
                    )}

                    {errorMessage && (
                        <Box sx={{ mt: 2, color: 'error.main' }}>
                            {errorMessage}
                        </Box>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formChanged || isSubmitting}
                    >
                        {isSubmitting ? "Saving..." : "Looks Good"}
                    </Button>
                </Box>
                <ImageModal open={imageModalOpen} onClose={handleImageClose} />
                <CompanyForm company={form.company as ICompany} setCompany={handleCompanyChange} open={companyFormOpen} onClose={handleCompanyFormClose} />
            </Box>
        </Dialog>
    );
};
