import { Box, Dialog, styled, Typography, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/images/brightloop-logo.svg";
import { useEffect, useState } from "react";
import { useDealContext } from "../../deal/context";

const CollaborationDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        width: "650px",
        height: "350px",
        borderRadius: "8px",
        padding: "2rem",
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        overflow: "hidden", // Ensure content doesn't overflow
        "& .background-image": {
            position: "absolute",
            opacity: "0.5",
            bottom: "50px",
            right: "-30px",
            width: "90%",
            height: "auto",
            zIndex: 0, // Ensure it is behind other elements
        },
        "& img.logo-image": {
            maxHeight: "34px",
            zIndex: 1, // Ensure it is above the background image
            "&.brightloop": {
                maxHeight: "24px",
            },
        },
        "& .bottom-row": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
            marginTop: "1rem",
            "& button": {
                padding: "0.5rem 1.5rem",
                borderRadius: "0.375rem",
                backgroundColor: "#1a5dbd",
                border: "2px solid #1a5dbd",
                color: "#ffffff",
                "&:hover": {
                    backgroundColor: "#4d8ae0",
                    color: "#ffffff",
                },
            },
        }
    }
}));

export const CompanyCollaborationModal = () => {
    const { state } = useLocation();
    const welcome = localStorage.getItem("welcome");

    const { dealContext } = useDealContext();   

    const project = dealContext.project;

    const [ open, setOpen ] = useState(false);
    const [ type, setType ] = useState("collaborator");

    useEffect(() => {
        if (state?.invitationAccepted) {
            setOpen(true);
        }

        if (welcome === "guest") {
            localStorage.removeItem("welcome");
            setOpen(true);
            setType("guest");
        }
    }, []);

    return (
        <CollaborationDialog open={open}>
            <img src="/images/illustrations/crowd-arrow.svg" className="background-image" />
            <Box>
                <Box sx={{ width: "350px" }}>
                    {project?.organization?.logo_url ? <img className="logo-image" src={project?.organization?.logo_url} /> : <Typography variant="h5">{project?.organization?.name}</Typography>}
                    { type === "collaborator" && <Typography variant="body1" sx={{ margin: "1rem 0" }}>You've been invited to start generating<br/>insights and opportunities for</Typography> }
                    { type === "guest" && <Typography variant="body1" sx={{ margin: "1rem 0" }}>You now have access to review the <br/>insights and opportunities for</Typography> }
                    {project?.logo_url ? <img className="logo-image" src={project?.logo_url} /> : project?.company?.logo_url ? <img className="logo-image" src={project?.company?.logo_url} /> : <Typography variant="h5">{project?.name}</Typography>}
                </Box>
            </Box>
            <Box className="bottom-row">
                <Box>
                    <Typography variant="body2">Powered by</Typography>
                    <img className="logo-image brightloop" src={logo.toString()} />
                </Box>
                <Button variant="contained" color="primary" onClick={() => { setOpen(false);}}>Get Started</Button>

            </Box>
        </CollaborationDialog>
    );
};