import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { createTouchpoint, updateTouchpoint } from "../../services/gutcheck-admin-api.service";

const touchpointTypes = [
    { value: "default", label: "Default" },
    { value: "manual", label: "Manual" },
    { value: "auto", label: "Automated" }
];

const touchpointStatuses = [
    { value: "draft", label: "Draft" },
    { value: "scheduled", label: "Scheduled" },
    { value: "active", label: "Active" },
    { value: "completed", label: "Completed" }
];

export const TouchpointForm = ({ project, touchpoint, editMode, setEditMode, reloadProjectData }: any) => {
    const { getAccessTokenSilently } = useAuth0();
    const client = new ProjectHttpClient();

    const [touchpointForm, setTouchpointForm] = useState({
        name: touchpoint?.name || "",
        type: touchpoint?.type || "default",
        status: touchpoint?.status || "draft",
        scheduled_at: touchpoint?.scheduled_at ? dayjs(touchpoint.scheduled_at) : null,
        started_at: touchpoint?.started_at ? dayjs(touchpoint.started_at) : null,
        completed_at: touchpoint?.completed_at ? dayjs(touchpoint.completed_at) : null,
        analysis_url: touchpoint?.analysis_url || "",
        product_description: touchpoint?.product_description || "",
        suppressed_questions: touchpoint?.suppressed_questions || [],
        product_description_section: touchpoint?.product_description_section || ""
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "suppressed_questions") {
            setTouchpointForm(prev => ({
                ...prev,
                [name]: value.split("\n")
            }));
        } else {
            setTouchpointForm(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleDateChange = (name: string) => (value: any) => {
        setTouchpointForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const formData = {
            ...touchpointForm,
            scheduled_at: touchpointForm.scheduled_at?.toISOString(),
            started_at: touchpointForm.started_at?.toISOString(),
            completed_at: touchpointForm.completed_at?.toISOString()
        };

        if (touchpoint?.id) {
            // Update existing touchpoint
            const { data, error } = await updateTouchpoint(
                project.id,
                touchpoint.id,
                formData,
                accessToken
            );

            if (!error) {
                setEditMode(false);
                reloadProjectData();
            }
        } else {
            // Create new touchpoint
            const { data, error } = await createTouchpoint(
                project.id,
                formData,
                accessToken
            );

            if (!error) {
                setEditMode(false);
                reloadProjectData();
            }
        }
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: 2,
                backgroundColor: "#f5f5f5",
                marginTop: 1,
                marginBottom: 1
            }}
        >
            <TextField
                fullWidth
                name="name"
                label="Name"
                value={touchpointForm.name}
                onChange={handleChange}
                required
            />

            <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
                <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                        name="type"
                        value={touchpointForm.type}
                        onChange={handleChange}
                        label="Type"
                        required
                        size="small"
                    >
                        {touchpointTypes.map(type => (
                            <MenuItem key={type.value} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                        name="status"
                        value={touchpointForm.status}
                        onChange={handleChange}
                        label="Status"
                        required
                        size="small"
                    >
                        {touchpointStatuses.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
                <DateTimePicker
                    label="Scheduled For"
                    value={touchpointForm.scheduled_at}
                    onChange={handleDateChange("scheduled_at")}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            size: "small"
                        }
                    }}
                />

                <DateTimePicker
                    label="Started At"
                    value={touchpointForm.started_at}
                    onChange={handleDateChange("started_at")}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            size: "small"
                        }
                    }}
                />

                <DateTimePicker
                    label="Completed At"
                    value={touchpointForm.completed_at}
                    onChange={handleDateChange("completed_at")}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            size: "small"
                        }
                    }}
                />
            </Box>

            <TextField
                fullWidth
                name="product_description_section"
                label="Product Description Section"
                value={touchpointForm.product_description_section}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                name="product_description"
                label="Product Description"
                value={touchpointForm.product_description}
                onChange={handleChange}
                multiline
                rows={4}
            />

            <TextField
                fullWidth
                name="analysis_url"
                label="Analysis URL"
                value={touchpointForm.analysis_url}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                name="suppressed_questions"
                label="Suppressed Questions"
                value={touchpointForm.suppressed_questions.join("\n")}
                onChange={handleChange}
                multiline
                rows={4}
            />

            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="contained" type="submit">
                    {touchpoint?.id ? "Update" : "Create"} Touchpoint
                </Button>
            </Box>
        </Box>
    );
}; 