import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, styled, Tooltip, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/brightloop-logo-web.svg";
import { useDealContext } from "../../deal/context";
import { House, ChartScatter, Repeat2, CalendarHeart, HandCoins, Cog, Send, UserPlus, Blocks, Linkedin, Link, Plus, Calendar } from "lucide-react";
import React from "react";
import { Sidebar, SidebarSection } from "../../../components/Sidebar";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useAdvisors } from "../../advisor";

export const CompanySidebar = () => {
    const { dealContext } = useDealContext();

    const project = dealContext.project;
    const role = dealContext.role;
    const isDraft = project.status === "draft";
    const isGuest = role === "guest";
    const shareLink = project.project_share_links[0];
    const { advisors } = useAdvisors();
    const hasAdvisors = advisors.length > 0;

    const baseUrl = role === "guest" ? `/guest/${shareLink.share_url}` : `/c`;

    const tabStatus = {
        advisors: isDraft ? false : isGuest ? shareLink.display_executives : true,
        scoring: isDraft ? false : isGuest ? shareLink.display_viewpoints : hasAdvisors,
        nurture: isDraft ? false : isGuest ? shareLink.display_viewpoints : hasAdvisors,
        meetings: isDraft ? false : isGuest ? shareLink.display_prospects : hasAdvisors,
        investment: isDraft ? false : isGuest ? shareLink.display_investors : hasAdvisors,
        setup: !isGuest,
        calendar: !isGuest && !isDraft,
        collaboration: !isGuest && !isDraft,
        outreach: !isGuest && !isDraft,
        suggest: !isGuest && !isDraft,
        linkedin: !isGuest && !isDraft,
        campaigns: !isGuest && !isDraft,
        blocklist: !isGuest && !isDraft,
        company: !isGuest && !isDraft
    };

    const sections: SidebarSection[] = [
        {
            visible: true,
            links: [
                {
                    to: `${baseUrl}`,
                    iconComponent: <House size={16}/>,
                    text: "Advisors",
                    enabled: tabStatus.advisors,
                    end: true,
                    visible: true
                }
            ]
        },
        {
            header: "Pipeline",
            visible: true,
            links: [
                {
                    to: `${baseUrl}/scoring`,
                    iconComponent: <ChartScatter size={16}/>,
                    text: "Scoring",
                    enabled: tabStatus.scoring,
                    visible: true
                },
                {
                    to: `${baseUrl}/nurture`,
                    iconComponent: <Repeat2 size={16}/>,
                    text: "Nurture",
                    enabled: tabStatus.nurture,
                    visible: true
                },
                {
                    to: `${baseUrl}/meetings`,
                    iconComponent: <CalendarHeart size={16}/>,
                    text: "Meetings",
                    enabled: tabStatus.meetings,
                    visible: true
                },
                {
                    to: `${baseUrl}/investment`,
                    iconComponent: <HandCoins size={16}/>,
                    text: "Investment",
                    enabled: tabStatus.investment,
                    visible: project.spv_stage !== "pending"
                }
            ]
        },
        {
            header: "Outreach",
            visible: true,
            links: [
                {
                    to: `${baseUrl}/suggest`,
                    iconComponent: <Plus size={16}/>,
                    text: "Suggest Advisors",
                    enabled: tabStatus.suggest,
                    visible: true
                },
                {
                    to: `${baseUrl}/links`,
                    iconComponent: <Link size={16}/>,
                    text: "Invitation Links",
                    enabled: tabStatus.outreach,
                    visible: true
                },
                {
                    to: `${baseUrl}/linkedin`,
                    iconComponent: <FontAwesomeIcon icon={faLinkedin}/>,
                    text: "Scan LinkedIn",
                    enabled: tabStatus.linkedin,
                    visible: true
                },
                {
                    to: `${baseUrl}/campaigns`,
                    iconComponent: <Repeat2 size={16}/>,
                    text: "Paid Campaigns",
                    enabled: tabStatus.campaigns,
                    visible: true
                },
                {
                    to: `${baseUrl}/blocklist`,
                    iconComponent: <Blocks size={16}/>,
                    text: "Blocklist",
                    enabled: tabStatus.blocklist,
                    visible: true
                }
            ]
        },
        {
            header: "Setup",
            visible: true,
            links: [
                {
                    to: `${baseUrl}/company`,
                    iconComponent: <Cog size={16}/>,
                    text: "Company Info",
                    enabled: tabStatus.company,
                    visible: role === "admin"
                },
                {
                    to: `${baseUrl}/calendar`,
                    iconComponent: <Calendar size={16}/>,
                    text: "Calendar",
                    enabled: tabStatus.calendar,
                    visible: role === "admin"
                },
                {
                    to: `${baseUrl}/collaboration`,
                    iconComponent: <UserPlus size={16}/>,
                    text: "Team",
                    enabled: tabStatus.collaboration,
                    visible: true
                }
            ]
        }
    ];

    const bottomRowContent = role === "guest" ? (
        <Box className="bottom-row">
            <Box>
                <Typography variant="body2">Powered by</Typography>
                <img className="logo-image brightloop" src={logo.toString()} />
            </Box>
        </Box>
    ) : undefined;

    return <Sidebar sections={sections} bottomRow={bottomRowContent} />;
};
