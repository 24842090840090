import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getPaidCampaigns } from "../services/gutcheck-admin-api.service";

export const usePaidCampaigns = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const [paidCampaigns, setPaidCampaigns] = useState<any[]>([]);
    const [fetched, setFetched] = useState(false);
    const [error, setError] = useState(null);

    const fetchPaidCampaigns = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getPaidCampaigns(projectId, accessToken);
            
            if (error) {
                setError(error?.message || "Failed to fetch paid campaigns");
                setPaidCampaigns([]);
            } else {
                setPaidCampaigns(Array.isArray(data.paidCampaigns) ? data.paidCampaigns : []);
            }
            
            setFetched(true);
        } catch (error: any) {
            setError(error?.message || "Failed to fetch paid campaigns");
            setPaidCampaigns([]);
            setFetched(true);
        }
    };

    useEffect(() => {
        if (projectId) {
            fetchPaidCampaigns();
        }
    }, [projectId]);

    return {
        paidCampaigns,
        fetched,
        error,
        reloadPaidCampaigns: fetchPaidCampaigns
    };
}; 