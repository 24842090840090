import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Typography, Modal } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { AdminTouchpointsList } from "./components/TouchpointsList";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { TouchpointForm } from "./components/TouchpointForm";
import { getTouchpoints } from "../services/gutcheck-admin-api.service";

export const AdminTouchpoints = () => {
    const { project, reloadProjectData } = useOutletContext<any>();
    const { getAccessTokenSilently } = useAuth0();
    const [touchpoints, setTouchpoints] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [createMode, setCreateMode] = useState(false);
    const client = new ProjectHttpClient();

    const fetchTouchpoints = async () => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getTouchpoints(project.id, accessToken);
        
        if (data) {
            setTouchpoints(data.touchpoints);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (project) {
            fetchTouchpoints();
        }
    }, [project]);

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Feedback Loops</h5>
                <button className="btn btn-primary" onClick={() => setCreateMode(true)}>
                    Create Feedback Loop
                </button>
            </div>
            
            <Modal 
                open={createMode} 
                onClose={() => setCreateMode(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    <TouchpointForm
                        project={project}
                        editMode={createMode}
                        setEditMode={setCreateMode}
                        reloadProjectData={fetchTouchpoints}
                    />
                </Box>
            </Modal>

            <AdminTouchpointsList
                project={project}
                sectionName="Draft"
                touchpoints={touchpoints.filter((t: any) => t.status === "draft")}
                reloadProjectData={fetchTouchpoints}
            />

            <AdminTouchpointsList
                project={project}
                sectionName="Scheduled"
                touchpoints={touchpoints.filter((t: any) => t.status === "scheduled")}
                reloadProjectData={fetchTouchpoints}
            />

            <AdminTouchpointsList
                project={project}
                sectionName="Active"
                touchpoints={touchpoints.filter((t: any) => t.status === "active")}
                reloadProjectData={fetchTouchpoints}
            />

            <AdminTouchpointsList
                project={project}
                sectionName="Completed"
                touchpoints={touchpoints.filter((t: any) => t.status === "completed")}
                reloadProjectData={fetchTouchpoints}
            />
        </div>
    );
}; 