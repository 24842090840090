import React, { createContext, useContext, ReactNode, useState } from 'react';
import { IAdvisor } from '../../../types';
import ClientProvider from '../../../modules/ClientProvider';
import { useAuth0 } from '@auth0/auth0-react';
// Define the context value type
interface AdvisorContextType {
    advisor: IAdvisor | null;
    loading: boolean;
    error: string | null;
    fetchAdvisor: (projectTag: string) => Promise<void>;
    updateAdvisor: (advisor: IAdvisor) => Promise<void>;
}

// Create the context
const AdvisorContext = createContext<AdvisorContextType | undefined>(undefined);

// Create a provider component
export function AdvisorProvider({ children }: { children: ReactNode }) {
    const [advisor, setAdvisor] = useState<IAdvisor | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();

    const fetchAdvisor = async (projectTag: string) => {
        setLoading(true);
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await ClientProvider.provideAdvisorClient().getSelf(projectTag, accessToken);
            if (data.advisor) {
                setAdvisor(data.advisor);
            } else {
                setError(error?.message || 'An error occurred');
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const updateAdvisor = async (advisor: IAdvisor) => {
        setLoading(true);
        try {
            // Implement your API call here
            // await api.updateAdvisor(advisor);
            // setAdvisors(prev => prev.map(a => a.id === advisor.id ? advisor : a));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };
    const value = {
        advisor,
        loading,
        error,
        fetchAdvisor,   
        updateAdvisor,
    };

    return (
        <AdvisorContext.Provider value={value}>
            {children}
        </AdvisorContext.Provider>
    );
}

// Create a custom hook to use the context
export function useAdvisor() {
    const context = useContext(AdvisorContext);
    if (context === undefined) {
        throw new Error('useAdvisor must be used within an AdvisorProvider');
    }
    return context;
}
