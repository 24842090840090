import { Button, TextField, TableBody, Dialog, Box, Stack, TableRow, TableCell, TableHead, styled, BoxProps } from "@mui/material";
import { Check, Copy } from "lucide-react";
import { useState, useEffect } from "react";
import { useDealContext } from "../../../deal/context";
import { AdvisorTable, SuccessButton } from "../../styles/AdvisorStyles";
import ProjectHttpClient from "../../../../core/infrastructure/clients/ProjectHttpClient";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

interface CustomLinkFormProps {
    showCustomLink: boolean;
    setShowCustomLink: (show: boolean) => void;
    fetchTemplates: () => void;
    editTemplate?: InvitationTemplate;
}

const InvitationTemplateBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #c9c9c9',
    "&:first-of-type": {
        borderTop: '1px solid #c9c9c9',
    },
    "& h5": {
        marginBottom: '10px',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '10px',
    },
    "& p": {
        marginBottom: '10px',
    },
    "& blockquote": {
        marginBottm: 0,
    }
}));

const CustomLinkForm = ({ showCustomLink, setShowCustomLink, fetchTemplates, editTemplate }: CustomLinkFormProps) => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [name, setName] = useState(editTemplate?.name || '');
    const [body, setBody] = useState(editTemplate?.body || '');

    const handleSave = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();

        if (editTemplate) {
            await projectClient.updateInvitationTemplate(
                project.id,
                editTemplate.uuid,
                {
                    name,
                    body,
                    type: 'copy',
                    project_id: project.id
                },
                accessToken
            );
        } else {
            await projectClient.addInvitationTemplate(
                project.id,
                {
                    name,
                    body,
                    type: 'copy',
                    project_id: project.id
                },
                accessToken
            );
        }

        setShowCustomLink(false);
        fetchTemplates();
    };

    const handleCancel = () => {
        setName('');
        setBody('');
        setShowCustomLink(false);
        fetchTemplates();
    }

    return (
        <Dialog open={showCustomLink} onClose={handleCancel}>
            <h5>{editTemplate ? 'Edit Template' : 'Add Custom Link'}</h5>
            <p>{editTemplate ? 'Edit your custom template.' : 'Add a new custom link your team can use to invite advisors.'}</p>
            <Stack direction="column" spacing={2}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    multiline
                    rows={4}
                    label="Text"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    helperText="Use {{link}} to insert the invitation link"
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                </Box>
            </Stack>
        </Dialog>
    );
}

interface InvitationTemplate {
    id: number;
    uuid: string;
    name: string;
    body: string;
    type: string;
}

export const InvitationTemplates = () => {
    const { dealContext } = useDealContext();
    const { project } = dealContext;
    const { getAccessTokenSilently } = useAuth0();
    const [showCustomLink, setShowCustomLink] = useState(false);
    const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
    const [templates, setTemplates] = useState<InvitationTemplate[]>([]);
    const [editingTemplate, setEditingTemplate] = useState<InvitationTemplate | undefined>();
    const [deleteTemplate, setDeleteTemplate] = useState<InvitationTemplate | null>(null);
    const [templateList, setTemplateList] = useState<any[]>([]);

    const link = project.organization.custom_domain_enabled ?
        `${process.env.REACT_APP_APP_PROTOCOL || 'https'}://${project.organization.custom_domain}${process.env.REACT_APP_APP_PORT ? `:${process.env.REACT_APP_APP_PORT}` : ''}/${project.tag}` :
        `${project._origin}/advise/${project.tag}`;

    const defaultTemplates = [
        {
            name: "Link to Advisor Invitation Landing Page",
            value: link,
            copyText: `${link}`,
            isBackendTemplate: false
        },
        {
            name: "Boilerplate Invitation",
            value: project.suppress_organization ?
                `${project.name} has an advisor program where I think your insights could be valuable. There's some detail here if you think it could be a fit: ${link}` :
                `${project.organization.name} has an advisor program for ${project.name} where I think your insights could be valuable. There's some detail here if you think it could be a fit: ${link}`,
            copyText: project.suppress_organization ?
                `${project.name} has an advisor program where I think your insights could be valuable. There's some detail here if you think it could be a fit: ${link}` :
                `${project.organization.name} has an advisor program for ${project.name} where I think your insights could be valuable. There's some detail here if you think it could be a fit: ${link}`,
            isBackendTemplate: false
        }
    ]

    const fetchTemplates = async () => {
        const projectClient = new ProjectHttpClient();
        const accessToken = await getAccessTokenSilently();
        const { data } = await projectClient.getInvitationTemplates(project.id, accessToken);
        if (data) {
            setTemplates(Array.isArray(data.templates) ? data.templates : []);
            setTemplateList([...defaultTemplates, ...data.templates.map((template: InvitationTemplate) => ({
                ...template,
                value: template.body.replace('{{link}}', link),
                copyText: template.body.replace('{{link}}', link),
                isBackendTemplate: true
            }))]);
        }
    };
    // Fetch templates on component mount
    useEffect(() => {

        fetchTemplates();
    }, [project.id, getAccessTokenSilently]);



    const handleCopy = (row: typeof defaultTemplates[0], index: number) => {
        navigator.clipboard.writeText(row.copyText);
        setCopiedIndex(index);

        setTimeout(() => {
            setCopiedIndex(null);
        }, 5000);
    }

    const handleAddCustomLink = async () => {
        setEditingTemplate(undefined);
        setShowCustomLink(true);
    }

    const handleEdit = (template: InvitationTemplate) => {
        setEditingTemplate(template);
        setShowCustomLink(true);
    };

    const handleCloseDialog = () => {
        setShowCustomLink(false);
        setEditingTemplate(undefined);
    };

    const handleDeleteClick = (template: InvitationTemplate) => {
        setDeleteTemplate(template);
    };

    const handleDeleteConfirm = async () => {
        if (deleteTemplate) {
            const projectClient = new ProjectHttpClient();
            const accessToken = await getAccessTokenSilently();
            await projectClient.deleteInvitationTemplate(project.id, deleteTemplate.uuid, accessToken);
            setDeleteTemplate(null);
            fetchTemplates();
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {templateList.map((template, index) => (
                <InvitationTemplateBox key={index}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "space-between", flexGrow: 1, width: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <h6>{template.name}</h6>
                            <blockquote>{template.value}</blockquote>
                        </Box>
                        <Stack direction="column" spacing={1} alignItems="center">
                            {copiedIndex === index ? (
                                <SuccessButton disabled sx={{ whiteSpace: "nowrap" }} variant="outlined" size="small">
                                    <Check size={14} />&nbsp;&nbsp;Copy
                                </SuccessButton>
                            ) : (
                                <Button sx={{ whiteSpace: "nowrap" }} variant="contained" size="small" onClick={() => handleCopy(template, index)}>
                                    <Copy size={14} />&nbsp;&nbsp;Copy
                                </Button>
                            )}
                            {template.isBackendTemplate && (
                                <Stack direction="row" spacing={1}>
                                    <span className="link"
                                        onClick={() => {
                                            if ('id' in template && 'uuid' in template) {
                                                handleEdit(template as InvitationTemplate);
                                            }
                                        }}
                                    >
                                        Edit
                                    </span>
                                    <span className="link"
                                        onClick={() => {
                                            if ('id' in template && 'uuid' in template) {
                                                handleDeleteClick(template as InvitationTemplate);
                                            }
                                        }}
                                    >
                                        Delete
                                    </span>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                </InvitationTemplateBox>
            ))}

            <Button sx={{ marginTop: '20px' }} variant="text" onClick={handleAddCustomLink}>+ Add Custom Link</Button>
            {showCustomLink && (
                <CustomLinkForm
                    showCustomLink={showCustomLink}
                    setShowCustomLink={handleCloseDialog}
                    fetchTemplates={fetchTemplates}
                    editTemplate={editingTemplate}
                />
            )}
            <ConfirmationModal
                open={!!deleteTemplate}
                onClose={() => setDeleteTemplate(null)}
                onConfirm={handleDeleteConfirm}
                success={false}
                title="Delete Template"
                message="Are you sure you want to delete this template?"
            />
        </Box>
    );
}