import { useUserContext } from "../../../users";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../../modules/ClientProvider";
import { Box, MenuItem, Select, Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Circle, CircleCheckBig } from "lucide-react";
import { CompanyTitle } from "../../components/CompanyTitle";
import { useDealContext } from "../../../deal/context";
import { ManageCalendar } from "../../components/calendar/CustomSetup";
import { CalendlySetup } from "../../components/calendar/CalendlySetup";

export const CalendarConfig = ({ setup = false, setCanProceed = () => {}, canProceed = true }: { setup?: boolean, setCanProceed?: (canProceed: boolean) => void, canProceed?: boolean }) => {
    const { dealContext } = useDealContext();
    const { project, organization, role } = dealContext;
    const { userContext } = useUserContext();

    const { getAccessTokenSilently } = useAuth0();
    const [schedulers, setSchedulers] = useState<any | null>(null);
    const [selectedMeetingScheduler, setSelectedMeetingScheduler] = useState<string>("");
    const [defaultProvider, setDefaultProvider] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const projectClient = ClientProvider.provideProjectClient();

    const [customScheduler, setCustomScheduler] = useState<any | null>(null);
    const [calendlyScheduler, setCalendlyScheduler] = useState<any | null>(null);


    const fetchSchedulerData = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.getDemoSchedulersByType(project.id, "prospect", accessToken);
        if (error) {
            console.error('Error fetching demo scheduler data:', error);
        } else {
            setSchedulers(data.schedulers);
            setSelectedMeetingScheduler(selectedMeetingScheduler || data.schedulers.find((scheduler: any) => scheduler.is_default === true)?.provider || "calendly");
            setDefaultProvider(data.schedulers.find((scheduler: any) => scheduler.is_default === true)?.provider || "");
            setCustomScheduler(data.schedulers.find((scheduler: any) => scheduler.provider === "custom")); 
            setCalendlyScheduler(data.schedulers.find((scheduler: any) => scheduler.provider === "calendly"));
        }
    };

    useEffect(() => {

        fetchSchedulerData();

        return () => {
            setSchedulers(null);
        };
    }, []);

    const getStatus = () => {
        if (!schedulers) return " Configuration";
        if (!schedulers.attendees?.length || !schedulers.calendars?.length || !schedulers.calendars?.find((calendar: any) => calendar.url !== "") || !schedulers.availability_windows?.length) return "Awaiting Configuration";
        if (!schedulers.is_active) return "Pending Activation";
        return "Active";
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        fetchSchedulerData();
        setIsLoading(false);
    };

    const handleMakeDefault = async (provider: string) => {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.updateDemoScheduler(project.id, "prospect", { is_default: true, provider: provider }, accessToken);
        if (error) {
            console.error('Error updating demo scheduler:', error);
        } else {
            setDefaultProvider(provider);
        }
        setIsLoading(false);
    };

    const handleSelectMeetingScheduler = (
        _event: React.MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        if (newValue === null) return; // Ignore deselection
        setSelectedMeetingScheduler(newValue);
        handleMakeDefault(newValue);
    };

    return (
        <div>
            <CompanyTitle viewerRole={role} project={project} section="Calendar Setup" subheading="Advisors will be able to schedule a demo with you and your team. If you already use a scheduler like Calendly, you can provide that here. Otherwise we'll connect to your Google or Microsoft calendar and create one for you." />
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: "40px"
            }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Box mb={6} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "30px" }}>
                        <Box>
                            <h5>Would you like to use your own Calendly?</h5>
                            <span>If not, we'll create one for you.</span>
                        </Box>
                        <ToggleButtonGroup
                            value={selectedMeetingScheduler}
                            exclusive
                            onChange={handleSelectMeetingScheduler}
                            aria-label="meeting scheduler selection"
                            size="small"
                            sx={{ flex: "1 1 0" }}
                        >
                            <ToggleButton value="calendly" aria-label="use calendly">
                                Yes
                            </ToggleButton>
                            <ToggleButton value="custom" aria-label="use custom">
                                No
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    {selectedMeetingScheduler === "calendly" && (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
                            <CalendlySetup schedulerData={calendlyScheduler} onSubmit={handleSubmit} setup={setup} />
                        </Box>
                    )}
                    {selectedMeetingScheduler === "custom" && (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", borderBottom: "1px solid #e0e0e0", paddingBottom: "20px", marginBottom: "20px" }}>
                            <ManageCalendar schedulerData={customScheduler} projectId={project.id} onSubmit={handleSubmit} setCanProceed={setCanProceed} canProceed={canProceed} />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}