import { Box,  Typography, MenuItem, Select } from "@mui/material";
import logo from "../../../assets/images/brightloop-logo-web.svg";
import { useDealContext } from "../../deal/context";
import { House, Handshake, Repeat2, CalendarHeart, HandCoins, Cog, Send, UserPlus, Blocks, Linkedin, Link, Plus, Calendar, MessageSquare, CalendarCheck2 } from "lucide-react";
import { Sidebar, SidebarSection } from "../../../components/Sidebar";
import { useAdvisor } from "../context/AdvisorContext";
import { useUserContext } from "../../users/context";
import { IMembership } from "../../../types/database/Membership";
import { useState } from "react";

export const AdvisorSidebar = () => {
    const { dealContext } = useDealContext();
    const { userContext } = useUserContext();
    const [ selectedMembership, setSelectedMembership ] = useState<IMembership | null>(userContext.projectMemberships.find((membership: IMembership) => membership.project.id === dealContext.project.id && membership.role === "advisor" && membership.project?.experience === "advisor") ?? null);
    const project = dealContext.project;
    const role = dealContext.role;
    const shareLink = project.project_share_links[0];

    const baseUrl = `/advisor`;

    const handleMembershipChange = (membership: IMembership) => {
        window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${membership.project.tag}.${process.env.REACT_APP_APP_ORIGIN}/advisor`;
        return;
    }

    const portfolioSwitcher = (
        <Box>
            <Select
                value={selectedMembership?.id} onChange={(e) => {
                    const membership = userContext.projectMemberships.find((membership: IMembership) => membership.id === e.target.value);
                    handleMembershipChange(membership);
                }}
                size="small"
                sx={{
                    marginLeft: "40px",
                    marginRight: "30px",
                    width: "130px",
                    backgroundColor: "transparent",
                    "& p": {
                        margin: 0,
                        padding: 0
                    },
                    "&:focus-visible": {
                        backgroundColor: "transparent",
                        border: "none"
                    },

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000"
                    }
                }}

            >
                { userContext.projectMemberships.filter((membership: IMembership) => membership.role === "advisor" && membership.project?.experience === "advisor").map((membership: IMembership) => (
                    <MenuItem key={membership.id} value={membership.id}>
                        <Typography variant="body2">{membership.project.name}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );

    const sections: SidebarSection[] = [
        {
            header: "Your Portfolio",
            visible: true,
            links: [
                {
                    to: `${baseUrl}`,
                    iconComponent: <House size={16}/>,
                    component: portfolioSwitcher,
                    text: project?.name ?? "Home",
                    enabled: true,
                    end: true,
                    visible: true
                }
            ]
        },
        {
            visible: true,
            links: [
                {
                    to: baseUrl,
                    iconComponent: <House size={16}/>,
                    text: "Home",
                    enabled: true,
                    visible: true,
                    end: true
                },
                {
                    to: `${baseUrl}/feedback`,
                    iconComponent: <MessageSquare size={16}/>,
                    text: "Feedback",
                    enabled: true,
                    visible: true
                },
                {
                    to: `${baseUrl}/meet`,
                    iconComponent: <CalendarCheck2 size={16}/>,
                    text: "Meet",
                    enabled: true,
                    visible: true
                },
                {
                    to: `${baseUrl}/refer`,
                    iconComponent: <Handshake size={16}/>,
                    text: "Refer",
                    enabled: true,
                    visible: true
                },
                {
                    to: `${baseUrl}/invest`,
                    iconComponent: <HandCoins size={16}/>,
                    text: "Invest",
                    enabled: true,
                    visible: project.spv_stage !== "pending"
                }
            ]
        }
    ];

    const bottomRowContent = role === "guest" ? (
        <Box className="bottom-row">
            <Box>
                <Typography variant="body2">Powered by</Typography>
                <img className="logo-image brightloop" src={logo.toString()} />
            </Box>
        </Box>
    ) : undefined;

    return <Sidebar sections={sections} bottomRow={bottomRowContent} />;
};
