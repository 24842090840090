import React from "react";
import logo from "../assets/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import useGetUserData from "../hooks/useGetUserData";
import { verifySelf } from "../core/services/gutcheck-api.service";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";


const SplashPage = styled(Box)(({ theme }) => ({


  "& .background-image": {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "90%",
    height: "90%",
    opacity: 0.4,
    zIndex: -1,
    overflow: "hidden",
  },

  "& .splash-content": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    zIndex: 1,
    maxWidth: "500px",
    padding: "20px 40px",
    "& p": {
      fontSize: "1.5rem",
      marginBottom: "1rem"
    },
    "& .logo-image": {
      height: "60px"
    },
    "& .sign-up-controls": {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      "& p": {
        fontSize: "1.2rem",
        marginBottom: "1rem"
      },
      "& .bl-attribution": {
        fontSize: "1rem",
        marginBottom: "1rem",
        "& img": {
          height: "20px",
          marginLeft: "0.5rem"
        }
      },
      "& .sub-attribution": {
        marginTop: "1rem",
        fontSize: "1rem",
        "& a": {
          color: "#1a5dbd",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline"
          }
        }
      },
    }
  },

}));

const EmailVerification = ({ auth0User, isLoading }: any) => {

  const { logout, getAccessTokenSilently } = useAuth0();
  const [fetched, user] = useGetUserData();
  const [retriggered, setRetriggered] = React.useState(false);

  const handleSend = async () => {
    setRetriggered(true);
    const accessToken = await getAccessTokenSilently();
    const { data, error } = await verifySelf(accessToken);
    if (!data) {
      setRetriggered(false);
    }
  }

  if (isLoading || !fetched) {
    return <></>;
  }

  if (!isLoading && fetched && !user) {
    const emailAddress = auth0User['https://gutcheck.svpvc.com/email']
    logout({ logoutParams: { returnTo: `${document.location.origin}/unrecognized?email=${encodeURIComponent(emailAddress)}` } });
  }

  return (
    <SplashPage>
      <img src="/images/illustrations/crowd-arrow.svg" className="background-image" />

      <Box className="splash-content">
        <Typography variant="h4" sx={{ margin: "1rem 0" }}>You're almost there...</Typography>
        <Typography variant="h5" sx={{ margin: "1rem 0" }}>Please check your {user.email} inbox for an email from BrightLoop and click the link to verify your email address.</Typography>

        {!retriggered && <p>Not seeing it after a few minutes?<br/><span className="link" onClick={handleSend}>Click here</span> to re-send.</p>}
        {retriggered && <p>Email re-sent!</p>}
      </Box>
    </SplashPage>
  )
  return user ? (
    <div className="text-center hero my-5">
      <img className="mb-3 app-logo" src={logo as unknown as string} alt="React logo" width="120" />
      <h1 className="mb-4">Almost there...</h1>
      <h3>Verify your email to continue.</h3>
      <p>Please check your {user.email} inbox for an email from BrightLoop and click the link to verify your email address</p>
      {!retriggered && <p>Not seeing it after a few minutes? <span className="link" onClick={handleSend}>Click here</span> to re-send.</p>}
      {retriggered && <p>Email re-sent!</p>}
    </div>
  ) : '';


};

export default EmailVerification;