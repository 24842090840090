import { Box } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { CompanyTitle } from "../components/CompanyTitle";
import { NurtureResponses } from "../components/nurture/NurtureResponses"
import { ExecTabs } from "../styles/AdvisorStyles";
import { ExecTab } from "../styles/AdvisorStyles";
import { useEffect, useState } from "react";
import { NurtureAnalytics } from "../components/nurture/NurtureAnalytics";
import { useAuth0 } from "@auth0/auth0-react";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { ITouchpoint } from "../../../types";

export const CompanyTouchpoint = () => {

    const { dealContext } = useDealContext();
    const [ value, setValue ] = useState(0);
    const project = dealContext.project;
    const { touchpointUuid } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const client = new ProjectHttpClient();
    const [touchpoint, setTouchpoint] = useState<ITouchpoint | null>(null);

    const handleTabSwitch = (event: any, newValue: any) => {
        setValue(newValue);
    }

    useEffect(() => {
        const fetchTouchpointResponses = async () => {
            if (!touchpointUuid) return;
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await client.getTouchpoint(project.id, touchpointUuid, accessToken);
            setTouchpoint(data.touchpoint as ITouchpoint);
            setValue(data.touchpoint.analysis_url ? 0 : 1);
        }

        if (project.id && touchpointUuid) {
            fetchTouchpointResponses();
        }
    }, [project.id, touchpointUuid]);

    if (!touchpoint) {
        return <Loading />;
    }

    return (
        <Box>
            <CompanyTitle viewerRole={dealContext.role} project={project} section={`Nurture > ${touchpoint?.name}`} subheading="Review individual responses and summary analyties." />

            <Box>
                <ExecTabs variant="fullWidth" value={value} onChange={handleTabSwitch}>
                    <ExecTab label={<span>Summary Analytics</span>} disabled={!touchpoint.analysis_url}></ExecTab>
                    <ExecTab label={<span>Individual Responses</span>}></ExecTab>
                </ExecTabs>
            </Box>
            { value === 0 && <NurtureAnalytics project={project} touchpoint={touchpoint} /> }
            { value === 1 && <NurtureResponses project={project} touchpoint={touchpoint}/> }
        </Box>
    )
}