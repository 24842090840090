import { ICompany } from "../types";
import { CompanyLogoBase, CompanyNameBase } from "./shared/StyledComponents";

export const CompanyLogo = ({ company, size="medium", style="centered" }: {company: ICompany, size?: string, style?: string}) => {

    if (company?.logo_url) {
        return (
            <CompanyLogoBase className={`${size} ${style}`}>
                    <img src={company.logo_url} alt={company.name} />
                </CompanyLogoBase>
        );
    }
    return (
        <CompanyNameBase className={`${size} ${style}`}>
            {company?.name}
        </CompanyNameBase>
    );
}