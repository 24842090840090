import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getAllDataForSelf } from '../../../core/services/gutcheck-api.service';

export interface iFirmContext {
    firmContext: {
        organization: any;
        role: any;
        fetched: boolean;
    };
    setFirmContext: React.Dispatch<React.SetStateAction<any>>;
    loadFirmContext: () => void;
}

export const FirmContext = React.createContext<iFirmContext>({
    firmContext: {
        organization: {},
        role: "",
        fetched: false
    },
    setFirmContext: () => { },
    loadFirmContext: () => { }
});

const loadFirmContext = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { firmContext, setFirmContext } = useContext(FirmContext);

    useEffect(() => {
        const getUserContext = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getAllDataForSelf(accessToken);
                setFirmContext({
                    ...firmContext,
                    organization: data.organization,
                    role: data.role,
                    fetched: true
                })
            } catch (error) {
                console.error(error);
            }
        }
        getUserContext();
    }, []);

}

export const useFirmContext = () => {
    const context = useContext(FirmContext);
    if (!context) {
        throw new Error('useFirmContext must be used within a FirmProvider');
    }
    return context;
};

export const FirmProvider = ({ children }: { children: ReactNode }) => {
    const [firmContext, setFirmContext] = useState<any>({
        organization: {},
        role: {},
        fetched: false
    });

    return (
        <FirmContext.Provider value={{ firmContext, setFirmContext, loadFirmContext }}>
            {children}
        </FirmContext.Provider>
    );
}





