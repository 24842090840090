import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAdvisor, initEvaluation, getScheduler } from "../../../core/services/gutcheck-api.service";
import { EvaluationSidebar } from "../components/EvaluationSidebar";
import { DealStatusMessage } from "../components/DealStatusMessage";
import Intercom from "@intercom/messenger-js-sdk";
import useAnalytics from "../../../hooks/useAnalytics";
import { useDealContext } from "../../deal/context";
import { AdvisorLogin } from "../components/AdvisorLogin";
import { IAdvisor, IScheduler } from "../../../types";

export const Evaluation = ({ initialSection }: any) => {

    const { evaluationId, expertId } = useParams();
    const [searchParams] = useSearchParams();
    const { dealContext, setDealContext } = useDealContext();

    const [advisor, setAdvisor] = useState<IAdvisor | null>(null);
    const [loading, setLoading] = useState(true);
    const [section, setSection] = useState<string>(initialSection || 'evaluate');
    const { trackAdvisorUser, trackAdvisorEvent } = useAnalytics();
    const [scheduler, setScheduler] = useState<IScheduler | null>(null);
    const navigate = useNavigate();

    const investmentStages = ['approved', 'open', 'closed_canceled', 'closed_funded'];


    const loadAdvisor = async () => {

        if (evaluationId) {

            const newParam = searchParams.get('new');
            const forceNew = newParam === 'true';

            if (forceNew && !expertId) {
                localStorage.removeItem('eval' + evaluationId);
                searchParams.delete('new');
            }

            const activeExpertId = expertId ?? localStorage.getItem('eval' + evaluationId);

            if (!activeExpertId) {
                const initResponse = await initEvaluation(evaluationId, searchParams);
                if (initResponse.data) {
                    const newExpertID = initResponse.data.advisor.public_id;
                    const { data, error } = await getAdvisor(newExpertID);
                    setAdvisor(data.advisor);
                    localStorage.setItem('eval' + evaluationId, data.advisor.public_id);
                    if (!window.location.origin.startsWith(data.advisor.survey_link.project.origin)) {
                        window.location.replace(`${data.advisor.survey_link.project.origin}${window.location.pathname}${window.location.hash}${window.location.search}`);
                        return;
                    }
                    trackAdvisorEvent(data.advisor,
                        'Lifecycle - Acquired',
                        {
                            survey_link_partner: data.advisor.survey_link.partner_name,
                            survey_link_name: data.advisor.survey_link.name,
                            survey_link_id: data.advisor.survey_link.public_id,
                            survey_link: window.location.href
                        }
                    );
                    fetchScheduler(data.advisor.public_id);
                }
            }
            else {
                const { data, error } = await getAdvisor(activeExpertId);
                if (data) {
                    if (data.advisor.survey_link.public_id !== evaluationId) {
                        localStorage.removeItem('eval' + evaluationId);
                        localStorage.setItem('eval' + data.advisor.survey_link.public_id, data.advisor.public_id);
                        const linkDomain = data.advisor.survey_link.project.experience === "advisor" ? `${data.advisor.survey_link?.project.organization.tag}.${process.env.REACT_APP_APP_ORIGIN}` : `app.${process.env.REACT_APP_APP_ORIGIN}`;
                        const url = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${linkDomain}/e/${data.advisor.survey_link?.public_id}/expert/${data.advisor.public_id}`
                        window.location.href= url;
                        return;
                    }
                    setAdvisor(data.advisor);
                    localStorage.setItem('eval' + evaluationId, data.advisor.public_id);
                    fetchScheduler(data.advisor.public_id);
                }
            }

            setLoading(false);
        }
    };
    
    const fetchScheduler = async (advisorId: string) => {
        const { data, error } = await getScheduler(advisorId, "prospect");
        if (error) {
            console.error("Error fetching scheduler:", error);
        } else {
            setScheduler(data.scheduler);
        }
    };

    useEffect(() => {
        let isMounted = true;

        if (evaluationId) {
            loadAdvisor();
        }

        return () => { 
            isMounted = false; 
            setScheduler(null);
        };
    }, []);

    useEffect(() => {

        if (advisor) {
            setDealContext({
                project: advisor.survey_link.project,
                organization: advisor.survey_link.project.organization,
                surveyLink: advisor.survey_link,
                role: 'advisor',
                fetched: true
            });
            trackAdvisorUser(advisor, { partner: advisor?.survey_link?.partner_name });
            trackAdvisorEvent(advisor, 'Visited survey link');
            if (process.env.REACT_APP_INTERCOM_APP_ID) {
                Intercom({
                    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                    user_id: advisor?.user?.uuid ??advisor?.uuid,
                    name: advisor?.user?.profile?.name ?? advisor.name,
                    email: advisor?.user?.email_address ?? advisor.email_address,
                    created_at: (new Date(advisor.created_at)).valueOf(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
                });
            } else {
                console.warn('Intercom is not configured');
            }
        }

        if (advisor && !expertId) {
            if (advisor.status !== "init") {
                navigate(`/e/${advisor.survey_link.public_id}/expert/${advisor.public_id}`);
                return;
            }
        }
    }, [advisor]);


    if (loading || !advisor) return (<></>);

    if (advisor?.survey_link?.passthrough && advisor?.status === 'init') {

        if (!advisor.survey_link.is_active) {
            const paramsPrefix = /\?/.test(advisor.survey_link.quota_redirect_url) ? '&' : '?';
            const redirectUrl = `${advisor.survey_link.quota_redirect_url}${paramsPrefix}${advisor.params}`;
            trackAdvisorEvent(
                advisor,
                'Redirected to over_quota',
                { passthrough: true },
                () => { window.location.replace(redirectUrl) }
            );
            return <></>;
        }

        trackAdvisorEvent(
            advisor,
            'Redirected to survey',
            { passthrough: true },
            () => { window.location.replace(`${advisor.survey_link.survey_url}?blid=${advisor.public_id}&${advisor.params}`) }
        );
        return <></>;
    }

    const organization = advisor.survey_link.project.organization;
    const project = advisor.survey_link.project;

    const investmentUnlocked = advisor.invited_to_invest && investmentStages.includes(advisor.survey_link?.project?.spv_stage ?? "") && (Date.now() - new Date(advisor.completed_at ?? advisor.created_at).getTime() > (project.evaluation_delay_hours ?? 48) * 60 * 60 * 1000);
    const engageUnlocked = advisor.viewpoints?.some((viewpoint: any) => viewpoint.is_approved) && scheduler?.is_active ? true : false;

    if (loading || !advisor) return (<>loading</>);

    if (project?.experience === "advisor" && advisor?.user?.email_address) {
        return (
            <AdvisorLogin project={project} advisor={advisor} />
        )
    }

    return (
        <div className="evaluation-expert-container">
            <aside className="page-sidebar evaluation-sidebar sidebar-light">
                <EvaluationSidebar advisor={advisor} investmentUnlocked={investmentUnlocked} engageUnlocked={engageUnlocked} section={section} setSection={setSection} />
            </aside>
            <main className="page-main evaluation-page sidebar">
                <div className={`gc_view`}>
                    <DealStatusMessage advisor={advisor} />

                    <div className="flex-row project-overview">
                        <Outlet context={{ advisor: advisor, loading: loading, investmentUnlocked: investmentUnlocked, engageUnlocked: engageUnlocked, loadAdvisor: loadAdvisor }} />
                    </div>
                </div>
            </main>
        </div>
    );
}   