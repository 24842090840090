import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "..//modules/ClientProvider";

type OrganizationInvitationsData = {
    fetched: boolean;
    invitations: any;
    error: { message: any } | null;
}

const initState: OrganizationInvitationsData = {
    fetched: false,
    invitations: [],
    error: null
}

export const useGetOrganizationInvitations = (organizationId: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const organizationClient = ClientProvider.provideOrganizationClient();

    const [state, setState] = useState(initState);

    const reloadOrganizationInvitations = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await organizationClient.getOrganizationInvitations(organizationId, accessToken);

        if (data) {
            const fetchedOrganizationInvitations: OrganizationInvitationsData = {
                fetched: true,
                invitations: data.invitations ?? [],
                error: error
            };
            setState(fetchedOrganizationInvitations);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchOrganizationInvitations = async () => {
            if (!isMounted) return;
            reloadOrganizationInvitations();
        };

        fetchOrganizationInvitations();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadOrganizationInvitations };
};
