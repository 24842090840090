import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../users';

export const Limbo = () => {
    const navigate = useNavigate();
    const { loadUserContext } = useUserContext();

    useEffect(() => {
        loadUserContext();
    }, []);

    return (
        <main className="page-main">
            <div className="gc_view">
                <div className="splash-page">
                    <h2>This feature is not available.</h2>
                    <button className="btn btn-primary" onClick={() => navigate("/")}>Click here to continue</button>
                </div>
            </div>
        </main>
    );
};