import { useOutletContext } from "react-router-dom";
import { useAdvisors } from "../hooks/useAdvisors";
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField, Box, Stack, Tooltip, TableSortLabel } from "@mui/material";
import { AdvisorForm } from "./components/AdvisorForm";
import { Cog, FolderSync, User } from "lucide-react";
import { MoveAdvisor } from "./components/MoveAdvisor";
import { AdvisorProfileSummary } from "../../advisor";
import { AdvisorCompany } from "../../advisor/components/AdvisorCompany";
import { ProfileUpdate } from "./components/ProfileUpdate";
import { IAdvisor } from "../../../types";
import { ExecTab, ExecTabs, AdvisorTable } from "../../company/styles/AdvisorStyles";
import dayjs from "dayjs";

const AdminAdvisorTable = ({ advisors, project, reloadAdvisors, initialSortField = 'id', initialSortDirection = 'asc' }: any) => {

    const [advisor, setAdvisor] = useState<IAdvisor | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [profileMode, setProfileMode] = useState(false);
    const [moveAdvisor, setMoveAdvisor] = useState(false);
    const [sortField, setSortField] = useState<string>(initialSortField);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(initialSortDirection);

    const handleMove = (advisor: any) => {
        setAdvisor(advisor);
        setMoveAdvisor(true);
    }

    const handleEdit = (advisor: any) => {
        setAdvisor(advisor);
        setEditMode(true);
    }

    const handleProfile = (advisor: any) => {
        setAdvisor(advisor);
        setProfileMode(true);
    }

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedAdvisors = [...advisors].sort((a, b) => {
        let aValue, bValue;
        
        switch (sortField) {
            case 'email':
                aValue = a.user?.email_address ?? a.email_address ?? a.viewpoints?.[0]?.email ?? '';
                bValue = b.user?.email_address ?? b.email_address ?? b.viewpoints?.[0]?.email ?? '';
                break;
            case 'name':
                aValue = a.user?.name ?? a.name ?? '';
                bValue = b.user?.name ?? b.name ?? '';
                break;
            case 'company':
                aValue = a.company ?? a.user?.company ?? '';
                bValue = b.company ?? b.user?.company ?? '';
                break;
            case 'created_at':
                aValue = new Date(a.created_at).getTime();
                bValue = new Date(b.created_at).getTime();
                break;
            case 'completed_at':
                aValue = a.completed_at ? new Date(a.completed_at).getTime() : 0;
                bValue = b.completed_at ? new Date(b.completed_at).getTime() : 0;
                break;
            default:
                aValue = a[sortField] ?? '';
                bValue = b[sortField] ?? '';
        }
        
        if (sortDirection === 'asc') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    return (
        <>
            <AdvisorTable className="survey-responses-table" sx={{ marginBottom: "40px" }} stickyHeader>
                <TableHead sx={{ backgroundColor: "white" }}>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'id'}
                                direction={sortField === 'id' ? sortDirection : 'asc'}
                                onClick={() => handleSort('id')}
                            >
                                Id
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'created_at'}
                                direction={sortField === 'created_at' ? sortDirection : 'asc'}
                                onClick={() => handleSort('created_at')}
                            >
                                Acquired On
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'completed_at'}
                                direction={sortField === 'completed_at' ? sortDirection : 'asc'}
                                onClick={() => handleSort('completed_at')}
                            >
                                Activated On
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'email'}
                                direction={sortField === 'email' ? sortDirection : 'asc'}
                                onClick={() => handleSort('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'name'}
                                direction={sortField === 'name' ? sortDirection : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'company'}
                                direction={sortField === 'company' ? sortDirection : 'asc'}
                                onClick={() => handleSort('company')}
                            >
                                Company
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="admin-action">Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {sortedAdvisors.map((advisor: any) => {
                        const email = advisor.user?.email_address ?? advisor.email_address ?? advisor.viewpoints?.[0]?.email;
                        return (
                            <TableRow key={advisor.uuid}>
                                <TableCell>{advisor.id}</TableCell>
                                <TableCell>{dayjs(advisor.created_at).format("MMM DD, YYYY")}</TableCell>
                                <TableCell>{advisor.completed_at ? dayjs(advisor.completed_at).format("MMM DD, YYYY") : "---"}</TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell>
                                    <AdvisorProfileSummary advisor={advisor} />
                                </TableCell>
                                <TableCell><AdvisorCompany advisor={advisor} /></TableCell>
                                <TableCell className="admin-action active">
                                    <Stack direction="row" spacing={2}>
                                        <Tooltip title="Move" placement="top" arrow>
                                            <Box onClick={() => handleMove(advisor)}><FolderSync size={16} /></Box>
                                        </Tooltip>
                                        <Tooltip title="Settings" placement="top" arrow>
                                            <Box onClick={() => handleEdit(advisor)}><Cog size={16} /></Box>
                                        </Tooltip>
                                        <Tooltip title="Profile" placement="top" arrow>
                                            <Box onClick={() => handleProfile(advisor)}><User size={16} /></Box>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </AdvisorTable>
            {editMode && advisor && <AdvisorForm project={project} advisor={advisor} reloadAdvisors={reloadAdvisors} editMode={editMode} setEditMode={setEditMode} />}
            {moveAdvisor && advisor && <MoveAdvisor project={project} advisor={advisor} reloadAdvisors={reloadAdvisors} open={moveAdvisor} setOpen={setMoveAdvisor} />}
            {profileMode && advisor && advisor.user && <ProfileUpdate user={advisor?.user} onUpdate={reloadAdvisors} open={profileMode} setOpen={setProfileMode} />}
        </>
    )
}


export const AdminProjectAdvisors = () => {

    const { project } = useOutletContext<any>();
    const { fetched, advisors, error, reloadAdvisors } = useAdvisors(project.id ?? "");
    const [filteredAdvisors, setFilteredAdvisors] = useState<IAdvisor[]>([]);
    const [tab, setTab] = useState("complete");

    const [hideNoEmail, setHideNoEmail] = useState(true);
    const [searchFilter, setSearchFilter] = useState("");
    const [sortField, setSortField] = useState<string>("completed_at");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const handleToggleHideNoEmail = () => {
        setHideNoEmail(!hideNoEmail);
    };

    const handleSearchFilter = (event: any) => {
        setSearchFilter(event.target.value);
    }

    useEffect(() => {
        if (fetched) {
            setFilteredAdvisors(advisors.filter((advisor: IAdvisor) => {
                const name = advisor.user?.profile?.name ?? advisor.name ?? "";
                const email = advisor.user?.email_address ?? "";
                if (hideNoEmail && email === "") {
                    return false;
                }
                if (searchFilter === "") {
                    return true;
                }
                if (name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
                    return true;
                }
                if (email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
                    return true;
                }
                return false;
            }));
        }
    }, [fetched, advisors, hideNoEmail, searchFilter]);
    if (!fetched || !project) { return <></> }

    const handleTabChange = (event: any, value: string) => {
        setTab(value);
        setSortField(value === "init" ? "created_at" : "completed_at");
        setSortDirection("desc");
    }


    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Advisors</h5>
            </div>

            <Box sx={{ marginBottom: "20px" }}>
                <p className="bold">Filters</p>
                <Stack direction="row" spacing={2}>
                    <TextField type="text" label="Search" value={searchFilter} onChange={handleSearchFilter} size="small" sx={{ marginRight: '20px' }} />
                    <FormControlLabel control={<Checkbox checked={hideNoEmail} onChange={handleToggleHideNoEmail} />} label="Hide Anonymous" />
                </Stack>
            </Box>

            <ExecTabs value={tab} onChange={(event: any, value: string) => handleTabChange(event, value)} sx={{ "& .MuiTab-root": { width: "100px" } }}>
                <ExecTab label="Acquired" value="init"></ExecTab>
                <ExecTab label="Activated" value="complete"></ExecTab>
                <ExecTab label="Approved" value="approved"></ExecTab>
                <ExecTab label="Rejected" value="rejected"></ExecTab>
            </ExecTabs>

            {tab === "approved" && (
                <AdminAdvisorTable advisors={filteredAdvisors.filter((advisor: any) => advisor.status === "approved")} project={project} reloadAdvisors={reloadAdvisors} initialSortField={sortField} initialSortDirection={sortDirection} />
            )}

            {tab === "complete" && (
                <AdminAdvisorTable advisors={filteredAdvisors.filter((advisor: any) => advisor.status === "complete")} project={project} reloadAdvisors={reloadAdvisors} initialSortField={sortField} initialSortDirection={sortDirection} />
            )}

            {tab === "init" && (
                <AdminAdvisorTable advisors={filteredAdvisors.filter((advisor: any) => advisor.status === "init")} project={project} reloadAdvisors={reloadAdvisors} initialSortField={sortField} initialSortDirection={sortDirection} />
            )}

            {tab === "rejected" && (
                <AdminAdvisorTable advisors={filteredAdvisors.filter((advisor: any) => advisor.status === "rejected" || advisor.status === "screened" || advisor.status === "over_quota")} project={project} reloadAdvisors={reloadAdvisors} initialSortField={sortField} initialSortDirection={sortDirection} />
            )}
        </div>
    )
}
