import { List, styled } from '@mui/material';

export const CompanyLogoBase = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
        maxHeight: "30px",
        maxWidth: "100px"
    },
    "&.mini": {
        "& img": {
            marginTop: "10px",
            maxHeight: "20px",
            maxWidth: "100px",
        }
    },
    "&.large": {
        "& img": {
            maxHeight: "50px",
            maxWidth: "175px",
        }
    },
    "&.left": {
        justifyContent: "flex-start",
        padding: "",
    },
    "&.right": {
        justifyContent: "flex-end",
        padding: "",
    }
}));

export const CompanyNameBase = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    fontWeight: 700,
    "&.left": {
        justifyContent: "flex-start",
    },
    "&.right": {
        justifyContent: "flex-end",
    },
    "&.mini": {
        fontSize: "0.8rem",
        padding: "5px",
    },
    "&.large": {
        fontSize: "1.5rem",
    }
}));

export const ProfileImageBase = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: `inset 0 0 5px ${theme.palette.grey[300]}`,
  textAlign: 'center',
  '&.small': {
    width: '50px',
    height: '50px'
  },
  '&.medium': {
    width: '80px',
    height: '80px'
  },
  '&.large': {
    width: '120px',
    height: '120px'
  }
}));

export const AdvisorFeatureListBase = styled(List)(({ theme }) => ({
    padding: "0px",
    "& .MuiListItem-root": {
        padding: "0px",
        marginTop: "20px",
        fontSize: "0.85rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        "&:last-child": {
            marginBottom: "20px",
        }
    }
}));