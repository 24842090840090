import { Box, Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Button, TextField } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useBrandContext } from '../../../hooks/useBrandContext';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAdvisorByProjectAndEmail, getFirmLanding, initEvaluation } from '../../../core/services/gutcheck-api.service';
import { useSubdomain } from '../../../hooks/useSubdomain';
import { DocumentTitle } from '../../../components/DocumentTitle';

export const FirmLanding = () => {

    const { brandContext } = useBrandContext();
    const { companyTag } = useParams();

    const [ project, setProject ] = useState<any>(null);
    const [ organization, setOrganization ] = useState<any>(null);
    const [ link, setLink ] = useState<any>(null);
    const [ surveyLink, setSurveyLink ] = useState<any>(null);
    const [ email, setEmail ] = useState<any>(null);
    const [ message, setMessage ] = useState<any>(null);
    const { brand } = brandContext;
    const { subdomain, subdomainFetched } = useSubdomain();

    useEffect(() => {

        const fetchFirmLanding = async () => {
            if (brand?.tag) {
                const { data, error } = await getFirmLanding(brand.tag);
                if (data) {
                    setOrganization(data.organization);
                }
            }   
        }

        if (brand?.tag && subdomainFetched) {
            if (!subdomain || subdomain === brand?.tag) {
                fetchFirmLanding();
            } else {
                window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://${brand?.tag}.${process.env.REACT_APP_APP_ORIGIN}/${subdomain}`;
            }
        }
    }, [brand?.tag, subdomainFetched]);

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (project && email) {
            const { data, error } = await getAdvisorByProjectAndEmail(project.tag, email);
            if (data) {
                setMessage("Check your email for a link");
            } else {
                const { data, error } = await initEvaluation(surveyLink.public_id, new URLSearchParams({ from: "landing" }), email);
                if (data) {
                    localStorage.setItem("eval" + surveyLink.public_id, data.advisor.public_id);
                    window.location.href = data.advisor.link;
                }
            }
        }
    }

    if (!organization) {
        return <></>;
    }
        
    return (
        <>
            <DocumentTitle pageTitle={`${organization?.name} Advisor Network`} showAttribution={false} />
            <Box sx={{
                width: '100%',
                background: 'linear-gradient(rgba(0,0,30,0.6), rgba(0,0,0,0.3)), url("/images/network.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#ffffff',
            minHeight: '20vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }}>
            <Box sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#ffffff',
                minHeight: '20vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: "30px",
                    maxWidth: '1200px',
                    width: '100%',
                    margin: '0 auto',
                    padding: "40px 80px" }}>
                    
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flex: '1 1 500px',
                        minWidth: 0,
                    }}>
                        <Typography variant="h3" fontSize={"2.5rem"} fontWeight="bold">The {organization?.name} Advisor network builds more successful startups by pairing senior executives with top startups in their wheelhouse.</Typography>
                    </Box>
                </Box>
            </Box>

            </Box>
        </>
    );
};