import { Routes, Route } from "react-router-dom";
import { Firm } from "./Firm";
import Loading from "../../../components/Loading";
import { useSubdomain } from "../../../hooks/useSubdomain";
import { useFirmContext } from "../context";
import { useUserContext } from "../../users";
import { FirmPortfolio } from "./FirmPortfolio";
import { FirmBranding } from "./FirmBranding";
import { FirmDomains } from "./FirmDomains";
import { FirmTeam } from "./FirmTeam";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdvisorsFirm } from "./advisors/AdvisorsFirm";
import { AdvisorsFirmPortfolio } from "./advisors/AdvisorsFirmPortfolio";

export const FirmRoutes = () => {
    const { subdomain, isCustomDomain, subdomainFetched } = useSubdomain();
    const { firmContext, setFirmContext } = useFirmContext();
    const { userContext } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (subdomainFetched && isCustomDomain && subdomain && !firmContext.fetched && userContext.fetched) {
            const organizationMembership = userContext.organizationMemberships.find(
                (membership: any) => membership.organization.tag === subdomain
            );
            
            if (organizationMembership) {
                if (organizationMembership.organization.experience !== "advisor") {
                    window.location.href = `${process.env.REACT_APP_APP_PROTOCOL ?? "https"}://app.${process.env.REACT_APP_APP_ORIGIN}/o/${organizationMembership.organization.tag}`;
                    return;
                }
                setFirmContext({
                    organization: organizationMembership.organization,
                    role: userContext.dbUser.role === "admin" ? "admin" : organizationMembership.role,
                    fetched: true
                });
            } else {
                navigate('/404');
            }
        }
    }, [subdomainFetched, isCustomDomain, subdomain, firmContext.fetched, userContext.fetched]);

    if (!subdomainFetched) {
        return <Loading />;
    }

    if (!firmContext.fetched) {
        return <Loading />;
    }

    if (firmContext.role === "advisor") {
        return (
            <Routes>
                <Route path="/" element={<AdvisorsFirm />}>
                    <Route index element={<AdvisorsFirmPortfolio />}></Route>
                </Route>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<Firm />}>
                <Route index element={<FirmPortfolio />} />
                <Route path="branding" element={<FirmBranding />} />
                <Route path="domains" element={<FirmDomains />} />
                <Route path="team" element={<FirmTeam />} />
            </Route>
        </Routes>
    );
}