import { Button, TableRow, Table, TableContainer, TableHead, TableCell, TableBody, Typography, Box } from "@mui/material";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useSmartleadCampaigns } from "../hooks/useSmartleadCampaigns";
import dayjs from "dayjs";

export const AdminSmartleadCampaigns = () => {

    const { getAccessTokenSilently } = useAuth0();
    const adminClient = ClientProvider.provideAdminClient();
    const [loadingStates, setLoadingStates] = useState({
        campaigns: false,
        sequences: {} as Record<string, boolean>,
        activities: {} as Record<string, boolean>
    });

    const { campaigns, reloadCampaigns } = useSmartleadCampaigns();

    const loadCampaigns = async () => {
        setLoadingStates(prev => ({ ...prev, campaigns: true }));
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await adminClient.loadCampaigns(accessToken);
        if (!error) {
            reloadCampaigns();
        }
        setLoadingStates(prev => ({ ...prev, campaigns: false }));
    }

    const loadSequences = async (campaignUuid: string) => {
        setLoadingStates(prev => ({ 
            ...prev, 
            sequences: { ...prev.sequences, [campaignUuid]: true } 
        }));
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await adminClient.loadSequences(campaignUuid, accessToken);
        if (!error) {
            reloadCampaigns();
        }
        setLoadingStates(prev => ({ 
            ...prev, 
            sequences: { ...prev.sequences, [campaignUuid]: false } 
        }));
    }

    const loadActivities = async (campaignUuid: string) => {
        setLoadingStates(prev => ({ 
            ...prev, 
            activities: { ...prev.activities, [campaignUuid]: true } 
        }));
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await adminClient.loadActivities(campaignUuid, accessToken);
        if (!error) {
            reloadCampaigns();
        }
        setLoadingStates(prev => ({ 
            ...prev, 
            activities: { ...prev.activities, [campaignUuid]: false } 
        }));
    }

    const reloadActiveCampaigns = async () => {
        setLoadingStates(prev => ({ 
            ...prev, 
            campaigns: true 
        }));
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await adminClient.reloadActiveCampaigns(accessToken);
        if (!error) {
            reloadCampaigns();
        }
        setLoadingStates(prev => ({ 
            ...prev, 
            campaigns: false 
        }));
    }   

    const downloadActivities = () => {
        // Define CSV headers
        const headers = [
            "Company",
            "Campaign",
            "Sequence Number",
            "Lead Name",
            "Lead Email",
            "Sent At",
            "Opened At",
            "Clicked At",
            "Replied At",
            "Open Count",
            "Click Count",
            "Is Unsubscribed",
            "Is Bounced",
            "Campaign Tag",
            "Channel Tag",
            "Type Tag",
            "Tags",
            "Acquired At",
            "Activated At",
        ];
        
        // Map data to CSV rows
        const csvRows = campaigns?.filter((campaign: any) => campaign.campaign_activities?.length > 0)
            .flatMap((campaign: any) => {
                return campaign.campaign_activities?.map((activity: any) => [
                    campaign.project?.name,
                    campaign.name,
                    activity.sequence_number,
                    activity.lead_name,
                    activity.lead_email,
                    activity.sent_at,
                    activity.opened_at,
                    activity.clicked_at,
                    activity.replied_at,
                    activity.open_count,
                    activity.click_count,
                    activity.is_unsubscribed,
                    activity.is_bounced,
                    campaign.campaign_tag,
                    campaign.channel_tag,
                    campaign.type_tag,
                    campaign.tags,
                    activity.advisor?.created_at,
                    activity.advisor?.completed_at,
                ]);
            });
        // Combine headers and rows
        const csvContent = [
            headers.join(','),
            ...csvRows.map((row: any) => row.map((cell: any) => `"${cell || ''}"`).join(','))
        ].join('\n');

        
        // Create download link
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `smartlead-campaign-activities.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>Smartlead Campaigns</h3>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                    <Button variant="outlined" color="primary" onClick={downloadActivities} disabled={loadingStates.campaigns}>Download Activities</Button>
                    <Button variant="outlined" color="primary" onClick={loadCampaigns} disabled={loadingStates.campaigns}>
                        {loadingStates.campaigns ? "Loading..." : "Load Campaigns"}
                    </Button> 
                    <Button variant="contained" color="primary" onClick={reloadActiveCampaigns} disabled={loadingStates.campaigns}>
                        {loadingStates.campaigns ? "Loading..." : "Reload Active Campaigns"}
                    </Button>
                </Box>
                <Typography variant="body2" color="textSecondary">Note: "Load Campaigns" runs in the background and takes 4-5 minutes to complete</Typography>
            </Box>
        </Box>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Campaign</TableCell>
                        <TableCell># Sequences</TableCell>
                        <TableCell># Sent</TableCell>
                        <TableCell># Acquired</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campaigns.map((campaign) => (
                        <TableRow key={campaign.id}>
                            <TableCell>{campaign.project?.name}</TableCell>
                            <TableCell>{campaign.name}</TableCell>
                            <TableCell>{campaign.sequences?.length}</TableCell>
                            <TableCell>{campaign.campaign_activities?.filter((activity: any) => activity.sequence_number === 1).length}</TableCell>
                            <TableCell>{campaign.campaign_activities?.filter((activity: any) => activity.sequence_number === 1 && activity.advisor).length}</TableCell>
                            <TableCell>{campaign.status}</TableCell>
                            <TableCell>{dayjs(campaign.created_at).format('MMM D, YYYY')}</TableCell>
                            <TableCell>{dayjs(campaign.updated_at).format('MMM D, YYYY')}</TableCell>
                            <TableCell>
                                <Button 
                                    variant="text" 
                                    color="primary" 
                                    onClick={() => loadSequences(campaign.uuid)}
                                    disabled={loadingStates.sequences[campaign.uuid]}
                                >
                                    {loadingStates.sequences[campaign.uuid] ? "Loading..." : "Load Sequences"}
                                </Button>
                                <Button 
                                    variant="text" 
                                    color="primary" 
                                    onClick={() => loadActivities(campaign.uuid)}
                                    disabled={loadingStates.activities[campaign.uuid]}
                                >
                                    {loadingStates.activities[campaign.uuid] ? "Loading..." : "Load Activities"}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>;
}