import { useAuth0 } from "@auth0/auth0-react";
import { getAdvisors } from "../services/gutcheck-admin-api.service";
import { useEffect, useState } from "react";
import { IAdvisor } from "../../../types";

type AdvisorsData = {
    fetched: boolean;
    advisors: IAdvisor[];
    error: {message: any} | null;
}

const initState: AdvisorsData = {
    fetched: false,
    advisors: [],
    error: null
}

export const useAdvisors = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadAdvisors = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAdvisors(projectId, accessToken);

      if (data) {        
        const fetchedAdvisors: AdvisorsData = {
          fetched: true,
          advisors: data.advisors ?? [],
          error: error
        };
        
        setState(fetchedAdvisors);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchAdvisors = async () => {
          if (!isMounted) return;    
          reloadAdvisors();
        };
    
        fetchAdvisors(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadAdvisors};
}