import { useDealContext } from "../../../deal/context";
import { useAdvisor } from "../../context/AdvisorContext";
import { IProject, ITouchpoint, IViewpoint } from "../../../../types";
import { Box, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import ClientProvider from "../../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../../../components/Loading";

const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]


const MediaAnswer = ({ mediaResponse }: { mediaResponse: any }) => {
    const [showFullSummary, setShowFullSummary] = useState(false);

    if (mediaResponse.display_as === "text") {
        return (
            <Typography sx={{ 
                marginTop: "0.25rem",
                fontSize: "1rem"
            }}> 
                {mediaResponse.text}
            </Typography>
        )
    } else {
        return (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "top", justifyContent: "center", flexWrap: "wrap", gap: "40px" }}>
            <Box sx={{ maxHeight: "225px", maxWidth: "400px", marginBottom: "20px", flex: "0 1 400px", marginLeft: "20px" }}>
                {mediaResponse.display_as === "video" ? (
                <video controls preload="none" poster={mediaResponse.thumbnail_url} style={{ width: "400px", height: "auto", maxHeight: "225px" }}>
                        <source src={mediaResponse.video_url} type="video/mp4" />
                    </video>
                ) : (
                    <audio controls>
                        <source src={mediaResponse.audio_url} type="audio/mp3" />
                    </audio>
                )}
            </Box>
            <Box sx={{ width: "640px", height: "auto", flex: "1 1" }}>
                <Typography variant="body1" sx={{ fontWeight: "700", fontSize: "1.2rem", color: "#000000", marginBottom: "10px" }}>Key Points:</Typography>
                <div dangerouslySetInnerHTML={{ __html: mediaResponse.summary_html }} />
                <span className="link" onClick={() => {setShowFullSummary(!showFullSummary)}}>Show/hide full transcript</span>
                {showFullSummary && <blockquote>
                    {mediaResponse.text}
                    </blockquote>}
                </Box>
            </Box>
        )
    }
}
export const TouchpointResponse = ({ project, touchpoint }: { project: IProject, touchpoint: ITouchpoint }) => {
    const { dealContext } = useDealContext();
    const { advisor } = useAdvisor();
    const [expandedDescription, setExpandedDescription] = useState(false);
    const [ currentSectionName, setCurrentSectionName ] = useState("");
    const advisorClient = ClientProvider.provideAdvisorClient();
    const { getAccessTokenSilently } = useAuth0();

    const [ viewpoints, setViewpoints ] = useState<IViewpoint[]>([]);

    useEffect(() => {
        const fetchViewpoints = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await advisorClient.getViewpointsForSelf(project.tag, accessToken);
            setViewpoints(data.viewpoints);
        }
        fetchViewpoints();
    }, [project.tag]);

    if (!viewpoints) {
        return <Loading />;
    }

    const viewpoint = viewpoints.find((viewpoint: IViewpoint) => viewpoint.touchpoint?.uuid === touchpoint.uuid);
    const responses = viewpoint?.response_json;
    const mediaResponses = viewpoint?.media_responses?.filter((response: any) => response.display_as === "video" || response.display_as === "audio" || response.display_as === "text" || response.display_as === "hide");
    const highlights = mediaResponses?.filter((response: any) => response.display_as !== "text" && response.display_as !== "hide");

    if (!viewpoint || !responses) {
        return null;
    }

    return (
        <Box sx={{ backgroundColor: "#f8f9fa", padding: "30px" }}>
            {responses.sections.map((section: any, sectionIndex: number) => (
                <Box 
                    key={sectionIndex + (highlights?.length ? 1 : 0)}
                    sx={{ marginBottom: "30px" }}
                >
                    <Box className="display-box white">
                        
                        <Typography sx={{ fontWeight: 900, fontSize: "1.4rem", borderBottom: "1px solid #c9c9c9",paddingBottom: "20px", marginBottom: "20px"}}>
                            {section.name}
                        </Typography>
                        {section.name === viewpoint?.touchpoint?.product_description_section && viewpoint?.touchpoint?.product_description && (
                            <Box sx={{ marginBottom: "40px" }}>
                                <Typography sx={{ 
                                    fontWeight: "900",
                                    fontSize: "1.2rem",
                                    color: "#000000"
                                }}>
                                    Please read the following product description
                                </Typography>
                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0", position: "relative" }}>
                                    <Typography sx={{
                                        marginTop: "0.25rem",
                                        fontSize: "1rem",
                                        maxHeight: expandedDescription ? "none" : "6em",
                                        overflow: "hidden",
                                        position: "relative",
                                    }}> 
                                        <div dangerouslySetInnerHTML={{ __html: viewpoint.touchpoint.product_description }} />
                                    </Typography>
                                    {!expandedDescription && (
                                        <Box sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: "100px",
                                            background: "linear-gradient(transparent, white)",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            paddingBottom: "10px"
                                        }}>
                                            <Button 
                                                onClick={() => setExpandedDescription(true)}
                                                variant="outlined"
                                                sx={{ 
                                                    backgroundColor: "white",
                                                    "&:hover": { backgroundColor: "#f5f5f5" }
                                                }}
                                            >
                                                Expand
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {section.questions.map((item: any, questionIndex: number) => { 
                            const mediaMatch = item.answers[0]?.match(/^videoUUID:(.*)$/) || item.answers[0]?.match(/^audioUUID:(.*)$/) || item.answers[0]?.match(/^textUUID:(.*)$/);
                            const mediaResponse = mediaMatch ? mediaResponses?.find((response: any) => response.uuid === mediaMatch[1] && (response.display_as === "video" || response.display_as === "audio" || response.display_as === "text" || response.display_as === "hide")) : null;
                            if (mediaResponse?.display_as === "hide") return null;
                            if (!viewpoint?.touchpoint?.suppressed_questions?.includes(item.question) && (item.answers.length > 0 && item.answers[0] !== "")) {
                                return (
                            <Box key={questionIndex} sx={{ marginBottom: "40px" }}>
                                <Typography sx={{ 
                                    fontWeight: "700",
                                    fontSize: "1.1rem",
                                    color: "#000000"
                                }}>
                                    {item.question}
                                </Typography>
                                <Box sx={{ borderLeft: "2px solid #ccc", padding: "15px 20px", margin: "15px 0" }}>
                                { mediaResponse ? 
                                    <MediaAnswer mediaResponse={mediaResponse} /> :
                                <Typography sx={{ 
                                    marginTop: "0.25rem",
                                    fontSize: "1rem"
                                }}> 
                                    {Array.isArray(item.answers) 
                                        ? item.answers.join(", ") 
                                            : item.answers}
                                    </Typography>
                                }
                                </Box>
                            </Box>
                            )
                            }
                        })}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}