import NavBar from "./NavBar";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

type PageProps = {
    children: ReactNode
}


export const BasicLayout = ({ children }: PageProps) => {

    const location = useLocation();
    const hideNavBar = location.pathname.includes('/e/exit');

    return (
        <ThemeProvider theme={theme}>
            <div id="app" className="d-flex flex-column h-100">
                {!hideNavBar && <NavBar hideUser={true} />}
                <div className="page-wrapper">
                    {children}
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    );
};
