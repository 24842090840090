import { border, styled, width } from "@mui/system";
import CollaborativeEditor from "../../../../components/CollaborativeEditor";
import TextEditor from "../../../../components/TextEditor"
import { Box } from "@mui/material";
import { useState } from "react";

const EditorBox = styled(Box)({
    aspectRation: '25/9',
    width: '100%',
    "& .ProseMirror": {
        aspectRatio: '25/9',
        width: '100%',
        border: '1px solid #c9c9c9',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        padding: '1rem',
    }

});

export const SetupDescription = ({ project, user }: any) => {

    const [projectDescription, setProjectDescription] = useState<any>(project.description || {});

    return (
        <Box>
            <p>Write a stellar description of the product for buyers to read. A strong description will generate higher demand and more pipeline. See the example on the right and tell the buyer exactly how they will use the product and what it will deliver to them.</p>
            <EditorBox>
                <CollaborativeEditor documentName={`${project.organization.tag}/${project.tag}/product_description`} form={projectDescription} setForm={setProjectDescription}  />
            </EditorBox>
        </Box>
    )
}