import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, styled, TableProps, Dialog, Typography, Tooltip, Button } from "@mui/material";
import { DealExecutive } from "./DealExecutive";
import { DealCompany } from "./DealCompany";
import { useEffect, useReducer, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { sendFeatureRequest } from "../../../core/services/gutcheck-api.service";
import { useDealContext } from "../context";
import { ProspectDialog } from "./prospects/ProspectDialog";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import ClientProvider from "../../../modules/ClientProvider";

const ProspectTable = styled(Table)<TableProps>(({ theme }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 0.5rem",
    //marginBottom: "60px",
    "& th": {
        fontWeight: 700,
        fontSize: "1rem",
        borderBottom: "1px solid #000000",
        verticalAlign: "bottom",
        "&.td-sm": {
            width: "10%"
        },
        "&.td-md": {
            width: "20%"
        },
        "&.td-lg": {
            width: "30%"
        },
        "&.sortable": {
            cursor: "pointer"
        }
    },
    "& td": {
        fontSize: "1rem",
        "&.action, & .action": {
            fontSize: "1.5rem",
            cursor: "pointer",
        }
    },
    "& .td-center": {
        textAlign: "center",
    }
}));

const ProspectHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    "& span": {
        fontSize: "1rem",
        marginLeft: "1rem",
        color: "#000000",
        backgroundColor: "#c9c9c9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        width: "35px",
        height: "35px",
        borderRadius: "50%"
    }
}));

const ProspectStaging = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

type TViewpointsState = {
    viewpoints: any[];
    filteredViewpoints: any[];
};

const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High"
    },
    {
        value: "Yes, probably",
        label: "High"
    },
    {
        value: "Maybe",
        label: "Medium"
    },
    {
        value: "No, probably not",
        label: "Low"
    },
    {
        value: "No, definitely not",
        label: "Very Low"
    }
]

const roleOptions = [
    {
        value: "Decide",
        label: "DECIDE"
    },
    {
        value: "Recommend",
        label: "RECOMMEND"
    },
    {
        value: "Evaluate",
        label: "EVALUATE"
    },
    {
        value: "Finance",
        label: "FINANCE"
    },
    {
        value: "Implement",
        label: "IMPLEMENT"
    },
    {
        value: "None",
        label: "NONE"
    }
]


const viewpointsReducer = (state: TViewpointsState, action: any) => {
    switch (action.type) {
        case 'UPDATE_VIEWPOINT':
            const { id, updates } = action.payload;
            const updatedViewpoints = state.viewpoints.map((viewpoint: any) =>
                viewpoint.id === id ? { ...viewpoint, ...updates } : viewpoint
            );
            return {
                ...state,
                viewpoints: updatedViewpoints
            };
        case 'SORT':
            const { sortCriteria } = action.payload;

            const sortFunction = (a: any, b: any) => {
                // First, check if either viewpoint is hidden
                if (a.prospect_hidden !== b.prospect_hidden) {
                    return a.prospect_hidden ? 1 : -1;
                }

                // If both are hidden or both are not hidden, sort by the criteria
                let aVal;
                let bVal;

                switch (sortCriteria.field) {
                    case 'demand':
                        aVal = demandOptions.findIndex((option) => option.value === a[sortCriteria.field]);
                        bVal = demandOptions.findIndex((option) => option.value === b[sortCriteria.field]);
                        break;
                    case 'role':
                        aVal = roleOptions.findIndex((option) => option.value === a[sortCriteria.field]);
                        bVal = roleOptions.findIndex((option) => option.value === b[sortCriteria.field]);
                        break;
                    case 'company':
                        aVal = !a[sortCriteria.field] || /^[^a-z]/i.test(a[sortCriteria.field]) ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                        bVal = !b[sortCriteria.field] || /^[^a-z]/i.test(b[sortCriteria.field]) ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                        break;
                    default:
                        aVal = a[sortCriteria.field];
                        bVal = b[sortCriteria.field];
                }

                if (sortCriteria.direction === 'asc') {
                    return aVal > bVal ? 1 : -1;
                } else {
                    return aVal < bVal ? 1 : -1;
                }
            };

            const filteredViewpoints = state.viewpoints;
            return {
                ...state,
                filteredViewpoints: filteredViewpoints.sort(sortFunction)
            };
            break;
        default:
            return state;
    }
}


export const DealProspectsListList = ({ project, viewpoints, user, prospectStage, openViewpointId }: any) => {

    const { getAccessTokenSilently } = useAuth0();
    dayjs.extend(relativeTime);

    const [showHidden, setShowHidden] = useState(false);

    const [prospectsList, setProspectsList] = useState<any[]>(viewpoints?.filter((viewpoint: any) => {
        const stageFilter = (
            (prospectStage === "Engaged" && viewpoint.advisor?.prospect_scheduled) ||
            (prospectStage === "Requested Trial" && viewpoint.interest_beta && !viewpoint.advisor?.prospect_scheduled) ||
            (prospectStage === "Requested Demo" && viewpoint.interest_demo && !viewpoint.interest_beta && !viewpoint.advisor?.prospect_scheduled)
        );
        return stageFilter;
    }));

    const [state, dispatch] = useReducer(viewpointsReducer, { viewpoints: prospectsList, filteredViewpoints: prospectsList });
    const [sortCriteria, setSortCriteria] = useState<{ field: string, direction: string }>({ field: 'demand', direction: 'asc' });
    const [introDialogOpen, setIntroDialogOpen] = useState(false);
    const [guestDialogOpen, setGuestDialogOpen] = useState(false);
    const [activeViewpoint, setActiveViewpoint] = useState<any>(null);
    const projectClient = ClientProvider.provideProjectClient();
    const { dealContext } = useDealContext();

    const [requested, setRequested] = useState(false);

    const handleSortChange = (event: any) => {
        const { name, value } = event.target;
        setSortCriteria({ field: name, direction: value });
    }

    useEffect(() => {
        dispatch({
            type: 'SORT',
            payload: {
                sortCriteria
            }
        });
    }, [sortCriteria]);

    const updateViewpointInProspectsList = (viewpoint: any) => {
        const index = prospectsList.findIndex((angel: any) => angel.id === viewpoint.id);
        if (index > -1) {
            const newProspectsList = [...prospectsList];
            newProspectsList[index] = viewpoint;
            setProspectsList(newProspectsList);
        }
    }

    const handleFeatureRequest = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await sendFeatureRequest(project.tag, "PROSPECTS", accessToken);
        if (data) {
            setRequested(true);
        }
    }

    const handleFeatureClick = (viewpoint: any) => {
        if (dealContext.role === "guest") {
            setGuestDialogOpen(true);
        } else {
            setActiveViewpoint(viewpoint);
            setIntroDialogOpen(true);
        }
    }

    const handleHideProspect = async (id: number) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.hideProspect(project.id, id, accessToken);
        if (data) {
            dispatch({
                type: 'UPDATE_VIEWPOINT',
                payload: {
                    id: id,
                    updates: { prospect_hidden: true }
                }
            });
            dispatch({
                type: 'SORT',
                payload: { sortCriteria }
            });
        }
    };

    const handleUnhideProspect = async (id: number) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.unhideProspect(project.id, id, accessToken);
        if (data) {
            dispatch({
                type: 'UPDATE_VIEWPOINT',
                payload: {
                    id: id,
                    updates: { prospect_hidden: false }
                }
            });
            dispatch({
                type: 'SORT',
                payload: { sortCriteria }
            });
        }
    };

    const visibleProspects = showHidden
        ? state.filteredViewpoints
        : state.filteredViewpoints.filter((viewpoint: any) => !viewpoint.prospect_hidden);

    const hiddenCount = state.filteredViewpoints.filter((viewpoint: any) => viewpoint.prospect_hidden).length;

    if (!dealContext.fetched) {
        return <div>Loading...</div>;
    }

    if (!prospectsList?.length) {
        return (
            <Box>
                <ProspectHeading>{prospectStage} <span>0</span></ProspectHeading>
                <ProspectStaging>
                    <p>Request introductions to the companies you want to engage</p>
                </ProspectStaging>
            </Box>
        );
    }

    return (
        <Box sx={{ marginBottom: "60px" }}>
            <ProspectHeading>{prospectStage} <span>{prospectsList.length}</span></ProspectHeading>
            <ProspectTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'company', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Company</TableCell>
                        <TableCell className="td-med">Contact</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'role', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Role</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'demand', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Purchasing Intent</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'max_spend', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Max Annual Spend</TableCell>
                        <TableCell sx={{ width: "20px" }}></TableCell>
                        <TableCell className="td-center" sx={{ width: "140px" }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleProspects.map((viewpoint: any, index: number) => (
                        <>
                            {showHidden && index === visibleProspects.findIndex((v: any) => v.prospect_hidden) && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center" >
                                        <Button onClick={() => setShowHidden(false)}>
                                            Hide {hiddenCount} row{hiddenCount !== 1 ? 's' : ''}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow key={viewpoint.id}>
                                <TableCell>
                                    <DealCompany viewpoint={viewpoint} />
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>

                                    <DealExecutive project={project} viewpoint={viewpoint} style="prospect-list" user={user} initialOpen={openViewpointId === viewpoint.uuid} />
                                </TableCell>
                                <TableCell className="td-center">{viewpoint.role}</TableCell>
                                <TableCell className="td-center">{demandOptions.find((option) => option.value === viewpoint?.demand)?.label}</TableCell>
                                <TableCell className="td-center">${viewpoint.max_spend?.toLocaleString()}</TableCell>
                                <TableCell sx={{ width: "20px" }}>
                                    {viewpoint.is_stale && (
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "1.2rem",
                                            color: "#ffa500"
                                        }}>
                                            <Tooltip placement="top" title={`Either ${viewpoint.name.split(" ")[0]}'s viewpoint or their LinkedIn profile suggest they are no longer at ${viewpoint.company?.name}`}>
                                                <FontAwesomeIcon icon="circle-exclamation" />
                                            </Tooltip>
                                        </Box>
                                    )}
                                </TableCell>
                                <TableCell className="td-center" sx={{ width: "140px" }}>
                                    <Box sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                        {viewpoint.advisor?.prospect_scheduled ?
                                            <Box>
                                                <FontAwesomeIcon icon="check" />
                                            </Box>
                                            :
                                            viewpoint.advisor?.last_prospect_message_at ?
                                                <Box onClick={() => handleFeatureClick(viewpoint)} className="action" sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                                    <FontAwesomeIcon icon="calendar" />
                                                    <div><Typography variant="body2">Last Sent:<br />{dayjs(viewpoint.advisor.last_prospect_message_at).fromNow()}</Typography></div>
                                                </Box>
                                                :
                                                <Box>
                                                    <Typography variant="body2">
                                                        <span className="link" onClick={() => handleFeatureClick(viewpoint)}>Schedule</span>&nbsp;&nbsp;|&nbsp;&nbsp;{viewpoint.prospect_hidden ? <span className="link" onClick={() => handleUnhideProspect(viewpoint.id)}>Unhide</span> : <span className="link" onClick={() => handleHideProspect(viewpoint.id)}>Hide</span>}
                                                    </Typography>
                                                </Box>
                                        }
                                    </Box>
                                </TableCell>

                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </ProspectTable>
            {!showHidden && hiddenCount > 0 && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                    <Button onClick={() => setShowHidden(true)}>
                        +{hiddenCount} hidden
                    </Button>
                </Box>
            )}

            {project.prospecting_enabled && (
                <ProspectDialog project={project} viewpoint={activeViewpoint} user={user} open={introDialogOpen} onClose={() => setIntroDialogOpen(false)} updateViewpoint={updateViewpointInProspectsList} />
            )}

            {!project.prospecting_enabled && (
                <Dialog open={introDialogOpen} onClose={() => setIntroDialogOpen(false)}>
                    <Box>
                        <h5>Calendering Demos and Trials</h5>
                        <p>Schedule sessions with executives interested in your product.</p>
                        {requested ? <><button className="requested disabled"><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Learn More</button> <span>&nbsp;&nbsp;Thanks. We'll be in touch shortly</span></> : <button onClick={handleFeatureRequest}>Learn More</button>}
                    </Box>
                </Dialog>
            )}

            <Dialog open={guestDialogOpen} onClose={() => setGuestDialogOpen(false)}>
                <Box>
                    <h5>Calendering Demos and Trials</h5>
                    <p>This feature is not available to guests.</p>
                </Box>
            </Dialog>
        </Box>

    )

}