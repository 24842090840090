import { Box, Typography } from "@mui/material";
import { AdvisorProfileImage } from "./AdvisorProfileImage";
import { AdvisorCompany } from "./AdvisorCompany";
import { IAdvisor } from "../../../types";

export const AdvisorProfileSummary = ({ advisor, showCompany = false }: {advisor: IAdvisor, showCompany?: boolean}) => {

    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", cursor: "pointer", gap: "1rem" }}>
            <AdvisorProfileImage advisor={advisor} size="small" />
            <Box>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{advisor.user?.profile?.name}</Typography>
                <Typography sx={{
                    fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{advisor.user?.profile?.title}</Typography>
                {showCompany && <AdvisorCompany advisor={advisor} size="mini"/>}
            </Box>
        </Box>
    )

}