import { callExternalApi } from "../../services/external-api.service";


class AdminHttpClient {
    private _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = `${apiServerUrl}/v2`;
    }

    async loadCampaigns(accessToken: string) {
        const config = {
            url: `${this._apiServerUrl}/admin/smartlead/load-campaigns`,
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        return {
            data: data.data || null,
            error,
        };
    }

    async fetchSmartleadCampaigns(accessToken: string) {
        const config = {
            url: `${this._apiServerUrl}/admin/smartlead/campaigns`,
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        return {
            data: data.data || null,
            error,
        };
    }

    async loadSequences(campaignUuid: string, accessToken: string) {
        const config = {
            url: `${this._apiServerUrl}/admin/smartlead/campaigns/${campaignUuid}/load-sequences`,
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        return {
            data: data.data || null,
            error,
        };
    }

    async loadActivities(campaignUuid: string, accessToken: string) {
        const config = {
            url: `${this._apiServerUrl}/admin/smartlead/campaigns/${campaignUuid}/load-activities`,
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        return {
            data: data.data || null,
            error,
        };
    }

    async reloadActiveCampaigns(accessToken: string) {
        const config = {
            url: `${this._apiServerUrl}/admin/smartlead/reload-active-campaigns`,
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        return {
            data: data.data || null,
            error,
        };
    }
}

export default AdminHttpClient;
