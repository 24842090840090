import { Routes, Route } from "react-router-dom";
import { Landing } from "./Landing";
import { CompanyLanding } from "./CompanyLanding";
import { FirmLanding } from "./FirmLanding";
import { CompanyExit } from "./CompanyExit";
import { LandingLogin } from "./LandingLogin";
import { CompanyStart } from "./CompanyStart";

export const LandingRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<Landing />}>
                <Route index element={<FirmLanding />} />
                <Route path=":companyTag" element={<CompanyLanding />} />
                <Route path=":companyTag/:advisorId/start" element={<CompanyStart />} />
                <Route path=":companyTag/:advisorId" element={<CompanyExit />} />
                <Route path="/login" element={<LandingLogin />} />
            </Route>
        </Routes>
    );
}