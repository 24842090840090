import { useState } from "react";
import { Modal, Box, Typography, FormControl, TextField, Tabs, Tab } from "@mui/material";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { createInvitations } from "../../../core/services/gutcheck-api.service";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAnalytics from "../../../hooks/useAnalytics";

type TProject = {
    project: any
}

type TProjectShareComponent = TProject & {
    role: any
}

type TProjectShareSuccess = TProjectShareComponent & {
    invitationForm: any
}

type TProjectShare = TProjectShareComponent & {
    handleInvitationChange: any,
    handleInvitationSubmit: any,
    handleSwitchTab: any,
    invitationForm: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

const ProjectShareForm = ({ project, role, handleInvitationChange, handleInvitationSubmit, invitationForm, handleSwitchTab }: TProjectShare) => {

    return (
        <Box sx={style}>
            <Tabs className="modal-modal-tabs" value={invitationForm.inviteeRole} variant="fullWidth" onChange={handleSwitchTab}>
                {role !== "guest" &&
                    <Tab label="Invite Team Members" value="contributor" />
                }
                <Tab label="Invite External Guests" value="guest" />
            </Tabs>
            {invitationForm.inviteeRole === "contributor" && (
                <div>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        <b>Invite Team Members</b>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Invite your <strong>{project.organization.name}</strong> team members to view results and collaborate on this and other deals.
                    </Typography>
                    <Typography id="modal-modal-warning" className="not-safe" sx={{ mt: 2 }}>
                        <FontAwesomeIcon icon="triangle-exclamation" />&nbsp;&nbsp;&nbsp;Team members have <strong>full access</strong> to <b>all</b> deals in your workspace.
                    </Typography>
                </div>
            )}
            {invitationForm.inviteeRole === "guest" && (
                <div>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        <b>Invite External Guests</b>
                    </Typography>
                    {role === "guest" &&
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Share the viewpoints collected with another guest. They'll be able to review each viewpoint and see who is interested in demos, betas, advising and investing.
                            </Typography>
                            <Typography id="modal-modal-warning" className="safe" sx={{ mt: 2 }}>
                                Your request will be forwarded to {project.organization.name} for approval.
                            </Typography>
                        </>
                    }
                    {role !== "guest" &&
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Share the viewpoints collected with an external guest. They'll be able to review each viewpoint and see who is interested in demos, betas, advising and investing.
                            </Typography>
                            <Typography id="modal-modal-warning" className="safe" sx={{ mt: 2 }}>
                                Guests have <strong>limited access</strong> and can only see the Viewpoints.
                            </Typography>
                        </>
                    }
                </div>
            )}
            <FormControl fullWidth>
                <TextField
                    multiline
                    type="text"
                    id="invitee_emails"
                    minRows={5}
                    maxRows={5}
                    placeholder="Email addresses (seperated by space, comma, or new line)"
                    className="invitation-email-list"
                    onChange={handleInvitationChange}
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleInvitationSubmit}>Invite {invitationForm.inviteeRole === "contributor" ? "Team Members" : "External Guests"}</Button>

            </FormControl>
        </Box>
    )
}

const ProjectShareSuccess = ({ project, role, invitationForm }: TProjectShareSuccess) => {

    const numInvites = invitationForm.processedInvitations?.length;
    return (
        <Box sx={style}>
            <Tabs className="modal-modal-tabs" value={invitationForm.inviteeRole} variant="fullWidth">
                {role !== "guest" &&
                    <Tab label="Invite Team Members" value="contributor" />
                }
                <Tab label="Invite External Guests" value="guest" />
            </Tabs>
            {invitationForm.inviteeRole === "contributor" && (
                <div>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        <b>Invite Team Members</b>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Invite your <strong>{project.organization.name}</strong> team members to view results and collaborate on this and other deals.
                    </Typography>
                    <Typography id="modal-modal-warning" className="not-safe" sx={{ mt: 2 }}>
                        <FontAwesomeIcon icon="triangle-exclamation" />&nbsp;&nbsp;&nbsp;Team members have <strong>full access</strong> to <b>all</b> deals in your workspace.
                    </Typography>
                </div>
            )}
            {invitationForm.inviteeRole === "guest" && (
                <div>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        <b>Invite External Guests</b>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Share the viewpoints you collected with an external guest. They'll be able to review each viewpoint and see who is interested in demos, betas, advising and investing.
                    </Typography>
                    <Typography id="modal-modal-warning" className="safe" sx={{ mt: 2 }}>
                        Guests have <strong>limited access</strong> and can only see the Viewpoints.
                    </Typography>
                </div>
            )}
            <Typography id="modal-modal-success" sx={{ mt: 2 }}>
                Success! You sent {numInvites > 1 ? `${numInvites} invitations` : `${numInvites} invitation`} to:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ul>
                    {invitationForm.processedInvitations.map((invitee: any) => {
                        if (invitee !== "") {
                            return <li key={invitee}>{invitee}</li>
                        }
                    })}
                </ul>
            </Typography>
        </Box>
    )
}

const ProjectWelcome = ({ project }: TProject) => {

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                Welcome to the {project.name} evaluation!
            </Typography>
        </Box>
    )
}

export const ProjectShareControl = ({ project, role }: TProjectShareComponent) => {

    const { state } = useLocation();
    const { trackEvent } = useAnalytics();

    const [invitationForm, setInvitiationForm] = useState({
        inviteeEmails: "",
        submitted: false,
        showWelcome: state?.invitationAccepted ?? false,
        processedInvitations: [],
        inviteeRole: role === "contributor" || role === "admin" ? "contributor" : "guest"
    });

    const [open, setOpen] = useState(state?.invitationAccepted ?? false);

    const { getAccessTokenSilently } = useAuth0();

    const handleOpen = () => {
        setInvitiationForm({
            ...invitationForm,
            inviteeEmails: "",
            submitted: false
        });
        setOpen(true);
    }

    const handleClose = () => {
        setInvitiationForm({
            ...invitationForm,
            showWelcome: false
        });
        setOpen(false);
    }

    const handleSwitchTab = (event: any, newValue: any) => {
        setInvitiationForm({
            ...invitationForm,
            inviteeRole: newValue
        });
    }

    const handleInvitationSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createInvitations(invitationForm.inviteeEmails, project.organization.id, project.id, invitationForm.inviteeRole, accessToken);

        if (!error) {
            setInvitiationForm({
                ...invitationForm,
                inviteeEmails: "",
                submitted: true,
                processedInvitations: data.invitees.map((invitee: any) => invitee.invitee_email)
            });

            data.invitees.map((invitee: any) => {
                trackEvent(
                    "Sent project invitation",
                    {
                        organization: project.organization.tag,
                        project: project.tag,
                        inviteeEmail: invitee.invitee_email,
                        inviteeRole: invitee.invitee_role,
                        approvalStatus: invitee.approval_status,
                        invitationLink: `${window.location.origin}/invitation/${invitee.invitation_code}`
                    });
            });
        }
    }

    const handleInvitationChange = (event: any) => {
        setInvitiationForm({
            ...invitationForm,
            inviteeEmails: event.target.value,
            submitted: false
        });
    };

    // Remove the share button for guests
    //if (role === "guest") {
    //    return null;
    //}

    return (
        <div>
            <Button
                onClick={handleOpen}
                color="primary"
                variant="contained"
            ><FontAwesomeIcon icon="user-plus" />&nbsp;&nbsp;&nbsp;Share</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="invitation-container"
            >
                <div>
                    {invitationForm.submitted ?
                        <ProjectShareSuccess project={project} role={role} invitationForm={invitationForm} />
                        : invitationForm.showWelcome ?
                            <ProjectWelcome project={project} />
                            :
                            <ProjectShareForm project={project} role={role} handleInvitationChange={handleInvitationChange} handleInvitationSubmit={handleInvitationSubmit} handleSwitchTab={handleSwitchTab} invitationForm={invitationForm} />
                    }
                </div>
            </Modal>
        </div>
    )
}