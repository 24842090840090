import { Box } from "@mui/material";
import { useDealContext } from "../../deal/context";
import { CompanyTitle } from "../components/CompanyTitle";
import { NurtureList } from "../components/nurture/NurtureList";
import { NurtureGraph } from "../components/nurture/NurtureGraph";
import ProjectHttpClient from "../../../core/infrastructure/clients/ProjectHttpClient";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ITouchpoint } from "../../../types";
export const CompanyNurture = () => {

    const { dealContext } = useDealContext();
    const project = dealContext.project;
    const viewerRole = dealContext.role;

    const client = new ProjectHttpClient();
    const { getAccessTokenSilently } = useAuth0();
    const [touchpoints, setTouchpoints] = useState([]);

    useEffect(() => {
        const fetchTouchpoints = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await client.getProjectTouchpoints(project.id, accessToken);
            setTouchpoints(data.touchpoints);
        }

        if (project) {
            fetchTouchpoints();
        }
    }, [project]);

    const scheduledTouchpoints = [
        {
            id: 10000000000000,
            uuid: "1",
            project: project,
            created_at: new Date("2025-04-01").toISOString(),
            name: "Market Pulse - April 2025",
            status: "scheduled",
            started_at: new Date("2025-04-01").toISOString(),
            type: "auto",
            viewpoints: [],
            created_by: null,
            updated_at: new Date("2025-04-01").toISOString(),
            completed_at: null
        },
        {
            id: 10000000000001,
            uuid: "2",
            project: project,
            created_at: new Date("2025-05-01").toISOString(),
            name: "Market Pulse - May 2025",
            status: "scheduled",
            started_at: new Date("2025-05-01").toISOString(),
            type: "auto",
            viewpoints: [],
            created_by: null,
            updated_at: new Date("2025-05-01").toISOString(),
            completed_at: null
        },
        {
            id: 10000000000002,
            uuid: "3",
            project: project,
            created_at: new Date("2025-06-01").toISOString(),
            name: "Market Pulse - June 2025",
            status: "scheduled",
            started_at: new Date("2025-06-01").toISOString(),
            type: "auto",
            viewpoints: [],
            created_by: null,
            updated_at: new Date("2025-06-01").toISOString(),
            completed_at: null
        }
    ] as ITouchpoint[]

    return (
        <Box>
            <CompanyTitle viewerRole={viewerRole} project={project} section="Nurture" subheading="Monthly feedback loops gather insights on the changing needs and priorities of advisors. You can also add your own feedback loop to poll advisors about new products, programs, etc. Each feedback loop prompts advisors to calendar intros and demos." />
            <NurtureGraph touchpoints={[...touchpoints, ...scheduledTouchpoints]} />
            <NurtureList section="Active" touchpoints={[
                ...touchpoints?.filter((touchpoint: any) => touchpoint.status === "active"),
                ...touchpoints?.filter((touchpoint: any) => touchpoint.status === "scheduled"),
                ...scheduledTouchpoints,
                ...touchpoints?.filter((touchpoint: any) => touchpoint.status === "draft"),
            ]} />
            <h5>Completed</h5>
            <NurtureList section="Completed" touchpoints={touchpoints?.filter((touchpoint: any) => touchpoint.status === "completed")} />

        </Box>
    )
}