import { FirmSidebar } from "../components/FirmSidebar";
import { Outlet } from "react-router-dom";
import { useFirmContext } from "../context";
import { Dialog } from "@mui/material";
import { UserProfileUpdate } from "../../users/components/UserProfileUpdate";
import { useUserContext } from "../../users/context";
import Loading from "../../../components/Loading";

export const Firm = () => {

    const { firmContext } = useFirmContext();
    const { userContext, loadUserContext } = useUserContext();

    if (!firmContext.fetched || !userContext.fetched) {
        return <Loading />;
    }

    return (
        <>
            <aside className="page-sidebar">
                <FirmSidebar />
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet />
            </main>
            {userContext.dbUser?.profile && !userContext.dbUser?.profile?.user_reviewed &&
                <Dialog open={true} onClose={() => {}}>
                    <h5>Welcome to the {firmContext.organization.name} Firm Network</h5>
                    <p>Please take a moment to review and update your profile.</p>
                    <UserProfileUpdate user={userContext.dbUser} onUpdate={loadUserContext} />
                </Dialog>
            }
        </>
    );
}