import { callExternalApi } from "../../services/external-api.service";

class CompanyHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = `${apiServerUrl}/v2`;
    }

    async getCompanies(query: string) {
        const config = {
            url: `${this._apiServerUrl}/company/suggest?q=${encodeURIComponent(query)}`,
            method: "GET",
        };

        const { data, error } = await callExternalApi({ config });

        return {
            data: data?.data || null,
            error
        }
    }
}

export default CompanyHttpClient;