import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { useOrganization } from "../hooks/useOrganization";
import { DocumentTitle } from "../../../components/DocumentTitle";

export const AdminOrganization = () => {
    const { organizationId } = useParams();
    const { fetched, organization, error, reloadOrganization } = useOrganization(organizationId ?? "");

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (!organization) return;

    return (
        <>
            <DocumentTitle pageTitle={`Admin - ${organization.name}`} />
            <div>
                <div className="admin-project-header">
                    <h4>{organization.name}</h4>
                    <div className="admin-subnav-bar">
                        <NavLink to={`/admin/organization/${organization.id}/edit`}>Setup</NavLink>
                        <NavLink to={`/admin/organization/${organization.id}`} end>Members</NavLink>
                        <NavLink to={`/admin/organization/${organization.id}/projects`}>Projects</NavLink>
                        <NavLink to={`/admin/organization/${organization.id}/domains`}>Domains</NavLink>
                    </div>
                </div>
                <div className="admin-screen">
                <Outlet context={{ organization: organization, reloadOrganization: reloadOrganization }} />
                </div>
            </div>
        </>
    );
}