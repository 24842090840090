import { useEffect, useState } from "react";
import ClientProvider from "../../../modules/ClientProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { FeaturedList } from "./FeaturedList";

export const InvestorAdvisors = ( { advisor, project }: any) => {

    const [ advisors, setAdvisors ] = useState<any>(null);

    const advisorClient = ClientProvider.provideAdvisorClient();
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        const loadViewpointActivity = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await advisorClient.getInvestorAdvisors(project.tag, accessToken);
            if (data) {
                setAdvisors(data.advisors);
            }
        }

        loadViewpointActivity();

    }, [advisor]);


    if (!advisors || advisors?.length === 0) {
        return <></>
    }

    return (
        <FeaturedList title="Soft-Circled" advisors={advisors} />
    );
}